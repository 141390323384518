import { TextProps } from '../../atoms/Text';
import { TextGroupProps } from '../../molecules/TextGroup';


type VerifyEmailBlockInternalProps = {
  stepperText?: TextProps;
  textGroup?: TextGroupProps;
  textGroup1?: TextGroupProps;
};

type VerifyEmailBlockPropsValues = {
  '': VerifyEmailBlockInternalProps;
};

const propValues: VerifyEmailBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    textGroup: {
    },
    textGroup1: {
    },
  },
};

const getProps = (type: string): VerifyEmailBlockInternalProps => {
  const values: VerifyEmailBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
