import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import { getTranslation } from '../../../lib/reactUtils';
import { UserContext } from '../../../modules/user/UserContext';
import { AutoRenewModalState, PaymentBlockCombinedProps } from './types';
import styles from './PaymentBlock.module.scss';
import { ProductListValueProps } from '../../organisms/ProductList';
import { formatPrice } from '../../../lib/utils';
import { 
  removePaymentMethodUseCase,
  sendPaymentReceiptUseCase,
  updateTeacherAnnualSubscriptionUseCase,
} from './PaymentBlock.interactor';
import { useNotification } from '../../../modules/notification';
import { NOTIFICATION_DURATION } from '../../../lib/constants';
import { GenericModalCombinedProps } from '../../organisms/GenericModal/types';
import { ModalContext } from '../../../modules/user/ModalContext';
import { PriceItem } from '../../../modules/payment/types';

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

const EMPTY_CARD_PLACEHOLDER = '-';

const usePresenter = (props: PaymentBlockCombinedProps): PaymentBlockCombinedProps => {
  const { t } = useTranslation();
  const { 
    user, defaultPaymentInfo, refetchUser, refetchDefaultPaymentMethod,
  } = useContext(UserContext);
  const { openPaymentInfoModal } = useContext(ModalContext);
  const { trigger } = useNotification();
  const [autoRenew, setAutoRenew] = useState<boolean>(false);
  const [modalState, setModalState] = useState<AutoRenewModalState>('UNSET');

  const { mutateAsync: sendPaymentReceipt } = useMutation(['sendPaymentReceipt', user], async (paymentId: string) => {
    if (user) {
      await sendPaymentReceiptUseCase(user.uuid, paymentId);
    }
  });

  const { 
    mutateAsync: updateAnnualSubscription, 
    isLoading: loadingUpdateAnnualSubscription, 
  } = useMutation([user], async () => {
    if (user) {
      return updateTeacherAnnualSubscriptionUseCase(user.uuid, {
        autoRenew: modalState === 'ENABLE_AUTO_RENEW',
      });
    }
  });

  const { 
    mutateAsync: removePaymentMethod, 
    isLoading: loadingRemoveCard, 
  } = useMutation([user], async () => {
    if (user) {
      await removePaymentMethodUseCase(user.uuid);
    }
  });

  useEffect(() => {
    if (user?.content?.subscriptionAutoRenew !== undefined) {
      setAutoRenew(user?.content?.subscriptionAutoRenew);
    }
  }, [user?.content?.subscriptionAutoRenew]);

  const handleAutoRenew = () => {
    setModalState(autoRenew ? 'DISABLE_AUTO_RENEW' : 'ENABLE_AUTO_RENEW');
  };

  const openRemoveCardModal = () => {
    setModalState('REMOVE_CARD');
  };

  const handleRemoveCard = async () => {
    try {
      await removePaymentMethod();
      await refetchDefaultPaymentMethod();
      setModalState('UNSET');
      trigger({
        type: 'Success',
        duration: NOTIFICATION_DURATION,
        message: t('notification.payment_info_updated'),
      });
    } catch (e) {
      // TODO: should show failing notification
    }
  };

  const resendReceipt = async (paymentId: string) => {
    try {
      await sendPaymentReceipt(paymentId);
      trigger({
        type: 'Success',
        message: t('payment_block.payment_history.resend_receipt_notification'),
        duration: NOTIFICATION_DURATION,
      });
    } catch {
      // no-op
    }
  };
  
  const handleResendReceiptButtonClick = async (paymentId: string) => {
    await resendReceipt(paymentId);
  };

  const productList: ProductListValueProps = {
    productItems: user?.content?.paymentRecords?.map(record => {
      const { id: paymentId, amount, date, priceItems } = record.content;
      const parsedPriceItems = typeof priceItems === 'string' && JSON.parse(priceItems) as PriceItem[];
      return {
        dateText: {
          value: new Intl.DateTimeFormat('en-US', dateFormatOptions).format(new Date(date)),
        },
        amountText: {
          value: formatPrice(amount),
        },
        productNameText: {
          value: parsedPriceItems[0].name,
        },
        button: {
          text: {
            value: t('payment_block.payment_history.button'),
          },
          icon: {
            asset: 'Email',
            colour: 'Brand',
          },
          onClick: () => handleResendReceiptButtonClick(paymentId),
        },
      };
    }),
  };

  const handleCloseModal = () => {
    setModalState('UNSET');
  };

  const handleUpdateAnnualSubscription = async () => {
    try {
      const data = await updateAnnualSubscription();
      if (data) {
        trigger({
          type: 'Success',
          duration: NOTIFICATION_DURATION,
          message: t('notification.payment_info_updated'),
        });
        setModalState('UNSET');
        await refetchUser();
      }
    } catch (e) {
      // no-op
    }
  };

  const getModalProps = (): GenericModalCombinedProps => {
    let modalType, primaryButtonHandler, secondaryButtonHandler, buttonHandler;
    switch (modalState) {
      case 'ENABLE_AUTO_RENEW':
        modalType = 'Default';
        primaryButtonHandler = handleUpdateAnnualSubscription;
        secondaryButtonHandler = handleCloseModal;
        buttonHandler = handleCloseModal;
        break;
      case 'DISABLE_AUTO_RENEW':
        modalType = 'Critical';
        primaryButtonHandler = handleUpdateAnnualSubscription;
        secondaryButtonHandler = handleCloseModal;
        buttonHandler = handleCloseModal;
        break;
      case 'REMOVE_CARD':
        modalType = 'Critical';
        primaryButtonHandler = handleRemoveCard;
        secondaryButtonHandler = handleCloseModal;
        buttonHandler = handleCloseModal;
        break;
      default:
        break;
    }
    return {
      type: modalType,
      text: {
        value: t(`payment_block.generic_modal.${modalState}.header`),
      },
      text1: {
        value: t(`payment_block.generic_modal.${modalState}.description`),
      },
      primaryButton: {
        text: {
          value: t(`payment_block.generic_modal.${modalState}.primary_button`),
        },
        loading: loadingUpdateAnnualSubscription || 
                 loadingRemoveCard ? 'Loading' : 'Default',
        onClick: primaryButtonHandler,
        disabled: loadingUpdateAnnualSubscription || loadingRemoveCard,
      },
      secondaryButton: {
        text: {
          value: t(`payment_block.generic_modal.${modalState}.secondary_button`),
        },
        onClick: secondaryButtonHandler,
      },
      button: {
        icon: {
          asset: 'Close',
        },
        onClick: buttonHandler,
      },
    };
  };

  const handlePaymentInfoClicked = () => {
    openPaymentInfoModal();
  };

  return {
    ...props,
    paymentHistorySection: {
      text: {
        value: t('payment_block.payment_history.header'),
      },
      dateText: {
        value: t('payment_block.payment_history.label.date'),
      },
      amountText: {
        value: t('payment_block.payment_history.label.amount'),
      },
      productNameText: {
        value: t('payment_block.payment_history.label.item'),
      },
      linkText: {
        value: t('payment_block.payment_history.label.link'),
      },
      productList: productList,
    },
    paymentInfoSection: {
      noPaymentInfo: !defaultPaymentInfo,
      text: {
        value: t('payment_block.payment_info.header'),
      },
      cardInfoLabelText: {
        value: t('payment_block.payment_info.card_info.label'),
      },
      cardNumberText: {
        value: defaultPaymentInfo ? t('payment_block.payment_info.card_info.value', {
          last4: defaultPaymentInfo.cardInfo?.last4,
        }) : EMPTY_CARD_PLACEHOLDER,
      },
      billingAddressLabelText: {
        value: t('payment_block.payment_info.billing_address.label'),
      },
      billingAddressText: {
        value: defaultPaymentInfo ? getTranslation('payment_block.payment_info.billing_address.value', {
          street: defaultPaymentInfo?.billingAddress?.streetAddress,
          city: defaultPaymentInfo?.billingAddress?.city,
          province: defaultPaymentInfo?.billingAddress?.province,
          postalCode: defaultPaymentInfo?.billingAddress?.postalCode,
          country: defaultPaymentInfo?.billingAddress?.country,
        }, {
          className: styles.billingAddress,
        }) : EMPTY_CARD_PLACEHOLDER,
      },
      checkboxItem: {
        state: defaultPaymentInfo && user?.content?.subscriptionId ? (autoRenew ? 'Checked' : 'Unchecked') : 'Disabled',
        text: {
          value: t('payment_block.payment_info.checkbox'),
        },
        onClick: handleAutoRenew,
        disabled: !defaultPaymentInfo || !user?.content?.subscriptionId,
      },
      button: {
        text: {
          value: t('payment_block.payment_info.button.remove_card'),
        },
        onClick: openRemoveCardModal,
      },
      button1: {
        text: {
          value: defaultPaymentInfo ? 
            t('payment_block.payment_info.button.edit_payment_info') : t('payment_block.payment_info.button.add_payment_info'),
        },
        onClick: handlePaymentInfoClicked,
      },
    },
    paymentBlockModal: {
      show: modalState !== 'UNSET',
      onHide: handleCloseModal,
      modalProps: {
        ...getModalProps(),
      },
    },
  };
};

export default usePresenter;
