import React from 'react';
import cx from 'classnames';

import CreateCourseBasicDetailsBlock from '../../blocks/CreateCourseBasicDetailsBlock';
import CreateCourseHeader from '../../organisms/CreateCourseHeader';

import styles from './CreateCourseBasicDetailsPage.module.scss';
import usePresenter from './CreateCourseBasicDetailsPage.presenter';
import getProps from './CreateCourseBasicDetailsPage.props';
import { CreateCourseBasicDetailsPageCombinedProps } from './types';
import GenericModal from '../../organisms/GenericModal';


const CreateCourseBasicDetailsPage: React.FC<CreateCourseBasicDetailsPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    createCourseHeader,
    createCourseBasicDetailsBlock,
    pageCloseWarningModal,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.createCourseBasicDetailsPage;

  return (
    <div className={cx(currentStyle, className)}>
      <CreateCourseHeader
        className={cx(styles.createCourseHeader, classes?.createCourseHeader)}
        {...internalProps.createCourseHeader}
        {...createCourseHeader}/>
      <div className={cx(styles.content, classes?.content)}>
        <CreateCourseBasicDetailsBlock
          className={cx(styles.createCourseBasicDetailsBlock, classes?.createCourseBasicDetailsBlock)}
          {...internalProps.createCourseBasicDetailsBlock}
          {...createCourseBasicDetailsBlock}/>
      </div>
      <GenericModal
        className={styles.modalWrapper}
        {...pageCloseWarningModal} />
    </div>
  );
};

export default CreateCourseBasicDetailsPage;

