import { useTranslation } from 'react-i18next';

import { getTranslation } from '../../../lib/reactUtils';
import { APP_DOWNLOAD_LINKS } from '../../../lib/constants';
import Confirmation from '../../../resources/images/NewStudentPassword.png';
import AppStore from '../../../resources/images/AppStore.png';
import GooglePlay from '../../../resources/images/GooglePlay.png';

import { ConfirmationBlockCombinedProps } from './types';

const usePresenter = (props: ConfirmationBlockCombinedProps): ConfirmationBlockCombinedProps => {
  const { t } = useTranslation();
  return {
    ...props,
    image: {
      imageSrc: Confirmation,
    },
    textGroup: {
      topText: {
        type: 'Heading3',
        align: 'Center',
        value: t('student_registration_success.header'),
      },
      bottomText: {
        align: 'Center',
        value: getTranslation('student_registration_success.description'),
      },
    },
    textGroup1: {
      topText: {
        type: 'Heading4',
        align: 'Center',
        value: t('student_registration_success.subheader'),
      },
      bottomText: {
        align: 'Center',
        value: t('student_registration_success.description2'),
      },
    },
    appStore: {
      imageSrc: AppStore,
      link: APP_DOWNLOAD_LINKS.appStore,
    },
    googlePlay: {
      imageSrc: GooglePlay,
      link: APP_DOWNLOAD_LINKS.googlePlay,
    },
  };
};

export default usePresenter;
