import { TextMessageProps } from '../../molecules/TextMessage';


type TextMessageListInternalProps = {
  textMessage?: TextMessageProps;
};

type TextMessageListPropsValues = {
  '': TextMessageListInternalProps;
};

const propValues: TextMessageListPropsValues = {
  '': {
    textMessage: {
      type: 'Teacher',
      align: 'Left',
    },
  },
};

const getProps = (type: string): TextMessageListInternalProps => {
  const values: TextMessageListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
