import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Image from '../../atoms/Image';

import styles from './GenericStateTwoBlock.module.scss';
import usePresenter from './GenericStateTwoBlock.presenter';
import getProps from './GenericStateTwoBlock.props';
import { GenericStateTwoBlockCombinedProps } from './types';


const GenericStateTwoBlock: React.FC<GenericStateTwoBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    topText,
    bottomText,
    topText1,
    bottomText1,
    button,
    button1,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.genericStateTwoBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Image
          className={cx(styles.image, classes?.image)}
          {...internalProps.image}
          {...image}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <div className={cx(styles.textGroupTop, classes?.textGroupTop)}>
          <Text
            className={cx(styles.topText, classes?.topText)}
            {...internalProps.topText}
            {...topText}/>
          <Text
            className={cx(styles.bottomText, classes?.bottomText)}
            {...internalProps.bottomText}
            {...bottomText}/>
        </div>
        <div className={cx(styles.textGroupBottom, classes?.textGroupBottom)}>
          <Text
            className={cx(styles.topText1, classes?.topText1)}
            {...internalProps.topText1}
            {...topText1}/>
          <Text
            className={cx(styles.bottomText1, classes?.bottomText1)}
            {...internalProps.bottomText1}
            {...bottomText1}/>
        </div>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
        <Button
          className={cx(styles.button1, classes?.button1)}
          {...internalProps.button1}
          {...button1}/>
      </div>
    </div>
  );
};

export default GenericStateTwoBlock;

