import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';

import styles from './PaymentInfoSection.module.scss';
import usePresenter from './PaymentInfoSection.presenter';
import getProps from './PaymentInfoSection.props';
import { PaymentInfoSectionCombinedProps } from './types';
import CheckboxItem from '../../atoms/CheckboxItem';


const PaymentInfoSection: React.FC<PaymentInfoSectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,
    cardInfoLabelText,
    cardNumberText,
    billingAddressLabelText,
    billingAddressText,
    checkboxItem,  //MANUAL OVERRIDE
    button,
    button1,
    noPaymentInfo,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.paymentInfoSection;

  const removeCardButtonView = noPaymentInfo ? undefined : (
    <Button
    className={cx(styles.button, classes?.button)}
    {...internalProps.button}
    {...button}/>
  );

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <Text
        className={cx(styles.cardInfoLabelText, classes?.cardInfoLabelText)}
        {...internalProps.cardInfoLabelText}
        {...cardInfoLabelText}/>
      <Text
        className={cx(styles.cardNumberText, classes?.cardNumberText)}
        {...internalProps.cardNumberText}
        {...cardNumberText}/>
      <Text
        className={cx(styles.billingAddressLabelText, classes?.billingAddressLabelText)}
        {...internalProps.billingAddressLabelText}
        {...billingAddressLabelText}/>
      <Text
        className={cx(styles.billingAddressText, classes?.billingAddressText)}
        {...internalProps.billingAddressText}
        {...billingAddressText}/>
      <CheckboxItem 
        className={cx(styles.checkboxItem, classes?.checkboxItem)}
        {...internalProps.checkboxItem}
        {...checkboxItem}/>
      { removeCardButtonView }
      <Button
        className={cx(styles.button1, classes?.button1)}
        {...internalProps.button1}
        {...button1}/>
    </div>
  );
};

export default PaymentInfoSection;

