import React, { useEffect, useState } from 'react';

import { ChipFieldCombinedProps, ChipFieldPresenterProps } from './types';
import { Option, RenderToken, RenderTokenProps } from 'react-bootstrap-typeahead/types/types';
import { ChipRecord, ChipCombinedProps } from '../../atoms/Chip/types';

import Chip from '../../atoms/Chip';

import styles from './ChipField.module.scss';

const usePresenter = (props: ChipFieldCombinedProps): ChipFieldPresenterProps => {
  const {
    selectedOptions,
    handleOptionRemoval,
    onInputChange,
    inputRef,
    onBlur,
    onKeyDown,
  } = props;

  const [chips, setChips] = useState(selectedOptions);

  useEffect(() => {
    if (selectedOptions) {
      setChips([...selectedOptions]);
    }
  }, [selectedOptions]);


  const handleOnRemoveChip = (index: number) => {
    if (handleOptionRemoval) {
      handleOptionRemoval(index);
    }
  };

  const handleRenderChip: RenderToken = (option: Option, renderTokenProps: RenderTokenProps, index: number) => {
    const { isPrimary } = option as ChipRecord;
    const chip : ChipCombinedProps = {
      styleType: 'White',
      size: 'L',
      type: 'TextIcon',
      state: isPrimary ? 'Blocked' : 'Default',
      error: 'None',
      className: styles.chipContainer,
      mutableChipProps: {
        option: option as ChipRecord,
        index: index,
        tokenProps: renderTokenProps,
        onRemoveChip: (chipIndex) => handleOnRemoveChip(chipIndex),
      },
      tabIndex: ((index + 1) * 2),
    };
    return <Chip {...chip} />;
  };

  return {
    ...props,
    selected: chips,
    targetRef: inputRef,
    onInputChanged: onInputChange,
    onKeyDown: onKeyDown,
    onBlur: onBlur,
    handleRenderChip: handleRenderChip,
  };
};

export default usePresenter;
