import React from 'react';
import cx from 'classnames';

import RosterBlock from '../../blocks/RosterBlock';
import CourseHeader from '../../organisms/CourseHeader';

import styles from './RosterPage.module.scss';
import usePresenter from './RosterPage.presenter';
import getProps from './RosterPage.props';
import { RosterPageCombinedProps } from './types';
import LoadingSpinner from '../../atoms/LoadingSpinner';


const RosterPage: React.FC<RosterPageCombinedProps> = (props) => {
  const {
    isLoading,
    className,
    classes,
    courseHeader,
    rosterBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.rosterPage;

  const rosterBlockView = isLoading ? (
    <LoadingSpinner
      className={cx(styles.loadingSpinner)}
      size='Huge'
      styleType='Primary'
      isActive
    />
  ) : (
    <RosterBlock
      className={cx(styles.rosterBlock, classes?.rosterBlock)}
      {...internalProps.rosterBlock}
      {...rosterBlock}/>
  );

  return (
    <div className={cx(currentStyle, className)}>
      <CourseHeader
        className={cx(styles.courseHeader, classes?.courseHeader)}
        {...internalProps.courseHeader}
        {...courseHeader}/>
      <div className={cx(styles.content, classes?.content)}>
        { rosterBlockView }
      </div>
    </div>
  );
};

export default RosterPage;

