import decode from 'jwt-decode';
import { AddressForm } from '../components/molecules/Address/types';
import { PaymentRecord } from '../modules/payment/types';
import { JourneyStatusEnum } from '../modules/teacher/types';
import { DataEntry } from './types';

interface UserInterface {
  uuid: string;
  accountId: number;
  createdAt: Date;
  updatedAt: Date;
  journeyStatus: JourneyStatusEnum;
  expiryDate: Date;
  apps: number;
  customerId: string;
  purchasedExtensions?: number;
  firstName: string;
  lastName: string;
  paymentRecords?: DataEntry<PaymentRecord>[];
  subscriptionAutoRenew?: boolean;
  subscriptionId?: string;
  roleId?: number | null;
  paidAppFee?: boolean;
  email?: string;
}

export type UserResponse = DataEntry<UserInterface & {
  profile?: string;
}>;

export type User = DataEntry<UserInterface & {
  profile?: Profile;
}>;

export type Account = {
  id: number;
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
};

export type Profile = AddressForm & {
  phoneNumber?: string;
};

export type InviteTokenPayload = {
  appId: number;
  email: string;
  exp: number;
  iat: number;
};

/**
 * Extracts payload from a jwt
 */
export const extractJwtPayload = (jwt: string): Account & {
  exp: number,
} => {
  const payload = window.atob(jwt.split('.')[1]);
  return JSON.parse(payload);
};

export const decodeInviteToken = (token: string): InviteTokenPayload | null => {
  try {
    return decode<InviteTokenPayload>(token);
  } catch {
    return null;
  }
};
