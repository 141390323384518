import React from 'react';
import cx from 'classnames';


import styles from './Divider.module.scss';
import usePresenter from './Divider.presenter';
import { DividerCombinedProps } from './types';


const Divider: React.FC<DividerCombinedProps> = (props) => {
  const {
    type,
    style,
    colour,
    className,
  } = usePresenter(props);

  const variantName = `${type}${style}${colour}`;
  const currentStyle = styles[`divider${variantName}`];

  let componentView;

  switch (variantName) {
    case 'HorizontalDottedBorderBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
        </div>
      );
      break;
    }
    case 'HorizontalSolidBorderBase':
    case 'HorizontalSolidBrand':
    case 'HorizontalSolidCritical':
    case 'HorizontalSolidInfo':
    case 'VerticalSolidBorderBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Divider;

