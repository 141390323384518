import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { 
  TIME_FORMAT_OPTIONS,
  STUDENT_ROUTES,
  PAYMENT_REQUIRED_MESSAGE,
  STUDENT_ALREADY_ENROLLED_MESSAGE,
  REGISTRATION_CLOSED_MESSAGE,
  CAPACITY_REACHED_MESSAGE,
  STUDENT_ALREADY_WAITLISTED_MESSAGE,
  FREE_LINK_SIGN_UP_JOURNEY,
  REGULAR_SIGN_UP_JOURNEY,
  FREE_LINK_AND_APPS_SIGN_IN_JOURNEY,
  FREE_LINK_NO_APPS_SIGN_IN_JOURNEY,
} from '../../../lib/constants';
import { trackSuccessfulCourseRegistrationFree } from '../../../lib/trackingUtils';
import { formatDate, getClassIcons } from '../../../lib/utils';

import { ConfirmAttendenceBlockCombinedProps } from './types';
import { ClassItemValueProps } from '../../molecules/ClassItem';
import { enrollStudentUseCase } from './ConfirmAttendenceBlock.interactor';
import { CourseStateType } from '../../../modules/course/types';

const usePresenter = (props: ConfirmAttendenceBlockCombinedProps): ConfirmAttendenceBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(true);
  const { state: {
    course,
    isNewlyRegistered,
    spotAvailableToken,
  } } = useLocation<CourseStateType>();

  const { mutateAsync: enrollStudent, isLoading } = useMutation(['enrollStudent'], async () => {
    if (course) {
      return enrollStudentUseCase(course.content.id, course.content.freeLinkToken, spotAvailableToken);
    }
  });

  const confirmEnrollment = async () => {
    try {
      const roster = await enrollStudent();
      if (roster) {
        if (course.content.freeLinkToken) {
          trackSuccessfulCourseRegistrationFree();
        }
        history.replace(STUDENT_ROUTES.auth.registrationSuccess, { course });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const classCapacityReachedRedirect = spotAvailableToken 
          ? STUDENT_ROUTES.auth.noSpotAvailable
          : STUDENT_ROUTES.auth.joinWaitlist;

        switch (error.response?.data.message) {
          case PAYMENT_REQUIRED_MESSAGE:
            history.replace(STUDENT_ROUTES.auth.payment, { course });
            break;
          case STUDENT_ALREADY_ENROLLED_MESSAGE:
          case STUDENT_ALREADY_WAITLISTED_MESSAGE:
            break;
          case REGISTRATION_CLOSED_MESSAGE:
            // no-op
            break;

          case CAPACITY_REACHED_MESSAGE:
            history.replace(classCapacityReachedRedirect, { course });
            break;

          default:
            break;
        }
      }
    }
  };

  const getClassItemsProps = useCallback((): ClassItemValueProps[] => {
    let classItems: ClassItemValueProps[] = [];
    if (course?.content.classes) {
      classItems = course.content.classes.map((c, index) => {
        const icons = getClassIcons(course.content.classFormat, c.content.location, c.content.classLink);
        return {
          text: {
            value: t('student_registration.course_detail.labels.class', { index: Number(index) + 1 }),
          },
          iconTextGroupList: {
            iconTextGroups: [
              {
                labelIcon: {
                  asset: 'Calendar',
                },
                infoText: {
                  value: formatDate(c.content.date),
                },
              },
              {
                labelIcon: {
                  asset: 'Clock',
                },
                infoText: {
                  value: `${
                    formatDate(c.content.startTime, TIME_FORMAT_OPTIONS)} - ${formatDate(c.content.endTime, TIME_FORMAT_OPTIONS)
                  }`,
                },
              },
              ...icons,
            ],
          },
        } as ClassItemValueProps;
      });
    }
    return classItems;
  }, [course, t]);

  const getCorrectProgressStep = (): number => {
    if (isNewlyRegistered) {
      return REGULAR_SIGN_UP_JOURNEY.attendance;
    }
    return FREE_LINK_AND_APPS_SIGN_IN_JOURNEY.attendance;
  };

  const getTotalStepsCorrectly = (): number => {
    if (isNewlyRegistered) {
      if (course.content.freeLinkToken) {
        return course.content.primaryTeacher.content.apps
          ? FREE_LINK_SIGN_UP_JOURNEY.total
          : REGULAR_SIGN_UP_JOURNEY.total;
      }
      return REGULAR_SIGN_UP_JOURNEY.total;
    }
    if (course.content.freeLinkToken) {
      return course.content.primaryTeacher.content.apps
        ? FREE_LINK_AND_APPS_SIGN_IN_JOURNEY.total
        : FREE_LINK_NO_APPS_SIGN_IN_JOURNEY.total;
    }
    return FREE_LINK_NO_APPS_SIGN_IN_JOURNEY.total;
  };

  return {
    ...props,
    stepperText: {
      value: t('stepperText', {
        currentStep: getCorrectProgressStep(),
        totalSteps: getTotalStepsCorrectly(),
      }),
    },
    textGroup: {
      topText: {
        value: t('student_confirm_attendance.header'),
      },
      bottomText: {
        value: t('student_confirm_attendance.description'),
      },
    },
    classList: {
      classItems: getClassItemsProps(),
    },
    checkboxItem: {
      text: {
        value: t('student_confirm_attendance.checkbox_confirm'),
      },
      onClick: () => setIsChecked(!isChecked),
      state: isChecked ? 'Checked' : 'Unchecked', 
    },
    button: {
      text: {
        value: t('student_confirm_attendance.button'),
      },
      icon: {
        asset: 'ArrowRight',
      },
      loading: isLoading ? 'Loading' : 'Default',
      disabled: !isChecked || isLoading,
      onClick: confirmEnrollment,
    },
  };
};

export default usePresenter;
