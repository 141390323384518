import { useToken } from 'react-bootstrap-typeahead';
import { ChipCombinedProps, ChipErrorEnum, ChipPresenterProps } from './types';
import React, { useState } from 'react';
import { isEmptyString } from '../../../lib/utils';

const usePresenter = (props: ChipCombinedProps): ChipPresenterProps => {
  const {
    mutableChipProps,
    onTextChanged,
  } = props;

  const { 
    option,
    tokenProps,
    index,
    onRemoveChip,
    onChangeChipValue,
  } = mutableChipProps;

  const { onRemove, ...otherProps } = useToken({ option, ...tokenProps });

  const [errorState] = useState<ChipErrorEnum>('None');
  const [inputValue, setInputValue] = React.useState<string>(option.value);

  const saveChipValue = () => {
    if (isEmptyString(inputValue)) {
      if (onRemoveChip) {
        onRemoveChip(index);
      }
    } else {
      if (onChangeChipValue && (index || index === 0)) { 
        onChangeChipValue(index, {
          value: inputValue,
          isValid: errorState === 'None',
        });
      }
    }
  };
  
  const onInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (onTextChanged) {
      onTextChanged(event.target.value);
    }
  };

  const onKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Enter':
      case 'Escape':
        saveChipValue();
        break;
      case 'Delete':
      case 'Backspace':
        if (onRemoveChip) {
          onRemoveChip(index);
        }
        break;
      default:
        break;
    }
  };

  const onBlur = () => {
    saveChipValue();
  };

  return {
    ...props,
    ...otherProps,
    inputValue: inputValue,
    text: {
      value: option.value,
    },
    error: errorState,
    button: {
      onClick: () => onRemoveChip && onRemoveChip(index),
      icon: {
        asset: 'Close',
      },
    },
    onKeyDown: onKeyDown,
    onInputChanged: onInputChanged,
    onBlur: onBlur,
  };
};

export default usePresenter;
