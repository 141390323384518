import { TextProps } from '../../atoms/Text';
import { DividerProps } from '../../atoms/Divider';


type PriceListItemInternalProps = {
  descriptionText?: TextProps;
  divider?: DividerProps;
  amountText?: TextProps;
};

type PriceListItemPropsValues = {
  'Default': PriceListItemInternalProps;
  'Total': PriceListItemInternalProps;
};

const propValues: PriceListItemPropsValues = {
  'Default': {
    descriptionText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    divider: {
      type: 'Horizontal',
      style: 'Dotted',
      colour: 'BorderBase',
    },
    amountText: {
      style: 'Regular',
      align: 'Right',
      colour: 'Default',
      type: 'Body1',
    },
  },
  'Total': {
    descriptionText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading4',
    },
    divider: {
      type: 'Horizontal',
      style: 'Dotted',
      colour: 'BorderBase',
    },
    amountText: {
      style: 'Bold',
      align: 'Right',
      colour: 'Default',
      type: 'Heading4',
    },
  },
};

const getProps = (type: string): PriceListItemInternalProps => {
  const values: PriceListItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
