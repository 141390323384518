import React, { useEffect, useRef } from 'react';
import cx from 'classnames';

import Button from '../Button';
import Icon from '../Icon';
import Text from '../Text';

import styles from './Chip.module.scss';
import usePresenter from './Chip.presenter';
import getProps from './Chip.props';
import { ChipCombinedProps } from './types';
  

// MANUAL OVERRIDE - converted to forwardRef Component
const Chip = React.forwardRef<HTMLDivElement, ChipCombinedProps>((props, ref) => {
  const {
    styleType,
    size,
    type,
    error,
    state,
    className,
    classes,
    text,
    textValue,
    textPlaceholder,
    onTextChanged,
    mutableChipProps,
    button,
    icon,
    inputValue,
    onKeyDown,
    onInputChanged,
    onClickHandler,
    onBlur,
    tabIndex,
    ...otherProps
  } = usePresenter(props);

  const variantName = `${styleType}${size}${type}${error}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`chip${variantName}`];
  let componentView;

  const inputRef = useRef<HTMLInputElement>(null);
  const sizingSpanRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current || !sizingSpanRef.current) {
      return;
    }

    const inputElement = inputRef.current;
    
    // Resize on every change
    inputElement.style.minWidth = `${sizingSpanRef.current.getBoundingClientRect().width + 8}px`;
  }, [inputRef, inputValue, sizingSpanRef, state]);
  
  switch (variantName) {
    case 'BeigeMTextNoneDefault':
    case 'BlueMTextNoneDefault':
    case 'DefaultMTextNoneDefault':
    case 'GreenMTextNoneDefault':
    case 'PinkMTextNoneDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
        </div>
      );
      break;
    }
    case 'WhiteLTextIconErrorEdit':
    case 'WhiteLTextIconNoneEdit':
    case 'OutlineWhiteLTextIconNoneEdit': {
      // MANUAL OVERRIDE
      componentView = (
        <div 
          {...otherProps}
          ref={ref}
          className={cx(currentStyle, className)}
        >
          <div className={cx(styles.chipContent, classes?.chipContent)}>
            <input
              className={cx(styles.input, classes?.input)}
              placeholder={textPlaceholder}
              value={inputValue}
              onChange={onInputChanged}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              autoFocus
              inputMode='text'
              ref={inputRef}
            />
              {/* Hidden element to calculate size of text in input */}
            <span
              className={cx(styles.input, classes?.input, styles.sizingSpan)}
              ref={sizingSpanRef}
              aria-hidden
            >{inputValue}</span>
          </div>
        </div>
      );
      break;
    }
    case 'OutlineWhiteLTextIconNoneDefault':
    case 'WhiteLTextIconNoneDefault': {
      componentView = (
        <div 
          {...otherProps} // MANUAL OVERRIDE
          ref={ref} // MANUAL OVERRIDE
          onClick={onClickHandler} // MANUAL OVERRIDE
          tabIndex={tabIndex} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}
          onKeyDown={onKeyDown}
        >
          <div className={cx(styles.chipContent, classes?.chipContent)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
        </div>
      );
      break;
    }
    case 'WhiteLTextIconErrorDefault': {
      componentView = (
        <div 
          {...otherProps} // MANUAL OVERRIDE
          ref={ref} // MANUAL OVERRIDE
          onClick={onClickHandler} // MANUAL OVERRIDE
          tabIndex={tabIndex} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}
        >
          <div className={cx(styles.chipContent, classes?.chipContent)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
        </div>
      );
      break;
    }
    case 'WhiteLTextIconNoneBlocked':
      componentView = (
        <div 
          {...otherProps}
          ref={ref}
          tabIndex={-1}
          className={cx(currentStyle, className)}
          onKeyDown={onKeyDown}
        >
          <div className={cx(styles.chipContent, classes?.chipContent)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
        </div>
      );
      break;
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
}); // MANUAL OVERRIDE

export default Chip;
