import { useTranslation } from 'react-i18next';

import ImageAsset from '../../../resources/images/NewStudentPassword.png';
import styles from './StudentUpdatedPasswordPage.module.scss';
import { StudentUpdatedPasswordPageCombinedProps } from './types';

const usePresenter = (props: StudentUpdatedPasswordPageCombinedProps): StudentUpdatedPasswordPageCombinedProps => {
  const { t } = useTranslation();
  return {
    ...props,
    genericStateNoActionBlock: {
      image: {
        imageSrc: ImageAsset,
      },
      textGroup: {
        classes: {
          bottomText: styles.bottomText,
          topText: styles.topText,
        },
        topText: {
          value: t('student_updated_password.header'),
          align: 'Center',
          type: 'Heading3',
        },
        bottomText: {
          value: t('student_updated_password.description'),
          align: 'Center',
          type: 'Body1',
        },
      },
    },
  };
};

export default usePresenter;
