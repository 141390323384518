import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type InlineNotificationInternalProps = {
  icon?: IconProps;
  text?: TextProps;
};

type InlineNotificationPropsValues = {
  'Error': InlineNotificationInternalProps;
};

const propValues: InlineNotificationPropsValues = {
  'Error': {
    icon: {
      asset: 'AlertFilled',
      colour: 'Critical',
    },
    text: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): InlineNotificationInternalProps => {
  const values: InlineNotificationInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
