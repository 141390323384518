import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';

import styles from './TextGroup.module.scss';
import usePresenter from './TextGroup.presenter';
import getProps from './TextGroup.props';
import { TextGroupCombinedProps } from './types';


const TextGroup: React.FC<TextGroupCombinedProps> = (props) => {
  const {
    className,
    classes,
    topText,
    bottomText,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.textGroup;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.topText, classes?.topText)}
        {...internalProps.topText}
        {...topText}/>
      <Text
        className={cx(styles.bottomText, classes?.bottomText)}
        {...internalProps.bottomText}
        {...bottomText}/>
    </div>
  );
};

export default TextGroup;

