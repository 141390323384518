import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type LogEntryStatusInternalProps = {
  icon?: IconProps;
  text?: TextProps;
};

type LogEntryStatusPropsValues = {
  'AwaitingReplyDefault': LogEntryStatusInternalProps;
  'AwaitingReplyInverse': LogEntryStatusInternalProps;
  'RepliedDefault': LogEntryStatusInternalProps;
  'RepliedInverse': LogEntryStatusInternalProps;
};

const propValues: LogEntryStatusPropsValues = {
  'AwaitingReplyDefault': {
    icon: {
      asset: 'ClockFilled',
      colour: 'Info',
    },
    text: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
  },
  'AwaitingReplyInverse': {
    icon: {
      asset: 'ClockFilled',
      colour: 'Inverse',
    },
    text: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  'RepliedDefault': {
    icon: {
      asset: 'RadioSelected',
      colour: 'Brand',
    },
    text: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
  },
  'RepliedInverse': {
    icon: {
      asset: 'ArrowRight',
      colour: 'Inverse',
    },
    text: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): LogEntryStatusInternalProps => {
  const values: LogEntryStatusInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
