import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { AUTH_ROUTES } from '../../../lib/constants';
import { getTranslation } from '../../../lib/reactUtils';
import styles from './AlreadyRegisteredPage.module.scss';
import { AlreadyRegisteredPageCombinedProps } from './types';

const usePresenter = (props: AlreadyRegisteredPageCombinedProps): AlreadyRegisteredPageCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();

  const goToSignin  = () => {
    history.replace(AUTH_ROUTES.signIn);
  };

  return {
    ...props,
    genericActionBlock: {
      textGroup: {
        topText: {
          value: t('already_registered_page.title'),
        },
        bottomText: {
          value: getTranslation('already_registered_page.description', {}, {
            className: styles.fontVariants,
          }),
        },
      },
      button: {
        text: {
          value: t('already_registered_page.log_in_button'),
        },
        icon: {
          asset: 'ArrowRight',
        },
        onClick: goToSignin,
      },
    },
  };
};

export default usePresenter;
