import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';

import styles from './AppFeeDetailsSection.module.scss';
import usePresenter from './AppFeeDetailsSection.presenter';
import getProps from './AppFeeDetailsSection.props';
import { AppFeeDetailsSectionCombinedProps } from './types';


const AppFeeDetailsSection: React.FC<AppFeeDetailsSectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,
    decriptionText,
    labelIcon,
    infoText,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.appFeeDetailsSection;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <Text
        className={cx(styles.decriptionText, classes?.decriptionText)}
        {...internalProps.decriptionText}
        {...decriptionText}/>
      <div className={cx(styles.iconTextGroup, classes?.iconTextGroup)}>
        <Icon
          className={cx(styles.labelIcon, classes?.labelIcon)}
          {...internalProps.labelIcon}
          {...labelIcon}/>
        <Text
          className={cx(styles.infoText, classes?.infoText)}
          {...internalProps.infoText}
          {...infoText}/>
      </div>
    </div>
  );
};

export default AppFeeDetailsSection;

