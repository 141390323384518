import { AxiosError } from 'axios';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { trackAnnualPlanPurchase } from '../../../lib/trackingUtils';
import { formatPrice, useInterval } from '../../../lib/utils';
import { getUser } from '../../../modules/auth/api';
import { STRIPE_CARD_ELEMENT_CONFIG, TEACHER_ALREADY_SUBSCRIBED } from '../../../lib/constants';

import { KORU_PRODUCT_SLUG } from '../../../modules/payment/constants';
import { ModalContext } from '../../../modules/user/ModalContext';
import { UserContext } from '../../../modules/user/UserContext';
import { CardInfoStateEnum } from '../../molecules/CardInfo/types';

import {
  createTeacherAnnualSubscriptionUseCase,
  createTeacherPaymentIntentUseCase,
  getProductDetailsUseCase,
} from './TrainingExtensionModal.interactor';
import { TrainingExtensionModalCombinedProps } from './types';

const usePresenter = (props: TrainingExtensionModalCombinedProps): TrainingExtensionModalCombinedProps => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { user, defaultPaymentInfo, updateUser } = useContext(UserContext);
  const { modalState, setModalState, openPaymentInfoModal, setConfirmationModalType } = useContext(ModalContext);

  const [cardInfoState, setCardInfoState] = useState<CardInfoStateEnum>('Display');
  const [cardError, setCardError] = useState<string | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [creditCardState, setCreditCardState] = useState<StripeCardElementChangeEvent>();
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string | undefined>(undefined);
  const [isPaymentProcessed, setIsPaymentProcessed] = useState<boolean>(false);
  const [autoRenew, setAutoRenew] = useState<boolean>(true);
  const productSlug = KORU_PRODUCT_SLUG[modalState];
  
  const fetchNewUser = useCallback(async () => {
    if (user) {
      try {
        const newUser = await getUser();
        if (newUser && user) {
          if (isPaymentProcessed && user.content.expiryDate !== newUser.content.expiryDate) {
            updateUser(newUser);
            setIsPaymentProcessing(false);
            setConfirmationModalType(modalState === 'PURCHASE_ANNUAL_PLAN' ? 'SUBSCRIPTION' : 'PURCHASE');
            setModalState('CONFIRMATION');
          }
        }
      } catch {
        // no-op
      }
    }
  }, [isPaymentProcessed, setConfirmationModalType, setModalState, updateUser, user, modalState]);

  useInterval(fetchNewUser, 5000);

  const { data: priceItem } = useQuery(['getProduct', productSlug], () => getProductDetailsUseCase(productSlug));
  const { mutateAsync: createPaymentIntent } = useMutation([user], async () => {
    if (user) {
      return createTeacherPaymentIntentUseCase(user.uuid, { 
        productSlug: productSlug,
      });
    }
  });

  const { mutateAsync: createAnnualSubscription } = useMutation([user], async () => {
    if (user) {
      return createTeacherAnnualSubscriptionUseCase({
        teacherUuid: user.uuid,
        autoRenew: autoRenew,
      });
    }
  });

  const purchaseTrainingExtension = async () => {
    const paymentIntentResponse = await createPaymentIntent();
    if (paymentIntentResponse && stripe) {
      const result = await stripe.confirmCardPayment(paymentIntentResponse.clientSecret);
      if (result.error) {
        setIsPaymentProcessing(false);
        setShowNotification(true);
        setNotificationMessage(result.error.message);
      }
      if (result.paymentIntent) {
        setIsPaymentProcessed(true);
      }
    }
  };

  const purchaseAnnualPlan = async () => {
    try {
      const subscription = await createAnnualSubscription();
      if (subscription && subscription.clientSecret && stripe) {
        const result = await stripe.confirmCardPayment(subscription.clientSecret);
        if (result.error) {
          setIsPaymentProcessing(false);
          setShowNotification(true);
          setNotificationMessage(result.error.message);
        }
        if (result.paymentIntent) {
          trackAnnualPlanPurchase();
          setIsPaymentProcessed(true);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data.message === TEACHER_ALREADY_SUBSCRIBED) {
          setNotificationMessage(t('purchase_annual_subscription_modal.error'));
          setShowNotification(true);
          setIsPaymentProcessing(false);
        }
      }
    }
  };

  useEffect(() => {
    // remove error when there is a change in payment method
    if (defaultPaymentInfo && defaultPaymentInfo.cardInfo?.last4) {
      setCardInfoState('Display');
      setCardError(undefined);
      setShowNotification(false);
      setNotificationMessage(undefined);
    }
  }, [defaultPaymentInfo]);

  const handleCloseModal = () => {
    setModalState('UNSET');
  };

  const handleChangeCardButtonClick = () => {
    openPaymentInfoModal();
  };

  const handleCardChange = (stripeEvent: StripeCardElementChangeEvent) => {
    setCreditCardState(stripeEvent);
  };

  const handleMakePaymentButtonClick = async () => {
    if (!user || !stripe || !elements) {
      return;
    }

    if (!defaultPaymentInfo || !defaultPaymentInfo.cardInfo?.last4) {
      setCardInfoState('DisplayError');
      setCardError(t('error.field_required'));
      return;
    }
    setIsPaymentProcessing(true);
    switch (modalState) {
      case 'TRAINING_EXTENSION': {
        await purchaseTrainingExtension();
        break;
      }
      case 'PURCHASE_ANNUAL_PLAN': {
        await purchaseAnnualPlan();
        break;
      }
    }
  };

  const formattedAmount =
    modalState === 'PURCHASE_ANNUAL_PLAN'
      ? t('training_extension_modal.price', {
        price: formatPrice(priceItem?.amount),
      })
      : formatPrice(priceItem?.amount);

  return {
    ...props,
    text: {
      value: modalState === 'TRAINING_EXTENSION' ? t('training_extension_modal.title') : t('purchase_annual_subscription_modal.title'),
    },
    button: {
      icon: {
        asset: 'Close',
      },
      onClick: handleCloseModal,
    },
    priceListItem: {
      descriptionText: {
        value: priceItem?.name,
      },
      amountText: {
        value: priceItem?.amount && formattedAmount,
      },
    },
    cardInfo: {
      state: cardInfoState,
      text: {
        value: defaultPaymentInfo?.cardInfo?.last4 && t('training_extension_modal.cardInfo.text', {
          last4: defaultPaymentInfo.cardInfo.last4,
        }),
      },
      button: {
        text: {
          value: t('training_extension_modal.button.change_card'),
        },
        onClick: handleChangeCardButtonClick,
      },
      labelText: {
        value: t('tuition_payment.card_info.label'),
      },
      cardElementOptions: STRIPE_CARD_ELEMENT_CONFIG,
      handleChange: handleCardChange,
      contextualContent: {
        text: {
          value: cardError,
        },
      },
    },
    primaryButton: {
      text: {
        value: t('training_extension_modal.button.purchase_now'),
      },
      loading: isPaymentProcessing ? 'Loading' : 'Default',
      disabled: isPaymentProcessing,
      onClick: handleMakePaymentButtonClick,
    },
    primaryButton1: {
      text: {
        value: t('training_extension_modal.button.cancel'),
      },
      onClick: handleCloseModal,
    },
    inlineNotification: {
      text: {
        value: notificationMessage,
      },
    },
    showNotification: showNotification,
    checkboxItem: {
      state: autoRenew ? 'Checked' : 'Unchecked',
      text: {
        value: t('purchase_annual_subscription_modal.checkbox'),
      },
      onClick: () => setAutoRenew(!autoRenew),
    },
    showCheckbox: modalState === 'PURCHASE_ANNUAL_PLAN' ? true : false,
  };
};

export default usePresenter;
