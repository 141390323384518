import { DataEntry } from '../../../lib/types';
import { Course } from '../../../modules/course/types';
import { Log } from '../../../modules/log/types';
import { Student } from '../../../modules/student/types';
import { DividerValueProps } from '../../atoms/Divider';
import { ToggleValueProps } from '../../molecules/Toggle';
import { LogEntriesCatalogCombinedProps } from '../../organisms/LogEntriesCatalog/types';
import { LogEntriesListValueProps } from '../../organisms/LogEntriesList';
import { LogEntryCombinedProps } from '../../organisms/LogEntry/types';
import { LogEntryStatusListValueProps } from '../../organisms/LogEntryStatusList';
import { ReplySectionValueProps } from '../../organisms/ReplySection';
import { TextMessageListValueProps } from '../../organisms/TextMessageList';
import { GenericStateNoActionBlockValueProps } from '../GenericStateNoActionBlock';

export type LogbookBlockClasses = {
  leftContent?: string;
  topSection?: string;
  content?: string;
  toggle?: string;
  divider?: string;
  content1?: string;
  logEntryStatusList?: string;
  divider1?: string;
  bottomSection?: string;
  logEntriesCatalog?: string;
  bottomContent?: string;
  divider2?: string;
  rightContent?: string;
  topContent?: string;
  logEntry?: string;
  textMessageList?: string;
  content2?: string;
  bottomContent1?: string;
  replySection?: string;
  noLogSelectedState?: string;
};

export type LogbookBlockValueProps = {
  toggle?: ToggleValueProps;
  divider?: DividerValueProps;
  logEntryStatusList?: LogEntryStatusListValueProps;
  divider1?: DividerValueProps;
  logEntriesList?: LogEntriesListValueProps;
  logEntriesCatalog?: LogEntriesCatalogCombinedProps;
  divider2?: DividerValueProps;
  logEntry?: LogEntryCombinedProps;
  textMessageList?: TextMessageListValueProps;
  replySection?: ReplySectionValueProps;
  course?: DataEntry<Course>; // MANUAL OVERRIDE
  isLogSelected?: boolean; // MANUAL OVERRIDE
  noLogSelectedState?: GenericStateNoActionBlockValueProps; // MANUAL OVERRIDE
};

export type LogbookBlockProps = {
  className?: string;
  classes?: LogbookBlockClasses;
  isLoading?: boolean;
};

export type LogbookBlockCombinedProps = LogbookBlockValueProps & LogbookBlockProps;

export enum LogStatusFilters {
  all = 'All',
  awaiting_reply = 'Awaiting Reply',
  replied = 'Replied',
}

export enum LogSortFilters {
  student = 'student',
  date = 'date',
}

export const logbookEntryFields = [
  'sessionDate', 'meditationDuration', 'gratefulFor', 'reflections',
  'skills', 'guidedMeditation', 'mindfulActivities',
] as const;

export enum LogbookDateTypes {
  'LeftPanel',
  'RightPanel',
  'MeditationDateTime',
}

export type StudentLogs = {
  student: DataEntry<Student>;
  hasUnreadMessages: boolean;
  logs: DataEntry<Log>[];
};
