import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextInputProps } from '../../atoms/TextInput';


type ReplySectionInternalProps = {
  divider?: DividerProps;
  textInput?: TextInputProps;
  button?: ButtonProps;
};

type ReplySectionPropsValues = {
  '': ReplySectionInternalProps;
};

const propValues: ReplySectionPropsValues = {
  '': {
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    textInput: {
      type: 'Text',
      error: 'None',
      size: 'L',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): ReplySectionInternalProps => {
  const values: ReplySectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
