import React from 'react';
import cx from 'classnames';

import ContextualContent from '../../atoms/ContextualContent';
import Text from '../../atoms/Text';
import TextArea from '../../atoms/TextArea';

import styles from './TextAreaField.module.scss';
import usePresenter from './TextAreaField.presenter';
import getProps from './TextAreaField.props';
import { TextAreaFieldCombinedProps } from './types';


const TextAreaField: React.FC<TextAreaFieldCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    text,
    textArea,
    contextualContent,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`textAreaField${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <TextArea
            className={cx(styles.textArea, classes?.textArea)}
            {...internalProps.textArea}
            {...textArea}/>
        </div>
      );
      break;
    }
    case 'Error':
    case 'Help': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <TextArea
            className={cx(styles.textArea, classes?.textArea)}
            {...internalProps.textArea}
            {...textArea}/>
          <ContextualContent
            className={cx(styles.contextualContent, classes?.contextualContent)}
            {...internalProps.contextualContent}
            {...contextualContent}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TextAreaField;

