import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import LabelTextGroupList from '../LabelTextGroupList';

import styles from './LogEntry.module.scss';
import usePresenter from './LogEntry.presenter';
import getProps from './LogEntry.props';
import { LogEntryCombinedProps } from './types';


const LogEntry: React.FC<LogEntryCombinedProps> = (props) => {
  const {
    className,
    classes,
    studentNameText,
    timeStampText,
    labelTextGroupList,
    divider,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.logEntry;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.topContent, classes?.topContent)}>
          <Text
            className={cx(styles.studentNameText, classes?.studentNameText)}
            {...internalProps.studentNameText}
            {...studentNameText}/>
          <Text
            className={cx(styles.timeStampText, classes?.timeStampText)}
            {...internalProps.timeStampText}
            {...timeStampText}/>
        </div>
        <LabelTextGroupList
          className={cx(styles.labelTextGroupList, classes?.labelTextGroupList)}
          {...internalProps.labelTextGroupList}
          {...labelTextGroupList}/>
      </div>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
    </div>
  );
};

export default LogEntry;

