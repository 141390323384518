import React from 'react';
import ModalWrapper, { ExportedModalProps } from '../../../modules/common/ModalWrapper';

import InternalTrainingExtensionModal from './TrainingExtensionModal';
import { 
  TrainingExtensionModalProps, 
  TrainingExtensionModalValueProps as InternalTrainingExtensionModalValueProps,
} from './types';

export type { TrainingExtensionModalProps };

export type TrainingExtensionModalValueProps = ExportedModalProps<InternalTrainingExtensionModalValueProps>;

const TrainingExtensionModal: React.FC<TrainingExtensionModalValueProps> = ({ className, onHide, show, modalProps }) => {
  return (
    <ModalWrapper
      className={className}
      content={<InternalTrainingExtensionModal {...modalProps} />}
      onHide={onHide}
      show={show}
    />
  );
};

export default TrainingExtensionModal;
