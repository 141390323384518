import { CoursesBlockValueProps } from '../../blocks/CoursesBlock';
import { GenericStateThreeBlockValueProps } from '../../blocks/GenericStateThreeBlock';
import { CoursesHeaderValueProps } from '../../organisms/CoursesHeader';
import { GenericModalValueProps } from '../../organisms/GenericModal';

export type CoursesPageClasses = {
  coursesHeader?: string;
  content?: string;
  coursesBlock?: string;
  genericStateThreeBlock?: string;
};

// MANUAL OVERRIDE
export type CoursesPageTypeEnum = 'Empty' | 'Active';

export type CoursesPageValueProps = {
  coursesHeader?: CoursesHeaderValueProps;
  coursesBlock?: CoursesBlockValueProps;
  genericStateThreeBlock?: GenericStateThreeBlockValueProps;
  updateRegistrationStatusWarningModal?: GenericModalValueProps; // MANUAL OVERRIDE
  archiveCourseWarningModal?: GenericModalValueProps; // MANUAL OVERRIDE
};

export type CoursesPageProps = {
  type?: CoursesPageTypeEnum; // MANUAL OVERRIDE
  className?: string;
  classes?: CoursesPageClasses;
  isLoading?: boolean;
};

export type CoursesPageCombinedProps = CoursesPageValueProps & CoursesPageProps;

export enum CourseModalState {
  hidden = 'Hidden',
  archive = 'Archive',
  updateRegistrationStatus = 'updateRegistrationStatus',
}
