import { PriceListItemProps } from '../../molecules/PriceListItem';


type PriceItemListInternalProps = {
  priceListItem?: PriceListItemProps;
};

type PriceItemListPropsValues = {
  '': PriceItemListInternalProps;
};

const propValues: PriceItemListPropsValues = {
  '': {
    priceListItem: {
      type: 'Default',
    },
  },
};

const getProps = (type: string): PriceItemListInternalProps => {
  const values: PriceItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
