import { ClassItemProps } from '../../molecules/ClassItem';


type ClassListInternalProps = {
  classItem?: ClassItemProps;
};

type ClassListPropsValues = {
  '': ClassListInternalProps;
};

const propValues: ClassListPropsValues = {
  '': {
    classItem: {
    },
  },
};

const getProps = (type: string): ClassListInternalProps => {
  const values: ClassListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
