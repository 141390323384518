import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CANADA_PROVINCES, COUNTRY_CODE_LIST, US_STATES, VALIDATION_COUNTRY_LIST } from '../../../lib/constants';
import { isEmptyString, isPostalCode } from '../../../lib/utils';
import { SelectFieldStateEnum } from '../SelectField/types';
import { AddressCombinedProps, AddressForm, SelectFields } from './types';

import styles from './Address.module.scss';
import { initAddressFormState } from './utils';

const usePresenter = (props: AddressCombinedProps): AddressCombinedProps => {
  const { t } = useTranslation();
  const { setAddressForm, setAddressFormError, setIsAddressValid, validateAddress, address, containerRef } = props;
  const [provinceState, setProvinceState] = useState<SelectFieldStateEnum>('Default');
  const [formState, setFormState] = useState<AddressForm>(address ? address : initAddressFormState);
  const [formErrorState, setFormErrorState] = useState<AddressForm>(initAddressFormState);
  const [isCountryMenuOpen, setIsCountryMenuOpen] = useState<boolean | undefined>(undefined);
  const [isStateMenuOpen, setIsStateMenuOpen] = useState<boolean | undefined>(undefined);

  const isFormValid = (shouldShowError?: boolean): boolean => {
    const invalidFormErrors: AddressForm = {
      streetAddress: '',
      country: '',
      province: '',
      city: '',
      postalCode: '',
    };
    Object.keys(initAddressFormState).forEach((field) => {
      if (isEmptyString(formState[field])) {
        invalidFormErrors[field] = t('error.field_required');
      }
      switch (field) {
        case 'province':
          // province not required for countries other than Canada and US
          if (formState.country && !(formState.country === VALIDATION_COUNTRY_LIST.canada || formState.country === VALIDATION_COUNTRY_LIST.unitedStates)) {
            invalidFormErrors[field] = '';
          }
          break;
        case 'postalCode':
          if (!isEmptyString(formState.postalCode) && !isPostalCode(formState.country, formState.postalCode)) {
            invalidFormErrors.postalCode = t('error.invalid', {
              field: 'postal code',
            });
          }
          break;
      }
    });

    if (shouldShowError) {
      setFormErrorState(invalidFormErrors);
      if (setAddressFormError) {
        setAddressFormError(invalidFormErrors);
      }
    }

    return (
      Object.values(invalidFormErrors).filter((errorMessage: string) => !!errorMessage)
        .length === 0
    );
  };

  useEffect(() => {
    if (address) {
      setFormState(address);
    }
  }, [address]);

  useEffect(() => {
    if (validateAddress !== undefined) {
      isFormValid(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateAddress]);

  useEffect(() => {
    if (setAddressForm) {
      setAddressForm(formState);
      if (setIsAddressValid) {
        setIsAddressValid(isFormValid(false));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  useEffect(() => {
    if (formState.country) {  
      setFormErrorState({
        ...formErrorState,
        country: '',
        province: '',
      });
      switch (formState.country) {
        case VALIDATION_COUNTRY_LIST.canada:
        case VALIDATION_COUNTRY_LIST.unitedStates:
        case '':
          setProvinceState('Default');
          break;
        default:
          setProvinceState('NoOptions');
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.country]);

  const handleTextChange = (field: keyof AddressForm) => {
    return (value: string) => {
      setFormState({
        ...formState,
        [field]: value,
      });

      setFormErrorState({
        ...formErrorState,
        [field]: '',
      });
    };
  };

  const handleSelectState = (field: SelectFields, selected: string) => {
    let updatedState = {
      [field]: selected === t('select.default') ? '' : selected,
    };
    if (field === 'country' && (selected === t('select.default') || selected !== formState.country)) {
      updatedState = {
        ...updatedState,
        province: '',
      };
    }
    setFormState({
      ...formState,
      ...updatedState,
    });
 
    // clear error if selected value is valid
    if (selected !== t('select.default')) {
      let updatedErrors = {
        [field]: '',
      };
      if (field === 'country' && selected !== formState.country) {
        updatedErrors = {
          ...updatedErrors,
          province: '',
        };
      }
      setFormErrorState({
        ...formErrorState,
        ...updatedErrors,
      });
    }
  };

  const renderContextualMenuItems = (field: SelectFields) => {
    let values;
    switch (field) {
      case 'country': {
        values = [t('select.default'), ...(COUNTRY_CODE_LIST.map(countryCode => countryCode.name))];
        break;
      }
      case 'province': {
        const options = (formState.country === VALIDATION_COUNTRY_LIST.canada) ? CANADA_PROVINCES : US_STATES;
        values = [t('select.default'), ...options];
        break;
      }
    }
    return values.map((value) => {
      return {
        text: {
          value: value,
        },
        onClick: () => handleSelectState(field, value),
      };
    });
  };

  return {
    ...props,
    streetAddressInputField: {
      state: formErrorState.streetAddress ? 'Error' : 'Default',
      text: {
        value: props.streetAddressInputField?.text?.value || t('complete_profile.labels.street_address'),
      },
      textInput: {
        textValue: formState.streetAddress,
        onTextChanged: handleTextChange('streetAddress'),
        maxLength: 128,
      },
      contextualContent: {
        text: {
          value: formErrorState.streetAddress,
        },
      },
    },
    selectFieldGroup: {
      selectFieldOne: {
        state: formErrorState.country ? 'Error' : 'Default',
        text: {
          value: t('complete_profile.labels.country'),
        },
        select: {
          onToggle: (show) => setIsCountryMenuOpen(show),
          toggleProps: {
            error: formErrorState.country ? 'Error' : 'None',
            text: {
              value: formState.country ? formState.country : t('select.default'),
            },
          },
          menuProps: {
            containerRef,
            contextualMenuList: {
              contextualMenuItems: renderContextualMenuItems('country'),
            },
            classes: {
              contextualMenuList: styles.contextualMenuList,
            },
            isMenuOpen: isCountryMenuOpen,
          },
        },
        contextualContent: {
          text: {
            value: formErrorState.country,
          },
        },
      },
      selectFieldTwo: {
        state: formErrorState.province ? 'Error' : provinceState,
        text: {
          value: t('complete_profile.labels.state'),
        },
        textInput: {
          textValue: formState.province ? formState.province : '',
          onTextChanged: handleTextChange('province'),
          maxLength: 64,
        },
        select: {
          onToggle: (show) => setIsStateMenuOpen(show),
          toggleProps: {
            error: formErrorState.province ? 'Error' : 'None',
            disabled: isEmptyString(formState.country),
            text: {
              value: formState.province ? formState.province : t('select.default'),
            },
          },
          menuProps: {
            containerRef,
            contextualMenuList: {
              contextualMenuItems: renderContextualMenuItems('province'),
            },
            classes: {
              contextualMenuList: styles.contextualMenuList,
            },
            isMenuOpen: isStateMenuOpen,
          },
        },
        contextualContent: {
          text: {
            value: formErrorState.province,
          },
        },
      },
    },
    inputFieldGroup: {
      inputFieldOne: {
        state: formErrorState.city ? 'Error' : 'Default',
        text: {
          value: t('complete_profile.labels.city'),
        },
        textInput: {
          textValue: formState.city,
          onTextChanged: handleTextChange('city'),
          maxLength: 64,
        },
        contextualContent: {
          text: {
            value: formErrorState.city,
          },
        },
      },
      inputFieldTwo: {
        state: formErrorState.postalCode ? 'Error' : 'Default',
        text: {
          value: t('complete_profile.labels.postal_code'),
        },
        textInput: {
          textValue: formState?.postalCode,
          onTextChanged: handleTextChange('postalCode'),
          maxLength: 32,
        },
        contextualContent: {
          text: {
            value: formErrorState.postalCode,
          },
        },
      },
    },
  };
};

export default usePresenter;
