import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type ButtonInternalProps = {
  icon?: IconProps;
  text?: TextProps;
};

type ButtonPropsValues = {
  'ContainedLargeIconTextPrimaryActionDefault': ButtonInternalProps;
  'ContainedLargeTextCriticalDefault': ButtonInternalProps;
  'ContainedLargeTextPrimaryActionDefault': ButtonInternalProps;
  'ContainedLargeTextPrimaryActionLoading': ButtonInternalProps;
  'ContainedLargeTextIconPrimaryActionDefault': ButtonInternalProps;
  'ContainedLargeTextIconPrimaryActionLoading': ButtonInternalProps;
  'OutlineLargeTextCriticalDefault': ButtonInternalProps;
  'OutlineLargeTextPrimaryActionDefault': ButtonInternalProps;
  'TextSmallIconBaseDefault': ButtonInternalProps;
  'TextSmallIconPrimaryActionDefault': ButtonInternalProps;
  'TextSmallIconTextPrimaryActionDefault': ButtonInternalProps;
  'TextSmallTextPrimaryActionDefault': ButtonInternalProps;
  'TextSmallTextIconBaseDefault': ButtonInternalProps;
  'TextSmallTextIconPrimaryActionDefault': ButtonInternalProps;
  'ContainedLargeTextIconCriticalLoading': ButtonInternalProps;
  'ContainedLargeTextCriticalLoading': ButtonInternalProps;
};

const propValues: ButtonPropsValues = {
  'ContainedLargeIconTextPrimaryActionDefault': {
    icon: {
      asset: 'AddSquare',
      colour: 'Inverse',
    },
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Label1',
    },
  },
  'ContainedLargeTextCriticalDefault': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Label1',
    },
  },
  'ContainedLargeTextPrimaryActionDefault': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Label1',
    },
  },
  'ContainedLargeTextPrimaryActionLoading': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Label1',
    },
  },
  'ContainedLargeTextIconPrimaryActionDefault': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Label1',
    },
    icon: {
      asset: 'AddSquare',
      colour: 'Inverse',
    },
  },
  'ContainedLargeTextIconPrimaryActionLoading': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Label1',
    },
    icon: {
      asset: 'AddSquare',
      colour: 'Inverse',
    },
  },
  'OutlineLargeTextCriticalDefault': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Critical',
      type: 'Label1',
    },
  },
  'OutlineLargeTextPrimaryActionDefault': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Info',
      type: 'Label1',
    },
  },
  'TextSmallIconBaseDefault': {
    icon: {
      asset: 'ArrowLeft',
      colour: 'Base',
    },
  },
  'TextSmallIconPrimaryActionDefault': {
    icon: {
      asset: 'AddSquare',
      colour: 'Info',
    },
  },
  'TextSmallIconTextPrimaryActionDefault': {
    icon: {
      asset: 'AddSquare',
      colour: 'Info',
    },
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Info',
      type: 'Label1',
    },
  },
  'TextSmallTextPrimaryActionDefault': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Info',
      type: 'Label1',
    },
  },
  'TextSmallTextIconBaseDefault': {
    icon: {
      asset: 'ArrowLeft',
      colour: 'Base',
    },
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Default',
      type: 'Label1',
    },
  },
  'TextSmallTextIconPrimaryActionDefault': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Info',
      type: 'Label1',
    },
    icon: {
      asset: 'AddSquare',
      colour: 'Info',
    },
  },
  'ContainedLargeTextIconCriticalLoading': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Label1',
    },
  },
  'ContainedLargeTextCriticalLoading': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Label1',
    },
  },
};

const getProps = (type: string): ButtonInternalProps => {
  const values: ButtonInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
