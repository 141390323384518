import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import TextInput from '../../atoms/TextInput';

import styles from './ReplySection.module.scss';
import usePresenter from './ReplySection.presenter';
import getProps from './ReplySection.props';
import { ReplySectionCombinedProps } from './types';


const ReplySection: React.FC<ReplySectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    divider,
    textInput,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.replySection;

  return (
    <div className={cx(currentStyle, className)}>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
      <div className={cx(styles.content, classes?.content)}>
        <TextInput
          className={cx(styles.textInput, classes?.textInput)}
          {...internalProps.textInput}
          {...textInput}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
    </div>
  );
};

export default ReplySection;

