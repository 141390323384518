import React from 'react';
import cx from 'classnames';

import StudentAppSection from '../../organisms/StudentAppSection';

import styles from './StudentAppBlock.module.scss';
import usePresenter from './StudentAppBlock.presenter';
import getProps from './StudentAppBlock.props';
import { StudentAppBlockCombinedProps } from './types';


const StudentAppBlock: React.FC<StudentAppBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    studentAppSection,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.studentAppBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.leftContent, classes?.leftContent)}>
        <StudentAppSection
          className={cx(styles.studentAppSection, classes?.studentAppSection)}
          {...internalProps.studentAppSection}
          {...studentAppSection}/>
      </div>
      <div className={cx(styles.rightContent, classes?.rightContent)}/>
    </div>
  );
};

export default StudentAppBlock;
