import React from 'react';
import cx from 'classnames';

import ContextualMenuList from '../ContextualMenuList';

import styles from './ContextualMenu.module.scss';
import usePresenter from './ContextualMenu.presenter';
import getProps from './ContextualMenu.props';
import { ContextualMenuCombinedProps } from './types';


// MANUAL OVERRIDE - converted to forwardRef
const ContextualMenu = React.forwardRef<HTMLDivElement, ContextualMenuCombinedProps>((props, ref) => {
  const {
    className,
    classes,
    contextualMenuList,
    isMenuOpen, // MANUAL OVERRIDE
    containerRef, // MANUAL OVERRIDE
    ...otherProps // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.contextualMenu;

  return (
    <div
      ref={ref} // MANUAL OVERRIDE
      {...otherProps} // MANUAL OVERRIDE
      className={cx(currentStyle, className)}>
      <ContextualMenuList
        isMenuOpen={isMenuOpen} // MANUAL OVERRIDE
        className={cx(styles.contextualMenuList, classes?.contextualMenuList)}
        {...internalProps.contextualMenuList}
        {...contextualMenuList}/>
    </div>
  );
});

export default ContextualMenu;

