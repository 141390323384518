import { ContextualMenuListProps } from '../ContextualMenuList';


type ContextualMenuInternalProps = {
  contextualMenuList?: ContextualMenuListProps;
};

type ContextualMenuPropsValues = {
  '': ContextualMenuInternalProps;
};

const propValues: ContextualMenuPropsValues = {
  '': {
    contextualMenuList: {
    },
  },
};

const getProps = (type: string): ContextualMenuInternalProps => {
  const values: ContextualMenuInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
