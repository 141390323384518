import React from 'react';
import cx from 'classnames';

import Text from '../Text';

import styles from './Tab.module.scss';
import usePresenter from './Tab.presenter';
import getProps from './Tab.props';
import { TabCombinedProps } from './types';


const Tab: React.FC<TabCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    onClick,
    text,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`tab${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Selected':
    case 'Unselected': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
          <div className={cx(styles.div, classes?.div)}/>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Tab;

