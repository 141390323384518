import axiosInstance from '../../lib/api/axios';
import { BFF_URL } from '../../lib/config';
import { UpdatePasswordPayload } from './types';

export const updatePassword = async (payload: UpdatePasswordPayload): Promise<void> => {
  await axiosInstance.patch(`${BFF_URL}/auth/updatePassword`, payload);
};

export const forgotPassword = async (email: string): Promise<void> => {
  await axiosInstance.post(`${BFF_URL}/auth/forgotPassword`, { email: email.trim() });
};

export const resendVerificationEmail = async (email: string): Promise<void> => {
  await axiosInstance.post(`${BFF_URL}/auth/resendVerifyEmail`, { email: email.trim() });
};
