import React from 'react';
import cx from 'classnames';

import styles from './LoadingSpinner.module.scss';
import usePresenter from './LoadingSpinner.presenter';
import { LoadingSpinnerCombinedProps } from './types';
import Text from '../Text';


const LoadingSpinner: React.FC<LoadingSpinnerCombinedProps> = (props) => {
  const { className, classes, styleType, size, isActive = true } = usePresenter(props);
  const variantName = `${size}${styleType}`;

  const containerStyle = styles.loadingSpinnerContainer;
  const baseStyle = styles.loadingSpinner;
  const sizeStyle = styles[`loadingSpinner${size}`];
  const styleStyle = styles[`loadingSpinner${styleType}`];

  if (!isActive) {
    return null;
  }

  let componentView: React.ReactElement;

  switch (variantName) {
    case 'SmallPrimary':
    case 'SmallWhite':
    case 'SmallBlack':
    case 'LargePrimary':
    case 'LargeWhite':
    case 'LargeBlack':
      componentView = (
        <div className={cx(baseStyle, sizeStyle, styleStyle, className)}>
          <svg className={cx(styles.spinner, classes?.spinner)} viewBox="0 0 50 50">
            <circle
              className={cx(styles.path, classes?.path)}
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="7"
            ></circle>
          </svg>
        </div>
      );
      break;
    case 'HugePrimary':
      componentView = (
        <div className={containerStyle}>
          <div className={cx(baseStyle, sizeStyle, styleStyle)}>
            <svg className={cx(styles.spinner, classes?.spinner)} viewBox="0 0 50 50">
              <circle
                className={cx(styles.path, classes?.path)}
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="3"
              ></circle>
            </svg>
          </div>
          <Text
            className={styles.loadingText}
            style='Regular'
            align='Center'
            colour='Info'
            type='Label1'
            value='Loading...' />
        </div>
      );
      break;
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default LoadingSpinner;
