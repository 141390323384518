import React from 'react';
import Dropdown from 'react-overlays/Dropdown';

import { DropdownWrapperProps } from './types';

const DropdownWrapper: React.FC<DropdownWrapperProps> = (props) => {
  const { 
    id, 
    show,
    drop,
    alignEnd,
    onToggle,
    Toggle, 
    toggleProps, 
    Menu, 
    menuProps,
    offset,
    className,
  } = props;

  const handleToggle = (nextShow: boolean, event?: React.SyntheticEvent | Event) => {
    if (onToggle) {
      onToggle(nextShow, event);
    }
  };

  return (
    <Dropdown
      show={show}
      onToggle={handleToggle}
      drop={drop}
      alignEnd={alignEnd}
    >
        <div style={{ position: 'relative', display: 'inline-block' }} className={className}>
          <Toggle {...toggleProps}/>
          <Menu {...menuProps} offset={offset}/>
        </div>
    </Dropdown>
  );
};

export default DropdownWrapper;
