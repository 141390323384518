import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router';

import { COURSE_ROUTES } from '../../../lib/constants';
import { useValidateTeacher } from '../../../lib/utils';
import { AuthContext } from '../../../modules/auth';
import { TabStateEnum } from '../../atoms/Tab/types';
import { getCourseByIdUseCase } from './LogbookPage.interactor';

import { LogbookPageCombinedProps } from './types';

const usePresenter = (props: LogbookPageCombinedProps): LogbookPageCombinedProps => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const { account } = useContext(AuthContext);
  const { id } = useParams<{ id?: string }>();

  const { data: course, isLoading } = useQuery(['getCourseById', id], () => getCourseByIdUseCase(Number(id)));

  useValidateTeacher(account, course);

  const getTabState = (tabPathname: string): TabStateEnum => {
    return pathname === tabPathname ? 'Selected' : 'Unselected';
  };

  return {
    ...props,
    isLoading,
    courseHeader: {
      text: {
        value: course?.content?.title,
      },
      button: {
        text: {
          value: t('course_header.title'),
        },
        onClick: () => history.push(COURSE_ROUTES.active),
      },
      tabsList: {
        tabs: [
          {
            text: {
              value: t('course_header.tablist.details'),
            },
            onClick: () => history.push(`/courses/detail/${id}`),
          },
          {
            text: {
              value: t('course_header.tablist.roster'),
            },
            onClick: () => history.push(`/courses/detail/${id}/roster`),
          },
          {
            text: {
              value: t('course_header.tablist.logbook'),
            },
            state: getTabState(`/courses/detail/${id}/logbook`),
            onClick: () => history.push(`/courses/detail/${id}/logbook`),
          },
        ],
      },
    },
    logbookBlock: {
      course,
    },
  };
};

export default usePresenter;
