import { ContextualMenuListCombinedProps } from './types';

const usePresenter = (props: ContextualMenuListCombinedProps): ContextualMenuListCombinedProps => {
  const { toggle } = props;

  const items = props.contextualMenuItems?.map((item) => {
    return {
      ...item,
      onClick: (event) => {
        event.preventDefault();
        if (toggle) {
          toggle(false);
        }
        if (item.onClick) {
          item.onClick(event);
        }
      },
    };
  });
  
  return {
    ...props,
    contextualMenuItems: items,
  };
};

export default usePresenter;
