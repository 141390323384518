import { ButtonProps } from '../../atoms/Button';
import { CheckboxItemProps } from '../../atoms/CheckboxItem';
import { TextProps } from '../../atoms/Text';
import { InputFieldProps } from '../../molecules/InputField';
import { InputFieldGroupProps } from '../../molecules/InputFieldGroup';
import { TextGroupProps } from '../../molecules/TextGroup';


type StudentSignupBlockInternalProps = {
  stepperText?: TextProps;
  textGroup?: TextGroupProps;
  inputFieldGroup?: InputFieldGroupProps;
  emailInputField?: InputFieldProps;
  phoneNumberInputField?: InputFieldProps;
  passwordInputField?: InputFieldProps;
  confirmPasswordInputField?: InputFieldProps;
  checkboxItem?: CheckboxItemProps;
  button?: ButtonProps;
  legalText?: TextProps;
};

type StudentSignupBlockPropsValues = {
  '': StudentSignupBlockInternalProps;
};

const propValues: StudentSignupBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    textGroup: {
    },
    inputFieldGroup: {
    },
    emailInputField: {
      state: 'Default',
    },
    phoneNumberInputField: {
      state: 'Default',
    },
    passwordInputField: {
      state: 'Default',
    },
    confirmPasswordInputField: {
      state: 'Default',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    legalText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): StudentSignupBlockInternalProps => {
  const values: StudentSignupBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
