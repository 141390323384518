import React from 'react';
import cx from 'classnames';

import { Typeahead } from 'react-bootstrap-typeahead';
import ContextualContent from '../../atoms/ContextualContent';
import Text from '../../atoms/Text';

import './Typeahead.css';
import styles from './ChipField.module.scss';
import usePresenter from './ChipField.presenter';
import getProps from './ChipField.props';
import { ChipFieldCombinedProps } from './types';

// MANUAL OVERRIDE
const ChipField: React.FC<ChipFieldCombinedProps> = (props) => {
  const {
    error,
    className,
    classes,
    label,
    contextualContent,
    allowNew,
    multiSelect,
    options,
    onKeyDown,
    onInputChanged,
    onFocus,
    onBlur,
    handleRenderChip,
    open,
    selected,
    targetRef,
    autoFocus,
  } = usePresenter(props);

  const variantName = `${error}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`chipField${variantName}`];

  let componentView;

  const typeaheadView = (
    <Typeahead 
      labelKey="value"
      id="typeahead"
      className='typeahead'
      allowNew={allowNew}
      multiple={multiSelect}
      options={options || []}
      selected={selected}
      onChange={() => {}}
      open={open}
      onKeyDown={onKeyDown}
      renderToken={handleRenderChip}
      onInputChange={onInputChanged}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={targetRef}
      autoFocus={autoFocus}
    />
  );

  switch (variantName) {
    case 'Error': {
      // MANUAL OVERRIDE
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.label, classes?.label)}
            {...internalProps.label}
            {...label}/>
          <div className={cx(styles.chipInput, classes?.chipInput)}>
            {typeaheadView}
          </div>
          <ContextualContent
            className={cx(styles.contextualContent, classes?.contextualContent)}
            {...internalProps.contextualContent}
            {...contextualContent}/>
        </div>
      );
      break;
    }
    case 'None': {
      // MANUAL OVERRIDE
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text className={cx(styles.label, classes?.label)}
            {...internalProps.label}
            {...label}/>
          <div className={cx(styles.chipInput, classes?.chipInput)}>
            {typeaheadView}
          </div>
          <ContextualContent
            className={cx(styles.contextualContent, classes?.contextualContent)}
            {...internalProps.contextualContent}
            {...contextualContent}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ChipField;
