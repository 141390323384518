import { MyProfileBlockProps } from '../../blocks/MyProfileBlock';
import { PaymentBlockProps } from '../../blocks/PaymentBlock';
import { StudentAppBlockProps } from '../../blocks/StudentAppBlock';
import { MyAccountHeaderProps } from '../../organisms/MyAccountHeader';


type MyProfilePageInternalProps = {
  myAccountHeader?: MyAccountHeaderProps;
  myProfileBlock?: MyProfileBlockProps;
  studentAppBlock?: StudentAppBlockProps;
  paymentBlock?: PaymentBlockProps;
};

type MyProfilePagePropsValues = {
  '': MyProfilePageInternalProps;
};

const propValues: MyProfilePagePropsValues = {
  '': {
    myAccountHeader: {
    },
    myProfileBlock: {
    },
    studentAppBlock: {
    },
    paymentBlock: {
    },
  },
};

const getProps = (type: string): MyProfilePageInternalProps => {
  const values: MyProfilePageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
