import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { KORU_DASHBOARD_URL } from '../../../lib/config';
import { ACCOUNT_STATUS_ROUTE, DASHBOARD_ROUTE } from '../../../lib/constants';
import { getIsAccessRestricted } from '../../../lib/utils';
import { UserContext } from '../../../modules/user/UserContext';
import { TabStateEnum } from '../../atoms/Tab/types';

import { MyProfilePageCombinedProps } from './types';

const usePresenter = (props: MyProfilePageCombinedProps): MyProfilePageCombinedProps => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const { user, isExpired, isLoading } = useContext(UserContext);
  const getTabState = (tabPathname: string): TabStateEnum => {
    return pathname === tabPathname ? 'Selected' : 'Unselected';
  };

  const handleCloseMyAccount = () => {
    if (user) {
      const isRestricted = getIsAccessRestricted(isExpired, user);
      if (isRestricted) {
        history.push(ACCOUNT_STATUS_ROUTE.subscriptionExpired);
      } else {
        window.location.assign(KORU_DASHBOARD_URL);
      }
    }
  };

  return {
    ...props,
    isLoading,
    myAccountHeader: {
      text: {
        value: t('account_header.title'),
      },
      button: {
        text: {
          value: t('account_header.button'),
        },
        icon: {
          asset: 'Close',
        },
        onClick: handleCloseMyAccount,
      },
      tabsList: {
        tabs: [
          {
            text: {
              value: t('account_header.tablist.my_profile'),
            },
            state: getTabState(DASHBOARD_ROUTE.profile),
            onClick: () => history.push(DASHBOARD_ROUTE.profile),
          },
          {
            text: {
              value: t('account_header.tablist.student_apps'),
            },
            state: getTabState(DASHBOARD_ROUTE.studentApps),
            onClick: () => history.push(DASHBOARD_ROUTE.studentApps),
          },
          {
            text: {
              value: t('account_header.tablist.payment'),
            },
            state: getTabState(DASHBOARD_ROUTE.payment),
            onClick: () => history.push(DASHBOARD_ROUTE.payment),
          },
        ],
      },
    },
  };
};

export default usePresenter;
