import { StudentItemProps } from '../../molecules/StudentItem';


type StudentsListInternalProps = {
  studentItem?: StudentItemProps;
};

type StudentsListPropsValues = {
  '': StudentsListInternalProps;
};

const propValues: StudentsListPropsValues = {
  '': {
    studentItem: {
    },
  },
};

const getProps = (type: string): StudentsListInternalProps => {
  const values: StudentsListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
