import React from 'react';
import cx from 'classnames';

import LogEntriesAccordianList from '../LogEntriesAccordianList';
import LogEntriesList from '../LogEntriesList';

import styles from './LogEntriesCatalog.module.scss';
import usePresenter from './LogEntriesCatalog.presenter';
import getProps from './LogEntriesCatalog.props';
import { LogEntriesCatalogCombinedProps } from './types';


const LogEntriesCatalog: React.FC<LogEntriesCatalogCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    logEntriesList,
    logEntriesAccordianList,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`logEntriesCatalog${variantName}`];

  let componentView;

  switch (variantName) {
    case 'ByDate': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LogEntriesList
            className={cx(styles.logEntriesList, classes?.logEntriesList)}
            {...internalProps.logEntriesList}
            {...logEntriesList}/>
        </div>
      );
      break;
    }
    case 'ByStudent': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LogEntriesAccordianList
            className={cx(styles.logEntriesAccordianList, classes?.logEntriesAccordianList)}
            {...internalProps.logEntriesAccordianList}
            {...logEntriesAccordianList}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default LogEntriesCatalog;

