import { ConfirmationBlockProps } from '../../blocks/ConfirmationBlock';


type RegistrationSuccessPageInternalProps = {
  confirmationBlock?: ConfirmationBlockProps;
};

type RegistrationSuccessPagePropsValues = {
  '': RegistrationSuccessPageInternalProps;
};

const propValues: RegistrationSuccessPagePropsValues = {
  '': {
    confirmationBlock: {
    },
  },
};

const getProps = (type: string): RegistrationSuccessPageInternalProps => {
  const values: RegistrationSuccessPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
