import { DataEntry } from '../../../lib/types';
import { getCourseById, getCourses, updateCourse } from '../../../modules/course/api';
import { Course, CourseState, UpdateCoursePayload } from '../../../modules/course/types';

export const getCoursesUseCase = async (courseState: CourseState): Promise<DataEntry<Course>[]> => {
  return getCourses(courseState);
};

export const updateCourseUseCase = async (
  courseId: number, payload: UpdateCoursePayload,
): Promise<DataEntry<Course>> => {
  return updateCourse(courseId, payload);
};

export const getCourseByIdUseCase = async (courseId: number): Promise<DataEntry<Course>> => {
  return getCourseById(courseId);
};