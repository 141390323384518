import { DataEntry } from '../../../lib/types';
import { getCourseById, validateCourseFreeLinkToken } from '../../../modules/course/api';
import { Course, CourseFreeLinkToken } from '../../../modules/course/types';

export const getCourseByIdUseCase = async (courseId: number): Promise<DataEntry<Course>> => {
  return getCourseById(courseId);
};

export const validateCourseFreeLinkTokenUseCase = async (id: number, token: string): Promise<CourseFreeLinkToken> => {
  return validateCourseFreeLinkToken(id, token);
};
