import { ClassFormCardProps } from '../../molecules/ClassFormCard';


type ClassCardsListInternalProps = {
  classFormCard?: ClassFormCardProps;
};

type ClassCardsListPropsValues = {
  '': ClassCardsListInternalProps;
};

const propValues: ClassCardsListPropsValues = {
  '': {
    classFormCard: {
      type: 'InPerson',
    },
  },
};

const getProps = (type: string): ClassCardsListInternalProps => {
  const values: ClassCardsListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
