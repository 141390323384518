import React from 'react';
import cx from 'classnames';

import StudentListSection from '../../organisms/StudentListSection';
import ReminderEmailsSection from '../../organisms/ReminderEmailsSection';

import styles from './RosterBlock.module.scss';
import usePresenter from './RosterBlock.presenter';
import getProps from './RosterBlock.props';
import { RosterBlockCombinedProps } from './types';
import GenericModal from '../../organisms/GenericModal';
import LoadingSpinner from '../../atoms/LoadingSpinner';
import CopyStudentsEmailSection from '../../organisms/CopyStudentsEmailSection';

const RosterBlock: React.FC<RosterBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    enrolledStudentsSection,
    waitlistSection,
    removedStudentsSection,
    reminderEmailsSection,
    genericModal,
    isLoading,
    copyStudentsEmailSection,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.rosterBlock;
  const spinner = (
    <LoadingSpinner
      className={cx(styles.loadingSpinner)}
      size='Huge'
      styleType='Primary'
      isActive
    />
  );

  return isLoading ? spinner : (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.leftContent, classes?.leftContent)}>
        <StudentListSection
          className={cx(styles.enrolledStudentsSection, classes?.enrolledStudentsSection)}
          {...internalProps.enrolledStudentsSection}
          {...enrolledStudentsSection}/>
        <StudentListSection
          className={cx(styles.waitlistSection, classes?.waitlistSection)}
          {...internalProps.waitlistSection}
          {...waitlistSection}/>
        <StudentListSection
          className={cx(styles.removedStudentsSection, classes?.removedStudentsSection)}
          {...internalProps.removedStudentsSection}
          {...removedStudentsSection}/>
      </div>
      <div className={cx(styles.rightContent, classes?.rightContent)}>
        <ReminderEmailsSection
          className={cx(styles.reminderEmailsSection, classes?.reminderEmailsSection)}
          {...internalProps.reminderEmailsSection}
          {...reminderEmailsSection}/>
        <CopyStudentsEmailSection
          className={cx(styles.copyStudentsEmailSection, classes?.copyStudentsEmailSection)}
          {...internalProps.copyStudentsEmailSection}
          {...copyStudentsEmailSection}/>
      </div>
      <GenericModal
        className={styles.modalWrapper}
        {...genericModal} />
    </div>
  );
};

export default RosterBlock;

