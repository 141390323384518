import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type CheckboxItemInternalProps = {
  icon?: IconProps;
  text?: TextProps;
};

type CheckboxItemPropsValues = {
  'Checked': CheckboxItemInternalProps;
  'Unchecked': CheckboxItemInternalProps;
  'Disabled': CheckboxItemInternalProps;
};

const propValues: CheckboxItemPropsValues = {
  'Checked': {
    icon: {
      asset: 'CheckboxChecked',
      colour: 'Info',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
  'Unchecked': {
    icon: {
      asset: 'CheckboxUnchecked',
      colour: 'Info',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
  'Disabled': {
    icon: {
      asset: 'CheckboxUnchecked',
      colour: 'Disabled',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Disabled',
      type: 'Body1',
    },
  },
};

const getProps = (type: string): CheckboxItemInternalProps => {
  const values: CheckboxItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
