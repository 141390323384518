import { ButtonProps } from '../../atoms/Button';
import { TextGroupProps } from '../../molecules/TextGroup';


type GenericActionBlockInternalProps = {
  textGroup?: TextGroupProps;
  button?: ButtonProps;
};

type GenericActionBlockPropsValues = {
  '': GenericActionBlockInternalProps;
};

const propValues: GenericActionBlockPropsValues = {
  '': {
    textGroup: {
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): GenericActionBlockInternalProps => {
  const values: GenericActionBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
