import { RadioItemProps } from '../../atoms/RadioItem';


type RadioItemListInternalProps = {
  radioItem?: RadioItemProps;
};

type RadioItemListPropsValues = {
  'Horizontal': RadioItemListInternalProps;
  'Vertical': RadioItemListInternalProps;
};

const propValues: RadioItemListPropsValues = {
  'Horizontal': {
    radioItem: {
      state: 'Unchecked',
    },
  },
  'Vertical': {
    radioItem: {
      state: 'Unchecked',
    },
  },
};

const getProps = (type: string): RadioItemListInternalProps => {
  const values: RadioItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
