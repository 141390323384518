import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { RadioItemListProps } from '../RadioItemList';


type RegistrationStatusSectionInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  radioItemList?: RadioItemListProps;
};

type RegistrationStatusSectionPropsValues = {
  '': RegistrationStatusSectionInternalProps;
};

const propValues: RegistrationStatusSectionPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    radioItemList: {
      type: 'Vertical',
    },
  },
};

const getProps = (type: string): RegistrationStatusSectionInternalProps => {
  const values: RegistrationStatusSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
