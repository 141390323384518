import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import KoruLogo from '../../atoms/KoruLogo';
import Text from '../../atoms/Text';
import UserProfile from '../../molecules/UserProfile';

import styles from './Header.module.scss';
import usePresenter from './Header.presenter';
import getProps from './Header.props';
import { HeaderCombinedProps } from './types';


const Header: React.FC<HeaderCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    koruLogo,
    divider,
    text,
    icon,
    userProfile,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`header${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.leftContent, classes?.leftContent)}>
              <KoruLogo
                className={cx(styles.koruLogo, classes?.koruLogo)}
                {...internalProps.koruLogo}
                {...koruLogo}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'Search': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.leftContent, classes?.leftContent)}>
              <Text
                className={cx(styles.text, classes?.text)}
                {...internalProps.text}
                {...text}/>
            </div>
            <div className={cx(styles.rightContent, classes?.rightContent)}>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'SearchIcon': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.leftContent, classes?.leftContent)}>
              <KoruLogo
                className={cx(styles.koruLogo, classes?.koruLogo)}
                {...internalProps.koruLogo}
                {...koruLogo}/>
            </div>
            <div className={cx(styles.rightContent, classes?.rightContent)}>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}/>
              <UserProfile
                className={cx(styles.userProfile, classes?.userProfile)}
                {...internalProps.userProfile}
                {...userProfile}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'WithUserProfile': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.leftContent, classes?.leftContent)}>
              <KoruLogo
                className={cx(styles.koruLogo, classes?.koruLogo)}
                {...internalProps.koruLogo}
                {...koruLogo}/>
            </div>
            <div className={cx(styles.rightContent, classes?.rightContent)}>
              <UserProfile
                className={cx(styles.userProfile, classes?.userProfile)}
                {...internalProps.userProfile}
                {...userProfile}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Header;

