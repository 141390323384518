import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { logout } from '../../modules/auth/utils';
import { PUBLIC_ROUTES } from '../constants';

const baseCourseRegistrationPathRegex = new RegExp('\\bcourseRegistration\\b');

const instance = axios.create({ withCredentials: true });

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => config;

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // log out unauthorized user
  // invalid token would throw 401 so we should validate the path is course registration path
  const isCourseRegistrationPath = baseCourseRegistrationPathRegex.test(window.location.pathname);
  if (error.response?.status === 401 && !PUBLIC_ROUTES.includes(window.location.pathname)) {
    if (!isCourseRegistrationPath) {
      logout();
    }
  }

  return Promise.reject(error);
};

instance.interceptors.request.use(onRequest, onRequestError);
instance.interceptors.response.use(onResponse, onResponseError);

export default instance;
