import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type RadioItemInternalProps = {
  icon?: IconProps;
  text?: TextProps;
};

type RadioItemPropsValues = {
  'Checked': RadioItemInternalProps;
  'Unchecked': RadioItemInternalProps;
};

const propValues: RadioItemPropsValues = {
  'Checked': {
    icon: {
      asset: 'RadioSelected',
      colour: 'Info',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
  'Unchecked': {
    icon: {
      asset: 'RadioUnselected',
      colour: 'Info',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
};

const getProps = (type: string): RadioItemInternalProps => {
  const values: RadioItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
