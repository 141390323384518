import { ButtonProps } from '../../atoms/Button';
import { CheckboxItemProps } from '../../atoms/CheckboxItem';
import { InlineNotificationProps } from '../../atoms/InlineNotification';
import { TextProps } from '../../atoms/Text';
import { CardInfoProps } from '../../molecules/CardInfo';
import { PriceListItemProps } from '../../molecules/PriceListItem';


type TrainingExtensionModalInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
  priceListItem?: PriceListItemProps;
  cardInfo?: CardInfoProps;
  primaryButton?: ButtonProps;
  primaryButton1?: ButtonProps;
  inlineNotification?: InlineNotificationProps;
  checkboxItem?: CheckboxItemProps;
};

type TrainingExtensionModalPropsValues = {
  '': TrainingExtensionModalInternalProps;
};

const propValues: TrainingExtensionModalPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'Base',
      loading: 'Default',
    },
    priceListItem: {
      type: 'Total',
    },
    cardInfo: {
    },
    primaryButton: {
      style: 'Contained',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    primaryButton1: {
      style: 'Outline',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    inlineNotification: {
      type: 'Error',
    },
    checkboxItem: {
    },
  },
};

const getProps = (type: string): TrainingExtensionModalInternalProps => {
  const values: TrainingExtensionModalInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
