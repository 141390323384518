import React from 'react';
import cx from 'classnames';

import Button from '../Button';

import styles from './TextInput.module.scss';
import usePresenter from './TextInput.presenter';
import getProps from './TextInput.props';
import { TextInputCombinedProps } from './types';


const TextInput: React.FC<TextInputCombinedProps> = (props) => {
  const {
    type,
    error,
    size,
    className,
    classes,
    textValue,
    textPlaceholder,
    onTextChanged,
    button,
    disabled,
    htmlType,
    maxLength, //MANUAL OVERRIDE
    inputRef, // MANUAL OVERRIDE
    preProcessTextInput, // MANUAL OVERRIDE
    autofocus, // MANUAL OVERRIDE
    dataInput, // MANUAL OVERRIDE for DateTimePickerField
  } = usePresenter(props);

  const variantName = `${type}${error}${size}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`textInput${variantName}`];

  const [inputValue, setInputValue] = React.useState<string>('');
  
  React.useEffect(() => {
    if (autofocus && inputRef?.current) {
      inputRef.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autofocus]);

  React.useEffect(() => {
    setInputValue(textValue || '');
  }, [textValue]);

  const onInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (preProcessTextInput && inputRef?.current) {
      inputRef.current.value = preProcessTextInput(event.target.value);
    }
    setInputValue(inputRef?.current?.value || '');
    if (onTextChanged) {
      onTextChanged(inputRef?.current?.value || '');
    }
  };

  let componentView;

  switch (variantName) {
    case 'PasswordErrorL':
    case 'PasswordNoneL': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <input
            className={cx(styles.input, classes?.input)}
            type={htmlType}
            placeholder={textPlaceholder}
            disabled={disabled}
            value={inputValue}
            ref={inputRef}
            maxLength={maxLength} //MANUAL OVERRIDE
            onChange={onInputChanged}
            data-input={dataInput}/>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
      );
      break;
    }
    case 'TextErrorL':
    case 'TextNoneL': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <input
            className={cx(styles.input, classes?.input)}
            type={htmlType}
            placeholder={textPlaceholder}
            disabled={disabled}
            value={inputValue}
            ref={inputRef}
            maxLength={maxLength} //MANUAL OVERRIDE
            onChange={onInputChanged}
            data-input={dataInput}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TextInput;

