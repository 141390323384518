import React from 'react';
import cx from 'classnames';

import CourseCard from '../../molecules/CourseCard';

import styles from './CourseCardsList.module.scss';
import usePresenter from './CourseCardsList.presenter';
import getProps from './CourseCardsList.props';
import { CourseCardsListCombinedProps } from './types';


const CourseCardsList: React.FC<CourseCardsListCombinedProps> = (props) => {
  const {
    className,
    classes,
    courseCards,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.courseCardsList;

  const courseCardViews = courseCards?.map((courseCard, index) => (
    <CourseCard
      key={index}
      className={cx(styles.courseCard, classes?.courseCard)}
      {...internalProps.courseCard}
      {...courseCard} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {courseCardViews}
    </div>
  );
};

export default CourseCardsList;

