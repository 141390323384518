import React from 'react';
import cx from 'classnames';

import styles from './SignInLayout.module.scss';
import usePresenter from './SignInLayout.presenter';
import { SignInLayoutProps } from './types';
import SigninPage from '../../pages/SigninPage';
import getProps from './SignInLayout.props';
import Header from '../../organisms/Header';

const SignInLayout: React.FC<SignInLayoutProps> = (props) => {
  const {
    className,
    classes,
    signInPage,
    header,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.signInLayout;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Header
          className={cx(styles.header1, classes?.header1)}
          {...internalProps.header}
          {...header}/>
      </div>
      <div className={cx(styles.body, classes?.body)}>
        <SigninPage
          className={cx(styles.signInPage, classes?.signInPage)}
          {...internalProps.signInPage}
          {...signInPage}
        />
      </div>
    </div>
  );
};

export default SignInLayout;
