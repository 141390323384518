import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { InitialsProps } from '../../atoms/Initials';
import { IconDropDownProps } from '../IconDropDown';


type StudentItemInternalProps = {
  initials?: InitialsProps;
  nameText?: TextProps;
  infoText?: TextProps;
  iconDropdown?: IconDropDownProps; // MANUAL OVERRIDE
  divider?: DividerProps;
};

type StudentItemPropsValues = {
  '': StudentItemInternalProps;
};

const propValues: StudentItemPropsValues = {
  '': {
    initials: {
      size: '48',
      colour: 'Green',
    },
    nameText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    infoText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body1',
    },
    iconDropdown: {}, // MANUAL OVERRIDE
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
};

const getProps = (type: string): StudentItemInternalProps => {
  const values: StudentItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
