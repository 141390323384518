import { ImageProps } from '../../atoms/Image';
import { TextGroupProps } from '../../molecules/TextGroup';


type GenericStateNoActionBlockInternalProps = {
  image?: ImageProps;
  textGroup?: TextGroupProps;
};

type GenericStateNoActionBlockPropsValues = {
  '': GenericStateNoActionBlockInternalProps;
};

const propValues: GenericStateNoActionBlockPropsValues = {
  '': {
    image: {
      type: 'Square',
    },
    textGroup: {
    },
  },
};

const getProps = (type: string): GenericStateNoActionBlockInternalProps => {
  const values: GenericStateNoActionBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
