import React from 'react';
import cx from 'classnames';


import styles from './KoruLogo.module.scss';
import usePresenter from './KoruLogo.presenter';
import { KoruLogoCombinedProps } from './types';


const KoruLogo: React.FC<KoruLogoCombinedProps> = (props) => {
  const {
    className,
    imageSrc,
    imageFallback,
  } = usePresenter(props);

  const currentStyle = styles.koruLogo;

  return (
    <div className={cx(currentStyle, className)} style={{ backgroundImage: `url(${imageSrc}), url(${imageFallback})` }}>
    </div>
  );
};

export default KoruLogo;

