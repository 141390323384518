import React from 'react';
import cx from 'classnames';

import LogEntryStatus from '../LogEntryStatus';
import Text from '../Text';

import styles from './LogEntryStatusItem.module.scss';
import usePresenter from './LogEntryStatusItem.presenter';
import getProps from './LogEntryStatusItem.props';
import { LogEntryStatusItemCombinedProps } from './types';


const LogEntryStatusItem: React.FC<LogEntryStatusItemCombinedProps> = (props) => {
  const {
    state,
    type,
    className,
    classes,
    logEntryStatus,
    text,
    onClick,
  } = usePresenter(props);

  const variantName = `${state}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`logEntryStatusItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'SelectedStatus':
    case 'UnselectedStatus': {
      componentView = (
        <div className={cx(currentStyle, className)} onClick={onClick} tabIndex={0}>
          <LogEntryStatus
            className={cx(styles.logEntryStatus, classes?.logEntryStatus)}
            {...internalProps.logEntryStatus}
            {...logEntryStatus}/>
        </div>
      );
      break;
    }
    case 'SelectedText':
    case 'UnselectedText': {
      componentView = (
        <div className={cx(currentStyle, className)} onClick={onClick} tabIndex={0}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default LogEntryStatusItem;

