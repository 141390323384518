import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { HeaderCombinedProps } from './types';
import { 
  DASHBOARD_ROUTE,
  KORU_EVENTS_URL,
  KORU_TEACHER_DIR_URL,
  KORU_TEACHER_CERT_ARG_URL,
} from '../../../lib/constants';
import { AuthContext } from '../../../modules/auth';
import { UserContext } from '../../../modules/user/UserContext';
import { logout } from '../../../modules/auth/utils';
import {
  getIsAccessRestricted, getPersonName, getPersonNameInitials,
  isStudent, NO_RESTRICTION_JOURNEY_STATUSES,
} from '../../../lib/utils';
import styles from './Header.module.scss';
import { CourseStateType } from '../../../modules/course/types';
import { ContextualMenuItemCombinedProps } from '../../atoms/ContextualMenuItem/types';

const usePresenter = (props: HeaderCombinedProps): HeaderCombinedProps => {
  const { t } = useTranslation();
  const { account } = useContext(AuthContext);
  const { user, isExpired } = useContext(UserContext);
  const history = useHistory();
  const { state } = useLocation<CourseStateType | undefined>();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const handleLogout = () => {
    if (isStudent(user) && state?.course) {
      const { id, freeLinkToken } = state?.course.content || {};
      const courseRegistrationURL = freeLinkToken ? `/courseRegistration/${id}?token=${freeLinkToken}` : `/courseRegistration/${id}`;
      logout(courseRegistrationURL);
    } else {
      logout();
    }
  };

  const handleMyAccountRedirect = (): void => {
    history.push(DASHBOARD_ROUTE.profile);
  };

  const openExternalLink = (href: string): void => {
    window.location.assign(href);
  };

  const accessRestricted = getIsAccessRestricted(isExpired, user);
  const isRegistrationCompleted = user && NO_RESTRICTION_JOURNEY_STATUSES.includes(user.content.journeyStatus);
  const menuItems: ContextualMenuItemCombinedProps[] = isStudent(user) ? [] : [
    {
      disabled: !isRegistrationCompleted,
      text: {
        colour: isRegistrationCompleted ? 'Default' : 'Subdued',
        value: t('user_menu.my_account'),
      },
      onClick: handleMyAccountRedirect,
    },
    {
      disabled: accessRestricted,
      text: {
        colour: accessRestricted ? 'Subdued' : 'Default',
        value: t('user_menu.koru_events'),
      },
      onClick: () => openExternalLink(KORU_EVENTS_URL),
    },
    {
      disabled: accessRestricted,
      text: {
        colour: accessRestricted ? 'Subdued' : 'Default',
        value: t('user_menu.teacher_dir'),
      },
      onClick: () => openExternalLink(KORU_TEACHER_DIR_URL),
    },
    {
      disabled: accessRestricted,
      text: {
        colour: accessRestricted ? 'Subdued' : 'Default',
        value: t('user_menu.teacher_cert_agr'),
      },
      onClick: () => openExternalLink(KORU_TEACHER_CERT_ARG_URL),
    },
  ];

  const handleUserMenuToggle = (show: boolean) => {
    setIsUserMenuOpen(show);
  };

  return {
    ...props,
    type: user ? 'WithUserProfile' : 'Default',
    userProfile: {
      onToggle: handleUserMenuToggle,
      toggleProps: {
        size: 'Medium',
        initials: {
          text: {
            value: getPersonNameInitials(account),
          },
        },
        text: {
          value: getPersonName(account),
        },
      },
      menuProps: {
        contextualMenuList: {
          contextualMenuItems: [
            ...menuItems,
            {
              type: 'Critical',
              text: {
                value: t('user_menu.logout'),
              },
              onClick: handleLogout,
            },
          ],
        },
        className: isUserMenuOpen ? styles.contextualMenu : styles.contextualMenuHidden,
      },
    },
  };
};

export default usePresenter;
