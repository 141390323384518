import { ImageProps } from '../../atoms/Image';
import { TextProps } from '../../atoms/Text';


type StaffItemInternalProps = {
  image?: ImageProps;
  topText?: TextProps;
  subText?: TextProps;
};

type StaffItemPropsValues = {
  '': StaffItemInternalProps;
};

const propValues: StaffItemPropsValues = {
  '': {
    image: {
      type: 'Oval',
    },
    topText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading4',
    },
    subText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): StaffItemInternalProps => {
  const values: StaffItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
