import { InputFieldProps } from '../InputField';


type InputFieldGroupInternalProps = {
  inputFieldOne?: InputFieldProps;
  inputFieldTwo?: InputFieldProps;
};

type InputFieldGroupPropsValues = {
  '': InputFieldGroupInternalProps;
};

const propValues: InputFieldGroupPropsValues = {
  '': {
    inputFieldOne: {
      state: 'Default',
    },
    inputFieldTwo: {
      state: 'Default',
    },
  },
};

const getProps = (type: string): InputFieldGroupInternalProps => {
  const values: InputFieldGroupInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
