import React, { createContext, useState } from 'react';
import { ConfirmationModalTypeEnum } from '../../components/organisms/ConfirmationModal/types';
import { DEFAULT_CUSTOM_STUDENT_APP_AMOUNT, DEFAULT_STUDENT_APP_AMOUNT } from '../../components/organisms/PurchaseStudentAppModal/constants';
import { PurchaseStudentAppModalState } from '../../components/organisms/PurchaseStudentAppModal/types';

export type ModalState = 
  'UNSET' | 
  'CONFIRMATION' | 
  'TRAINING_EXTENSION' | 
  'PURCHASE_STUDENT_APPS' | 
  'PURCHASE_ANNUAL_PLAN';
export type PaymentInfoModalState = 'UNSET' | 'PAYMENT';

export type ModalContextValue = {
  modalState: ModalState;
  paymentInfoModalState: PaymentInfoModalState;
  setModalState: (modalState: ModalState) => void;
  openPaymentInfoModal: () => void;
  closePaymentInfoModal: () => void;
  confirmationModalType: ConfirmationModalTypeEnum;
  setConfirmationModalType: (confirmationModalType: ConfirmationModalTypeEnum) => void;
  onHideModal: () => void;
  purchaseStudentAppModalState: PurchaseStudentAppModalState;
  setPurchaseStudentAppModalState: (purchaseStudentAppModalState: PurchaseStudentAppModalState) => void;
};

const initialModalContext: ModalContextValue = {
  modalState: 'UNSET',
  paymentInfoModalState: 'UNSET',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setModalState: (_modalState: ModalState) => {},
  openPaymentInfoModal: () => {},
  closePaymentInfoModal: () => {},
  confirmationModalType: 'PURCHASE',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setConfirmationModalType: (_confirmationModalType: ConfirmationModalTypeEnum) => {},
  onHideModal: () => {},
  purchaseStudentAppModalState: {
    state: 'Default',
    customAmount: 1,
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setPurchaseStudentAppModalState: (_purchaseStudentAppModalState) => {},
};

export const ModalContext = createContext<ModalContextValue>(initialModalContext);

export const ModalProvider: React.FC<{}> = ({ children }) => {
  const [modalState, setModalState] = useState<ModalState>('UNSET');
  const [paymentInfoModalState, setPaymentInfoModalState] = useState<PaymentInfoModalState>('UNSET');
  const [confirmationModalType, setConfirmationModalType] = useState<ConfirmationModalTypeEnum>('PURCHASE');
  const [purchaseStudentAppModalState, setPurchaseStudentAppModalState] = useState<PurchaseStudentAppModalState>({
    state: 'Default',
    defaultAmount: DEFAULT_STUDENT_APP_AMOUNT,
    customAmount: DEFAULT_CUSTOM_STUDENT_APP_AMOUNT,
  });
  const [returnModalState, setReturnModalState] = useState<ModalState>('UNSET');

  const closePaymentInfoModal = () => {
    setPaymentInfoModalState('UNSET');
    setModalState(returnModalState);
  };

  const openPaymentInfoModal = () => {
    setPaymentInfoModalState('PAYMENT');
    setReturnModalState(modalState);
    setModalState('UNSET');
  };

  const onHideModal = () => {
    if (modalState === 'PURCHASE_STUDENT_APPS') {
      // restore to default state
      setPurchaseStudentAppModalState({
        state: 'Default',
        defaultAmount: DEFAULT_STUDENT_APP_AMOUNT,
        customAmount: DEFAULT_CUSTOM_STUDENT_APP_AMOUNT,
      });
    }
    setPaymentInfoModalState('UNSET');
    setModalState('UNSET');
  };

  return (
    <ModalContext.Provider
      value={{
        modalState,
        paymentInfoModalState,
        setModalState,
        openPaymentInfoModal,
        closePaymentInfoModal,
        confirmationModalType,
        setConfirmationModalType,
        onHideModal,
        purchaseStudentAppModalState,
        setPurchaseStudentAppModalState,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};