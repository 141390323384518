import { TextProps } from '../../atoms/Text';
import { IconProps } from '../../atoms/Icon';


type IconTextGroupInternalProps = {
  labelIcon?: IconProps;
  infoText?: TextProps;
};

type IconTextGroupPropsValues = {
  '': IconTextGroupInternalProps;
};

const propValues: IconTextGroupPropsValues = {
  '': {
    labelIcon: {
      asset: 'Clock',
      colour: 'Base',
    },
    infoText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
};

const getProps = (type: string): IconTextGroupInternalProps => {
  const values: IconTextGroupInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
