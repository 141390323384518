import React, { useContext, createContext, Dispatch, useReducer } from 'react';
import { Notification, NotificationAction, TriggerNotificationPayload } from './types';

export type NotificationContextValue = {
  notifications: Notification[];
  clear: () => void;
  dispatch: Dispatch<NotificationAction>;
  trigger: (payload: TriggerNotificationPayload) => void;
};

export const initialNotificationContext: NotificationContextValue = {
  notifications: [],
  clear: () => {},
  dispatch: () => {},
  trigger: () => {},
};

const NotificationContext = createContext<NotificationContextValue>(initialNotificationContext);

const notificationsReducer = (state: Notification[], action: NotificationAction) => {
  switch (action.type) {
    case 'ADD':
      return [
        ...state,
        {
          id: action.payload?.id,
          message: action.payload?.message,
          type: action.payload?.type,
        },
      ];
    case 'CLEAR':
      return [];
    case 'REMOVE':
      return state.filter(t => t.id !== action.payload?.id);
    default:
      return state;
  }
};

export const NotificationProvider: React.FC<{}> = ({ children }) => {
  const [notifications, dispatch] = useReducer(notificationsReducer, []);

  const trigger = (payload: TriggerNotificationPayload) => {
    const id = +new Date();
    const { message, type, duration } = payload;
    dispatch({ type: 'ADD', payload: { id, message, type } });
    setTimeout(() => {
      dispatch({ type: 'REMOVE', payload: { id } });
    }, duration);
  };

  const clear = () => {
    dispatch({ type: 'CLEAR' });
  };

  return (
    <NotificationContext.Provider value={{
      notifications,
      clear,
      dispatch,
      trigger,
    }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
