import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { NOTIFICATION_DURATION } from '../../../lib/constants';
import { DataEntry } from '../../../lib/types';
import { formatDate, getPersonName, getPersonNameInitials } from '../../../lib/utils';
import { CourseTeacher } from '../../../modules/course/types';
import { logSortByLocalStorageKey, LOG_REFETCH_INTERVAL } from '../../../modules/log/constants';
import {
  Log, LogEntry, LogEntrySenderType, LogStatus, SortableLogFields,
} from '../../../modules/log/types';
import { useNotification } from '../../../modules/notification';
import { Student } from '../../../modules/student/types';
import { UserContext } from '../../../modules/user/UserContext';
import NoLogSelectedImage from '../../../resources/images/NoLogSelected.png';
import { LabelTextGroupValueProps } from '../../molecules/LabelTextGroup';
import {
  LogEntryItemCombinedProps, LogEntryItemTypeEnum,
} from '../../molecules/LogEntryItem/types';
import { TextMessageCombinedProps, TextMessageTypeEnum } from '../../molecules/TextMessage/types';
import { LogEntriesAccordianItemValueProps } from '../../organisms/LogEntriesAccordianItem';
import { LogEntriesAccordianItemCombinedProps } from '../../organisms/LogEntriesAccordianItem/types';

import {
  createLogEntryUseCase, getCourseLogsByCourseIdUseCase, updateLogUseCase,
} from './LogbookBlock.interactor';
import styles from './LogbookBlock.module.scss';
import {
  LogbookBlockCombinedProps, LogbookDateTypes, logbookEntryFields, LogStatusFilters, StudentLogs,
} from './types';
import { getLogBookDateTimeStamp, getStudentLogs } from './utils';

const TextMessageTimeFormatOptions: Intl.DateTimeFormatOptions = {
  hour12: true,
  hour: '2-digit',
  minute: '2-digit',
};

const TextMessageDateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
};

const usePresenter = (props: LogbookBlockCombinedProps): LogbookBlockCombinedProps => {
  const { t } = useTranslation();
  const { course } = props;
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);
  const { trigger } = useNotification();
  const lastTextMessageRef = useRef<HTMLDivElement>(null);

  const logsSortBy = localStorage.getItem(logSortByLocalStorageKey) as SortableLogFields;

  const [logStatusFilter, setLogStatusFilter] = useState<LogStatusFilters>(LogStatusFilters.all);
  const [logSortFilter, setLogSortFilter] = useState<SortableLogFields>(
    logsSortBy || SortableLogFields.date,
  );

  const [selectedLogUuid, setSelectedLogUuid] = useState<string | undefined>(undefined);
  const [selectedLog, setSelectedLog] = useState<DataEntry<Log> | undefined>(undefined);
  const [selectedStudentUuid, setSelectedStudentUuid] = useState<string | undefined>(undefined);

  const [filteredLogs, setFilteredLogs] = useState<DataEntry<Log>[]>([]);
  const [logList, setLogList] = useState<LogEntryItemCombinedProps[]>([]);
  const [studentLogList, setStudentLogList] = useState<LogEntriesAccordianItemValueProps[]>([]);
  const [textMessages, setTextMessages] = useState<TextMessageCombinedProps[]>([]);
  const [logEntryDetails, setLogEntryDetails] = useState<LabelTextGroupValueProps[]>([]);

  const [replyText, setReplyText] = useState<string | undefined>(undefined);

  const [
    currentUserSenderType, setCurrentUserSenderType,
  ] = useState<LogEntrySenderType | undefined>(undefined);

  useEffect(() => {
    if (course && user) {
      const isUserPrimaryTeacher = user.uuid === course.content.primaryTeacher.uuid;
      if (isUserPrimaryTeacher) {
        setCurrentUserSenderType(LogEntrySenderType.teacher);
        return;
      }

      const coTeachersUuid = course.content.coTeachers?.map((coTeacher) => coTeacher.uuid);
      if (coTeachersUuid?.includes(user.uuid)) {
        setCurrentUserSenderType(LogEntrySenderType.coTeacher);
      }
    }
  }, [course, user]);

  const { data: logs, isLoading } = useQuery(['getCourseLogsByCourseId', course?.content?.id, logSortFilter], async () => {
    if (course) {
      return getCourseLogsByCourseIdUseCase(Number(course.content.id), logSortFilter);
    }
  }, {
    refetchInterval: LOG_REFETCH_INTERVAL,
  });

  const { mutateAsync: markLogAsRead } = useMutation(
    ['markLogAsRead'],
    async (logUuid: string) => {
      return updateLogUseCase(logUuid, {
        opened: true,
      });
    },
  );

  const { mutateAsync: sendMessage, isLoading: isSending } = useMutation(
    ['createNewLogEntry', replyText, selectedLog?.uuid],
    async () => {
      if (replyText && selectedLog && user && currentUserSenderType) {
        const log = await createLogEntryUseCase(selectedLog.uuid, {
          message: replyText,
          senderId: user.uuid,
          senderType: currentUserSenderType,
        });
        return log;
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          const cachedLogs = queryClient.getQueryData<DataEntry<Log>[]>(
            ['getCourseLogsByCourseId', course?.content?.id, logSortFilter],
          );
          if (cachedLogs) {
            // creating a new variable to re-render the logs
            const updatedLogs = [...cachedLogs];
            const cachedSelectedLogIndex = updatedLogs.findIndex((cachedLog) => (
              cachedLog.uuid === selectedLogUuid
            ));
            // if there is a selectedLog update logEntries for it
            if (cachedSelectedLogIndex !== -1) {
              const cachedSelectedLog = updatedLogs[cachedSelectedLogIndex];
              const updatedSelectedLog = {
                ...cachedSelectedLog,
                content: {
                  ...cachedSelectedLog.content,
                  logEntries: [...(cachedSelectedLog.content.logEntries || []), data],
                },
              };
              updatedLogs.splice(
                cachedSelectedLogIndex, 1, updatedSelectedLog,
              );
              setSelectedLog(updatedSelectedLog);
            }
            // update cache for logs
            queryClient.setQueryData<DataEntry<Log>[]>(
              ['getCourseLogsByCourseId', course?.content?.id, logSortFilter],
              updatedLogs,
            );
          }

          // empty reply text input and show toast notification
          setReplyText(undefined);
          trigger({
            duration: NOTIFICATION_DURATION,
            message: t('notification.message_sent'),
            type: 'Success',
          });
        }
      },
    },
  );

  const handleLogEntryClick = async (logUuid: string, event?: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    if (logs && course) {
      const newSelectedLog = logs.find((log) => log.uuid === logUuid);
      if (newSelectedLog) {
        setSelectedLogUuid(logUuid);
        if (!newSelectedLog.content.opened) {
          await markLogAsRead(newSelectedLog.uuid);
          await queryClient.invalidateQueries(['getCourseLogsByCourseId', course.content.id, logSortFilter]);
        }
      }
    }
  };

  const handleReplyTextChange = (value: string) => {
    setReplyText(value);
  };

  const handleSendButtonClick = async () => {
    await sendMessage();
  };

  const getLogType = (log: DataEntry<Log>): LogEntryItemTypeEnum => {
    if (log.uuid === selectedLogUuid) {
      return 'Selected';
    }
    if (!log.content.opened) {
      return 'New';
    }
    return 'Default';
  };

  const getTextMessageType = (logEntry: LogEntry): TextMessageTypeEnum => {
    let textMessageType: TextMessageTypeEnum;
    const { senderType, sender } = logEntry;
    switch (senderType) {
      case LogEntrySenderType.coTeacher:
      case LogEntrySenderType.teacher:
        textMessageType = sender === user?.uuid ? 'Teacher' : 'Coteacher';
        break;
      default:
        textMessageType = 'Student';
        break;
    }
    return textMessageType;
  };

  const getLogGratefulFor = (log: Log): React.ReactNode[] => {
    const { gratefulFor } = log;
    const gratefulForThings: React.ReactNode[] = [];
    if (gratefulFor) {
      (gratefulFor as string[]).forEach((gratefulAboutThing, index) => {
        gratefulForThings.push(t('logbook.log_entry.gratefulForThings', {
          index: (index + 1),
          gratefulFor: gratefulAboutThing,
        }));
        if (index !== (gratefulFor.length - 1)) {
          gratefulForThings.push(<br />);
        }
      });
    }
    return gratefulForThings;
  };

  const getLogEntryItemProps = (log: DataEntry<Log>): LogEntryItemCombinedProps => {
    const { status, student, reflections } = log.content;
    return {
      type: getLogType(log),
      nameText: {
        value: getPersonName(student),
      },
      previewText: {
        classes: {
          value: styles.logbookEntryMessagePreview,
        },
        value: reflections,
      },
      logEntryStatus: {
        icon: {
          asset: status === LogStatus.replied ? 'RadioSelected' : 'ClockFilled',
          colour: status === LogStatus.replied ? 'Multicolor' : 'Info',
        },
        text: {
          value: status,
        },
      },
      timeStampText: {
        value: getLogBookDateTimeStamp(
          log.content.sessionDate, LogbookDateTypes.LeftPanel,
        ),
      },
      onClick: (event) => handleLogEntryClick(log.uuid, event),
    };
  };

  const getStudentLogbookEntryItemsProps = (
    studentLogs: StudentLogs[],
  ): LogEntriesAccordianItemCombinedProps[] => {
    return studentLogs.map((studentLog) => {
      const isCurrentStudentLogSelected = selectedStudentUuid === studentLog.student.uuid;
      return {
        state: studentLog.hasUnreadMessages && !isCurrentStudentLogSelected ? 'New' : 'Default',
        type: isCurrentStudentLogSelected ? 'Expanded' : 'Collapsed',
        onClick: () => (
          setSelectedStudentUuid(isCurrentStudentLogSelected  ? undefined : studentLog.student.uuid)
        ),
        nameText: {
          value: getPersonName(studentLog.student),
        },
        button: {
          text: {
            value: isCurrentStudentLogSelected
              ? t('logbook.log_entry.hide_entries')
              : t('logbook.log_entry.show_entries', {
                totalEntries: studentLog.logs.length || '',
              }),
          },
          icon: {
            asset: isCurrentStudentLogSelected ? 'ChevronUpBig' : 'ChevronDownSmall',
            colour: 'Base',
          },
        },
        logEntriesList: {
          logEntryItems: studentLog.logs.map(getLogEntryItemProps),
        },
      } as LogEntriesAccordianItemCombinedProps;
    });
  };

  const handleLogSortFilterClick = (sortBy: SortableLogFields) => {
    localStorage.setItem(logSortByLocalStorageKey, sortBy);
    setLogSortFilter(sortBy);
  };

  // Filter logs "By Student" ot "By Date"
  useEffect(() => {
    if (logs) {
      let newFilteredLogs: DataEntry<Log>[] = [];
      switch (logStatusFilter) {
        case LogStatusFilters.awaiting_reply:
          newFilteredLogs = logs.filter((log) => log.content.status === LogStatus.awaiting_reply);
          break;

        case LogStatusFilters.replied:
          newFilteredLogs = logs.filter((log) => log.content.status === LogStatus.replied);
          break;
      
        default:
          newFilteredLogs = logs;
          break;
      }
      setFilteredLogs(newFilteredLogs);
      if (selectedLogUuid) {
        const newSelectedLog = logs.find((log) => log.uuid === selectedLogUuid);
        if (newSelectedLog) {
          setSelectedLog(newSelectedLog);
        }
      }
    }
  }, [logs, logStatusFilter, selectedLogUuid]);

  // Group Logbook Entries by Logbook Submission Date
  useEffect(() => {
    if (filteredLogs) {
      const logbookLogs: LogEntryItemCombinedProps[] = filteredLogs.map(getLogEntryItemProps);
      setLogList(logbookLogs);      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredLogs, selectedLogUuid]);

  const getSender = (
    sender: string, student: DataEntry<Student>,
  ): DataEntry<Student> | DataEntry<CourseTeacher> | undefined => {
    if (sender === student.uuid) {
      return student;
    }
    if (course) {
      const teachers = [course?.content.primaryTeacher, ...course?.content.coTeachers || []];
      const senderTeacher = teachers.find((teacher) => teacher.uuid === sender);
      return senderTeacher;
    }
  };

  // Added new text messages for selectedLog 
  useEffect(() => {
    if (selectedLog && course) {
      const { logEntries, student } = selectedLog.content;
      const selectedLogMessages = logEntries ? logEntries.map((logEntry, index) => {
        const { message, sender, senderType } = logEntry.content;

        return {
          textMessageRef: logEntries && (index === (logEntries.length - 1))
            ? lastTextMessageRef : undefined,
          align: senderType === LogEntrySenderType.student ? 'Right' : 'Left',
          type: getTextMessageType(logEntry.content),
          initials: {
            text: {
              value: getPersonNameInitials(getSender(sender, student)),
            },
          },
          text: {
            value: message,
          },
          text1: {
            value: t('logbook.text_message.message_details', {
              senderName: getPersonName(getSender(sender, student)),
              messageTime: formatDate(logEntry.createdAt, TextMessageTimeFormatOptions),
              messageDate: formatDate(logEntry.createdAt, TextMessageDateFormatOptions),
            }),
          },
        } as TextMessageCombinedProps;
      }) : [];

      setTextMessages(selectedLogMessages);

      const labelGroupList = logbookEntryFields.map(key => {
        const { sessionDate, meditationDuration, guidedMeditation, skills } = selectedLog.content;
        const labelText = t(`logbook.log_entry.labels.${key}`);
        let infoText;
        switch (key) {
          case 'guidedMeditation':
            infoText = t(`logbook.log_entry.guided_meditation.${guidedMeditation ? 'yes' : 'no'}`);
            break;
  
          case 'sessionDate':
            infoText = getLogBookDateTimeStamp(sessionDate, LogbookDateTypes.MeditationDateTime);
            break;
  
          case 'meditationDuration':
            infoText = t('logbook.log_entry.mediationDuration', {
              duration: meditationDuration,
            });
            break;

          case 'gratefulFor':
            infoText = getLogGratefulFor(selectedLog.content);
            break;

          case 'skills':
            infoText = skills && (skills as string[]).join(', ');
            break;

          default:
            infoText = selectedLog.content[key];
            break;
        }
        return (
          {
            labelText: {
              className: styles.labelText,
              value: labelText,
            },
            infoText: {
              value: infoText,
            },
          } as LabelTextGroupValueProps
        );
      });
      setLogEntryDetails(labelGroupList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLog, course, t]);

  // Group Logbook Entries by Student Name
  useEffect(() => {
    if (filteredLogs) {
      const studentLogs = getStudentLogs(filteredLogs);
      const studentLogAccordionList = getStudentLogbookEntryItemsProps(studentLogs);
      setStudentLogList(studentLogAccordionList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredLogs, selectedStudentUuid, selectedLogUuid, t]);

  useEffect(() => {
    if (textMessages) {
      lastTextMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [textMessages]);

  return {
    ...props,
    isLoading,
    classes: {
      rightContent: !selectedLogUuid ? styles.rightContentEmptyState : '',
    },
    toggle: {
      toggleItemOne: {
        text: {
          value: t('logbook.toggle.date'),
        },
        onClick: () => handleLogSortFilterClick(SortableLogFields.date),
        state: logSortFilter === SortableLogFields.date ? 'Selected' : 'Unselected',
      },
      toggleItemTwo: {
        text: {
          value: t('logbook.toggle.student'),
        },
        onClick: () => handleLogSortFilterClick(SortableLogFields.student),
        state: logSortFilter === SortableLogFields.student ? 'Selected' : 'Unselected',
      },
    },
    logEntryStatusList: {
      logEntryStatusItems: [
        {
          type: 'Text',
          state: logStatusFilter === LogStatusFilters.all ? 'Selected' : 'Unselected',
          text: {
            value: t('logbook.entry_status.all'),
          },
          onClick: () => setLogStatusFilter(LogStatusFilters.all),
        },
        {
          type: 'Status',
          state: logStatusFilter === LogStatusFilters.awaiting_reply ? 'Selected' : 'Unselected',
          logEntryStatus: {
            icon: {
              asset: 'ClockFilled',
            },
            text: {
              value: t('logbook.entry_status.awaiting_reply'),
            },
          },
          onClick: () => setLogStatusFilter(LogStatusFilters.awaiting_reply),
        },
        {
          type: 'Status',
          state: logStatusFilter === LogStatusFilters.replied ? 'Selected' : 'Unselected',
          logEntryStatus: {
            icon: {
              asset: 'RadioSelected',
              colour: 'Multicolor',
            },
            text: {
              value: t('logbook.entry_status.replied'),
            },
          },
          onClick: () => setLogStatusFilter(LogStatusFilters.replied),
        },
      ],
    },
    replySection: {
      button: {
        text: {
          value: t('logbook.reply_section.send_button'),
        },
        disabled: !replyText || isSending,
        loading: isSending ? 'Loading' : 'Default',
        onClick: handleSendButtonClick,
      },
      textInput: {
        textPlaceholder: t('logbook.reply_section.message_placeholder'),
        textValue: replyText,
        onTextChanged: handleReplyTextChange,
      },
    },
    logEntriesList: {
      logEntryItems: logList,
    },
    logEntriesCatalog: {
      type: logSortFilter === SortableLogFields.date ? 'ByDate' : 'ByStudent',
      logEntriesAccordianList: {
        logEntriesAccordianItems: studentLogList,
      },
      logEntriesList: {
        logEntryItems: logList,
      },
    },
    isLogSelected: !!selectedLogUuid,
    noLogSelectedState: {
      image: {
        imageSrc: NoLogSelectedImage,
      },
      textGroup: {
        topText: {
          value: t('logbook.no_log_selected'),
        },
      },
    },
    logEntry: {
      studentNameText: {
        value: selectedLog && getPersonName(selectedLog.content.student),
      },
      labelTextGroupList: {
        labelTextGroups: logEntryDetails,
      },
    },
    textMessageList: {
      textMessages,
    },
  };
};

export default usePresenter;
