import { useDropdownToggle } from 'react-overlays';
import { UserProfileCombinedProps } from './types';

const usePresenter = (props: UserProfileCombinedProps): UserProfileCombinedProps => {
  const [toggleProps, { show }] = useDropdownToggle();
  return {
    ...props,
    ...{
      ...toggleProps,
      onClick: (event?: React.MouseEvent<HTMLElement>) => {
        if (event) {
          toggleProps.onClick(event);
        }
      },
    },
    state: show ? 'Expanded' : 'Collapsed',
  };
};

export default usePresenter;
