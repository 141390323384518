import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import {
  AUTH_ROUTES, STUDENT_ROUTES,
  SPOT_AVAILABLE_TOKEN_QUERY_PARAM,
  USER_NOT_VERIFIED_MESSAGE,
  MIGRATION_NOT_COMPLETE_MESSAGE,
} from '../../../lib/constants';
import { DataEntry } from '../../../lib/types';
import { isEmptyString } from '../../../lib/utils';
import { AuthContext } from '../../../modules/auth';
import { Course, EnrollmentStatus, RegistrationFlowEnum } from '../../../modules/course/types';
import { UserContext } from '../../../modules/user/UserContext';
import { signInUseCase, getStudentEnrollmentStatusUseCase } from './StudentSignInBlock.interactor';
import { SignInState, StudentSignInBlockCombinedProps } from './types';

const formInitialState: SignInState = {
  email: '',
  password: '',
};

const usePresenter = (props: StudentSignInBlockCombinedProps): StudentSignInBlockCombinedProps => {
  const history = useHistory();
  const { registerFlow, course: fetchedCourse } = props;
  const { t } = useTranslation();
  const { refetchAccount } = useContext(AuthContext);
  const { refetchUser } = useContext(UserContext);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const confirmationToken = query.get('token');
  const spotAvailableToken = query.get(SPOT_AVAILABLE_TOKEN_QUERY_PARAM);

  const [formState, setFormState] = useState<SignInState>(formInitialState);
  const [formErrorState, setFormErrorState] = useState<SignInState>(formInitialState);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showMigrationWarning, setShowMigrationWarning] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [course, setCourse] = useState<DataEntry<Course> | undefined>(fetchedCourse);

  const { mutateAsync: signIn } = useMutation('student_sign_in', async () => {
    try {
      let payload;
      if (formState.email.includes('@')) {
        payload = {
          email: formState.email.trim(),
          password: formState.password,
        };
      } else {
        payload = {
          phoneNumber: formState.email.trim(),
          password: formState.password,
        };
      }
      const data = await signInUseCase(payload);
      return data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const isUserVerificationError = error.response?.data.message === USER_NOT_VERIFIED_MESSAGE;
        if (isUserVerificationError) {
          history.replace(STUDENT_ROUTES.auth.verifyEmail, { isLogin: true, email: formState.email, course });
          return;
        }

        const isMigrationError = error.response?.data.message === MIGRATION_NOT_COMPLETE_MESSAGE;
        const isForbiddenError = error.response?.data?.code === 403;
        if (isForbiddenError && isMigrationError) {
          setShowMigrationWarning(true);
        } else {
          setShowNotification(true);
        }
      }
    }
  });

  const { mutateAsync: getStudentEnrollmentStatus } = useMutation(
    ['getStudentEnrollmentStatus', course?.content?.id],
    async () => {
      try {
        if (course?.content?.id) {
          const enrollmentStatus = await getStudentEnrollmentStatusUseCase(course.content.id);
          return enrollmentStatus;
        }
      } catch (error) {
        // no-op
      }
    });

  useEffect(() => {
    if (fetchedCourse) {
      setCourse(fetchedCourse);
    }
  }, [fetchedCourse]);

  const goToForgotPassword = () => {
    history.push(AUTH_ROUTES.forgotPassword, { isStudentFlow: true, course });
  };

  const handleTextChange = (field: keyof SignInState) => {
    return (value: string) => {
      setShowNotification(false);
      setFormState({
        ...formState,
        [field]: value,
      });

      setFormErrorState({
        ...formErrorState,
        [field]: '',
      });
    };
  };

  const isFormValid = (): boolean => {
    const invalidFormErrors = {
      email: isEmptyString(formState.email) ? t('error.field_required') : '',
      password: isEmptyString(formState.password) ? t('error.field_required') : '',
    };
    setFormErrorState(invalidFormErrors);
    return (
      Object.values(invalidFormErrors).filter((errorMessage: string) => !!errorMessage)
        .length === 0
    );
  };

  const handleSignIn = async () => {
    try {
      setShowNotification(false);
      setIsLoading(true);
      if (isFormValid()) {
        const accountResponse = await signIn();
        if (accountResponse) {
          const hasAvailableSeats = registerFlow === RegistrationFlowEnum.register;
          const nextStepPathname = hasAvailableSeats ? 
            STUDENT_ROUTES.auth.confirmAttendence : STUDENT_ROUTES.auth.joinWaitlist;
          refetchAccount();
          await refetchUser();

          if (spotAvailableToken && fetchedCourse) {
            history.push(STUDENT_ROUTES.auth.confirmAttendence, {
              course: fetchedCourse,
              spotAvailableToken,
            });
            return;
          }

          const enrollmentStatus = await getStudentEnrollmentStatus();
          switch (enrollmentStatus) {
            case EnrollmentStatus.enrolled:
              history.push(STUDENT_ROUTES.auth.registrationSuccess, { course });
              break;
            default:
              history.push(nextStepPathname, { course, isNewlyRegistered: !!confirmationToken, enrollmentStatus });
              break;
          }
        }
      }
    } catch (error) {
      // no-op
    } finally {
      setIsLoading(false);
    }
  };
  
  return {
    ...props,
    textGroup: {
      topText: {
        value: t('student_sign_in.title'),
      },
      bottomText: {
        value: t('student_sign_in.description'),
      },
    },
    emailField: {
      state: formErrorState.email ? 'Error' : 'Default',
      text: {
        value: t('student_sign_in.labels.email'),
      },
      textInput: {
        maxLength: 255,
        textValue: formState.email,
        onTextChanged: handleTextChange('email'),
      },
      contextualContent: {
        text: {
          value: formErrorState.email,
        },
      },
    },
    passwordField: {
      state: formErrorState.password ? 'Error' : 'Default',
      text: {
        value: t('student_sign_in.labels.password'),
      },
      textInput: {
        maxLength: 128,
        button: {
          icon: {
            asset: showPassword ? 'Hide' : 'Show',
          },
          onClick: () => {
            setShowPassword(!showPassword);
          },
        },
        type: 'Password',
        textValue: formState.password,
        onTextChanged: handleTextChange('password'),
        htmlType: showPassword ? 'text' : 'password',
      },
      contextualContent: {
        text: {
          value: formErrorState.password,
        },
      },
    },
    button: {
      icon: {
        asset: 'Question',
      },
      text: {
        value: t('student_sign_in.button.forgot_password'),
      },
      onClick: goToForgotPassword,
      loading: 'Default',
    },
    button1: {
      icon: {
        asset: 'ArrowRight',
      },
      text: {
        value: t('student_sign_in.button.login'),
      },
      onClick: handleSignIn,
      loading: isLoading ? 'Loading' : 'Default',
      disabled: isLoading,
    },
    inlineNotification: {
      text: {
        value: showMigrationWarning ? t('notification.migration_warning') : t('notification.invalid_email_number_or_password'),
      },
    },
    showNotification: showNotification || showMigrationWarning,
  };
};

export default usePresenter;
