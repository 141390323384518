import { PaymentHistorySectionProps } from '../../organisms/PaymentHistorySection';
import { PaymentInfoSectionProps } from '../../organisms/PaymentInfoSection';


type PaymentBlockInternalProps = {
  paymentHistorySection?: PaymentHistorySectionProps;
  paymentInfoSection?: PaymentInfoSectionProps;
};

type PaymentBlockPropsValues = {
  '': PaymentBlockInternalProps;
};

const propValues: PaymentBlockPropsValues = {
  '': {
    paymentHistorySection: {
    },
    paymentInfoSection: {
    },
  },
};

const getProps = (type: string): PaymentBlockInternalProps => {
  const values: PaymentBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
