import { ButtonCombinedProps } from './types';

const usePresenter = (props: ButtonCombinedProps): ButtonCombinedProps => {
  // MANUAL OVERRIDE
  const { text, disabled, style } = props;
  if (disabled) {
    return {
      ...props,
      text: {
        ...text,
        colour: style === 'Contained' ? 'Inverse' : 'Disabled',
      },
    };
  }
  return props;
};

export default usePresenter;
