import { DividerProps } from '../Divider';
import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type ToastNotificationInternalProps = {
  divider?: DividerProps;
  icon?: IconProps;
  text?: TextProps;
};

type ToastNotificationPropsValues = {
  'Critical': ToastNotificationInternalProps;
  'Info': ToastNotificationInternalProps;
  'Success': ToastNotificationInternalProps;
};

const propValues: ToastNotificationPropsValues = {
  'Critical': {
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'Critical',
    },
    icon: {
      asset: 'AlertFilled',
      colour: 'Critical',
    },
    text: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  'Info': {
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'Info',
    },
    icon: {
      asset: 'InfoFilled',
      colour: 'Info',
    },
    text: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  'Success': {
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'Brand',
    },
    icon: {
      asset: 'RadioSelected',
      colour: 'BrandLight',
    },
    text: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): ToastNotificationInternalProps => {
  const values: ToastNotificationInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
