import React from 'react';
import cx from 'classnames';

import ClassItem from '../../molecules/ClassItem';

import styles from './ClassList.module.scss';
import usePresenter from './ClassList.presenter';
import getProps from './ClassList.props';
import { ClassListCombinedProps } from './types';


const ClassList: React.FC<ClassListCombinedProps> = (props) => {
  const {
    className,
    classes,
    classItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.classList;

  const classItemViews = classItems?.map((classItem, index) => (
    <ClassItem
      key={index}
      className={cx(styles.classItem, classes?.classItem)}
      {...internalProps.classItem}
      {...classItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {classItemViews}
    </div>
  );
};

export default ClassList;

