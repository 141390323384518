import { TextProps } from '../../atoms/Text';


type TextGroupInternalProps = {
  topText?: TextProps;
  bottomText?: TextProps;
};

type TextGroupPropsValues = {
  '': TextGroupInternalProps;
};

const propValues: TextGroupPropsValues = {
  '': {
    topText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading1',
    },
    bottomText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
};

const getProps = (type: string): TextGroupInternalProps => {
  const values: TextGroupInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
