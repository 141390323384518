import React from 'react';
import cx from 'classnames';

import CopyLinkSection from '../../organisms/CopyLinkSection';
import DetailsBasicInfoSection from '../../organisms/DetailsBasicInfoSection';
import DetailsClassInfoSection from '../../organisms/DetailsClassInfoSection';
import GenericModal from '../../organisms/GenericModal';
import RegistrationStatusSection from '../../organisms/RegistrationStatusSection';

import styles from './DetailsBlock.module.scss';
import usePresenter from './DetailsBlock.presenter';
import getProps from './DetailsBlock.props';
import { DetailsBlockCombinedProps } from './types';


const DetailsBlock: React.FC<DetailsBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    detailsBasicInfoSection,
    detailsClassInfoSection,
    registrationStatusSection,
    copyLinkSection,
    updateRegistrationStatusWarningModal, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.detailsBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.leftContent, classes?.leftContent)}>
        <DetailsBasicInfoSection
          className={cx(styles.detailsBasicInfoSection, classes?.detailsBasicInfoSection)}
          {...internalProps.detailsBasicInfoSection}
          {...detailsBasicInfoSection}/>
        <DetailsClassInfoSection
          className={cx(styles.detailsClassInfoSection, classes?.detailsClassInfoSection)}
          {...internalProps.detailsClassInfoSection}
          {...detailsClassInfoSection}/>
      </div>
      <div className={cx(styles.rightContent, classes?.rightContent)}>
        <RegistrationStatusSection
          className={cx(styles.registrationStatusSection, classes?.registrationStatusSection)}
          {...internalProps.registrationStatusSection}
          {...registrationStatusSection}/>
        <CopyLinkSection
          className={cx(styles.copyLinkSection, classes?.copyLinkSection)}
          {...internalProps.copyLinkSection}
          {...copyLinkSection}/>
      </div>
      {/* MANUAL OVERRIDE */}
      <GenericModal
        className={styles.modalWrapper}
        {...updateRegistrationStatusWarningModal} />
    </div>
  );
};

export default DetailsBlock;

