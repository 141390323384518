import React from 'react';
import cx from 'classnames';

import Text from '../Text';

import styles from './ContextualMenuItem.module.scss';
import usePresenter from './ContextualMenuItem.presenter';
import getProps from './ContextualMenuItem.props';
import { ContextualMenuItemCombinedProps } from './types';


const ContextualMenuItem: React.FC<ContextualMenuItemCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    onClick,
    text,
    isMenuOpen,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`contextualMenuItem${variantName}`];


  let componentView;

  switch (variantName) {
    case 'Critical':
    case 'Default': {
      componentView = (
        <button
          tabIndex={isMenuOpen ? 0 : -1}
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ContextualMenuItem;

