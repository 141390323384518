import { StudentAppFeeBlockProps } from '../../blocks/StudentAppFeeBlock';
import { ConfirmAttendenceBlockProps } from '../../blocks/ConfirmAttendenceBlock';
import { StudentKoruAccountBlockProps } from '../../blocks/StudentKoruAccountBlock';
import { StudentSignInBlockProps } from '../../blocks/StudentSignInBlock';
import { StudentSignupBlockProps } from '../../blocks/StudentSignupBlock';
import { VerifyEmailBlockProps } from '../../blocks/VerifyEmailBlock';
import { CourseRegistrationSubheaderProps } from '../../organisms/CourseRegistrationSubheader';


type StudentKoruAccountPageInternalProps = {
  studentKoruAccountBlock?: StudentKoruAccountBlockProps;
  courseRegistrationSubheader?: CourseRegistrationSubheaderProps;
  studentSignupBlock?: StudentSignupBlockProps;
  studentSigninBlock?: StudentSignInBlockProps;
  verifyEmailBlock?: VerifyEmailBlockProps;
  studentAppFeeBlock?: StudentAppFeeBlockProps;
  confirmAttendenceBlock?: ConfirmAttendenceBlockProps;
};

type StudentKoruAccountPagePropsValues = {
  '': StudentKoruAccountPageInternalProps;
};

const propValues: StudentKoruAccountPagePropsValues = {
  '': {
    courseRegistrationSubheader: {
    },
    studentKoruAccountBlock: {
    },
    studentSignupBlock: {
    },
    studentSigninBlock: {
    },
    verifyEmailBlock:{
    },
    studentAppFeeBlock: {
    },
    confirmAttendenceBlock:{
    },
  },
};

const getProps = (type: string): StudentKoruAccountPageInternalProps => {
  const values: StudentKoruAccountPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
