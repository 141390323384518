import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import InputField from '../../molecules/InputField';
import TextGroup from '../../molecules/TextGroup';

import styles from './StudentSignInBlock.module.scss';
import usePresenter from './StudentSignInBlock.presenter';
import getProps from './StudentSignInBlock.props';
import { StudentSignInBlockCombinedProps } from './types';
import InlineNotification from '../../atoms/InlineNotification';


const StudentSignInBlock: React.FC<StudentSignInBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    stepperText,
    textGroup,
    emailField,
    passwordField,
    button,
    button1,
    showNotification,
    inlineNotification,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.studentSignInBlock;

  const inlineNotificationView = showNotification ? 
  <InlineNotification 
    className={cx(styles.inlineNotification, classes?.inlineNotification)}
    {...internalProps.inlineNotification}
    {...inlineNotification}
  /> : undefined;


  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.stepperText, classes?.stepperText)}
          {...internalProps.stepperText}
          {...stepperText}/>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        { inlineNotificationView }
        <InputField
          className={cx(styles.emailField, classes?.emailField)}
          {...internalProps.emailField}
          {...emailField}/>
        <InputField
          className={cx(styles.passwordField, classes?.passwordField)}
          {...internalProps.passwordField}
          {...passwordField}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.button1, classes?.button1)}
          {...internalProps.button1}
          {...button1}/>
      </div>
    </div>
  );
};

export default StudentSignInBlock;

