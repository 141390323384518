import React from 'react';
import cx from 'classnames';

import SelectField from '../SelectField';

import styles from './SelectFieldGroup.module.scss';
import usePresenter from './SelectFieldGroup.presenter';
import getProps from './SelectFieldGroup.props';
import { SelectFieldGroupCombinedProps } from './types';


const SelectFieldGroup: React.FC<SelectFieldGroupCombinedProps> = (props) => {
  const {
    className,
    classes,
    selectFieldOne,
    selectFieldTwo,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.selectFieldGroup;

  return (
    <div className={cx(currentStyle, className)}>
      <SelectField
        className={cx(styles.selectFieldOne, classes?.selectFieldOne)}
        {...internalProps.selectFieldOne}
        {...selectFieldOne}/>
      <SelectField
        className={cx(styles.selectFieldTwo, classes?.selectFieldTwo)}
        {...internalProps.selectFieldTwo}
        {...selectFieldTwo}/>
    </div>
  );
};

export default SelectFieldGroup;

