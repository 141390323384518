import React from 'react';
import cx from 'classnames';

import TextMessage from '../../molecules/TextMessage';

import styles from './TextMessageList.module.scss';
import usePresenter from './TextMessageList.presenter';
import getProps from './TextMessageList.props';
import { TextMessageListCombinedProps } from './types';


const TextMessageList: React.FC<TextMessageListCombinedProps> = (props) => {
  const {
    className,
    classes,
    textMessages,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.textMessageList;

  const textMessageViews = textMessages?.map((textMessage, index) => (
    <TextMessage
      key={index}
      className={cx(styles.textMessage, classes?.textMessage)}
      {...internalProps.textMessage}
      {...textMessage} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {textMessageViews}
    </div>
  );
};

export default TextMessageList;

