import React from 'react';
import cx from 'classnames';

import ContextualMenuItem from '../../atoms/ContextualMenuItem';

import styles from './ContextualMenuList.module.scss';
import usePresenter from './ContextualMenuList.presenter';
import getProps from './ContextualMenuList.props';
import { ContextualMenuListCombinedProps } from './types';


const ContextualMenuList: React.FC<ContextualMenuListCombinedProps> = (props) => {
  const {
    className,
    classes,
    contextualMenuItems,
    isMenuOpen,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.contextualMenuList;

  const contextualMenuItemViews = contextualMenuItems?.map((contextualMenuItem, index) => (
    <ContextualMenuItem
      key={index}
      isMenuOpen={isMenuOpen}
      className={cx(styles.contextualMenuItem, classes?.contextualMenuItem)}
      {...internalProps.contextualMenuItem}
      {...contextualMenuItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {contextualMenuItemViews}
    </div>
  );
};

export default ContextualMenuList;

