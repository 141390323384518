import { IconProps } from '../../atoms/Icon';
import { InitialsProps } from '../../atoms/Initials';
import { TextProps } from '../../atoms/Text';


type UserProfileInternalProps = {
  initials?: InitialsProps;
  text?: TextProps;
  icon?: IconProps;
};

type UserProfilePropsValues = {
  'Medium': UserProfileInternalProps;
  'Small': UserProfileInternalProps;
};

const propValues: UserProfilePropsValues = {
  'Medium': {
    initials: {
      size: '32',
      colour: 'Green',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    icon: {
      asset: 'ChevronDownSmall',
      colour: 'Base',
    },
  },
  'Small': {
    initials: {
      size: '32',
      colour: 'Green',
    },
    icon: {
      asset: 'ChevronDownSmall',
      colour: 'Base',
    },
  },
};

const getProps = (type: string): UserProfileInternalProps => {
  const values: UserProfileInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
