import React from 'react';
import cx from 'classnames';
import { Elements } from '@stripe/react-stripe-js';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import Address from '../../molecules/Address';
import DiscountCode from '../../molecules/DiscountCode';
import PriceListItem from '../../molecules/PriceListItem';
import RadioField from '../../molecules/RadioField';
import TextGroup from '../../molecules/TextGroup';
import PriceItemList from '../../organisms/PriceItemList';

import styles from './TuitionPaymentBlock.module.scss';
import usePresenter from './TuitionPaymentBlock.presenter';
import getProps from './TuitionPaymentBlock.props';
import { TuitionPaymentBlockCombinedProps } from './types';
import CardInfo from '../../molecules/CardInfo';
import InlineNotification from '../../atoms/InlineNotification';
import { stripeElementOptions, stripePromise } from '../../../lib/utils';


const TuitionPaymentBlock: React.FC<TuitionPaymentBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    stepperText,
    textGroup,
    priceItemList,
    discountCode,
    priceListItem,
    cardInfoField,
    radioField,
    address,
    button,
    isDifferentBillingAddress,
    inlineNotification,
    showNotification,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.tuitionPaymentBlock;

  const billingAddressView =
  <Address
    className={cx(styles.address, classes?.address)}
    display={isDifferentBillingAddress}
    {...internalProps.address}
    {...address}/>;

  const inlineNotificationView = showNotification ? 
  <InlineNotification 
    className={cx(styles.inlineNotification, classes?.inlineNotification)}
    {...internalProps.inlineNotification}
    {...inlineNotification}
  /> : undefined;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.stepperText, classes?.stepperText)}
          {...internalProps.stepperText}
          {...stepperText}/>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        { inlineNotificationView }
        <PriceItemList
          className={cx(styles.priceItemList, classes?.priceItemList)}
          {...internalProps.priceItemList}
          {...priceItemList}/>
        <DiscountCode
          className={cx(styles.discountCode, classes?.discountCode)}
          {...internalProps.discountCode}
          {...discountCode}/>
        <PriceListItem
          className={cx(styles.priceListItem, classes?.priceListItem)}
          {...internalProps.priceListItem}
          {...priceListItem}/>
        <CardInfo
          className={cx(styles.cardInfoField, classes?.cardInfoField)}
          {...internalProps.cardInfoField}
          {...cardInfoField}
        />
        <RadioField
          className={cx(styles.radioField, classes?.radioField)}
          {...internalProps.radioField}
          {...radioField}/>
        { billingAddressView }
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
    </div>
  );
};

const WrappedTuitionPaymentBlock = (props) => {
  return (
    <Elements
        stripe={stripePromise}
        options={stripeElementOptions}>
      <TuitionPaymentBlock {...props} />
    </Elements>
  );
};

export default WrappedTuitionPaymentBlock;

