import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { ClassListProps } from '../ClassList';
import { LabelTextGroupListProps } from '../LabelTextGroupList';


type DetailsClassInfoSectionInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
  divider?: DividerProps;
  labelTextGroupList?: LabelTextGroupListProps;
  classList?: ClassListProps;
};

type DetailsClassInfoSectionPropsValues = {
  '': DetailsClassInfoSectionInternalProps;
};

const propValues: DetailsClassInfoSectionPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    labelTextGroupList: {
    },
    classList: {
    },
  },
};

const getProps = (type: string): DetailsClassInfoSectionInternalProps => {
  const values: DetailsClassInfoSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
