import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';

import styles from './InlineNotification.module.scss';
import usePresenter from './InlineNotification.presenter';
import getProps from './InlineNotification.props';
import { InlineNotificationCombinedProps } from './types';


const InlineNotification: React.FC<InlineNotificationCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    icon,
    text,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`inlineNotification${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Error': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default InlineNotification;

