import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import ActionSection from '../ActionSection';

import styles from './StudentAppSection.module.scss';
import usePresenter from './StudentAppSection.presenter';
import getProps from './StudentAppSection.props';
import { StudentAppSectionCombinedProps } from './types';


const StudentAppSection: React.FC<StudentAppSectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,
    infoText,
    text1,
    text2,
    actionSection,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.studentAppSection;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <Text
        className={cx(styles.infoText, classes?.infoText)}
        {...internalProps.infoText}
        {...infoText}/>
      <div className={cx(styles.status, classes?.status)}>
        <Text
          className={cx(styles.text1, classes?.text1)}
          {...internalProps.text1}
          {...text1}/>
        <div className={cx(styles.remainingApps, classes?.remainingApps)}>
          <Text
            className={cx(styles.text2, classes?.text2)}
            {...internalProps.text2}
            {...text2}/>
        </div>
      </div>
      <ActionSection
        className={cx(styles.actionSection, classes?.actionSection)}
        {...internalProps.actionSection}
        {...actionSection}/>
    </div>
  );
};

export default StudentAppSection;
