import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { LabelTextGroupListProps } from '../LabelTextGroupList';


type LogEntryInternalProps = {
  studentNameText?: TextProps;
  timeStampText?: TextProps;
  labelTextGroupList?: LabelTextGroupListProps;
  divider?: DividerProps;
};

type LogEntryPropsValues = {
  '': LogEntryInternalProps;
};

const propValues: LogEntryPropsValues = {
  '': {
    studentNameText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading2',
    },
    timeStampText: {
      style: 'Regular',
      align: 'Right',
      colour: 'Default',
      type: 'Body1',
    },
    labelTextGroupList: {
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
};

const getProps = (type: string): LogEntryInternalProps => {
  const values: LogEntryInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
