import React from 'react';
import cx from 'classnames';

import GenericModal from '../../organisms/GenericModal';
import PaymentHistorySection from '../../organisms/PaymentHistorySection';
import PaymentInfoSection from '../../organisms/PaymentInfoSection';

import styles from './PaymentBlock.module.scss';
import usePresenter from './PaymentBlock.presenter';
import getProps from './PaymentBlock.props';
import { PaymentBlockCombinedProps } from './types';


const PaymentBlock: React.FC<PaymentBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    paymentHistorySection,
    paymentInfoSection,
    paymentBlockModal,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.paymentBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.leftContent, classes?.leftContent)}>
        <PaymentHistorySection
          className={cx(styles.paymentHistorySection, classes?.paymentHistorySection)}
          {...internalProps.paymentHistorySection}
          {...paymentHistorySection}/>
      </div>
      <div className={cx(styles.rightContent, classes?.rightContent)}>
        <PaymentInfoSection
          className={cx(styles.paymentInfoSection, classes?.paymentInfoSection)}
          {...internalProps.paymentInfoSection}
          {...paymentInfoSection}/>
      </div>
      <GenericModal
        className={styles.modalWrapper}
        {...paymentBlockModal} />
    </div>
  );
};

export default PaymentBlock;

