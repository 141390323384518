import { ButtonProps } from '../../atoms/Button';
import { InlineNotificationProps } from '../../atoms/InlineNotification';
import { TextProps } from '../../atoms/Text';
import { InputFieldProps } from '../../molecules/InputField';
import { TextGroupProps } from '../../molecules/TextGroup';


type StudentSignInBlockInternalProps = {
  stepperText?: TextProps;
  textGroup?: TextGroupProps;
  emailField?: InputFieldProps;
  passwordField?: InputFieldProps;
  button?: ButtonProps;
  button1?: ButtonProps;
  inlineNotification?: InlineNotificationProps;
};

type StudentSignInBlockPropsValues = {
  '': StudentSignInBlockInternalProps;
};

const propValues: StudentSignInBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    textGroup: {
    },
    emailField: {
      state: 'Default',
    },
    passwordField: {
      state: 'Default',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'PrimaryAction',
    },
    button1: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
    },
    inlineNotification: {
      type: 'Error',
    },
  },
};

const getProps = (type: string): StudentSignInBlockInternalProps => {
  const values: StudentSignInBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
