import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { AUTH_ROUTES, MIGRATION_NOT_COMPLETE_MESSAGE } from '../../../lib/constants';
import { KORU_DASHBOARD_URL } from '../../../lib/config';
import { getRegistrationStepRoute, getUserRegistrationStatus, isEmptyString } from '../../../lib/utils';
import { AuthContext } from '../../../modules/auth';
import { UserContext } from '../../../modules/user/UserContext';
import { signInUseCase } from './SigninBlock.interactor';
import { LocationState, SigninBlockCombinedProps, SignInState } from './types';
import { getRedirectUrl } from './utils';
import { NotificationMsg } from '../StudentSignInBlock/types';

const formInitialState: SignInState = {
  email: '',
  password: '',
};

const usePresenter = (props: SigninBlockCombinedProps): SigninBlockCombinedProps => {
  const history = useHistory();
  const { t } = useTranslation();
  const { refetchAccount } = useContext(AuthContext);
  const { user, refetchUser } = useContext(UserContext);

  const { search, state: locationState } = useLocation<LocationState>();
  const query = new URLSearchParams(search);
  const target = query.get('target');
  const [sessionToken, setSessionToken] = useState<string | undefined>(undefined);

  const [formState, setFormState] = useState<SignInState>(formInitialState);
  const [formErrorState, setFormErrorState] = useState<SignInState>(formInitialState);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<NotificationMsg>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { mutateAsync: signIn } = useMutation('signIn', async () => {
    try {
      const data = await signInUseCase({
        email: formState.email,
        password: formState.password,
      });
      return data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const isMigrationError = error.response?.data.message === MIGRATION_NOT_COMPLETE_MESSAGE;
        const isForbiddenError = error.response?.data?.code === 403;
        if (isForbiddenError && isMigrationError) {
          setNotificationMessage(NotificationMsg.migrationWarning);
        } else {
          setNotificationMessage(NotificationMsg.invalidCreds);
        }
        setShowNotification(true);
      }
    }
  });

  useEffect(() => {
    if (user && sessionToken) {
      const registrationStatus = getUserRegistrationStatus(user);
      const registrationStepRoute = getRegistrationStepRoute(registrationStatus);

      if (registrationStepRoute && registrationStepRoute !== AUTH_ROUTES.alreadyRegistered) {
        history.replace(registrationStepRoute);
        return;
      }

      const koruUrl = `${KORU_DASHBOARD_URL}/auto-login?token=${sessionToken}&target=${target ? target : getRedirectUrl(user)}`;
      window.location.assign(koruUrl);
    }
  }, [user, sessionToken, target, history]);

  useEffect(() => {
    if (locationState?.sessionEnd) {
      setShowNotification(true);
      setNotificationMessage(NotificationMsg.expiredSession);
    }
  }, [locationState]);

  const goToForgotPassword = () => {
    history.push(AUTH_ROUTES.forgotPassword);
  };

  const handleTextChange = (field: keyof SignInState) => {
    return (value: string) => {
      setFormState({
        ...formState,
        [field]: value,
      });

      setFormErrorState({
        ...formErrorState,
        [field]: '',
      });
    };
  };

  const isFormValid = (): boolean => {
    const invalidFormErrors = {
      email: isEmptyString(formState.email) ? t('error.field_required') : '',
      password: isEmptyString(formState.password) ? t('error.field_required') : '',
    };
    setFormErrorState(invalidFormErrors);
    return (
      Object.values(invalidFormErrors).filter((errorMessage: string) => !!errorMessage)
        .length === 0
    );
  };

  const handleSignIn = async () => {
    try {
      setShowNotification(false);
      setIsLoading(true);
      if (isFormValid()) {
        const accountResponse = await signIn();
        if (accountResponse) {
          setSessionToken(accountResponse.token);
          refetchAccount();
          await refetchUser();
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getCorrectNotificationMsg  = (): string => {
    switch (notificationMessage) {
      case NotificationMsg.expiredSession:
        return t('notification.expired_session');
      case NotificationMsg.migrationWarning:
        return t('notification.migration_warning');
      case NotificationMsg.invalidCreds:
        return t('notification.invalid_email_or_password');
      default:
        return '';
    }
  };
  
  return {
    ...props,
    textGroup: {
      topText: {
        value: t('signIn.title'),
      },
      bottomText: {
        value: t('signIn.description'),
      },
    },
    emailField: {
      state: formErrorState.email ? 'Error' : 'Default',
      text: {
        value: t('signIn.labels.email'),
      },
      textInput: {
        maxLength: 255,
        textValue: formState.email,
        onTextChanged: handleTextChange('email'),
      },
      contextualContent: {
        text: {
          value: formErrorState.email,
        },
      },
    },
    passwordField: {
      state: formErrorState.password ? 'Error' : 'Default',
      text: {
        value: t('signIn.labels.password'),
      },
      textInput: {
        maxLength: 128,
        button: {
          icon: {
            asset: showPassword ? 'Hide' : 'Show',
          },
          onClick: () => {
            setShowPassword(!showPassword);
          },
        },
        type: 'Password',
        textValue: formState.password,
        onTextChanged: handleTextChange('password'),
        htmlType: showPassword ? 'text' : 'password',
      },
      contextualContent: {
        text: {
          value: formErrorState.password,
        },
      },
    },
    button: {
      icon: {
        asset: 'Question',
      },
      text: {
        value: t('signIn.button.forgot_password'),
      },
      onClick: goToForgotPassword,
      loading: 'Default',
    },
    button1: {
      icon: {
        asset: 'ArrowRight',
      },
      text: {
        value: t('signIn.button.login'),
      },
      onClick: handleSignIn,
      loading: isLoading ? 'Loading' : 'Default',
      disabled: isLoading,
    },
    inlineNotification: {
      text: {
        value: getCorrectNotificationMsg(),
      },
    },
    showNotification,
  };
};

export default usePresenter;
