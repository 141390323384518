import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Icon from '../../atoms/Icon';

import styles from './IconTextGroup.module.scss';
import usePresenter from './IconTextGroup.presenter';
import getProps from './IconTextGroup.props';
import { IconTextGroupCombinedProps } from './types';


const IconTextGroup: React.FC<IconTextGroupCombinedProps> = (props) => {
  const {
    className,
    classes,
    labelIcon,
    infoText,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.iconTextGroup;

  return (
    <div className={cx(currentStyle, className)}>
      <Icon
        className={cx(styles.labelIcon, classes?.labelIcon)}
        {...internalProps.labelIcon}
        {...labelIcon}/>
      <Text
        className={cx(styles.infoText, classes?.infoText)}
        {...internalProps.infoText}
        {...infoText}/>
    </div>
  );
};

export default IconTextGroup;

