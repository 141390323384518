import { InputFieldGroupProps } from '../InputFieldGroup';
import { SelectFieldGroupProps } from '../SelectFieldGroup';
import { InputFieldProps } from '../InputField';


type AddressInternalProps = {
  streetAddressInputField?: InputFieldProps;
  selectFieldGroup?: SelectFieldGroupProps;
  inputFieldGroup?: InputFieldGroupProps;
};

type AddressPropsValues = {
  '': AddressInternalProps;
};

const propValues: AddressPropsValues = {
  '': {
    streetAddressInputField: {
      state: 'Default',
    },
    selectFieldGroup: {
    },
    inputFieldGroup: {
    },
  },
};

const getProps = (type: string): AddressInternalProps => {
  const values: AddressInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
