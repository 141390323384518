import React from 'react';
import cx from 'classnames';

import Initials from '../../atoms/Initials';
import Text from '../../atoms/Text';

import styles from './TextMessage.module.scss';
import usePresenter from './TextMessage.presenter';
import getProps from './TextMessage.props';
import { TextMessageCombinedProps } from './types';


const TextMessage: React.FC<TextMessageCombinedProps> = (props) => {
  const {
    type,
    align,
    className,
    classes,
    text,
    initials,
    text1,
    textMessageRef,
  } = usePresenter(props);

  const variantName = `${type}${align}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`textMessage${variantName}`];

  let componentView;

  switch (variantName) {
    case 'CoteacherLeft':
    case 'StudentRight':
    case 'TeacherLeft': {
      componentView = (
        <div className={cx(currentStyle, className)} ref={textMessageRef}>
          <div className={cx(styles.textBubble, classes?.textBubble)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
          <div className={cx(styles.textBubbleTag, classes?.textBubbleTag)}>
            <Initials
              className={cx(styles.initials, classes?.initials)}
              {...internalProps.initials}
              {...initials}/>
            <Text
              className={cx(styles.text1, classes?.text1)}
              {...internalProps.text1}
              {...text1}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TextMessage;

