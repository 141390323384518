import React from 'react';
import cx from 'classnames';

import CoursesHeader from '../../organisms/CoursesHeader';

import styles from './CoursesPage.module.scss';
import usePresenter from './CoursesPage.presenter';
import getProps from './CoursesPage.props';
import { CoursesPageCombinedProps } from './types';
import GenericStateThreeBlock from '../../blocks/GenericStateThreeBlock';
import CoursesBlock from '../../blocks/CoursesBlock';
import GenericModal from '../../organisms/GenericModal';
import LoadingSpinner from '../../atoms/LoadingSpinner';


const CoursesPage: React.FC<CoursesPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    coursesHeader,
    genericStateThreeBlock,
    coursesBlock,
    type, // MANUAL OVERRIDE
    updateRegistrationStatusWarningModal, // MANUAL OVERRIDE
    archiveCourseWarningModal, // MANUAL OVERRIDE
    isLoading,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles.coursesPage;

  // MANUAL OVERRIDE
  let componentView;
  switch (variantName) {
    case 'Active':
      componentView = (
        <CoursesBlock
          className={cx(styles.coursesBlock, classes?.coursesBlock)}
          {...internalProps.coursesBlock}
          {...coursesBlock}
        />
      );
      break;

    case 'Empty':
      componentView = (
        <GenericStateThreeBlock 
          className={cx(styles.genericStateThreeBlock, classes?.genericStateThreeBlock)}
          {...internalProps.genericStateThreeBlock}
          {...genericStateThreeBlock}/>
      );
      break;

    default:
      componentView = <div>�</div>;
      break;
  }

  componentView = isLoading ? (
    <LoadingSpinner
      className={cx(styles.loadingSpinner)}
      size='Huge'
      styleType='Primary'
      isActive
    />
  ) : componentView;

  return (
    <div className={cx(currentStyle, className)}>
      <CoursesHeader
        className={cx(styles.coursesHeader, classes?.coursesHeader)}
        {...internalProps.coursesHeader}
        {...coursesHeader}/>
      <div className={cx(styles.content, classes?.content)}>
        {/*  MANUAL OVERRIDE */}
        {componentView}
      </div>
      <GenericModal
        className={styles.modalWrapper}
        {...updateRegistrationStatusWarningModal} />
      <GenericModal
        className={styles.modalWrapper}
        {...archiveCourseWarningModal} />
    </div>
  );
};

export default CoursesPage;

