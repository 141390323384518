import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { getPersonName, getPersonNameInitials } from '../../../lib/utils';
import { Class } from '../../../modules/class/types';
import { EnrollmentStatus, Roster } from '../../../modules/course/types';
import { Student } from '../../../modules/student/types';
import { StudentItemValueProps } from '../../molecules/StudentItem';
import { TextIconGroupValueProps } from '../../molecules/TextIconGroup/types';
import { CUSTOM_EMAIL_INDEX, initialColours } from './constants';
import { getCourseRosterUseCase, removeStudentUseCase, waitlistStudentUseCase, enrollStudentUseCase } from './RosterBlock.interactor';
import { RosterBlockCombinedProps } from './types';
import styles from './RosterBlock.module.scss';
import { GenericModalValueProps } from '../../organisms/GenericModal';
import { getTranslation } from '../../../lib/reactUtils';
import { useNotification } from '../../../modules/notification';
import { NOTIFICATION_DURATION } from '../../../lib/constants';
import { UserContext } from '../../../modules/user/UserContext';
import { DataEntry } from '../../../lib/types';
import { fallbackCopyTextToClipboard, getEmailsFromRosters } from './utils';
import { ContextualMenuItemCombinedProps } from '../../atoms/ContextualMenuItem/types';

const usePresenter = (props: RosterBlockCombinedProps): RosterBlockCombinedProps => {
  const { t } = useTranslation();
  const { course } = props;
  const { trigger } = useNotification();
  const { user, refetchUser } = useContext(UserContext);
  const [enrolled, setEnrolled] = useState<StudentItemValueProps[]>([]);
  const [waitlisted, setWaitlisted] = useState<StudentItemValueProps[]>([]);
  const [removed, setRemoved] = useState<StudentItemValueProps[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<EnrollmentStatus>();
  const [selectedStudent, setSelectedStudent] = useState<DataEntry<Student>>();

  const { mutateAsync: removeStudent, isLoading: removeStudentLoading } = useMutation(['removeStudent', selectedStudent, course?.content?.id], async () => {
    if (course?.content?.id && selectedStudent?.content?.email) {
      return removeStudentUseCase(course.content.id, selectedStudent.content.email);
    }
  });

  const { mutateAsync: waitListStudent, isLoading: waitlistStudentLoading } = useMutation(['joinWaitlist', selectedStudent, course?.content?.id], async () => {
    if (course?.content?.id && selectedStudent?.content?.email) {
      return waitlistStudentUseCase(course.content.id, selectedStudent.content.email);
    }
  });

  const { mutateAsync: enrollStudent, isLoading: enrollStudentLoading } = useMutation(['enrollStudent', selectedStudent, course?.content?.id], async () => {
    if (course?.content?.id && selectedStudent?.content?.email) {
      return enrollStudentUseCase(course.content.id, selectedStudent.content.email);
    }
  });

  const { data: expandedRoster, refetch, isRefetching, isLoading } = useQuery(['getCourseRoster', course?.content?.id, course], async () => {
    try {
      if (course?.content?.id) {
        const enrollmentStatus = await getCourseRosterUseCase(course.content.id);
        return enrollmentStatus;
      }
    } catch (error) {
      // no-op
    }
  });

  const closeModal = () => {
    setShowModal(false);
  };

  const handleMenuClicked = (status: EnrollmentStatus, newStudent: DataEntry<Student>, event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      event.stopPropagation();
    }
    setShowModal(true);
    setModalType(status);
    setSelectedStudent(newStudent);
  };

  const handleRemoveStudent = async () => {
    await removeStudent();
    await refetch();
    if (!removeStudentLoading && !isRefetching) {
      setShowModal(false);
      trigger({
        type: 'Success',
        duration: NOTIFICATION_DURATION,
        message: t('roster_block.remove_student_modal.notification'),
      });
    }
  };

  const handleWaitlistStudent = async () => {
    await waitListStudent();
    await refetch();
    if (!removeStudentLoading && !isRefetching) {
      setShowModal(false);
      trigger({
        type: 'Success',
        duration: NOTIFICATION_DURATION,
        message: t('roster_block.waitlist_student_modal.notification'),
      });
    }
  };

  const handleEnrollStudent = async () => {
    await enrollStudent();
    await refetch();
    await refetchUser();
    if (!enrollStudentLoading && !isRefetching) {
      setShowModal(false);
      trigger({
        type: 'Success',
        duration: NOTIFICATION_DURATION,
        message: t('roster_block.enroll_student_modal.notification'),
      });
    }
  };

  const renderModal = (): GenericModalValueProps | undefined => {
    switch (modalType) {
      case (EnrollmentStatus.removed): {
        return {
          show: showModal,
          onHide: closeModal,
          modalProps: {
            type: 'Critical',
            button: {
              icon: {
                asset: 'Close',
              },
              onClick: closeModal,
            },
            primaryButton: {
              text: {
                value: t('roster_block.remove_student_modal.primary_button'),
              },
              onClick: handleRemoveStudent,
              loading: removeStudentLoading ? 'Loading' : 'Default',
              disabled: removeStudentLoading,
            },
            secondaryButton: {
              text: {
                value: t('roster_block.remove_student_modal.secondary_button'),
              },
              onClick: closeModal,
            },
            text: {
              value: t('roster_block.remove_student_modal.title'),
            },
            text1: {
              value: getTranslation('roster_block.remove_student_modal.description'),
            },
          },
        } as GenericModalValueProps;
      }
      case (EnrollmentStatus.waitlisted): {
        return {
          show: showModal,
          onHide: closeModal,
          modalProps: {
            type: 'Default',
            button: {
              icon: {
                asset: 'Close',
              },
              onClick: closeModal,
            },
            primaryButton: {
              text: {
                value: t('roster_block.waitlist_student_modal.primary_button'),
              },
              onClick: handleWaitlistStudent,
              loading: waitlistStudentLoading ? 'Loading' : 'Default',
              disabled: waitlistStudentLoading,
            },
            secondaryButton: {
              text: {
                value: t('roster_block.waitlist_student_modal.secondary_button'),
              },
              onClick: closeModal,
            },
            text: {
              value: t('roster_block.waitlist_student_modal.title'),
            },
            text1: {
              value: t('roster_block.waitlist_student_modal.description'),
            },
          },
        } as GenericModalValueProps;
      }
      case (EnrollmentStatus.enrolled): {
        return {
          show: showModal,
          onHide: closeModal,
          modalProps: {
            type: 'Default',
            button: {
              icon: {
                asset: 'Close',
              },
              onClick: closeModal,
            },
            primaryButton: {
              text: {
                value: t('roster_block.enroll_student_modal.primary_button'),
              },
              onClick: handleEnrollStudent,
              loading: enrollStudentLoading ? 'Loading' : 'Default',
              disabled: enrollStudentLoading || (!user?.content?.apps && !selectedStudent?.content?.paidAppFee),
            },
            secondaryButton: {
              text: {
                value: t('roster_block.enroll_student_modal.secondary_button'),
              },
              onClick: closeModal,
            },
            text: {
              value: t('roster_block.enroll_student_modal.title'),
            },
            text1: {
              value: getTranslation('roster_block.enroll_student_modal.description', {}, {}, {
                ul: <ul className={styles.requirementsList} />,
                li: <li />,
              }),
            },
          },
        } as GenericModalValueProps;
      }
    }

  };

  const renderMenuItems = (
    student: DataEntry<Student>,
    status: EnrollmentStatus,
  ): ContextualMenuItemCombinedProps[] | undefined => {
    const removeStudentBlock: ContextualMenuItemCombinedProps = {
      type: 'Critical',
      text: {
        value: t('roster_block.remove_student'),
      },
      onClick: (event) => handleMenuClicked(EnrollmentStatus.removed, student, event),
    };
    const enrollStudentBlock: ContextualMenuItemCombinedProps = {
      text: {
        colour: 'Default',
        value: t('roster_block.enroll_student'),
      },
      onClick: (event) => handleMenuClicked(EnrollmentStatus.enrolled, student, event),
    };
    const waitlistStudentBlock: ContextualMenuItemCombinedProps = {
      text: {
        colour: 'Default',
        value: t('roster_block.waitlist_student'),
      },
      onClick: (event) => handleMenuClicked(EnrollmentStatus.waitlisted, student, event),
    };
    switch (status) {
      case EnrollmentStatus.enrolled:
        return [
          removeStudentBlock,
          waitlistStudentBlock,
        ];
      case EnrollmentStatus.waitlisted:
        return [
          enrollStudentBlock,
          removeStudentBlock,
        ];
      case EnrollmentStatus.removed:
        return [
          enrollStudentBlock,
          waitlistStudentBlock,
        ];
      default:
        break;
    }
  };

  const createStudentItemValue = useCallback((
    student: DataEntry<Student>,
    index: number,
    status: EnrollmentStatus,
  ): StudentItemValueProps => {
    const { email, phoneNumber } = student.content;
    return {
      nameText: {
        value: getPersonName(student),
      },
      initials: {
        text: {
          value: getPersonNameInitials(student),
        },
        colour: initialColours[index % initialColours.length],
      },
      infoText: {
        value: t('roster_block.info_text', {
          email,
          phoneNumber,
        }),
      },
      iconDropdown: {
        toggleProps: {
          button: {
            icon: {
              asset: 'VerticalMenu',
              colour: 'Inverse',
            },
          },
        },
        menuProps: {
          contextualMenuList: {
            contextualMenuItems: renderMenuItems(student, status),
          },
          className: styles.contextualMenu,
        },
        alignEnd: true,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStudentLists = useCallback((rosters: DataEntry<Roster>[]) => {
    const enrolledStudents: StudentItemValueProps[] = [];
    const waitListedStudents: StudentItemValueProps[] = [];
    const removedStudents: StudentItemValueProps[] = [];
    rosters.forEach((roster) => {
      const { student } = roster.content;
      switch (roster.content.enrollmentStatus) {
        case EnrollmentStatus.enrolled:
          enrolledStudents.push(createStudentItemValue(
            student,
            enrolledStudents.length,
            EnrollmentStatus.enrolled,
          ));
          break;
        case EnrollmentStatus.waitlisted:
          waitListedStudents.push(createStudentItemValue(
            student,
            waitListedStudents.length,
            EnrollmentStatus.waitlisted,
          ));
          break;
        case EnrollmentStatus.removed:
          removedStudents.push(createStudentItemValue(
            student,
            removedStudents.length,
            EnrollmentStatus.removed,
          ));
          break;
        default:
          break;
      }
    });
    setEnrolled(enrolledStudents);
    setWaitlisted(waitListedStudents);
    setRemoved(removedStudents);
  }, [createStudentItemValue]);

  const onReminderClicked = useCallback((index: number, rosters?: DataEntry<Roster>[], event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      event.preventDefault();
    }
    if (rosters && typeof index === 'number') {
      const studentEmails = getEmailsFromRosters(rosters);
      let emailString = t('email_reminder.custom', { students: studentEmails });
      if (index < CUSTOM_EMAIL_INDEX) {
        emailString = t(`email_reminder.${index}`, { students: studentEmails });
      }
      window.location.href = emailString;
    }
  }, [t]);

  const createTextIconGroupList = useCallback((classes: DataEntry<Class>[]) => {
    const textIconGroupList: TextIconGroupValueProps[] = [];
    classes.forEach((courseClass, index) => {
      const textIconGroup: TextIconGroupValueProps = {
        infoText: {
          value: t('roster_block.reminder', {
            classNumber: index + 1,
          }),
        },
        onClick: (event) => onReminderClicked(index, expandedRoster, event),
      };
      textIconGroupList.push(textIconGroup);
    });
    return textIconGroupList;
  }, [t, onReminderClicked, expandedRoster]);

  useEffect(() => {
    if (expandedRoster) {
      updateStudentLists(expandedRoster);
    }
  }, [expandedRoster, updateStudentLists]);

  const onCopyClick = async () => {
    const studentEmails = getEmailsFromRosters(expandedRoster || []);
    try {
      if (!navigator.clipboard) {
        // Asynchronous Clipboard API not supported, fallback to document.execCommand('copy') which
        // is deprecated for modern browsers
        fallbackCopyTextToClipboard(studentEmails, trigger);
        return;
      }
      await navigator.clipboard.writeText(studentEmails);
      trigger({
        type: 'Success',
        duration: NOTIFICATION_DURATION,
        message: t('notification.emails_copied'),
      });
    } catch (err) {
      // TODO
    }
  };

  return {
    ...props,
    isLoading,
    enrolledStudentsSection: {
      text: {
        value: t('roster_block.enrolled'),
      },
      studentsList: {
        studentItems: enrolled,
      },
    },
    waitlistSection: {
      text: {
        value: t('roster_block.waitlist'),
      },
      studentsList: {
        studentItems: waitlisted,
      },
    },
    removedStudentsSection: {
      text: {
        value: t('roster_block.removed'),
      },
      studentsList: {
        studentItems: removed,
      },
    },
    reminderEmailsSection: {
      text: {
        value: t('roster_block.email'),
      },
      textIconGroupList: {
        textIconGroups: course?.content?.classes && createTextIconGroupList(course?.content?.classes),
      },
      textIconGroup: {
        infoText: {
          value: t('roster_block.custom_reminder'),
        },
        // use a random number that's not a template (0-3)
        onClick: (event) => onReminderClicked(CUSTOM_EMAIL_INDEX, expandedRoster, event),
      },
    },
    copyStudentsEmailSection: {
      text: {
        value: t('roster_block.copy_student_emails'),
      },
      bodyText: {
        value: t('roster_block.copy_student_emails_description'),
      },
      copyEmailButton: {
        text: {
          value: t('roster_block.copy_student_emails'),
        },
        icon: {
          asset: 'Copy',
          colour: 'Inverse',
        },
        onClick: () => onCopyClick(),
      },
    },
    genericModal: renderModal(),
  };
};

export default usePresenter;
