import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';

import styles from './Button.module.scss';
import usePresenter from './Button.presenter';
import getProps from './Button.props';
import { ButtonCombinedProps } from './types';


const Button = React.forwardRef<HTMLButtonElement, ButtonCombinedProps>((props, ref) => {
  const {
    style,
    size,
    type,
    colour,
    loading,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    onClick,
    icon,
    text,
  } = usePresenter(props);

  const variantName = `${style}${size}${type}${colour}${loading}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`button${variantName}`];

  // MANUAL OVERRIDE
  const loadingSpinner = loading === 'Loading' ? (<div className={styles.loader}></div>) : 
    (<Icon
      className={cx(styles.icon, classes?.icon)}
      {...internalProps.icon}
      {...icon}
    />);
  let componentView;

  switch (variantName) {
    case 'ContainedLargeIconTextPrimaryActionDefault':
    case 'TextSmallIconTextPrimaryActionDefault':
    case 'TextSmallTextIconBaseDefault': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          ref={ref} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
        </button>
      );
      break;
    }
    case 'ContainedLargeTextCriticalDefault':
    case 'ContainedLargeTextPrimaryActionDefault':
    case 'OutlineLargeTextCriticalDefault':
    case 'OutlineLargeTextPrimaryActionDefault':
    case 'TextSmallTextPrimaryActionDefault': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          ref={ref} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
        </button>
      );
      break;
    }
    case 'ContainedLargeTextIconPrimaryActionDefault':
    case 'ContainedLargeTextIconCriticalLoading':
    case 'ContainedLargeTextIconPrimaryActionLoading':
    case 'ContainedLargeTextPrimaryActionLoading':
    case 'ContainedLargeTextCriticalLoading':
    case 'TextSmallTextIconPrimaryActionDefault': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          ref={ref} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
            { loadingSpinner }
          </div>
        </button>
      );
      break;
    }
    case 'TextSmallIconBaseDefault':
    case 'TextSmallIconPrimaryActionDefault': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          ref={ref} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
          </div>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
});

export default Button;

