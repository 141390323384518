import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './index.css';
import App from './App';
import AppProvider from './AppProvider';
import * as serviceWorker from './serviceWorker';
import { SENTRY_ENV_FRONTEND, SENTRY_DSN, SENTRY_TRACES_SAMPLE_RATE } from './lib/config';
import { initializeTracking } from './lib/trackingUtils';

initializeTracking();

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV_FRONTEND,
  integrations: [new BrowserTracing()],
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,

  // Manually ignored errors and URLs
  ignoreErrors: [],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
