import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountExpiredPageCombinedProps } from './types';
import styles from './AccountExpiredPage.module.scss';
import { GenericStateBlockValueProps } from '../../blocks/GenericStateBlock';
import { KORU_TEACHER_ANNUAL_PLAN_URL, MAX_PURCHASED_EXTENSIONS } from '../../../lib/constants';
import { getTranslation } from '../../../lib/reactUtils';
import { UserContext } from '../../../modules/user/UserContext';
import ExpiredState from '../../../resources/images/ExpiredState.png';
import { ModalContext } from '../../../modules/user/ModalContext';
import { JourneyStatusEnum } from '../../../modules/teacher/types';
import { ModalState } from '../../../modules/user/ModalContext';
import { trackViewPurchaseAnnualPlanModal } from '../../../lib/trackingUtils';


const usePresenter = (props: AccountExpiredPageCombinedProps): AccountExpiredPageCombinedProps => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { setModalState } = useContext(ModalContext);

  const openPurchaseModal = (modalState: ModalState): void => {
    if (modalState === 'PURCHASE_ANNUAL_PLAN') {
      trackViewPurchaseAnnualPlanModal();
    }
    setModalState(modalState);
  };

  const getPropBasedOnJourneyStatus = (status?: JourneyStatusEnum): GenericStateBlockValueProps => {
    const genericBlockProps: GenericStateBlockValueProps = {
      image: {
        imageSrc: ExpiredState,
      },
    };

    switch (status) {
      case JourneyStatusEnum.graduate:
      case JourneyStatusEnum.certified:
        return {
          ...genericBlockProps,
          topText: {
            value: t('account_expired.title', {
              name: user && user.content.firstName,
            }),
          },
          bottomText: {
            value: getTranslation('account_expired.bottomText', {}, {
              className: styles.fontVariant,
            }, {
              learn: <a
                className={styles.link}
                target='_blank'
                rel='noreferrer noopener'
                href={KORU_TEACHER_ANNUAL_PLAN_URL}
              >{t('account_expired.bottomText')}
              </a>,
            }),
          },
          button: {
            loading: 'Default',
            text: {
              value: t('account_expired.annual_plan'),
            },
            onClick: () => openPurchaseModal('PURCHASE_ANNUAL_PLAN'),
          },
        };
      case JourneyStatusEnum.in_training:
        return {
          ...genericBlockProps,
          topText: {
            value: t('training_expired.title'),
          },
          bottomText: {
            value: getTranslation('training_expired.bottomText', {}, {
              className: styles.fontVariant,
            }),
          },
          button: {
            loading: 'Default',
            disabled: user?.content?.purchasedExtensions === MAX_PURCHASED_EXTENSIONS,
            text: {
              value: t('training_expired.extend_training_period'),
            },
            onClick: () => openPurchaseModal('TRAINING_EXTENSION'),
          },
        };
      default:
        return genericBlockProps;
    }
  };

  return {
    className: props.className,
    genericStateBlock: getPropBasedOnJourneyStatus(user?.content?.journeyStatus),
  };
};

export default usePresenter;
