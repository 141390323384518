import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { AUTH_ROUTES } from '../../../lib/constants';
import { isEmail, isEmptyString } from '../../../lib/utils';
import { forgotPasswordUseCase } from './ForgotPasswordBlock.interactor';

import { ForgotPasswordBlockCombinedProps, ResetPasswordState } from './types';

const usePresenter = (props: ForgotPasswordBlockCombinedProps): ForgotPasswordBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state = { isStudentFlow: false } } = useLocation<ResetPasswordState>();
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);

  const { mutateAsync: forgotPassword, isLoading } = useMutation(['forgotPassword', email], async () => {
    if (email) {
      await forgotPasswordUseCase(email);
    }
  });

  const goToLogin = () => {
    history.replace(AUTH_ROUTES.signIn);
  };

  const handleTextChange = (value: string) => {
    setEmail(value);
    if (value) {
      setEmailError(undefined);
    }
  };

  const isFormValid = (): boolean => {
    if (isEmptyString(email)) {
      setEmailError(t('forgot_password.errors.no_email'));
      return false;
    } else if (email && !isEmail(email)) {
      setEmailError(t('forgot_password.errors.invalid_email'));
      return false;
    }
    return true;
  };

  const handleSendResetEmailCLick = async () => {
    if (isFormValid()) {
      try {
        await forgotPassword();
        setEmailError(undefined);
        const { isStudentFlow, ...course } = state;
        history.push(AUTH_ROUTES.forgotPasswordConfirmed, {
          email,
          isStudentFlow: isStudentFlow,
          course,
        });
      } catch (error) {
        if (error instanceof AxiosError && error.response?.data.code === 404) {
          setEmailError(t('forgot_password.errors.invalid_email'));
        }
        // TODO: show error for not able to send reset password link
      }
    }
  };

  return {
    ...props,
    hideBackButton: state?.isStudentFlow,
    button: {
      text: {
        value: t('forgot_password.back_button'),
      },
      icon: {
        asset: 'ArrowLeft',
      },
      onClick: goToLogin,
    },
    textGroup: {
      topText: {
        value: t('forgot_password.title'),
      },
      bottomText: {
        value: t('forgot_password.description'),
      },
    },
    emailField: {
      state: emailError ? 'Error' : 'Default',
      text: {
        value: t('forgot_password.email_label'),
      },
      textInput: {
        textValue: email,
        onTextChanged: handleTextChange,
      },
      contextualContent: {
        text: {
          value: emailError,
        },
      },
    },
    button1: {
      text: {
        value: t('forgot_password.send_reset_link'),
      },
      icon: {
        asset: 'ArrowRight',
      },
      onClick: handleSendResetEmailCLick,
      loading: isLoading ? 'Loading' : 'Default',
      disabled: isLoading,
    },
  };
};

export default usePresenter;
