import { KORU_DASHBOARD_URL, FRONTEND_URL } from '../../../lib/config';
import { ACCOUNT_STATUS_ROUTE, AUTH_ROUTES } from '../../../lib/constants';
import { User } from '../../../lib/token';
import { getIsAccessRestricted } from '../../../lib/utils';
import { JourneyStatusEnum } from '../../../modules/teacher/types';

export const getRedirectUrl = (user: User): string => {
  const expiryDate = new Date(user.content.expiryDate).getTime();
  const currentDate = new Date().getTime();
  const isRestricted = getIsAccessRestricted(expiryDate < currentDate, user);
  let customUrl = '';
  if (user.content.journeyStatus === JourneyStatusEnum.graduate && expiryDate > currentDate) {
    customUrl = `${FRONTEND_URL}${ACCOUNT_STATUS_ROUTE.congratulations}`;
  } else if (isRestricted) {
    customUrl = `${FRONTEND_URL}${ACCOUNT_STATUS_ROUTE.subscriptionExpired}`;
  } else if (user.content.journeyStatus === JourneyStatusEnum.renew_required) {
    customUrl = `${FRONTEND_URL}${AUTH_ROUTES.tuitionPayment}`;
  } else {
    customUrl = KORU_DASHBOARD_URL;
  }
  
  return customUrl;
};
