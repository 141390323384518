import { TextGroupProps } from '../../molecules/TextGroup';
import { AppFeeDetailsSectionProps } from '../../organisms/AppFeeDetailsSection';
import { ClassListProps } from '../../organisms/ClassList';
import { CourseDetailsSectionProps } from '../../organisms/CourseDetailsSection';


type StudentCourseDetailsBlockInternalProps = {
  textGroup?: TextGroupProps;
  courseDetailsSection?: CourseDetailsSectionProps;
  appFeeDetailsSection?: AppFeeDetailsSectionProps;
  classList?: ClassListProps;
};

type StudentCourseDetailsBlockPropsValues = {
  '': StudentCourseDetailsBlockInternalProps;
};

const propValues: StudentCourseDetailsBlockPropsValues = {
  '': {
    textGroup: {
    },
    courseDetailsSection: {
    },
    appFeeDetailsSection: {
    },
    classList: {
    },
  },
};

const getProps = (type: string): StudentCourseDetailsBlockInternalProps => {
  const values: StudentCourseDetailsBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
