import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { TabsListProps } from '../TabsList';


type CoursesHeaderInternalProps = {
  text?: TextProps;
  tabsList?: TabsListProps;
  divider?: DividerProps;
};

type CoursesHeaderPropsValues = {
  '': CoursesHeaderInternalProps;
};

const propValues: CoursesHeaderPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading1',
    },
    tabsList: {
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
};

const getProps = (type: string): CoursesHeaderInternalProps => {
  const values: CoursesHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
