import { useCallback, useContext, useEffect, useState } from 'react';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { AxiosError } from 'axios';

import { AUTH_ROUTES, COUNTRY_CODE_LIST, EXPIRED_DISCOUNT_COUPON_MESSAGE, NOTIFICATION_DURATION, SIGNUP_STEPS, STRIPE_CARD_ELEMENT_CONFIG } from '../../../lib/constants';
import { trackSuccessfulTuitionFeePayment } from '../../../lib/trackingUtils';
import { formatPrice, useInterval } from '../../../lib/utils';
import { DiscountCodeStateEnum } from '../../molecules/DiscountCode/types';
import { PriceListItemValueProps } from '../../molecules/PriceListItem';
import { AddressForm } from '../../molecules/Address/types';
import { createTeacherPaymentIntentUseCase, getCouponDetailsUseCase, getTeacherFeesUseCase } from './TuitionPaymentBlock.interactor';
import {  TuitionPaymentBlockCombinedProps } from './types';
import { InputFieldStateEnum } from '../../molecules/InputField/types';

import styles from './TuitionPaymentBlock.module.scss';
import { UserContext } from '../../../modules/user/UserContext';
import { JourneyStatusEnum } from '../../../modules/teacher/types';
import { getUser } from '../../../modules/auth/api';
import { useNotification } from '../../../modules/notification';


type TuitionPaymentForm = {
  streetAddress: string;
  country: string;
  province: string;
  city: string;
  postalCode: string;
  creditCard: string;
};

const initFormState: TuitionPaymentForm = {
  streetAddress: '',
  country: '',
  province: '',
  city: '',
  postalCode: '',
  creditCard: '',
};


const usePresenter = (props: TuitionPaymentBlockCombinedProps): TuitionPaymentBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { trigger } = useNotification();
  const { user, updateUser } = useContext(UserContext);

  const [updatedUser, setUpdatedUser] = useState(user);

  const CountryCodeMap = new Map(
    COUNTRY_CODE_LIST.map((countryCode) => [countryCode.name, countryCode.code]),
  );

  // Credit card state
  const [creditCardState, setCreditCardState] = useState<StripeCardElementChangeEvent>();

  // form states
  const [formState, setFormState] = useState<TuitionPaymentForm>(initFormState);
  const [formErrorState, setFormErrorState] = useState<TuitionPaymentForm>(initFormState);
  const [validateAddress, setValidateAddress] = useState<boolean>();
  const [isAddressValid, setIsAddressValid] = useState<boolean>();
  const [isDifferentBillingAddress, setIsDifferentBillingAddress] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string | undefined>(undefined);

  // price states
  const [totalPrice, setTotalPrice] = useState<number>();
  const [discountPrice, setDiscountPrice] = useState<number | undefined>(undefined);

  // Discount Code states
  const [discountCodeState, setDiscountCodeState] = useState<DiscountCodeStateEnum>('Default');
  const [discountCode, setDiscountCode] = useState<string | undefined>(undefined);
  const [discountInputState, setDiscountInputState] = useState<InputFieldStateEnum>('Default');
  const [discountCodeErrorText, setDiscountCodeErrorText] = useState<string | undefined>(undefined);

  // button loading state
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

  const { data: feeDetails } = useQuery(['getTeacherFees'], () => getTeacherFeesUseCase());
  const { mutateAsync: getCouponDetails, isLoading } = useMutation(['getCouponDetails', discountCode], async () => {
    if (discountCode) {
      return getCouponDetailsUseCase(discountCode);
    }
  });

  const { mutateAsync: createPaymentIntent } = useMutation(['createPaymentIntent', discountCode, user], async () => {
    if (user) {
      return createTeacherPaymentIntentUseCase(user.uuid, { 
        productSlug: 'teacher-certificate',
        discountCode,
      });
    }
  });

  useEffect(() => {
    let creditCardErrorMessage = '';
    if (creditCardState?.error) {
      creditCardErrorMessage = t('error.invalid_credit_card');
    }
    setFormErrorState({
      ...formErrorState,
      creditCard: creditCardErrorMessage,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCardState]);

  useEffect(() => {
    if (feeDetails) {
      const { subtotal } = feeDetails;
      setTotalPrice(subtotal.amount);
    }
  }, [feeDetails]);

  const fetchNewUser = useCallback(async () => {
    const newUser = await getUser();
    setUpdatedUser(newUser);
  }, []);

  useInterval(fetchNewUser, 5000);

  useEffect(() => {
    if (updatedUser?.content?.journeyStatus === JourneyStatusEnum.payment_complete) {
      setIsPaymentProcessing(false);
      updateUser(updatedUser);
      trigger({
        duration: NOTIFICATION_DURATION,
        message: t('notification.payment_success'),
        type: 'Success',
      });
      trackSuccessfulTuitionFeePayment();
      history.replace(AUTH_ROUTES.certificationAgreement);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedUser, updateUser, history]);

  const handleRadioSelection = (value: boolean) => {
    setIsDifferentBillingAddress(value);
  };
  
  const handleCardChange = (stripeEvent: StripeCardElementChangeEvent) => {
    setCreditCardState(stripeEvent);
  };

  const handleAddressForm = (address: AddressForm) => {
    setFormState({
      ...formState,
      ...address,
    });
  };

  const handleAddressFormError = (addressError: AddressForm) => {
    setFormErrorState({
      ...formErrorState,
      ...addressError,
    });
  };

  const handleDiscountCodeChange = (code: string) => {
    setDiscountCode(code);
    setDiscountInputState('Default');
    setDiscountCodeErrorText('');
  };

  const addDiscountCodeError = (errorMessage: string) => {
    setDiscountCodeErrorText(errorMessage);
    setDiscountPrice(undefined);
    setDiscountInputState('Error');
    setDiscountCodeState('Expanded');
  };

  const addDiscountPrice = (amountOff: number) => {
    setDiscountCodeErrorText(undefined);
    setDiscountPrice(amountOff);
    setDiscountInputState('Default');
    setDiscountCodeState('Applied');
  };

  const applyDiscountCode = async () => {
    try {
      const couponDetails = await getCouponDetails();
      if (couponDetails) {
        addDiscountPrice(couponDetails.amountOff);
        if (totalPrice) {
          setTotalPrice(totalPrice + couponDetails.amountOff);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data.message === EXPIRED_DISCOUNT_COUPON_MESSAGE) {
        addDiscountCodeError(t('tuition_payment.discount_code.error.expired'));
        return;
      }
      addDiscountCodeError(t('tuition_payment.discount_code.error.invalid'));
    }
  };

  const removeDiscountCode = () => {
    setDiscountCode(undefined);
    setDiscountInputState('Default');
    setDiscountCodeState('Default');
    if (totalPrice && discountPrice) {
      setTotalPrice(totalPrice - discountPrice);
    }
    setDiscountPrice(undefined);
  };

  const handleDiscountCodeButtonClick = async () => {
    switch (discountCodeState) {
      case 'Default':
        setDiscountCodeState('Expanded');
        break;

      case 'Expanded':
        await applyDiscountCode();
        break;

      case 'Applied':
        removeDiscountCode();
        break;
    
      default:
        break;
    }
  };

  const handleMakePaymentButtonClick = async () => {
    if (!creditCardState || creditCardState?.empty) {
      setFormErrorState({
        ...formErrorState,
        creditCard: t('error.field_required'),
      });
    }
    // Check if user details and available and validated
    if (!user) {
      return;
    }
    setShowNotification(false);
    if (isDifferentBillingAddress) {
      setValidateAddress(!validateAddress);
    }

    // Setting billing address
    let address;
    if (isDifferentBillingAddress) {
      address = {
        ...formState,
        country: CountryCodeMap.get(formState.country) || '',
      };
    } else {
      if (user.content.profile) {
        const profile = user.content.profile;
        address = {
          streetAddress: profile.streetAddress,
          country: CountryCodeMap.get(profile.country ?  profile.country : '') || '',
          province: profile.province,
          city: profile.city,
          postalCode: profile.postalCode,
        };
      }
    }

    if (isDifferentBillingAddress && !isAddressValid) {
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (discountCode && !discountPrice) {
      // TODO: show error message when discount code is entered but not applied.
      return;
    }

    // Card details are filled completely
    if (!creditCardState?.complete) {
      return;
    }

    setIsPaymentProcessing(true);

    const paymentIntentResponse = await createPaymentIntent();

    if (paymentIntentResponse) {
      const result = await stripe.confirmCardPayment(paymentIntentResponse.clientSecret, {
        payment_method: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          card: elements.getElement(CardElement)!,
          billing_details: {
            address: {
              line1: address.streetAddress,
              city: address.city,
              postal_code: address.postalCode,
              state: address.province,
              country: address.country,
            },
          },
        },
      });

      if (result.error) {
        setIsPaymentProcessing(false);
        setShowNotification(true);
        setNotificationMessage(result.error.message);
      }
    }
  };

  const priceListItems: PriceListItemValueProps[] = [];
  if (feeDetails) {
    Object.keys(feeDetails).forEach((fee) => {
      priceListItems.push({
        descriptionText: {
          value: t(`tuition_payment.price_list_items.label.${fee}`),
        },
        divider: {
          type: 'Horizontal',
          style: 'Dotted',
          colour: 'BorderBase',
        },
        amountText: {
          value: formatPrice(feeDetails[fee].amount),
        },
      });
    });
  }

  return {
    ...props,
    stepperText: {
      value: t('stepperText', {
        currentStep: 3,
        totalSteps: SIGNUP_STEPS,
      }),
    },
    textGroup: {
      topText: {
        value: t('tuition_payment.title'),
      },
      bottomText: {
        value: t('tuition_payment.description'),
      },
    },
    priceItemList: {
      priceListItems: priceListItems,
    },
    discountCode: {
      classes: {
        button: discountInputState === 'Error' ? styles.discountCodeButton : '',
      },
      state: discountCodeState,
      inputField: {
        state: discountInputState,
        text: {
          value: t('tuition_payment.discount_code.label'),
        },
        textInput: {
          disabled: !!discountPrice,
          textValue: discountCode,
          maxLength: 64,
          onTextChanged: handleDiscountCodeChange,
        },
        contextualContent: {
          type: 'Error',
          text: {
            value: discountCodeErrorText,
          },
        },
      },
      priceListItem: {
        amountText: {
          value: (totalPrice && totalPrice < 0) ? '100%' : `${formatPrice(discountPrice)}`,
        },
        descriptionText: {
          value: t('tuition_payment.discount_code.price_item_description'),
        },
      },
      button: {
        loading: isLoading ? 'Loading' : 'Default',
        disabled: isLoading,
        type: (isLoading || discountCodeState === 'Default') ? 'TextIcon' : 'Text',
        text: {
          value: t(`tuition_payment.discount_code.button.${discountCodeState}`),
        },
        onClick: handleDiscountCodeButtonClick,
      },
    },
    priceListItem: {
      descriptionText: {
        value: t('tuition_payment.price_list_items.label.total'),
      },
      divider: {
        type: 'Horizontal',
        style: 'Dotted',
        colour: 'BorderBase',
      },
      amountText: {
        value: `${formatPrice(Math.max(0, (totalPrice || 0)))}`,
      },
    },
    cardInfoField: {
      state: formErrorState.creditCard ? 'Error' : 'Default',
      labelText: {
        value: t('tuition_payment.card_info.label'),
      },
      cardElementOptions: STRIPE_CARD_ELEMENT_CONFIG,
      handleChange: handleCardChange,
      contextualContent: {
        text: {
          value: formErrorState.creditCard,
        },
      },
    },
    radioField: {
      labelText: {
        value: t('tuition_payment.radio_options.label'),
      },
      radioItemList: {
        radioItems: [
          {
            state: isDifferentBillingAddress ? 'Unchecked' : 'Checked',
            text: {
              value: t('tuition_payment.radio_options.same_address'),
            },
            onClick: () => handleRadioSelection(false),
          },
          {
            state: isDifferentBillingAddress ? 'Checked' : 'Unchecked',
            text: {
              value: t('tuition_payment.radio_options.different_address'),
            },
            onClick: () => handleRadioSelection(true),
          },
        ],
      },
    },
    address: {
      setAddressForm: handleAddressForm,
      setAddressFormError: handleAddressFormError,
      validateAddress: validateAddress,
      setIsAddressValid,
    },
    button: {
      icon: {
        asset: 'ArrowRight',
      },
      text: {
        value: t('button.make_payment'),
      },
      loading: isPaymentProcessing ? 'Loading' : 'Default',
      onClick: handleMakePaymentButtonClick,
      disabled: isPaymentProcessing,
    },
    isDifferentBillingAddress: isDifferentBillingAddress,
    inlineNotification: {
      text: {
        value: notificationMessage,
      },
    },
    showNotification: showNotification,
  };
};

export default usePresenter;
