import { useDropdownToggle } from 'react-overlays';
import { IconDropDownCombinedProps } from './types';

// MANUAL OVERRIDE
const usePresenter = (props: IconDropDownCombinedProps): IconDropDownCombinedProps => {
  const [toggleProps] = useDropdownToggle();

  return {
    ...props,
    ...{
      ...toggleProps,
      onClick: (event?: React.MouseEvent<HTMLElement>) => {
        if (event) {
          event.stopPropagation();
          toggleProps.onClick(event);
        }
      },
    },
    forwardRef: toggleProps.ref,
  };
};
export default usePresenter;
