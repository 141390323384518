import { useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { STRIPE_CARD_ELEMENT_CONFIG } from '../../../lib/constants';
import { trackStudentAppsPurchase } from '../../../lib/trackingUtils';
import { formatPrice, preprocessNumber, useInterval } from '../../../lib/utils';
import { getUser } from '../../../modules/auth/api';
import { KORU_PRODUCT_SLUG } from '../../../modules/payment/constants';
import { ModalContext } from '../../../modules/user/ModalContext';
import { UserContext } from '../../../modules/user/UserContext';
import { RadioItemProps } from '../../atoms/RadioItem';
import { CardInfoStateEnum } from '../../molecules/CardInfo/types';
import { CustomAppsStateEnum } from '../../molecules/CustomApps/types';

import { DEFAULT_CUSTOM_STUDENT_APP_AMOUNT, DEFAULT_STUDENT_APP_AMOUNT, FIXED_STUDENT_APPS } from './constants';
import { createTeacherPaymentIntentUseCase, getProductDetailsUseCase } from './PurchaseStudentAppModal.interactor';
import { PurchaseStudentAppModalCombinedProps } from './types';

const usePresenter = (props: PurchaseStudentAppModalCombinedProps): PurchaseStudentAppModalCombinedProps => {
  const { t } = useTranslation();
  const { defaultPaymentInfo } = useContext(UserContext);
  const { 
    modalState, 
    setModalState, 
    openPaymentInfoModal, 
    setConfirmationModalType,
    purchaseStudentAppModalState,
    setPurchaseStudentAppModalState,
  } = useContext(ModalContext);
  const { user, updateUser } = useContext(UserContext);
  const stripe = useStripe();
  const productSlug = KORU_PRODUCT_SLUG[modalState];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [creditCardState, setCreditCardState] = useState<StripeCardElementChangeEvent>();
  const [cardInfoState, setCardInfoState] = useState<CardInfoStateEnum>('Display');
  const [cardError, setCardError] = useState<string | undefined>(undefined);
  const [studentApps, setStudentApps] = useState<number>(20);
  const [customAmountError, setCustomAmountError] = useState<string>('');
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string | undefined>(undefined);

  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [isPaymentProcessed, setIsPaymentProcessed] = useState<boolean>(false);

  const { data: priceItem } = useQuery(['getProduct', productSlug], () => getProductDetailsUseCase('student-apps'));
  const { mutateAsync: createPaymentIntent } = useMutation(
    ['createTeacherPaymentIntentStudentApps', user, studentApps, productSlug],
    async () => {
      if (user && studentApps) {
        return createTeacherPaymentIntentUseCase(user.uuid, { 
          productSlug,
          quantity: studentApps,
        });
      }
    },
  );

  // update total price
  useEffect(() => {
    if (studentApps >= 0 && priceItem) {
      setTotalPrice(studentApps * priceItem.amount);
    }
  }, [priceItem, studentApps]);

  useEffect(() => {
    // remove error when there is a change in payment method
    if (defaultPaymentInfo && defaultPaymentInfo.cardInfo?.last4) {
      setCardInfoState('Display');
      setCardError(undefined);
      setShowNotification(false);
      setNotificationMessage(undefined);
    }
  }, [defaultPaymentInfo]);

  // update student apps amount based on custom amount input
  useEffect(() => {
    // only runs when custom amount is expanded
    if (purchaseStudentAppModalState.state === 'Expanded') {
      setCustomAmountError(!purchaseStudentAppModalState.customAmount ?  t('purchase_student_app_modal.error.custom_apps') : '');
      if (purchaseStudentAppModalState.customAmount) {
        setStudentApps(purchaseStudentAppModalState.customAmount);
      }
    } else if (purchaseStudentAppModalState.state === 'Default') {
      setStudentApps(purchaseStudentAppModalState.defaultAmount || DEFAULT_STUDENT_APP_AMOUNT);
    }
  }, [purchaseStudentAppModalState, t]);

  const fetchNewUser = useCallback(async () => {
    if (user && isPaymentProcessed) {
      try {
        const newUser = await getUser();
        if (newUser && user.content.apps !== newUser.content.apps) {
          trackStudentAppsPurchase(formatPrice(totalPrice));
          updateUser(newUser);
          setIsPaymentProcessing(false);
          setShowNotification(false);
          setNotificationMessage(undefined);
          setConfirmationModalType('STUDENT_APPS_PURCHASE');
          setModalState('CONFIRMATION');
          setPurchaseStudentAppModalState({
            state: 'Default',
            defaultAmount: DEFAULT_STUDENT_APP_AMOUNT,
            customAmount: DEFAULT_CUSTOM_STUDENT_APP_AMOUNT,
          });
        }
      } catch {
        // no-op
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentProcessed, studentApps, totalPrice, user]);

  useInterval(fetchNewUser, 5000);

  const handleChangeCardButtonClick = () => {
    openPaymentInfoModal();
  };

  const handleCloseModal = () => {
    if (setModalState) {
      setPurchaseStudentAppModalState({
        state: 'Default',
        defaultAmount: DEFAULT_STUDENT_APP_AMOUNT,
        customAmount: DEFAULT_CUSTOM_STUDENT_APP_AMOUNT,
      });
      setModalState('UNSET');
    }
  };

  const handleSetCustomAmountState = (value: CustomAppsStateEnum) => {
    setStudentApps(0);
    setPurchaseStudentAppModalState({
      ...purchaseStudentAppModalState,
      state: (value),
    });
  };

  const handleTextChange = (value: string) => {
    if (value) {
      setPurchaseStudentAppModalState({
        ...purchaseStudentAppModalState,
        customAmount: parseInt(value),
      });
      setShowNotification(false);
      setNotificationMessage(undefined);
      setCustomAmountError('');
    } else {
      setStudentApps(0);
      setPurchaseStudentAppModalState({
        ...purchaseStudentAppModalState,
        customAmount: 0,
      });
      setCustomAmountError(t('purchase_student_app_modal.error.custom_apps'));
    }
  };

  const handleRadioSelection = (value: number) => {
    if (!purchaseStudentAppModalState.customAmount) {
      setPurchaseStudentAppModalState({
        ...purchaseStudentAppModalState,
        customAmount: DEFAULT_CUSTOM_STUDENT_APP_AMOUNT,
      });
    }
    setCustomAmountError('');
    setPurchaseStudentAppModalState({
      ...purchaseStudentAppModalState,
      state: 'Default',
      defaultAmount: value,
      customAmount: DEFAULT_CUSTOM_STUDENT_APP_AMOUNT,
    });
    setStudentApps(value);
    setShowNotification(false);
    setNotificationMessage(undefined);
  };

  const isFormValid = () => {
    setCustomAmountError(!studentApps ?  t('purchase_student_app_modal.error.custom_apps') : '');
    return !!studentApps;
  };

  const handlePurchaseNowButtonClick = async () => {
    if (!user || !stripe) {
      return;
    }

    if (!defaultPaymentInfo || !defaultPaymentInfo.cardInfo?.last4) {
      setCardInfoState('DisplayError');
      setCardError(t('error.field_required'));
      return;
    }

    if (isFormValid()) {
      try {
        setIsPaymentProcessing(true);
        const paymentIntentResponse = await createPaymentIntent();

        if (paymentIntentResponse) {
          const result = await stripe.confirmCardPayment(paymentIntentResponse.clientSecret);
          if (result.error) {
            setShowNotification(true);
            setIsPaymentProcessing(false);
            setNotificationMessage(result.error.message);
          }
          if (result.paymentIntent) {
            setIsPaymentProcessed(true);
          }
        }
      } catch {
        setIsPaymentProcessing(false);
      }
    }
  };

  const handleCardChange = (stripeEvent: StripeCardElementChangeEvent) => {
    setCreditCardState(stripeEvent);
  };

  const radioItems: RadioItemProps[] = FIXED_STUDENT_APPS.map((amount) => {
    return {
      state: studentApps === amount && purchaseStudentAppModalState.state === 'Default' ? 'Checked' : 'Unchecked',
      text: {
        value: t('purchase_student_app_modal.radio', {
          quantity: amount,
        }),
      },
      onClick: () => handleRadioSelection(amount),
    };
  });

  return {
    ...props,
    text: {
      value: t('purchase_student_app_modal.title'),
    },
    button: {
      icon: {
        asset: 'Close',
      },
      onClick: handleCloseModal,
    },
    labelText: {
      value: t('purchase_student_app_modal.label.middle_text'),
    },
    radioItemList: {
      radioItems: radioItems,
    },
    customApps: {
      state: purchaseStudentAppModalState.state,
      button: {
        loading: 'Default',
        text: {
          value: t('purchase_student_app_modal.label.custom_apps'),
        },
        onClick: () => handleSetCustomAmountState('Expanded'),
      },
      inputField: {
        state: customAmountError ? 'Error' : 'Default',
        text: {
          value: t('purchase_student_app_modal.label.custom_apps'),
        },
        textInput: {
          maxLength: 5,
          textValue: `${purchaseStudentAppModalState.customAmount}`,
          onTextChanged: handleTextChange,
          preProcessTextInput: preprocessNumber,
        },
        contextualContent: {
          text: {
            value: customAmountError,
          },
        },
      },
    },
    priceListItem: {
      amountText: {
        value: formatPrice(totalPrice),
      },
      descriptionText: {
        value: t('purchase_student_app_modal.label.total'),
      },
    },
    cardInfo: {
      state: cardInfoState,
      text: {
        value: defaultPaymentInfo?.cardInfo?.last4 && t('purchase_student_app_modal.cardInfo.text', {
          last4: defaultPaymentInfo.cardInfo.last4,
        }),
      },
      button: {
        text: {
          value: t('purchase_student_app_modal.button.change_card'),
        },
        onClick: handleChangeCardButtonClick,
      },
      labelText: {
        value: t('purchase_student_app_modal.label.pay_with'),
      },
      cardElementOptions: STRIPE_CARD_ELEMENT_CONFIG,
      handleChange: handleCardChange,
      contextualContent: {
        text: {
          value: cardError,
        },
      },
    },
    primaryButton: {
      text: {
        value: t('purchase_student_app_modal.button.purchase_now'),
      },
      disabled: isPaymentProcessing,
      onClick: handlePurchaseNowButtonClick,
      loading: isPaymentProcessing ? 'Loading' : 'Default',
    },
    primaryButton1: {
      text: {
        value: t('purchase_student_app_modal.button.cancel'),
      },
      onClick: handleCloseModal,
    },
    inlineNotification: {
      text: {
        value: notificationMessage,
      },
    },
    showNotification,
  };
};

export default usePresenter;
