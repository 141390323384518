import { updatePassword } from '../../../modules/account/api';
import { UpdatePasswordPayload } from '../../../modules/account/types';
import { isStudentRole } from '../../../modules/user/api';

export const updatePasswordUseCase = (payload: UpdatePasswordPayload) => {
  return updatePassword(payload);
};

export const isStudentRoleUseCase = (accountUuid: string) => {
  return isStudentRole(accountUuid);
};