import { Class, CreateClassPayload, UpdateClassPayload } from '../class/types';
import type { Teacher } from '../teacher/types';
import type { Student } from '../student/types';
import { DataEntry } from '../../lib/types';

export enum EnrollmentStatus {
  enrolled = 'Enrolled',
  waitlisted = 'Waitlisted',
  removed = 'Removed',
}

export type Roster = {
  course: DataEntry<Course>;
  student: DataEntry<Student>;
  enrollmentStatus: EnrollmentStatus;
};

export enum CourseTypeEnum {
  koru_basic = 'Koru Basic',
  koru_two = 'Koru 2.0',
  koru_retreat = 'Koru Retreat',
}

export enum ClassFormatEnum {
  InPerson = 'In-person',
  Online = 'Online',
  Both = 'Hybrid',
}

export enum ClassFormatEnumReverse {
  'In-person' = 'InPerson',
  'Online' = 'Online',
  'Hybrid' = 'Both',
}

export type Course = {
  id: number;
  title: string;
  description: string;
  courseType: CourseTypeEnum;
  classFormat: ClassFormatEnum;
  classCapacity: number;
  registrationOpen: boolean;
  primaryTeacher: DataEntry<CourseTeacher>;
  coTeachers?: DataEntry<CourseTeacher>[];
  classes?: DataEntry<Class>[];
  freeLinkToken?: string;
  archived?: boolean;
  enrolledStudents?: number;
  timezone?: string;
};

export type CourseTeacher = Pick<Teacher, 'uuid' | 'email' | 'firstName' | 'lastName' | 'apps'>;

export type CourseStateType = {
  course: DataEntry<Course>;
  isNewlyRegistered?: boolean;
  spotAvailableToken?: string;
  enrollmentStatus?: EnrollmentStatus;
};

export type CreateCoursePayload = Omit<Course, 'id' | 'createdAt' | 'updatedAt' | 'classes' | 'coTeachers' | 'primaryTeacher'> & {
  classes: CreateClassPayload[];
  coTeachers: string[];
};

export type UpdateCoursePayload = Partial<CreateCoursePayload> & {
  classes?: UpdateClassPayload[];
};

export enum CourseState {
  Active = 'active',
  Archived = 'archived',
}

export type CourseFreeLinkToken = {
  courseId: number;
};

export enum RegistrationFlowEnum {
  joinWaitlist = 'Join Waitlist',
  register = 'Register',
}

export type SpotAvailableTokenPayload = {
  courseId: number;
  studentEmail: string;
};
