import React from 'react';
import cx from 'classnames';

import ProductItem from '../../atoms/ProductItem';

import styles from './ProductList.module.scss';
import usePresenter from './ProductList.presenter';
import getProps from './ProductList.props';
import { ProductListCombinedProps } from './types';


const ProductList: React.FC<ProductListCombinedProps> = (props) => {
  const {
    className,
    classes,
    productItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.productList;

  const productItemViews = productItems?.map((productItem, index) => (
    <ProductItem
      key={index}
      className={cx(styles.productItem, classes?.productItem)}
      {...internalProps.productItem}
      {...productItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {productItemViews}
    </div>
  );
};

export default ProductList;

