import React from 'react';
import cx from 'classnames';


import styles from './Text.module.scss';
import usePresenter from './Text.presenter';
import { TextCombinedProps } from './types';


const Text: React.FC<TextCombinedProps> = (props) => {
  const {
    style,
    align,
    colour,
    type,
    className,
    classes,
    value,
  } = usePresenter(props);

  const variantName = `${style}${align}${colour}${type}`;
  const currentStyle = styles[`text${variantName}`];

  let componentView;

  switch (variantName) {
    case 'BoldCenterBrandBody1':
    case 'BoldCenterBrandBody2':
    case 'BoldCenterCriticalBody2':
    case 'BoldCenterDefaultBody1':
    case 'BoldCenterDefaultBody2':
    case 'BoldCenterDisabledBody1':
    case 'BoldCenterDisabledBody2':
    case 'BoldCenterSubduedBody1':
    case 'BoldCenterSubduedBody2':
    case 'BoldLeftDefaultBody1':
    case 'BoldLeftDefaultBody2':
    case 'BoldLeftDisabledBody1':
    case 'BoldLeftDisabledBody2':
    case 'BoldLeftNewBody1':
    case 'BoldLeftNewBody2':
    case 'BoldLeftSubduedBody1':
    case 'BoldLeftSubduedBody2':
    case 'BoldRightDefaultBody1':
    case 'BoldRightDefaultBody2':
    case 'BoldRightDisabledBody1':
    case 'BoldRightDisabledBody2':
    case 'BoldRightSubduedBody1':
    case 'BoldRightSubduedBody2':
    case 'RegularCenterBrandBody1':
    case 'RegularCenterCriticalLabel1':
    case 'RegularCenterDefaultBody1':
    case 'RegularCenterDefaultBody2':
    case 'RegularCenterDefaultLabel1':
    case 'RegularCenterDefaultLabel2':
    case 'RegularCenterDisabledBody1':
    case 'RegularCenterDisabledBody2':
    case 'RegularCenterDisabledLabel1':
    case 'RegularCenterDisabledLabel2':
    case 'RegularCenterInfoLabel1':
    case 'RegularCenterInverseBody2':
    case 'RegularCenterInverseLabel1':
    case 'RegularCenterSubduedBody1':
    case 'RegularCenterSubduedBody2':
    case 'RegularCenterSubduedLabel1':
    case 'RegularCenterSubduedLabel2':
    case 'RegularLeftCriticalBody1':
    case 'RegularLeftCriticalBody2':
    case 'RegularLeftCriticalLabel1':
    case 'RegularLeftDefaultBody1':
    case 'RegularLeftDefaultBody2':
    case 'RegularLeftDefaultLabel1':
    case 'RegularLeftDefaultLabel2':
    case 'RegularLeftDisabledBody1':
    case 'RegularLeftDisabledBody2':
    case 'RegularLeftDisabledLabel1':
    case 'RegularLeftDisabledLabel2':
    case 'RegularLeftInfoBody1':
    case 'RegularLeftInfoLabel1':
    case 'RegularLeftInverseBody2':
    case 'RegularLeftInverseLabel1':
    case 'RegularLeftSubduedBody1':
    case 'RegularLeftSubduedBody2':
    case 'RegularLeftSubduedLabel1':
    case 'RegularLeftSubduedLabel2':
    case 'RegularRightCriticalLabel1':
    case 'RegularRightDefaultBody1':
    case 'RegularRightDefaultBody2':
    case 'RegularRightDefaultLabel1':
    case 'RegularRightDefaultLabel2':
    case 'RegularRightDisabledBody1':
    case 'RegularRightDisabledBody2':
    case 'RegularRightDisabledLabel1':
    case 'RegularRightDisabledLabel2':
    case 'RegularRightInfoBody1':
    case 'RegularRightInfoLabel1':
    case 'RegularRightInverseBody2':
    case 'RegularRightInverseLabel1':
    case 'RegularRightSubduedBody1':
    case 'RegularRightSubduedBody2':
    case 'RegularRightSubduedLabel1':
    case 'RegularRightSubduedLabel2':
    case 'SemiboldCenterDefaultBody2':
    case 'SemiboldCenterDisabledBody2':
    case 'SemiboldCenterInverseBody2':
    case 'SemiboldCenterSubduedBody2':
    case 'SemiboldLeftDefaultBody2':
    case 'SemiboldLeftDisabledBody2':
    case 'SemiboldLeftInverseBody2':
    case 'SemiboldLeftSubduedBody2':
    case 'SemiboldRightDefaultBody2':
    case 'SemiboldRightDisabledBody2':
    case 'SemiboldRightSubduedBody2': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <p className={cx(styles.value, classes?.value)}>
            {value}
          </p>
        </div>
      );
      break;
    }
    case 'BoldCenterDefaultHeading1':
    case 'BoldCenterDisabledHeading1':
    case 'BoldCenterSubduedHeading1':
    case 'BoldLeftBrandHeading1':
    case 'BoldLeftDefaultHeading1':
    case 'BoldLeftDisabledHeading1':
    case 'BoldLeftSubduedHeading1':
    case 'BoldRightDefaultHeading1':
    case 'BoldRightDisabledHeading1':
    case 'BoldRightSubduedHeading1': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <h1 className={cx(styles.value, classes?.value)}>
            {value}
          </h1>
        </div>
      );
      break;
    }
    case 'BoldCenterDefaultHeading2':
    case 'BoldCenterDisabledHeading2':
    case 'BoldCenterSubduedHeading2':
    case 'BoldLeftDefaultHeading2':
    case 'BoldLeftDisabledHeading2':
    case 'BoldLeftInverseHeading2':
    case 'BoldLeftSubduedHeading2':
    case 'BoldRightDefaultHeading2':
    case 'BoldRightDisabledHeading2':
    case 'BoldRightSubduedHeading2': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <h2 className={cx(styles.value, classes?.value)}>
            {value}
          </h2>
        </div>
      );
      break;
    }
    case 'BoldCenterDefaultHeading3':
    case 'BoldCenterDisabledHeading3':
    case 'BoldCenterSubduedHeading3':
    case 'BoldLeftDefaultHeading3':
    case 'BoldLeftDisabledHeading3':
    case 'BoldLeftSubduedHeading3':
    case 'BoldRightDefaultHeading3':
    case 'BoldRightDisabledHeading3':
    case 'BoldRightSubduedHeading3': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <h3 className={cx(styles.value, classes?.value)}>
            {value}
          </h3>
        </div>
      );
      break;
    }
    case 'BoldCenterDefaultHeading4':
    case 'BoldCenterDisabledHeading4':
    case 'BoldCenterSubduedHeading4':
    case 'BoldLeftDefaultHeading4':
    case 'BoldLeftDisabledHeading4':
    case 'BoldLeftSubduedHeading4':
    case 'BoldRightDefaultHeading4':
    case 'BoldRightDisabledHeading4':
    case 'BoldRightSubduedHeading4': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <h4 className={cx(styles.value, classes?.value)}>
            {value}
          </h4>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Text;

