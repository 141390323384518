import React from 'react';
import cx from 'classnames';
import { CardElement } from '@stripe/react-stripe-js';

import ContextualContent from '../../atoms/ContextualContent';
import Text from '../../atoms/Text';

import styles from './CardInfo.module.scss';
import usePresenter from './CardInfo.presenter';
import getProps from './CardInfo.props';
import { CardInfoCombinedProps } from './types';
import './Stripe.css';
import Icon from '../../atoms/Icon';
import Button from '../../atoms/Button';


const CardInfo: React.FC<CardInfoCombinedProps> = (props) => {
  const {
    className,
    classes,
    labelText,
    icon,
    text,
    button,
    cardElementOptions,
    handleChange,
    contextualContent,
    state,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`cardInfo${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.labelText, classes?.labelText)}
            {...internalProps.labelText}
            {...labelText}/>
          <div className={cx(styles.content, classes?.content)}>
            <CardElement 
              id="card-element" 
              options={cardElementOptions}
              onChange={handleChange}/>
          </div>
        </div>
      );
      break;
    }
    case 'Error': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.labelText, classes?.labelText)}
            {...internalProps.labelText}
            {...labelText}/>
          <div className={cx(styles.content, classes?.content)}>
            <CardElement 
              id="card-element" 
              options={cardElementOptions}
              onChange={handleChange}/>
          </div>
          <ContextualContent
                className={cx(styles.contextualContent, classes?.contextualContent)}
                {...internalProps.contextualContent}
                {...contextualContent}/>
        </div>
      );
      break;
    }
    case 'Display': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.labelText, classes?.labelText)}
            {...internalProps.labelText}
            {...labelText}/>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
        </div>
      );
      break;
    }
    // MANUAL OVERRIDE
    case 'DisplayError': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.labelText, classes?.labelText)}
            {...internalProps.labelText}
            {...labelText}/>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
          <ContextualContent
                className={cx(styles.contextualContent, classes?.contextualContent)}
                {...internalProps.contextualContent}
                {...contextualContent}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default CardInfo;

