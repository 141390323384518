import React from 'react';
import cx from 'classnames';

import StudentCourseDetailsFooter from '../../organisms/StudentCourseDetailsFooter';
import StudentCourseDetailsPage from '../../pages/StudentCourseDetailsPage';

import styles from './BodyFooterLayout.module.scss';
import usePresenter from './BodyFooterLayout.presenter';
import getProps from './BodyFooterLayout.props';
import { BodyFooterLayoutCombinedProps } from './types';
import InlineNotification from '../../atoms/InlineNotification';
import LoadingSpinner from '../../atoms/LoadingSpinner';


const BodyFooterLayout: React.FC<BodyFooterLayoutCombinedProps> = (props) => {
  const {
    className,
    classes,
    isLoading,
    studentCourseDetailsPage,
    studentCourseDetailsFooter,
    inlineNotification,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.bodyFooterLayout;

  const inlineNotificationView = inlineNotification ?   (
  <InlineNotification 
    className={cx(styles.inlineNotification, classes?.inlineNotification)}
    {...internalProps.inlineNotification}
    {...inlineNotification}
  />) : undefined;

  return isLoading ? (
    <LoadingSpinner
      className={cx(styles.loadingSpinner)}
      size='Huge'
      styleType='Primary'
      isActive
    />
  ) : (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.body, classes?.body)}>
        { inlineNotificationView }
        <StudentCourseDetailsPage
          className={cx(styles.studentCourseDetailsPage, classes?.studentCourseDetailsPage)}
          {...internalProps.studentCourseDetailsPage}
          {...studentCourseDetailsPage}/>
      </div>
      <div className={cx(styles.footer, classes?.footer)}>
        <StudentCourseDetailsFooter
          className={cx(styles.studentCourseDetailsFooter, classes?.studentCourseDetailsFooter)}
          {...internalProps.studentCourseDetailsFooter}
          {...studentCourseDetailsFooter}/>
      </div>
    </div>
  );
};

export default BodyFooterLayout;

