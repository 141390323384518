import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import StaffItem, { StaffItemValueProps } from '../../molecules/StaffItem';

import styles from './WelcomeBlock.module.scss';
import usePresenter from './WelcomeBlock.presenter';
import getProps from './WelcomeBlock.props';
import { WelcomeBlockCombinedProps } from './types';


const WelcomeBlock: React.FC<WelcomeBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    stepperText,
    leftText,
    rightText,
    divider,
    bodyText,
    noteText,
    button,
    staffItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.welcomeBlock;

  const rowItems: StaffItemValueProps[][] = [];
  while (staffItems?.length) {
    rowItems.push(staffItems.splice(0, 2));
  }

  const dividerComponent = (
    <Divider
      className={cx(styles.divider, classes?.divider)}
      {...internalProps.divider}
      {...divider}/>
  );

  const staffItemView = rowItems.map((row, index) => {
    const lastRow = (index === rowItems.length - 1);
    const dividerView = !lastRow ? dividerComponent : undefined;
    return (
      <>
        <div className={cx(styles.row, classes?.row)}>
          <StaffItem
            className={cx(styles.staffItem, classes?.staffItem)}
            {...internalProps.staffItem}
            {...row[0]}/>
          <StaffItem
            className={cx(styles.staffItem, classes?.staffItem)}
            {...internalProps.staffItem}
            {...row[1]}/>
        </div>
      { dividerView }
      </>
    );
  });

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.stepperText, classes?.stepperText)}
          {...internalProps.stepperText}
          {...stepperText}/>
        <div className={cx(styles.textGroup, classes?.textGroup)}>
          <Text
            className={cx(styles.leftText, classes?.leftText)}
            {...internalProps.leftText}
            {...leftText}/>
          <Text
            className={cx(styles.rightText, classes?.rightText)}
            {...internalProps.rightText}
            {...rightText}/>
        </div>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        { staffItemView }
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Text
          className={cx(styles.bodyText, classes?.bodyText)}
          {...internalProps.bodyText}
          {...bodyText}/>
        <Text
          className={cx(styles.noteText, classes?.noteText)}
          {...internalProps.noteText}
          {...noteText}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
    </div>
  );
};

export default WelcomeBlock;

