import { ProductItemProps } from '../../atoms/ProductItem';


type ProductListInternalProps = {
  productItem?: ProductItemProps;
};

type ProductListPropsValues = {
  '': ProductListInternalProps;
};

const propValues: ProductListPropsValues = {
  '': {
    productItem: {
    },
  },
};

const getProps = (type: string): ProductListInternalProps => {
  const values: ProductListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
