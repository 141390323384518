import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router';

import { formatDate, getClassIcons, getPersonName } from '../../../lib/utils';
import { ClassItemValueProps } from '../../molecules/ClassItem';
import { LabelTextGroupValueProps } from '../../molecules/LabelTextGroup';

import { validateCourseFreeLinkTokenUseCase } from './StudentCourseDetailBlock.interactor';
import { courseBasicInfoKeys, StudentCourseDetailsBlockCombinedProps } from './types';

const timeFormatOptions: Intl.DateTimeFormatOptions = {
  hour12: true,
  timeStyle: 'short',
};

const usePresenter = (props: StudentCourseDetailsBlockCombinedProps): StudentCourseDetailsBlockCombinedProps => {
  const { t } = useTranslation();
  const { course } = props;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');
  const [isFreeLinkTokenValid, setIsFreeLinkTokenValid] = useState<boolean>(false);

  const freeAppsAvailable =
    course?.content?.primaryTeacher?.content?.apps &&
    course.content.primaryTeacher.content.apps > 0 &&
    isFreeLinkTokenValid;

  const { mutateAsync: validateToken } = useMutation(
    ['validateCourseFreeLinkToken', token, course?.content?.id],
    async () => {
      if (course?.content?.id && token) {
        return validateCourseFreeLinkTokenUseCase(course.content.id, token);
      }
    });

  useEffect(() => {
    const validateFreeLinkToken = async () => {
      try {
        const data = await validateToken();
        if (data) {
          setIsFreeLinkTokenValid(true);
        }
      } catch {
        // no-op
        // student will still be able to view the screen and proceed to course registration flow
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    validateFreeLinkToken();
  }, [course?.content?.id, token, validateToken]);

  let labelTextGroups: LabelTextGroupValueProps[] = [];
  if (course) {
    const coTeacherNames = course.content.coTeachers?.map(coTeacher => getPersonName(coTeacher));
    const teacherNames = [getPersonName(course.content.primaryTeacher), ...coTeacherNames || []];
    labelTextGroups = courseBasicInfoKeys.map(key => (
      {
        labelText: {
          value: t(`student_registration.course_detail.labels.${key}`),
        },
        infoText: {
          value: key === 'coTeachers' ? teacherNames.join(', ') : course.content[key],
        },
      }
    ));
  }

  let classItems: ClassItemValueProps[] = [];
  if (course?.content?.classes) {
    classItems = course.content.classes.map((c, index) => {
      const icons = getClassIcons(course.content.classFormat, c.content.location, c.content.classLink);
      return {
        text: {
          value: t('student_registration.course_detail.labels.class', { index: Number(index) + 1 }),
        },
        iconTextGroupList: {
          iconTextGroups: [
            {
              labelIcon: {
                asset: 'Calendar',
              },
              infoText: {
                value: formatDate(c.content.date),
              },
            },
            {
              labelIcon: {
                asset: 'Clock',
              },
              infoText: {
                value: `${formatDate(c.content.startTime, timeFormatOptions)} - ${formatDate(c.content.endTime, timeFormatOptions)}`,
              },
            },
            ...icons,
          ],
        },
      } as ClassItemValueProps;
    });
  }

  return {
    ...props,
    textGroup: {
      topText: {
        value: course?.content?.title,
      },
      bottomText: {
        value: course?.content?.description,
      },
    },
    courseDetailsSection: {
      text: {
        value: course?.content?.title,
      },
      labelTextGroupList: {
        labelTextGroups: labelTextGroups,
      },
    },
    appFeeDetailsSection: freeAppsAvailable ? undefined : {
      text: {
        value: t('student_registration.app_fee_detail.header'),
      },
      decriptionText: {
        value: t('student_registration.app_fee_detail.description'),
      },
      infoText: {
        value: t('student_registration.app_fee_detail.info'),
      },
    },
    classList: {
      classItems: classItems,
    },
  };
};

export default usePresenter;
