import { StudentListSectionProps } from '../../organisms/StudentListSection';
import { ReminderEmailsSectionProps } from '../../organisms/ReminderEmailsSection';
import { CopyLinkSectionProps } from '../../organisms/CopyLinkSection';


type RosterBlockInternalProps = {
  enrolledStudentsSection?: StudentListSectionProps;
  waitlistSection?: StudentListSectionProps;
  removedStudentsSection?: StudentListSectionProps;
  reminderEmailsSection?: ReminderEmailsSectionProps;
  copyStudentsEmailSection?: CopyLinkSectionProps;
};

type RosterBlockPropsValues = {
  '': RosterBlockInternalProps;
};

const propValues: RosterBlockPropsValues = {
  '': {
    enrolledStudentsSection: {
    },
    waitlistSection: {
    },
    removedStudentsSection: {
    },
    reminderEmailsSection: {
    },
    copyStudentsEmailSection:{
    },
  },
};

const getProps = (type: string): RosterBlockInternalProps => {
  const values: RosterBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
