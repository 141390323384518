import React from 'react';
import cx from 'classnames';

import Image from '../../atoms/Image';
import TextGroup from '../../molecules/TextGroup';

import styles from './GenericStateNoActionBlock.module.scss';
import usePresenter from './GenericStateNoActionBlock.presenter';
import getProps from './GenericStateNoActionBlock.props';
import { GenericStateNoActionBlockCombinedProps } from './types';


const GenericStateNoActionBlock: React.FC<GenericStateNoActionBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    textGroup,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.genericStateNoActionBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Image
          className={cx(styles.image, classes?.image)}
          {...internalProps.image}
          {...image}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
      </div>
    </div>
  );
};

export default GenericStateNoActionBlock;

