import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { AUTH_ROUTES, SIGNUP_STEPS } from '../../../lib/constants';
import { AuthContext } from '../../../modules/auth';
import { NextStepState } from '../../../modules/teacher/types';
import { AlreadySignupBlockCombinedProps } from './types';

const usePresenter = (props: AlreadySignupBlockCombinedProps): AlreadySignupBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<NextStepState>();
  const { account } = useContext(AuthContext);

  const goToCreateProfile = () => {
    if (account) {
      history.replace(state.nextStepRoute);
      return;
    }

    history.replace(AUTH_ROUTES.signIn);
  };

  return {
    ...props,
    stepperText: {
      value: t('stepperText', {
        currentStep: 1,
        totalSteps: SIGNUP_STEPS,
      }),
    },
    textGroup: {
      topText: {
        value: t('account_created.title'),
      },
      bottomText: {
        value: t('account_created.description'),
      },
    },
    button: {
      loading: 'Default',
      text: {
        value: t('button.complete_registration'),
      },
      icon: {
        asset: 'ArrowRight',
      },
      onClick: goToCreateProfile,
    },
  };
};

export default usePresenter;
