import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';

import styles from './GenericStateThreeBlock.module.scss';
import usePresenter from './GenericStateThreeBlock.presenter';
import getProps from './GenericStateThreeBlock.props';
import { GenericStateThreeBlockCombinedProps } from './types';


const GenericStateThreeBlock: React.FC<GenericStateThreeBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    topText,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.genericStateThreeBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Image
          className={cx(styles.image, classes?.image)}
          {...internalProps.image}
          {...image}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <div className={cx(styles.textGroupTop, classes?.textGroupTop)}>
          <Text
            className={cx(styles.topText, classes?.topText)}
            {...internalProps.topText}
            {...topText}/>
        </div>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
    </div>
  );
};

export default GenericStateThreeBlock;

