import React from 'react';
import cx from 'classnames';

import Text from '../Text';
import Button from '../Button';

import styles from './ProductItem.module.scss';
import usePresenter from './ProductItem.presenter';
import getProps from './ProductItem.props';
import { ProductItemCombinedProps } from './types';


const ProductItem: React.FC<ProductItemCombinedProps> = (props) => {
  const {
    className,
    classes,
    dateText,
    amountText,
    productNameText,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.productItem;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.dateText, classes?.dateText)}
        {...internalProps.dateText}
        {...dateText}/>
      <Text
        className={cx(styles.amountText, classes?.amountText)}
        {...internalProps.amountText}
        {...amountText}/>
      <Text
        className={cx(styles.productNameText, classes?.productNameText)}
        {...internalProps.productNameText}
        {...productNameText}/>
      <Button
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button}/>
    </div>
  );
};

export default ProductItem;

