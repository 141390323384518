import { ButtonProps } from '../../atoms/Button';
import { ImageProps } from '../../atoms/Image';
import { TextProps } from '../../atoms/Text';


type ConfirmationModalInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
  image?: ImageProps;
  text1?: TextProps;
  primaryButton?: ButtonProps;
};

type ConfirmationModalPropsValues = {
  '': ConfirmationModalInternalProps;
};

const propValues: ConfirmationModalPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'Base',
      loading: 'Default',
    },
    image: {
      type: 'Square',
    },
    text1: {
      style: 'Regular',
      align: 'Center',
      colour: 'Default',
      type: 'Body1',
    },
    primaryButton: {
      style: 'Contained',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): ConfirmationModalInternalProps => {
  const values: ConfirmationModalInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
