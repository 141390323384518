import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { ImageProps } from '../../atoms/Image';


type GenericStateTwoBlockInternalProps = {
  image?: ImageProps;
  topText?: TextProps;
  bottomText?: TextProps;
  topText1?: TextProps;
  bottomText1?: TextProps;
  button?: ButtonProps;
  button1?: ButtonProps;
};

type GenericStateTwoBlockPropsValues = {
  '': GenericStateTwoBlockInternalProps;
};

const propValues: GenericStateTwoBlockPropsValues = {
  '': {
    image: {
      type: 'Square',
    },
    topText: {
      style: 'Bold',
      align: 'Center',
      colour: 'Default',
      type: 'Heading3',
    },
    bottomText: {
      style: 'Regular',
      align: 'Center',
      colour: 'Default',
      type: 'Body1',
    },
    topText1: {
      style: 'Bold',
      align: 'Center',
      colour: 'Default',
      type: 'Heading4',
    },
    bottomText1: {
      style: 'Regular',
      align: 'Center',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    button1: {
      style: 'Text',
      size: 'Small',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): GenericStateTwoBlockInternalProps => {
  const values: GenericStateTwoBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
