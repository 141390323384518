import React from 'react';
import cx from 'classnames';

import Image from '../../atoms/Image';
import StudentCourseDetailsBlock from '../../blocks/StudentCourseDetailsBlock';

import styles from './StudentCourseDetailsPage.module.scss';
import usePresenter from './StudentCourseDetailsPage.presenter';
import getProps from './StudentCourseDetailsPage.props';
import { StudentCourseDetailsPageCombinedProps } from './types';


const StudentCourseDetailsPage: React.FC<StudentCourseDetailsPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    logo,
    studentCourseDetailsBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.studentCourseDetailsPage;

  return (
    <div className={cx(currentStyle, className)}>
      <Image
        className={cx(styles.image, classes?.image)}
        {...internalProps.image}
        {...image}/>
      <div className={cx(styles.logoContainer, classes?.logoContainer)}>
        <Image
          className={cx(styles.logo, classes?.logo)}
          {...internalProps.logo}
          {...logo}/>
      </div>
      <div className={cx(styles.content, classes?.content)}>
        <StudentCourseDetailsBlock
          className={cx(styles.studentCourseDetailsBlock, classes?.studentCourseDetailsBlock)}
          {...internalProps.studentCourseDetailsBlock}
          {...studentCourseDetailsBlock}/>
      </div>
    </div>
  );
};

export default StudentCourseDetailsPage;

