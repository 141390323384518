import { LabelTextGroupProps } from '../../molecules/LabelTextGroup';


type LabelTextGroupListInternalProps = {
  labelTextGroup?: LabelTextGroupProps;
};

type LabelTextGroupListPropsValues = {
  '': LabelTextGroupListInternalProps;
};

const propValues: LabelTextGroupListPropsValues = {
  '': {
    labelTextGroup: {
      type: 'Horizontal',
    },
  },
};

const getProps = (type: string): LabelTextGroupListInternalProps => {
  const values: LabelTextGroupListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
