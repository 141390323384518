import { ButtonProps } from '../../atoms/Button';
import { InputFieldProps } from '../InputField';
import { PriceListItemProps } from '../PriceListItem';


type DiscountCodeInternalProps = {
  inputField?: InputFieldProps;
  button?: ButtonProps;
  priceListItem?: PriceListItemProps;
};

type DiscountCodePropsValues = {
  'Applied': DiscountCodeInternalProps;
  'Default': DiscountCodeInternalProps;
  'Expanded': DiscountCodeInternalProps;
};

const propValues: DiscountCodePropsValues = {
  'Applied': {
    inputField: {
      state: 'Default',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'Text',
      colour: 'Critical',
    },
    priceListItem: {
      type: 'Default',
    },
  },
  'Default': {
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'PrimaryAction',
    },
  },
  'Expanded': {
    inputField: {
      state: 'Default',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
    },
  },
};

const getProps = (type: string): DiscountCodeInternalProps => {
  const values: DiscountCodeInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
