import { LogEntriesAccordianItemProps } from '../LogEntriesAccordianItem';


type LogEntriesAccordianListInternalProps = {
  logEntriesAccordianItem?: LogEntriesAccordianItemProps;
};

type LogEntriesAccordianListPropsValues = {
  '': LogEntriesAccordianListInternalProps;
};

const propValues: LogEntriesAccordianListPropsValues = {
  '': {
    logEntriesAccordianItem: {
      type: 'Collapsed',
      state: 'Default',
    },
  },
};

const getProps = (type: string): LogEntriesAccordianListInternalProps => {
  const values: LogEntriesAccordianListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
