import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { formatDate } from '../../../lib/utils';
import { COURSE_DATE_FORMAT_OPTIONS, COURSE_ROUTES, COURSE_TIME_FORMAT_OPTIONS } from '../../../lib/constants';
import { CourseState } from '../../../modules/course/types';
import { TabStateEnum } from '../../atoms/Tab/types';
import { CourseCardCombinedProps } from '../../molecules/CourseCard';
import { getNextAvailableClassIndex } from '../CoursesPage/utils';

import { getCoursesUseCase } from './ArchivedCoursesPage.interactor';
import styles from './ArchivedCoursesPage.module.scss';
import { ArchivedCoursesPageCombinedProps } from './types';

const usePresenter = (props: ArchivedCoursesPageCombinedProps): ArchivedCoursesPageCombinedProps => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();

  const [courseList, setCourseList] = useState<CourseCardCombinedProps[]>([]);

  const { data: courses, isLoading } = useQuery(['getArchivedCourses'], () => {
    return getCoursesUseCase(CourseState.Archived);
  });

  useEffect(() => {
    if (courses?.length) {
      const courseListProps: (CourseCardCombinedProps)[] = [];
      courses.forEach((course) => {
        const { classes, classCapacity, enrolledStudents } = course.content;

        const courseCardProps: CourseCardCombinedProps = {
          type: 'Course',
          classes: {
            topContent: styles.archivedCourseCardTopContent,
          },
          registrationStatusTag: {
            text: {
              value: course.content.registrationOpen
                ? t('course_card.registration_status.open')
                : t('course_card.registration_status.closed'),
            },
          },
          iconDropdown: {
            toggleProps: {
              button: {
                icon: {
                  asset: 'VerticalMenu',
                  colour: 'Inverse',
                },
              },
            },
          },
          text: {
            value: course.content.title,
            classes: {
              value: styles.title,
            },
          },
          iconTextGroup: {
            labelIcon: {
              asset: 'People',
            },
            infoText: {
              value: t('course_card.class_capacity', {
                // TODO: fetch current number of student enrolled for course
                currentEnrolled: enrolledStudents || 0,
                totalCapacity: classCapacity,
              }),
            },
          },
          button1: {
            icon: {
              asset: 'ArrowRight',
            },
            text: {
              value: t('course_card.buttons.view_course'),
            },
            onClick: () => history.push(`/courses/detail/${course.content.id}`),
          },
          handleOnClick: () => history.push(`/courses/detail/${course.content.id}`),
        };

        if (classes?.length) {
          const nextAvailableClassIndex = getNextAvailableClassIndex(classes);
          const { date, startTime, endTime, location, classLink } = classes[nextAvailableClassIndex].content;
          courseCardProps.classItem = {
            text: {
              value: t('course_card.class_name', {
                classNumber: `${nextAvailableClassIndex + 1}`, 
              }),
            },
            iconTextGroupList: {
              iconTextGroups: [
                {
                  labelIcon: {
                    asset: 'Calendar',
                  },
                  infoText: {
                    value: formatDate(date, COURSE_DATE_FORMAT_OPTIONS),
                  },
                },
                {
                  labelIcon: {
                    asset: 'Clock',
                  },
                  infoText: {
                    value: `${formatDate(startTime, COURSE_TIME_FORMAT_OPTIONS)} - ${formatDate(endTime, COURSE_TIME_FORMAT_OPTIONS)}`,
                  },
                },
                {
                  labelIcon: {
                    asset: location ? 'Location' : 'Timezone',
                  },
                  infoText: {
                    value: location || classLink,
                  },
                },
              ],
            },
          };
        }

        courseListProps.push(courseCardProps);
        return;
      });
      setCourseList([...courseListProps]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses, t]);

  const getTabState = (tabPathname: string): TabStateEnum => {
    return pathname === tabPathname ? 'Selected' : 'Unselected';
  };

  return {
    ...props,
    isLoading,
    coursesHeader: {
      text: {
        value: t('course_header.title'),
      },
      tabsList: {
        tabs: [
          {
            text: {
              value: t('course_header.tablist.active'),
            },
            state: getTabState(COURSE_ROUTES.active),
            onClick: () => history.push(COURSE_ROUTES.active),
          },
          {
            text: {
              value: t('course_header.tablist.archived'),
            },
            state: getTabState(COURSE_ROUTES.archived),
            onClick: () => history.push(COURSE_ROUTES.archived),
          },
        ],
      },
    },
    coursesBlock: {
      courseCardsList: {
        courseCards: courseList,
      },
    },
  };
};

export default usePresenter;
