import { GenericStateBlockProps } from '../../blocks/GenericStateBlock';


type AccountExpiredPageInternalProps = {
  genericStateBlock?: GenericStateBlockProps;
};

type AccountExpiredPagePropsValues = {
  '': AccountExpiredPageInternalProps;
};

const propValues: AccountExpiredPagePropsValues = {
  '': {
    genericStateBlock: {
    },
  },
};

const getProps = (type: string): AccountExpiredPageInternalProps => {
  const values: AccountExpiredPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
