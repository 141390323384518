import { CourseCardProps } from '../../molecules/CourseCard';


type CourseCardsListInternalProps = {
  courseCard?: CourseCardProps;
};

type CourseCardsListPropsValues = {
  '': CourseCardsListInternalProps;
};

const propValues: CourseCardsListPropsValues = {
  '': {
    courseCard: {
      type: 'Course',
    },
  },
};

const getProps = (type: string): CourseCardsListInternalProps => {
  const values: CourseCardsListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
