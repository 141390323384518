import React from 'react';
import ModalWrapper, { ExportedModalProps } from '../../../modules/common/ModalWrapper';

import InternalConfirmationModalProps from './ConfirmationModal';
import { 
  ConfirmationModalProps, 
  ConfirmationModalValueProps as InternalConfirmationModalValueProps,
} from './types';

export type { ConfirmationModalProps };

export type ConfirmationModalValueProps = ExportedModalProps<InternalConfirmationModalValueProps>;

const ConfirmationModal: React.FC<ConfirmationModalValueProps> = ({ className, onHide, show, modalProps }) => (
  <ModalWrapper
    className={className}
    content={<InternalConfirmationModalProps {...modalProps} />}
    onHide={onHide}
    show={show}
  />
);

export default ConfirmationModal;
