import React from 'react';
import ModalWrapper, { ExportedModalProps } from '../../../modules/common/ModalWrapper';
import InternalPaymentInfoModal from './PaymentInfoModal';
import { 
  PaymentInfoModalProps, 
  PaymentInfoModalValueProps as InternalPaymentInfoModalValueProps,
} from './types';

export type { PaymentInfoModalProps };

export type PaymentInfoModalValueProps = ExportedModalProps<InternalPaymentInfoModalValueProps>;

const PaymentInfoModal: React.FC<PaymentInfoModalValueProps> = ({ className, onHide, show, modalProps }) => {
  return (
    <ModalWrapper
      className={className}
      content={<InternalPaymentInfoModal {...modalProps} />}
      onHide={onHide}
      show={show}
    />
  );
};

export default PaymentInfoModal;
