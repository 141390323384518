import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { TabsListProps } from '../TabsList';


type CourseHeaderInternalProps = {
  button?: ButtonProps;
  text?: TextProps;
  tabsList?: TabsListProps;
  divider?: DividerProps;
};

type CourseHeaderPropsValues = {
  '': CourseHeaderInternalProps;
};

const propValues: CourseHeaderPropsValues = {
  '': {
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'Base',
      loading: 'Default',
    },
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading1',
    },
    tabsList: {
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
};

const getProps = (type: string): CourseHeaderInternalProps => {
  const values: CourseHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
