import React from 'react';
import cx from 'classnames';

import GenericActionBlock from '../../blocks/GenericActionBlock';

import styles from './AlreadyRegisteredPage.module.scss';
import usePresenter from './AlreadyRegisteredPage.presenter';
import getProps from './AlreadyRegisteredPage.props';
import { AlreadyRegisteredPageCombinedProps } from './types';


const AlreadyRegisteredPage: React.FC<AlreadyRegisteredPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    genericActionBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.alreadyRegisteredPage;

  return (
    <div className={cx(currentStyle, className)}>
      <GenericActionBlock
        className={cx(styles.genericActionBlock, classes?.genericActionBlock)}
        {...internalProps.genericActionBlock}
        {...genericActionBlock}/>
    </div>
  );
};

export default AlreadyRegisteredPage;

