import { useEffect, useRef, useState } from 'react';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { useHistory } from 'react-router';

import { BFF_URL, STRIPE_API_KEY, STUDENT_ROLE_ID } from './config';
import { DataEntry, isPersonAccount, Person } from './types';
import { REGEX_FIRST_AND_LAST_NAME, VALIDATION_COUNTRY_LIST, REGEX_ACCEPT_PHONE_NUMBER, AUTH_ROUTES, NOT_FOUND_ROUTE } from './constants';
import { JourneyStatusEnum, RegistrationStatus, RegistrationStatusEnum, TeacherRegistrationStatus } from '../modules/teacher/types';
import { ADMIN_ROLE_ID } from './config';
import { Account, User } from './token';
import { IconTextGroupValueProps } from '../components/molecules/IconTextGroup';
import { ClassFormatEnum, Course } from '../modules/course/types';

export const stripePromise = loadStripe(STRIPE_API_KEY);
export const stripeElementOptions: StripeElementsOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Cabin:wght@100;200;300;400;500;600;700;800;900&display=swap',
    },
  ],
};

export const getServerUrl = (): string => {
  if (!BFF_URL) {
    throw new Error('BFF url not set');
  }
  return BFF_URL;
};

export const isEmptyString = (value?: string) => (value ? value.trim().length === 0 : true);

export const isEmail = (value: string) => {
  //eslint-disable-next-line
  const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexEmail.test(value.trim());
};

export const isPostalCode = (country: string, value: string) => {
  const regexIsCanadianPostalCode = /^[A-Z]\d[A-Z][ -]?\d[A-Z]\d$/;
  const regexIsUSPostalCode = /^[0-9]{5}[ -]?([0-9]{4})?$/;
  if (country === VALIDATION_COUNTRY_LIST.canada) {
    return regexIsCanadianPostalCode.test(value.toUpperCase());
  } else if (country === VALIDATION_COUNTRY_LIST.unitedStates) {
    return regexIsUSPostalCode.test(value);
  }
  // do not check for countries other than Canada and United States
  return true;
};

export const preprocessNumber = (value: string): string => {
  const REGEX_NON_NUMBER = /[^0-9]/g;
  return value.replace(REGEX_NON_NUMBER, '');
};

export const preprocessFirstAndLastName = (value: string): string => {
  return value.replace(REGEX_FIRST_AND_LAST_NAME, '');
};

export const acceptPhoneNumber = (value: string): string => {
  // accepts only + sign and numeric values
  const phoneString = value.replace(REGEX_ACCEPT_PHONE_NUMBER, '');
  const rest = phoneString.substring(1, phoneString.length);
  // do not allow subsequent characters other than the first to be '+'
  return `${phoneString.charAt(0)}${rest.replace('+', '')}`;
};

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
export const formatPrice = (price?: number) => {
  if (price !== undefined) {
    const priceWithDecimal = (price / 100);
    return currencyFormat.format(priceWithDecimal);
  }
  return '';
};

export const isNumber = (value: string) => {
  const regexNumber = /^[0-9]+$/;
  return regexNumber.test(value);
};

export const isStudent = (user?: User) => {
  return user?.content?.roleId === Number(STUDENT_ROLE_ID);
};

export const isExpiryDatePassed = (exp: number): boolean => {
  const now = (new Date().getTime()) / 1000;
  return exp < now;
};

export const useInterval = (callback: () => Promise<void>, delay: number) => {
  const savedCallback = useRef<() => Promise<void>>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    async function tick() {
      if (savedCallback.current) {
        await savedCallback.current();
      }
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export const useLimitedInterval = (callback: () => Promise<void>, delay: number, limit: number) => {
  const [count, setCount] = useState<number>(limit);
  const applyCallback = async () => {
    if (count > 0) {
      await callback();
      setCount(count - 1);
    }
  };
  useInterval(applyCallback, delay);
};

export const getUserRegistrationStatus = (user: User): TeacherRegistrationStatus => {
  let registrationStatus: RegistrationStatusEnum = user.content.journeyStatus;
  if (user.content.profile && user.content.journeyStatus === JourneyStatusEnum.account_created) {
    registrationStatus = RegistrationStatus.profile_completed;
  }

  return { registrationStatus: registrationStatus };
};

export const getRegistrationStepRoute = (registrationStatus: TeacherRegistrationStatus): string => {
  let nextStepRoute;
  if (registrationStatus.registrationStatus === JourneyStatusEnum.account_created) {
    nextStepRoute = AUTH_ROUTES.completeProfile;
  }

  if (registrationStatus.registrationStatus === RegistrationStatus.profile_completed) {
    nextStepRoute = AUTH_ROUTES.tuitionPayment;
  }

  if (registrationStatus.registrationStatus === JourneyStatusEnum.payment_complete) {
    nextStepRoute = AUTH_ROUTES.certificationAgreement;
  }

  if (registrationStatus?.registrationStatus === JourneyStatusEnum.in_training) {
    nextStepRoute = AUTH_ROUTES.alreadyRegistered;
  }

  return nextStepRoute;
};

export const NO_RESTRICTION_JOURNEY_STATUSES = [
  JourneyStatusEnum.certified,
  JourneyStatusEnum.graduate,
  JourneyStatusEnum.under_review,
  JourneyStatusEnum.in_training,
];


export const getIsAccessRestricted = (isExpired: boolean, user?: User): boolean => {
  if (!user) {
    return true;
  }
  const isAdmin = user.content.roleId === Number(ADMIN_ROLE_ID);
  // check for user statuses which would have a restricted access regardles of an expiry date
  const isRestrictedUserStatus = !(NO_RESTRICTION_JOURNEY_STATUSES.includes(user.content.journeyStatus));
  // check if user has status under_review, if they do - no restrictions should be added
  const isUserUnderReview = user.content.journeyStatus === JourneyStatusEnum.under_review;
  const accessRestricted = !isAdmin && !isUserUnderReview && (isExpired || isRestrictedUserStatus);
  return accessRestricted;
};

export const dateFormatOptions: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

export const formatDate = (date: Date, formatOptions: Intl.DateTimeFormatOptions = dateFormatOptions) => {
  return Intl.DateTimeFormat('en-US', formatOptions).format(new Date(date));
};

export const isDateEqual = (dateA: Date, dateB: Date): boolean => {
  return dateA.getDate() === dateB.getDate()
    && dateA.getMonth() === dateB.getMonth()
    && dateA.getFullYear() === dateB.getFullYear();
};

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch {
    console.warn('Unable to copy text to clipboard');
  } finally {
    document.body.removeChild(textArea);
  }
};

export const copyTextToClipboard = async (text: string) => {
  if (!navigator.clipboard) {
    // Asynchronous Clipboard API not supported, fallback to document.execCommand('copy') which
    // is deprecated for modern browsers
    fallbackCopyTextToClipboard(text);
    return;
  }

  // Try to use the Asynchronous Clipboard API
  // Support at 91.46% as of May 6th, 2022: https://caniuse.com/async-clipboard
  try {
    await navigator.clipboard.writeText(text);
  } catch {
    console.warn('Unable to copy text to clipboard');
  }
};

export const getPersonName = (person?: Person): string => {
  if (!person) {
    return '';
  }
  const { firstName = '', lastName = '' } = isPersonAccount(person) ? person : person.content;
  return `${firstName.trim()} ${lastName.trim()}`.trim();
};

export const getPersonNameInitials = (person?: Person): string => {
  if (!person) {
    return '';
  }

  const { firstName = '', lastName = '' } = isPersonAccount(person) ? person : person.content;
  const firstInitial: string = firstName.trim()[0] ?? '';
  const secondInitial: string = lastName.trim()[0] ?? '';
  return `${firstInitial}${secondInitial}`.toLocaleUpperCase();
};

export const getClassIcons = (classFormat: string, location?: string, classLink?: string): IconTextGroupValueProps[] => {
  switch (classFormat) {
    case ClassFormatEnum.Both:
      return [
        {
          labelIcon: {
            asset: 'Location',
          },
          infoText: {
            value: location,
          },
        },
        {
          labelIcon: {
            asset: 'Timezone',
          },
          infoText: {
            value: classLink,
          },
        },
      ];
    case ClassFormatEnum.InPerson:
      return [
        {
          labelIcon: {
            asset: 'Location',
          },
          infoText: {
            value: location,
          },
        },
      ];
    case ClassFormatEnum.Online:
      return [
        {
          labelIcon: {
            asset: 'Timezone',
          },
          infoText: {
            value: classLink,
          },
        },
      ];
    default:
      return [];
  }
};

export const findTeacherInCourse = (account: Account, course: DataEntry<Course>) => {
  const { email: authEmail } = account;
  const { primaryTeacher, coTeachers } = course.content;
  const teachers = [primaryTeacher, ...(coTeachers || [])];
  return teachers.find((teacher) => teacher.content.email.toLowerCase() === authEmail.toLowerCase());
};

export const useValidateTeacher = (account?: Account, course?: DataEntry<Course>) => {
  const history = useHistory();
  useEffect(() => {
    if (account && course) {
      const validTeacher = findTeacherInCourse(account, course);
      if (!validTeacher) {
        history.replace(NOT_FOUND_ROUTE);
      }
    }
  }, [account, course, history]);
};
