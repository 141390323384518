import { HeaderProps } from '../../organisms/Header';
import { LeftNavProps } from '../../organisms/LeftNav';
import { ArchivedCoursesPageProps } from '../../pages/ArchivedCoursesPage';
import { CourseDetailsPageProps } from '../../pages/CourseDetailsPage';
import { CoursesPageProps } from '../../pages/CoursesPage';
import { CreateCourseBasicDetailsPageProps } from '../../pages/CreateCourseBasicDetailsPage';
import { CreateCourseSchedulePageProps } from '../../pages/CreateCourseSchedulePage';
import { MyProfilePageProps } from '../../pages/MyProfilePage';


type HeaderSidenavLayoutInternalProps = {
  header?: HeaderProps;
  myProfilePage?: MyProfilePageProps;
  coursesPage?: CoursesPageProps;
  archivedCoursesPage?: ArchivedCoursesPageProps;
  createCourseBasicInfoPage?: CreateCourseBasicDetailsPageProps;
  courseDetailsPage?: CourseDetailsPageProps;
  createCourseSchedulePage?: CreateCourseSchedulePageProps;
  leftNav?: LeftNavProps;
};

type HeaderSidenavLayoutPropsValues = {
  '': HeaderSidenavLayoutInternalProps;
};

const propValues: HeaderSidenavLayoutPropsValues = {
  '': {
    header: {
      type: 'Default',
    },
    myProfilePage: {
    },
    coursesPage: {
    },
    archivedCoursesPage: {
    },
    leftNav: {
    },
    createCourseBasicInfoPage: {
    },
    createCourseSchedulePage: {
    },
  },
};

const getProps = (type: string): HeaderSidenavLayoutInternalProps => {
  const values: HeaderSidenavLayoutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
