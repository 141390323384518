import React from 'react';
import cx from 'classnames';
import { Route, Switch } from 'react-router';

import Header from '../../organisms/Header';
import MyProfilePage from '../../pages/MyProfilePage';

import styles from './HeaderSidenavLayout.module.scss';
import usePresenter from './HeaderSidenavLayout.presenter';
import getProps from './HeaderSidenavLayout.props';
import { HeaderSidenavLayoutCombinedProps } from './types';
import LeftNav from '../../organisms/LeftNav';
import CoursesPage from '../../pages/CoursesPage';
import { COURSE_ROUTES, DASHBOARD_ROUTE } from '../../../lib/constants';
import ArchivedCoursesPage from '../../pages/ArchivedCoursesPage';
import CreateCourseBasicDetailsPage from '../../pages/CreateCourseBasicDetailsPage';
import CourseDetailsPage from '../../pages/CourseDetailsPage';
import CreateCourseSchedulePage from '../../pages/CreateCourseSchedulePage';
import LogbookPage from '../../pages/LogbookPage';
import RosterPage from '../../pages/RosterPage';


const HeaderSidenavLayout: React.FC<HeaderSidenavLayoutCombinedProps> = (props) => {
  const {
    className,
    classes,
    header,
    myProfilePage,
    coursesPage,
    archivedCoursesPage,
    createCourseBasicInfoPage,
    courseDetailsPage,
    createCourseSchedulePage,
    leftNav,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.headerSidenavLayout;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Header
          className={cx(styles.header1, classes?.header1)}
          {...internalProps.header}
          {...header}/>
      </div>
      <div className={cx(styles.body, classes?.body)}>
        <LeftNav 
          className={cx(styles.leftNav, classes?.leftNav)}
          {...internalProps.leftNav}
          {...leftNav}/>
        <Switch>
          <Route path={DASHBOARD_ROUTE.dashboard}>
            <MyProfilePage
            className={cx(styles.myProfilePage, classes?.myProfilePage)}
            {...internalProps.myProfilePage}
            {...myProfilePage}/>
          </Route>
          <Route path={COURSE_ROUTES.active}>
            <CoursesPage 
            className={cx(styles.coursesPage, classes?.coursesPage)}
            {...internalProps.coursesPage}
            {...coursesPage}/>
          </Route>
          <Route path={COURSE_ROUTES.archived}>
            <ArchivedCoursesPage 
            className={cx(styles.archivedCoursesPage, classes?.archivedCoursesPage)}
            {...internalProps.archivedCoursesPage}
            {...archivedCoursesPage}/>
          </Route>
          <Route path={COURSE_ROUTES.newCourse.step1}>
            <CreateCourseBasicDetailsPage
              className={cx(styles.createCourseBasicInfoPage, classes?.createCourseBasicInfoPage)}
              {...internalProps.createCourseBasicInfoPage}
              {...createCourseBasicInfoPage}/>
          </Route>
          <Route path={COURSE_ROUTES.editCourse.step1}>
            <CreateCourseBasicDetailsPage
              className={cx(styles.createCourseBasicInfoPage, classes?.createCourseBasicInfoPage)}
              {...internalProps.createCourseBasicInfoPage}
              {...createCourseBasicInfoPage}/>
          </Route>
          <Route path={COURSE_ROUTES.editCourse.step2}>
            <CreateCourseSchedulePage
              className={cx(styles.createCourseSchedulePage, classes?.createCourseSchedulePage)}
              {...internalProps.createCourseSchedulePage}
              {...createCourseSchedulePage}/>
          </Route>
          <Route path={COURSE_ROUTES.courseLogbookDetail}>
            <LogbookPage
              className={cx(styles.courseDetailsPage, classes?.courseDetailsPage)}
              {...internalProps.courseDetailsPage}
              {...courseDetailsPage}/>
          </Route>
          <Route path={COURSE_ROUTES.courseRoster}>
            <RosterPage
              className={cx(styles.courseDetailsPage, classes?.courseDetailsPage)}
              {...internalProps.courseDetailsPage}
              {...courseDetailsPage}/>
          </Route>
          <Route path={COURSE_ROUTES.courseDetail}>
            <CourseDetailsPage
              className={cx(styles.courseDetailsPage, classes?.courseDetailsPage)}
              {...internalProps.courseDetailsPage}
              {...courseDetailsPage}/>
          </Route>
          <Route path={COURSE_ROUTES.newCourse.step2}>
            <CreateCourseSchedulePage
              className={cx(styles.createCourseSchedulePage, classes?.createCourseSchedulePage)}
              {...internalProps.createCourseSchedulePage}
              {...createCourseSchedulePage}/>
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default HeaderSidenavLayout;
