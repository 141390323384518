import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type NavMenuItemInternalProps = {
  icon?: IconProps;
  text?: TextProps;
  trailingIcon?: IconProps;
};

type NavMenuItemPropsValues = {
  'IconDefault': NavMenuItemInternalProps;
  'IconSelected': NavMenuItemInternalProps;
  'IconTextDefault': NavMenuItemInternalProps;
  'IconTextSelected': NavMenuItemInternalProps;
  'IconTextIconDefault': NavMenuItemInternalProps;
  'IconTextIconSelected': NavMenuItemInternalProps;
};

const propValues: NavMenuItemPropsValues = {
  'IconDefault': {
    icon: {
      asset: 'Support',
      colour: 'Multicolor',
    },
  },
  'IconSelected': {
    icon: {
      asset: 'Support',
      colour: 'Multicolor',
    },
  },
  'IconTextDefault': {
    icon: {
      asset: 'Support',
      colour: 'Multicolor',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label1',
    },
  },
  'IconTextSelected': {
    icon: {
      asset: 'Support',
      colour: 'Multicolor',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label1',
    },
  },
  'IconTextIconDefault': {
    icon: {
      asset: 'Support',
      colour: 'Multicolor',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label1',
    },
    trailingIcon: {
      asset: 'ChevronDownBig',
      colour: 'Base',
    },
  },
  'IconTextIconSelected': {
    icon: {
      asset: 'Support',
      colour: 'Multicolor',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label1',
    },
    trailingIcon: {
      asset: 'ChevronDownBig',
      colour: 'Base',
    },
  },
};

const getProps = (type: string): NavMenuItemInternalProps => {
  const values: NavMenuItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
