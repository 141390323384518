import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

import styles from './GenericModal.module.scss';
import usePresenter from './GenericModal.presenter';
import getProps from './GenericModal.props';
import { GenericModalCombinedProps } from './types';


const GenericModal: React.FC<GenericModalCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    button,
    text1,
    primaryButton,
    secondaryButton,
    type,
  } = usePresenter(props);

  const internalProps = getProps(`${type}`);
  const currentStyle = styles.genericModal;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <Text
          className={cx(styles.text1, classes?.text1)}
          {...internalProps.text1}
          {...text1}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.primaryButton, classes?.primaryButton)}
          {...internalProps.primaryButton}
          {...primaryButton}/>
        <Button
          className={cx(styles.secondaryButton, classes?.secondaryButton)}
          {...internalProps.secondaryButton}
          {...secondaryButton}/>
      </div>
    </div>
  );
};

export default GenericModal;

