import { DividerProps } from '../../atoms/Divider';
import { ToggleProps } from '../../molecules/Toggle';
import { LogEntriesCatalogProps } from '../../organisms/LogEntriesCatalog';
import { LogEntryProps } from '../../organisms/LogEntry';
import { LogEntryStatusListProps } from '../../organisms/LogEntryStatusList';
import { ReplySectionProps } from '../../organisms/ReplySection';
import { TextMessageListProps } from '../../organisms/TextMessageList';
import { GenericStateNoActionBlockProps } from '../GenericStateNoActionBlock';


type LogbookBlockInternalProps = {
  toggle?: ToggleProps;
  divider?: DividerProps;
  logEntryStatusList?: LogEntryStatusListProps;
  divider1?: DividerProps;
  logEntriesCatalog?: LogEntriesCatalogProps;
  divider2?: DividerProps;
  logEntry?: LogEntryProps;
  textMessageList?: TextMessageListProps;
  replySection?: ReplySectionProps;
  noLogSelectedState?: GenericStateNoActionBlockProps; // MANUAL OVERRIDE
};

type LogbookBlockPropsValues = {
  '': LogbookBlockInternalProps;
};

const propValues: LogbookBlockPropsValues = {
  '': {
    toggle: {
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    logEntryStatusList: {
    },
    divider1: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    logEntriesCatalog: {
    },
    divider2: {
      type: 'Vertical',
      style: 'Solid',
      colour: 'BorderBase',
    },
    logEntry: {
    },
    textMessageList: {
    },
    replySection: {
    },
    noLogSelectedState: {
    },
  },
};

const getProps = (type: string): LogbookBlockInternalProps => {
  const values: LogbookBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
