import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import CardInfo from '../../molecules/CardInfo';
import PriceListItem from '../../molecules/PriceListItem';

import styles from './TrainingExtensionModal.module.scss';
import usePresenter from './TrainingExtensionModal.presenter';
import getProps from './TrainingExtensionModal.props';
import { TrainingExtensionModalCombinedProps } from './types';
import InlineNotification from '../../atoms/InlineNotification';
import CheckboxItem from '../../atoms/CheckboxItem';


const TrainingExtensionModal: React.FC<TrainingExtensionModalCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    button,
    priceListItem,
    cardInfo,
    primaryButton,
    primaryButton1,
    inlineNotification,
    showNotification,
    checkboxItem,
    showCheckbox,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.trainingExtensionModal;

  const inlineNotificationView = showNotification ? 
  <InlineNotification 
    className={cx(styles.inlineNotification, classes?.inlineNotification)}
    {...internalProps.inlineNotification}
    {...inlineNotification}
  /> : undefined;

  const checkboxItemView = showCheckbox ?
  <CheckboxItem 
    className={cx(styles.checkboxItem, classes?.checkboxItem)}
    {...internalProps.checkboxItem}
    {...checkboxItem}
  /> : undefined;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
      { inlineNotificationView }
        <PriceListItem
          className={cx(styles.priceListItem, classes?.priceListItem)}
          {...internalProps.priceListItem}
          {...priceListItem}/>
        <CardInfo
          className={cx(styles.cardInfo, classes?.cardInfo)}
          {...internalProps.cardInfo}
          {...cardInfo}/>
        { checkboxItemView }
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.primaryButton, classes?.primaryButton)}
          {...internalProps.primaryButton}
          {...primaryButton}/>
        <Button
          className={cx(styles.primaryButton1, classes?.primaryButton1)}
          {...internalProps.primaryButton1}
          {...primaryButton1}/>
      </div>
    </div>
  );
};

export default TrainingExtensionModal;

