import React from 'react';
import cx from 'classnames';

import CreateCourseScheduleBlock from '../../blocks/CreateCourseScheduleBlock';
import CreateCourseHeader from '../../organisms/CreateCourseHeader';

import styles from './CreateCourseSchedulePage.module.scss';
import usePresenter from './CreateCourseSchedulePage.presenter';
import getProps from './CreateCourseSchedulePage.props';
import { CreateCourseSchedulePageCombinedProps } from './types';
import GenericModal from '../../organisms/GenericModal';


const CreateCourseSchedulePage: React.FC<CreateCourseSchedulePageCombinedProps> = (props) => {
  const {
    className,
    classes,
    createCourseHeader,
    createCourseScheduleBlock,
    pageCloseWarningModal,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.createCourseSchedulePage;

  return (
    <div className={cx(currentStyle, className)}>
      <CreateCourseHeader
        className={cx(styles.createCourseHeader, classes?.createCourseHeader)}
        {...internalProps.createCourseHeader}
        {...createCourseHeader}/>
      <div className={cx(styles.content, classes?.content)}>
        <CreateCourseScheduleBlock
          className={cx(styles.createCourseScheduleBlock, classes?.createCourseScheduleBlock)}
          {...internalProps.createCourseScheduleBlock}
          {...createCourseScheduleBlock}/>
      </div>
      <GenericModal
        className={styles.modalWrapper}
        {...pageCloseWarningModal} />
    </div>
  );
};

export default CreateCourseSchedulePage;

