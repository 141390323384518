import { DataEntry } from '../../lib/types';
import type { Course } from '../course/types';
import type { Student } from '../student/types';

export enum LogEntrySenderType {
  student = 'Student',
  teacher = 'Teacher',
  coTeacher = 'Co-Teacher',
}

export enum LogStatus {
  awaiting_reply = 'Awaiting Reply',
  replied = 'Replied',
}

export enum SortableLogFields {
  date = 'date',
  student = 'student',
}

export type LogEntry = {
  log: DataEntry<Log>;
  senderType: LogEntrySenderType;
  sender: string;
  message: string;
};

export type Log = {
  course: DataEntry<Course>;
  student:  DataEntry<Student>;
  logEntries?: DataEntry<LogEntry>[];
  status: LogStatus;
  unreadMessages: number;
  sessionDate: Date;
  meditationDuration: number;
  gratefulFor?: string | string[];
  reflections?: string;
  skills?: string | string[];
  guidedMeditation?: boolean;
  mindfulActivities?: string;
  opened?: boolean;
};

export type UpdateLogPayload = Partial<Pick<Log, 'status' | 'unreadMessages' | 'opened'>>;

export type CreateLogEntryPayload = Omit<LogEntry, 'log' | 'sender'> & {
  senderId: string;
};
