import { useRef } from 'react';
import { TextAreaCombinedProps } from './types';

const usePresenter = (props: TextAreaCombinedProps): TextAreaCombinedProps => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  return {
    ...props,
    textareaRef,
  };
};

export default usePresenter;
