import { ButtonProps } from '../../atoms/Button';


type IconDropDownInternalProps = {
  button?: ButtonProps;
};

type IconDropDownPropsValues = {
  '': IconDropDownInternalProps;
};

const propValues: IconDropDownPropsValues = {
  '': {
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): IconDropDownInternalProps => {
  const values: IconDropDownInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;