import { TextProps } from '../../atoms/Text';
import { IconProps } from '../../atoms/Icon';


type TextIconGroupInternalProps = {
  infoText?: TextProps;
  labelIcon?: IconProps;
};

type TextIconGroupPropsValues = {
  '': TextIconGroupInternalProps;
};

const propValues: TextIconGroupPropsValues = {
  '': {
    infoText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Info',
      type: 'Body1',
    },
    labelIcon: {
      asset: 'External',
      colour: 'Info',
    },
  },
};

const getProps = (type: string): TextIconGroupInternalProps => {
  const values: TextIconGroupInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
