import React from 'react';
import cx from 'classnames';


import styles from './Image.module.scss';
import usePresenter from './Image.presenter';
import { ImageCombinedProps } from './types';


const Image: React.FC<ImageCombinedProps> = (props) => {
  const {
    type,
    className,
    imageSrc,
    imageFallback,
  } = usePresenter(props);

  const variantName = `${type}`;
  const currentStyle = styles[`image${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Rectangle': // MANUAL OVERRIDE
    case 'Oval':
    case 'Square': {
      componentView = (
        <div className={cx(currentStyle, className)} style={{ backgroundImage: `url(${imageSrc}), url(${imageFallback})` }}/>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Image;

