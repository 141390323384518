import { TextProps } from '../../atoms/Text';
import { TextGroupProps } from '../../molecules/TextGroup';
import { TeacherAgreementFooterProps } from '../../organisms/TeacherAgreementFooter';


type TeacherAgreementBlockInternalProps = {
  stepperText?: TextProps;
  textGroup?: TextGroupProps;
  text?: TextProps;
  textGroups?: TextGroupProps;
  teacherAgreementFooter?: TeacherAgreementFooterProps;
};

type TeacherAgreementBlockPropsValues = {
  '': TeacherAgreementBlockInternalProps;
};

const propValues: TeacherAgreementBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    textGroup: {
    },
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    textGroups: {
    },
  },
};

const getProps = (type: string): TeacherAgreementBlockInternalProps => {
  const values: TeacherAgreementBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
