import { LogEntryStatusItemProps } from '../../atoms/LogEntryStatusItem';


type LogEntryStatusListInternalProps = {
  logEntryStatusItem?: LogEntryStatusItemProps;
};

type LogEntryStatusListPropsValues = {
  '': LogEntryStatusListInternalProps;
};

const propValues: LogEntryStatusListPropsValues = {
  '': {
    logEntryStatusItem: {
      state: 'Unselected',
      type: 'Status',
    },
  },
};

const getProps = (type: string): LogEntryStatusListInternalProps => {
  const values: LogEntryStatusListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
