import React from 'react';
import { Route, Switch } from 'react-router';
import cx from 'classnames';

import { STUDENT_ROUTES } from '../../../lib/constants';
import CourseRegistrationSubheader from '../../organisms/CourseRegistrationSubheader';
import StudentKoruAccountBlock from '../../blocks/StudentKoruAccountBlock';
import StudentSignupBlock from '../../blocks/StudentSignupBlock';
import StudentSignInBlock from '../../blocks/StudentSignInBlock';

import styles from './StudentKoruAccountPage.module.scss';
import usePresenter from './StudentKoruAccountPage.presenter';
import getProps from './StudentKoruAccountPage.props';
import { StudentKoruAccountPageCombinedProps } from './types';
import VerifyEmailBlock from '../../blocks/VerifyEmailBlock';
import StudentAppFeeBlock from '../../blocks/StudentAppFeeBlock';
import ConfirmAttendenceBlock from '../../blocks/ConfirmAttendenceBlock';
import ProtectedRoute from '../../../modules/auth/ProtectedRoute';
import LoadingSpinner from '../../atoms/LoadingSpinner';


const StudentKoruAccountPage: React.FC<StudentKoruAccountPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    courseRegistrationSubheader,
    studentKoruAccountBlock,
    studentSignupBlock,
    studentSigninBlock,
    verifyEmailBlock,
    studentAppFeeBlock,
    confirmAttendenceBlock,
    isLoading, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.studentKoruAccountPage;

  // MANUAL OVERRIDE
  const spinner = (
    <div className={cx(currentStyle, className, styles.loadingContainer)}>
      <LoadingSpinner
        className={cx(styles.loadingSpinner, classes?.loadingSpinner)}
        size='Huge'
        styleType='Primary'
        isActive
      />
    </div>
  );

  // MANUAL OVERRIDE
  return isLoading ? spinner : (
    <>
      <CourseRegistrationSubheader
        className={cx(styles.courseRegistrationSubheader, classes?.courseRegistrationSubheader)}
        {...internalProps.courseRegistrationSubheader}
        {...courseRegistrationSubheader}/>
      <div className={cx(currentStyle, className)}>
        <Switch>
          <Route exact path={STUDENT_ROUTES.auth.base}>
            <StudentKoruAccountBlock
              className={cx(styles.studentKoruAccountBlock, classes?.studentKoruAccountBlock)}
              {...internalProps.studentKoruAccountBlock}
              {...studentKoruAccountBlock}/>
          </Route>
          <Route path={STUDENT_ROUTES.auth.signUp}>
            <StudentSignupBlock
              className={cx(styles.studentSignupBlock, classes?.studentSignupBlock)}
              {...internalProps.studentSignupBlock}
              {...studentSignupBlock}/>
          </Route>
          <Route path={STUDENT_ROUTES.auth.signIn}>
            <StudentSignInBlock
              className={cx(styles.studentSigninBlock, classes?.studentSigninBlock)}
              {...internalProps.studentSigninBlock}
              {...studentSigninBlock}/>
          </Route>
          <Route path={STUDENT_ROUTES.auth.verifyEmail}>
            <VerifyEmailBlock
              className={cx(styles.verifyEmailBlock, classes?.verifyEmailBlock)}
              {...internalProps.verifyEmailBlock}
              {...verifyEmailBlock}/>
          </Route>
          <ProtectedRoute path={STUDENT_ROUTES.auth.payment}>
            <StudentAppFeeBlock
              className={cx(styles.studentAppFeeBlock, classes?.studentAppFeeBlock)}
              {...internalProps.studentAppFeeBlock}
              {...studentAppFeeBlock}/>
          </ProtectedRoute>
          <ProtectedRoute path={STUDENT_ROUTES.auth.confirmAttendence}>
            <ConfirmAttendenceBlock
              className={cx(styles.confirmAttendenceBlock, classes?.confirmAttendenceBlock)}
              {...internalProps.confirmAttendenceBlock}
              {...confirmAttendenceBlock}/>
          </ProtectedRoute>
        </Switch>
      </div>
    </>
  );
};

export default StudentKoruAccountPage;
