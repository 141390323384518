import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import Confirmation from '../../../resources/images/Confirmation.png';
import { ModalContext } from '../../../modules/user/ModalContext';
import { KORU_DASHBOARD_URL } from '../../../lib/config';
import { ACCOUNT_STATUS_ROUTE } from '../../../lib/constants';

import { ConfirmationModalCombinedProps, ConfirmationModalValueProps } from './types';

const usePresenter = (props: ConfirmationModalCombinedProps): ConfirmationModalCombinedProps => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { confirmationModalType, setModalState } = useContext(ModalContext);
  const handleCloseModal = () => {
    if (confirmationModalType === 'PURCHASE' && pathname === ACCOUNT_STATUS_ROUTE.subscriptionExpired) {
      window.location.assign(KORU_DASHBOARD_URL);
    } else {
      setModalState('UNSET');
    }
  };

  const goToDashboard = () => {
    window.location.assign(KORU_DASHBOARD_URL);
  };

  const getConfirmationModalProps = (): ConfirmationModalValueProps => {
    switch (confirmationModalType) {
      case 'PURCHASE': {
        return {
          text: {
            value: t('confirmation_modal.purchase.title'),
          },
          text1: {
            value: t('confirmation_modal.purchase.description'),
          },
          image: {
            imageSrc: Confirmation,
          },
          button: {
            icon: {
              asset: 'Close',
            },
            onClick: handleCloseModal,
          },
          primaryButton: {
            text: {
              value: t('confirmation_modal.purchase.button'),
            },
            onClick: handleCloseModal,
          },
        };
      }
      case 'STUDENT_APPS_PURCHASE': {
        return {
          text: {
            value: t('confirmation_modal.student_apps_purchase.title'),
          },
          text1: {
            value: t('confirmation_modal.student_apps_purchase.description'),
          },
          image: {
            imageSrc: Confirmation,
          },
          button: {
            icon: {
              asset: 'Close',
            },
            onClick: handleCloseModal,
          },
          primaryButton: {
            text: {
              value: t('confirmation_modal.student_apps_purchase.button'),
            },
            onClick: handleCloseModal,
          },
        };
      }
      case 'SUBSCRIPTION': {
        return {
          text: {
            value: t('confirmation_modal.subscription.title'),
          },
          text1: {
            value: t('confirmation_modal.subscription.description'),
          },
          image: {
            imageSrc: Confirmation,
          },
          button: {
            icon: {
              asset: 'Close',
            },
            onClick: goToDashboard,
          },
          primaryButton: {
            text: {
              value: t('confirmation_modal.subscription.button'),
            },
            onClick: goToDashboard,
          },
        };
      }
    }
  };

  return {
    ...props,
    ...getConfirmationModalProps(),
  };
};

export default usePresenter;
