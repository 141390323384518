import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Icon from '../../atoms/Icon';

import styles from './TextIconGroup.module.scss';
import usePresenter from './TextIconGroup.presenter';
import getProps from './TextIconGroup.props';
import { TextIconGroupCombinedProps } from './types';


const TextIconGroup: React.FC<TextIconGroupCombinedProps> = (props) => {
  const {
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    infoText,
    labelIcon,
  } = usePresenter(props);

  const variantName = '';
  const internalProps = getProps(variantName);
  const currentStyle = styles[`textIconGroup${variantName}`];


  let componentView;

  switch (variantName) {
    case '': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.infoText, classes?.infoText)}
            {...internalProps.infoText}
            {...infoText}/>
          <Icon
            className={cx(styles.labelIcon, classes?.labelIcon)}
            {...internalProps.labelIcon}
            {...labelIcon}/>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TextIconGroup;

