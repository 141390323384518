import axiosInstance from '../../lib/api/axios';
import { BFF_URL } from '../../lib/config';
import { DataEntry } from '../../lib/types';
import { Log, SortableLogFields } from '../log/types';
import {
  Course, CourseFreeLinkToken, CourseState, CreateCoursePayload, EnrollmentStatus, Roster, UpdateCoursePayload,
} from './types';

export const getCourseById = async (courseId: number): Promise<DataEntry<Course>> => {
  const { data } = await axiosInstance.get<DataEntry<Course>>(`${BFF_URL}/courses/${courseId}`);
  return data;
};

export const getCourseLogsByCourseId = async (
  courseId: number, sortBy: SortableLogFields,
): Promise<DataEntry<Log>[]> => {
  const params = { sortBy };
  const { data } = await axiosInstance.get<DataEntry<Log>[]>(
    `${BFF_URL}/courses/${courseId}/logs`,
    { params },
  );
  return data;
};

export const getCourses = async (courseState: CourseState): Promise<DataEntry<Course>[]> => {
  const params = {
    state: courseState,
  };
  const { data } = await axiosInstance.get<DataEntry<Course>[]>(`${BFF_URL}/courses`, { params });
  return data;
};

export const createCourse = async (payload: CreateCoursePayload): Promise<DataEntry<Course>> => {
  const { data } = await axiosInstance.post<DataEntry<Course>>(`${BFF_URL}/courses`, payload);
  return data;
};

export const updateCourse = async (courseId: number, payload: UpdateCoursePayload): Promise<DataEntry<Course>> => {
  const { data } = await axiosInstance.patch<DataEntry<Course>>(`${BFF_URL}/courses/${courseId}`, payload);
  return data;
};

export const validateCourseFreeLinkToken = async (id: number, token: string): Promise<CourseFreeLinkToken> => {
  const { data } = await axiosInstance.post<CourseFreeLinkToken>(`${BFF_URL}/courses/validateToken`, {
    id,
    token,
  });
  return data;
};

export const enrollStudentInCourse = async (
  courseId: number, courseFreeLinkToken?: string, email?: string, spotAvailableToken?: string,
): Promise<DataEntry<Roster>> => {
  const { data } = await axiosInstance.post<DataEntry<Roster>>(`${BFF_URL}/courses/${courseId}/action/enroll`, {
    courseFreeLinkToken,
    email,
    spotAvailableToken,
  });
  return data;
};

export const joinWaitlist = async (courseId: number, email?: string): Promise<DataEntry<Roster>> => {
  if (email) {
    const { data } = await axiosInstance.post<DataEntry<Roster>>(`${BFF_URL}/courses/${courseId}/action/joinWaitlist`, {
      email,
    });
    return data;
  } else {
    const { data } = await axiosInstance.post<DataEntry<Roster>>((`${BFF_URL}/courses/${courseId}/action/joinWaitlist`));
    return data;
  }
};

export const removeStudent = async (courseId: number, email: string): Promise<DataEntry<Roster>> => {
  const { data } = await axiosInstance.post<DataEntry<Roster>>(`${BFF_URL}/courses/${courseId}/action/removeStudent`, {
    email,
  });
  return data;
};

export const checkCourseAvailability = async (courseId: number): Promise<boolean> => {
  const { data } = await axiosInstance.get<boolean>((`${BFF_URL}/courses/${courseId}/availability`));
  return data;
};

export const getStudentEnrollmentStatus = async (courseId: number): Promise<EnrollmentStatus> => {
  const { data } = await axiosInstance.get<EnrollmentStatus>(`${BFF_URL}/courses/${courseId}/enrollmentStatus`);
  return data;
};

export const getCourseRoster = async (courseId: number): Promise<DataEntry<Roster>[]> => {
  const { data } = await axiosInstance.get<DataEntry<Roster>[]>(`${BFF_URL}/courses/${courseId}/roster`);
  return data;
};
