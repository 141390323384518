import { StudentAppSectionProps } from '../../organisms/StudentAppSection';


type StudentAppBlockInternalProps = {
  studentAppSection?: StudentAppSectionProps;
};

type StudentAppBlockPropsValues = {
  '': StudentAppBlockInternalProps;
};

const propValues: StudentAppBlockPropsValues = {
  '': {
    studentAppSection: {
    },
  },
};

const getProps = (type: string): StudentAppBlockInternalProps => {
  const values: StudentAppBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
