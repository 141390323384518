import { ContextualContentProps } from '../../atoms/ContextualContent';
import { TextProps } from '../../atoms/Text';

type ChipFieldInternalProps = {
  label?: TextProps;
  contextualContent?: ContextualContentProps;
};

type ChipFieldPropsValues = {
  'Error': ChipFieldInternalProps;
  'None': ChipFieldInternalProps;
};

const propValues: ChipFieldPropsValues = {
  'Error': {
    label: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    contextualContent: {
      type: 'Error',
    },
  },
  'None': {
    label: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
};

const getProps = (type: string): ChipFieldInternalProps => {
  const values: ChipFieldInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
