import { IconTextGroupProps } from '../../molecules/IconTextGroup';


type IconTextGroupListInternalProps = {
  iconTextGroup?: IconTextGroupProps;
};

type IconTextGroupListPropsValues = {
  '': IconTextGroupListInternalProps;
};

const propValues: IconTextGroupListPropsValues = {
  '': {
    iconTextGroup: {
    },
  },
};

const getProps = (type: string): IconTextGroupListInternalProps => {
  const values: IconTextGroupListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
