import React from 'react';
import cx from 'classnames';

import Icon from '../../atoms/Icon';
import Initials from '../../atoms/Initials';
import Text from '../../atoms/Text';

import styles from './UserProfile.module.scss';
import usePresenter from './UserProfile.presenter';
import getProps from './UserProfile.props';
import { UserProfileCombinedProps } from './types';

// MANUAL OVERRIDE - converted to forwardRef
const UserProfile =  React.forwardRef<HTMLButtonElement, UserProfileCombinedProps>((props, ref) => {
  const {
    size,
    className, // MANUAL OVERRIDE
    classes,
    disabled, // MANUAL OVERRIDE
    initials,
    text,
    icon,
    onClick, // MANUAL OVERRIDE
    state, // MANUAL OVERRIDE
    ...otherProps // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${size}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`userProfile${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Medium': {
      componentView = (
        // MANUAL OVERRIDE
        <button
          disabled={disabled}
          onClick={onClick}
          className={cx(currentStyle, className)}
          ref={ref}
          {...otherProps}>
          <Initials
            className={cx(styles.initials, classes?.initials)}
            {...internalProps.initials}
            {...initials}/>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
        </button>
      );
      break;
    }
    case 'Small': {
      componentView = (
        // MANUAL OVERRIDE
        <button
          disabled={disabled}
          onClick={onClick}
          className={cx(currentStyle, className)}
          ref={ref}
          {...otherProps}>
          <Initials
            className={cx(styles.initials, classes?.initials)}
            {...internalProps.initials}
            {...initials}/>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
});

export default UserProfile;

