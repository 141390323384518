import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import TextGroup from '../../molecules/TextGroup';

import styles from './TeacherAgreementBlock.module.scss';
import usePresenter from './TeacherAgreementBlock.presenter';
import getProps from './TeacherAgreementBlock.props';
import { TeacherAgreementBlockCombinedProps } from './types';
import TeacherAgreementFooter from '../../organisms/TeacherAgreementFooter';


const TeacherAgreementBlock: React.FC<TeacherAgreementBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    stepperText,
    textGroup,
    text,
    textGroups,
    teacherAgreementFooter,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.teacherAgreementBlock;

  const textGroupsView = textGroups?.map((group, index) => (
    <TextGroup
      key={index}
      className={cx(styles.textGroups, classes?.textGroups)}
      {...internalProps.textGroups}
      {...group}/>
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.stepperText, classes?.stepperText)}
          {...internalProps.stepperText}
          {...stepperText}/>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
      { textGroupsView }
      </div>
      <div className={cx(styles.footer, classes?.footer)}>
        <TeacherAgreementFooter
          className={cx(styles.teacherAgreementFooter, classes?.teacherAgreementFooter)}
          {...internalProps.teacherAgreementFooter}
          {...teacherAgreementFooter}/>
      </div>
    </div>
  );
};

export default TeacherAgreementBlock;

