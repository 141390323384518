import { AlreadySignupBlockProps } from '../../blocks/AlreadySignupBlock';
import { CompleteProfileBlockProps } from '../../blocks/CompleteProfileBlock';
import { CreatePasswordBlockProps } from '../../blocks/CreatePasswordBlock';
import { ForgotPasswordBlockProps } from '../../blocks/ForgotPasswordBlock';
import { ForgotPasswordSuccessBlockProps } from '../../blocks/ForgotPasswordSuccessBlock';
import { SigninBlockProps } from '../../blocks/SigninBlock';
import { SignupBlockProps } from '../../blocks/SignupBlock';
import { TeacherAgreementBlockProps } from '../../blocks/TeacherAgreementBlock';
import { TuitionPaymentBlockProps } from '../../blocks/TuitionPaymentBlock';
import { WelcomeBlockProps } from '../../blocks/WelcomeBlock';
import { AlreadyRegisteredPageProps } from '../AlreadyRegisteredPage';
import { ExpiredStatePageProps } from '../ExpiredStatePage';
import { PasswordLinkExpiredPageProps } from '../PasswordLinkExpiredPage';
import { StudentUpdatedPasswordPageProps } from '../StudentUpdatedPasswordPage';


type SigninPageInternalProps = {
  signinBlock?: SigninBlockProps;
  signupBlock?: SignupBlockProps;
  alreadySignupBlock?: AlreadySignupBlockProps;
  completeProfileBlock?: CompleteProfileBlockProps;
  tuitionPaymentBlock?: TuitionPaymentBlockProps;
  welcomeBlock?: WelcomeBlockProps;
  teacherAgreementBlock?: TeacherAgreementBlockProps;
  forgotPasswordBlock?: ForgotPasswordBlockProps;
  forgotPasswordSuccessBlock?: ForgotPasswordSuccessBlockProps;
  expiredStatePage?: ExpiredStatePageProps;
  createPasswordBlock?: CreatePasswordBlockProps;
  passwordLinkExpiredPage?: PasswordLinkExpiredPageProps;
  alreadyRegisteredPage?: AlreadyRegisteredPageProps;
  studentUpdatedPasswordPage?: StudentUpdatedPasswordPageProps;
};

type SigninPagePropsValues = {
  '': SigninPageInternalProps;
};

const propValues: SigninPagePropsValues = {
  '': {
    signinBlock: {
    },
    signupBlock: {
    },
    alreadySignupBlock: {
    },
    completeProfileBlock: {
    },
    tuitionPaymentBlock: {
    },
    teacherAgreementBlock: {
    },
    welcomeBlock: {
    },
    forgotPasswordBlock: {
    },
    forgotPasswordSuccessBlock: {
    },
    expiredStatePage: {
    },
    createPasswordBlock:{
    },
    passwordLinkExpiredPage: {
    },
    alreadyRegisteredPage:{
    },
    studentUpdatedPasswordPage: {
    },
  },
};

const getProps = (type: string): SigninPageInternalProps => {
  const values: SigninPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
