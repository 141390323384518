import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type ContextualContentInternalProps = {
  icon?: IconProps;
  text?: TextProps;
};

type ContextualContentPropsValues = {
  'Error': ContextualContentInternalProps;
  'Help': ContextualContentInternalProps;
};

const propValues: ContextualContentPropsValues = {
  'Error': {
    icon: {
      asset: 'AlertFilled',
      colour: 'Critical',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Critical',
      type: 'Body2',
    },
  },
  'Help': {
    icon: {
      asset: 'InfoFilled',
      colour: 'Subdued',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): ContextualContentInternalProps => {
  const values: ContextualContentInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
