import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { STUDENT_ROUTES } from '../../../lib/constants';
import { validateCourseFreeLinkToken } from '../../../modules/course/api';
import { ButtonValueProps } from '../../atoms/Button';

import { checkCourseAvailabilityUseCase } from './StudentCourseDetailsFooter.interactor';
import { StudentCourseDetailsFooterCombinedProps } from './types';

const usePresenter = (props: StudentCourseDetailsFooterCombinedProps): StudentCourseDetailsFooterCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');
  const { course } = props;

  // FIXME: Use enrolledStudents property from course
  const { data: hasAvailableSeats } = useQuery(['getCourseAvailability', course?.content?.id], () => {
    if (course?.content?.id) {
      try {
        return checkCourseAvailabilityUseCase(course.content.id);
      } catch {
        return false;
      }
    }
  });

  const handleStartRegistration = async () => {
    if (token && course?.content?.id) {
      try {
        await validateCourseFreeLinkToken(course.content.id, token);
        history.push(STUDENT_ROUTES.auth.base, {
          course: {
            ...course,
            content: { ...course.content, freeLinkToken: token },
          },
        });
      } catch {
        history.push(STUDENT_ROUTES.auth.base, {
          course: {
            ...course,
            content: { ...course.content, freeLinkToken: null },
          },
        });
      }
    } else {
      history.push(STUDENT_ROUTES.auth.base, {
        course: {
          ...course,
          content: { ...course?.content, freeLinkToken: null },
        },
      });
    }
  };

  const footerButtonProps = (): ButtonValueProps | undefined => {
    if (course?.content?.primaryTeacher && course) {
      if (hasAvailableSeats) {
        return {
          text: {
            value: t('student_registration.button.register_now'),
          },
          onClick: handleStartRegistration,
        };
      }
      return {
        text: {
          value: t('student_registration.button.join_waitlist'),
        },
        onClick: handleStartRegistration,
      };
    }
  };

  return {
    ...props,
    text: {
      value: course?.content?.title,
    },
    button: {
      ...footerButtonProps(),
      disabled: !course?.content?.registrationOpen,
    },
  };
};

export default usePresenter;
