import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import LogEntriesList from '../LogEntriesList';

import styles from './LogEntriesAccordianItem.module.scss';
import usePresenter from './LogEntriesAccordianItem.presenter';
import getProps from './LogEntriesAccordianItem.props';
import { LogEntriesAccordianItemCombinedProps } from './types';


const LogEntriesAccordianItem: React.FC<LogEntriesAccordianItemCombinedProps> = (props) => {
  const {
    type,
    state,
    className,
    classes,
    nameText,
    button,
    divider,
    icon,
    logEntriesList,
    onClick,
  } = usePresenter(props);

  const variantName = `${type}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`logEntriesAccordianItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'CollapsedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)} onClick={onClick}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.topContent, classes?.topContent)}>
              <Text
                className={cx(styles.nameText, classes?.nameText)}
                {...internalProps.nameText}
                {...nameText}/>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'CollapsedNew': {
      componentView = (
        <div className={cx(currentStyle, className)} onClick={onClick}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.topContent, classes?.topContent)}>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}/>
              <Text
                className={cx(styles.nameText, classes?.nameText)}
                {...internalProps.nameText}
                {...nameText}/>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'ExpandedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)} onClick={onClick}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.topContent, classes?.topContent)}>
              <Text
                className={cx(styles.nameText, classes?.nameText)}
                {...internalProps.nameText}
                {...nameText}/>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <LogEntriesList
            className={cx(styles.logEntriesList, classes?.logEntriesList)}
            {...internalProps.logEntriesList}
            {...logEntriesList}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default LogEntriesAccordianItem;

