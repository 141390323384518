import React from 'react';
import cx from 'classnames';

import PriceListItem from '../../molecules/PriceListItem';

import styles from './PriceItemList.module.scss';
import usePresenter from './PriceItemList.presenter';
import getProps from './PriceItemList.props';
import { PriceItemListCombinedProps } from './types';


const PriceItemList: React.FC<PriceItemListCombinedProps> = (props) => {
  const {
    className,
    classes,
    priceListItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.priceItemList;

  const priceListItemViews = priceListItems?.map((priceListItem, index) => (
    <PriceListItem
      key={index}
      className={cx(styles.priceListItem, classes?.priceListItem)}
      {...internalProps.priceListItem}
      {...priceListItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {priceListItemViews}
    </div>
  );
};

export default PriceItemList;

