import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation, useHistory } from 'react-router';

import JoinWaitlist from '../../../resources/images/JoinWaitlist.png';
import { getTranslation } from '../../../lib/reactUtils';
import { CourseStateType, EnrollmentStatus } from '../../../modules/course/types';

import { OnWaitlistPageCombinedProps } from './types';
import { joinWaitlistUseCase } from './OnWaitlistPage.interactor';
import { useEffect, useState } from 'react';
import { STUDENT_ROUTES } from '../../../lib/constants';

const usePresenter = (props: OnWaitlistPageCombinedProps): OnWaitlistPageCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: { course, enrollmentStatus }, pathname } = useLocation<CourseStateType>();
  const [isWaitlisted, setIsWaitlisted] = useState(false);

  const { mutateAsync: joinWaitlist, isLoading } = useMutation(['joinWaitlist', course.content.id], async () => {
    if (course.content.id) {
      return joinWaitlistUseCase(course.content.id);
    }
  });

  useEffect(() => {
    if (enrollmentStatus === EnrollmentStatus.waitlisted) {
      history.push(STUDENT_ROUTES.auth.joinWaitlistSuccess, { course });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollmentStatus]);

  useEffect(() => {
    if (pathname === STUDENT_ROUTES.auth.joinWaitlistSuccess) {
      setIsWaitlisted(true);
    }
  }, [pathname]);
  
  const handleJoinWaitlist = async () => {
    await joinWaitlist();
    history.replace(STUDENT_ROUTES.auth.joinWaitlistSuccess, { course });
  };

  return {
    ...props,
    genericStateBlock: {
      hideButton: isWaitlisted,
      image: {
        imageSrc: JoinWaitlist,
      },
      topText: {
        value: isWaitlisted ? t('join_waitlist.header_success') : t('join_waitlist.header'),
      },
      bottomText: {
        value: getTranslation('join_waitlist.description'),
      },
      button: {
        type: 'TextIcon',
        text: {
          value: t('join_waitlist.button'),
        },
        icon: {
          asset: 'ArrowRight',
        },
        loading: isLoading ? 'Loading' : 'Default',
        disabled: isLoading,
        onClick: handleJoinWaitlist,
      },
    },
  };
};

export default usePresenter;
