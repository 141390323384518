import { GenericStateNoActionBlockProps } from '../../blocks/GenericStateNoActionBlock';


type ExpiredStatePageInternalProps = {
  genericStateNoActionBlock?: GenericStateNoActionBlockProps;
};

type ExpiredStatePagePropsValues = {
  '': ExpiredStatePageInternalProps;
};

const propValues: ExpiredStatePagePropsValues = {
  '': {
    genericStateNoActionBlock: {
    },
  },
};

const getProps = (type: string): ExpiredStatePageInternalProps => {
  const values: ExpiredStatePageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
