import { StudentCourseDetailsPageCombinedProps } from './types';
import HeaderBackground from '../../../resources/images/HeaderBackground.png';
import KoruLogoOval from '../../../resources/images/KoruLogoOval.png';

const usePresenter = (props: StudentCourseDetailsPageCombinedProps): StudentCourseDetailsPageCombinedProps => {
  return {
    ...props,
    image: {
      imageSrc: HeaderBackground,
    },
    logo: {
      imageSrc: KoruLogoOval,
    },
  };
};

export default usePresenter;
