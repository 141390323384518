import React from 'react';
import cx from 'classnames';

import LogEntryStatusItem from '../../atoms/LogEntryStatusItem';

import styles from './LogEntryStatusList.module.scss';
import usePresenter from './LogEntryStatusList.presenter';
import getProps from './LogEntryStatusList.props';
import { LogEntryStatusListCombinedProps } from './types';


const LogEntryStatusList: React.FC<LogEntryStatusListCombinedProps> = (props) => {
  const {
    className,
    classes,
    logEntryStatusItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.logEntryStatusList;

  const logEntryStatusItemViews = logEntryStatusItems?.map((logEntryStatusItem, index) => (
    <LogEntryStatusItem
      key={index}
      className={cx(styles.logEntryStatusItem, classes?.logEntryStatusItem)}
      {...internalProps.logEntryStatusItem}
      {...logEntryStatusItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {logEntryStatusItemViews}
    </div>
  );
};

export default LogEntryStatusList;

