import { AccountResponse } from '../../../modules/auth/types';
import { getStudentEnrollmentStatus } from '../../../modules/course/api';
import { EnrollmentStatus } from '../../../modules/course/types';
import { studentSignIn, verifyStudentAccount } from '../../../modules/student/api';
import { StudentSignInPayload } from '../../../modules/student/types';

export const verifyStudentAccountUseCase = async (id: number, token: string): Promise<void> => {
  await verifyStudentAccount(id, token);
};

export const signInUseCase = async (payload: StudentSignInPayload): Promise<AccountResponse> => {
  return studentSignIn(payload);
};

export const getStudentEnrollmentStatusUseCase = async (courseId: number): Promise<EnrollmentStatus> => {
  return getStudentEnrollmentStatus(courseId);
};
