import React from 'react';
import cx from 'classnames';

import ClassFormCard from '../../molecules/ClassFormCard';

import styles from './ClassCardsList.module.scss';
import usePresenter from './ClassCardsList.presenter';
import getProps from './ClassCardsList.props';
import { ClassCardsListCombinedProps } from './types';


const ClassCardsList: React.FC<ClassCardsListCombinedProps> = (props) => {
  const {
    className,
    classes,
    classFormCards,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.classCardsList;

  const classFormCardViews = classFormCards?.map((classFormCard, index) => (
    <ClassFormCard
      key={index}
      className={cx(styles.classFormCard, classes?.classFormCard)}
      {...internalProps.classFormCard}
      {...classFormCard} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {classFormCardViews}
    </div>
  );
};

export default ClassCardsList;

