import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { 
  COUNTRY_CODE_LIST,
  FREE_LINK_NO_APPS_SIGN_IN_JOURNEY,
  REGULAR_SIGN_UP_JOURNEY,
  STRIPE_CARD_ELEMENT_CONFIG,
  STUDENT_ROUTES,
} from '../../../lib/constants';
import { trackSuccessfulCourseRegistrationPaid } from '../../../lib/trackingUtils';
import { formatPrice, useInterval } from '../../../lib/utils';
import { getUser } from '../../../modules/auth/api';
import { CourseStateType } from '../../../modules/course/types';
import { KORU_PRODUCT_SLUG } from '../../../modules/payment/constants';
import { UserContext } from '../../../modules/user/UserContext';
import { AddressForm } from '../../molecules/Address/types';
import { createStudentPaymentIntentUseCase, getProductDetailsUseCase } from './StudentAppFeeBlock.interactor';
import { StudentAppFeeBlockCombinedProps } from './types';

type TuitionPaymentForm = {
  streetAddress: string;
  country: string;
  province: string;
  city: string;
  postalCode: string;
  creditCard: string;
};

const initFormState: TuitionPaymentForm = {
  streetAddress: '',
  country: '',
  province: '',
  city: '',
  postalCode: '',
  creditCard: '',
};

const usePresenter = (props: StudentAppFeeBlockCombinedProps): StudentAppFeeBlockCombinedProps => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const productSlug = KORU_PRODUCT_SLUG.PURCHASE_STUDENT_APPS;
  const { user, updateUser } = useContext(UserContext);
  const { state: {
    course,
    isNewlyRegistered,
  } } = useLocation<CourseStateType>();

  // user state
  const [updatedUser, setUpdatedUser] = useState(user);

  const CountryCodeMap = new Map(
    COUNTRY_CODE_LIST.map((countryCode) => [countryCode.name, countryCode.code]),
  );

  // Credit card state
  const [creditCardState, setCreditCardState] = useState<StripeCardElementChangeEvent>();

  // form states
  const [formState, setFormState] = useState<TuitionPaymentForm>(initFormState);
  const [formErrorState, setFormErrorState] = useState<TuitionPaymentForm>(initFormState);
  const [validateAddress, setValidateAddress] = useState<boolean>();
  const [isAddressValid, setIsAddressValid] = useState<boolean>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string | undefined>(undefined);

  // button loading state
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

  const { mutateAsync: createPaymentIntent } = useMutation(['createPaymentIntent'], async () => {
    if (course) {
      return createStudentPaymentIntentUseCase(course.content.id);
    }
  });
  
  useEffect(() => {
    let creditCardErrorMessage = '';
    if (creditCardState?.error) {
      creditCardErrorMessage = t('error.invalid_credit_card');
    }
    setFormErrorState({
      ...formErrorState,
      creditCard: creditCardErrorMessage,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCardState]);

  const { data: priceItem } = useQuery(['getProduct', productSlug], () => getProductDetailsUseCase(productSlug));

  const fetchNewUser = useCallback(async () => {
    const newUser = await getUser(true);
    setUpdatedUser(newUser);
  }, []);

  useInterval(fetchNewUser, 5000);

  useEffect(() => {
    if (updatedUser?.content?.paidAppFee) {
      setIsPaymentProcessing(false);
      updateUser(updatedUser);
      trackSuccessfulCourseRegistrationPaid();
      history.replace(STUDENT_ROUTES.auth.registrationSuccess, { course });
    }
  }, [history, updateUser, updatedUser, course]);

  const handleAddressForm = (address: AddressForm) => {
    setFormState({
      ...formState,
      ...address,
    });
  };

  const handleAddressFormError = (addressError: AddressForm) => {
    setFormErrorState({
      ...formErrorState,
      ...addressError,
    });
  };

  const handleCardChange = (stripeEvent: StripeCardElementChangeEvent) => {
    setShowNotification(false);
    setCreditCardState(stripeEvent);
  };

  const handleMakePaymentButtonClick = async () => {
    if (!creditCardState || creditCardState?.empty) {
      setFormErrorState({
        ...formErrorState,
        creditCard: t('error.field_required'),
      });
    }

    setShowNotification(false);
    setValidateAddress(!validateAddress);

    // Setting billing address
    const address = {
      ...formState,
      country: CountryCodeMap.get(formState.country) || '',
    };

    if (!isAddressValid) {
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Card details are filled completely
    if (!creditCardState?.complete) {
      return;
    }

    setIsPaymentProcessing(true);

    const paymentIntentResponse = await createPaymentIntent();

    if (paymentIntentResponse) {
      const result = await stripe.confirmCardPayment(paymentIntentResponse.clientSecret, {
        payment_method: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          card: elements.getElement(CardElement)!,
          billing_details: {
            address: {
              line1: address.streetAddress,
              city: address.city,
              postal_code: address.postalCode,
              state: address.province,
              country: address.country,
            },
          },
        },
      });

      if (result.error) {
        setIsPaymentProcessing(false);
        setShowNotification(true);
        setNotificationMessage(result.error.message);
      }
    }
  };

  const getCorrectProgressStep = (): number => {
    if (isNewlyRegistered) {
      return REGULAR_SIGN_UP_JOURNEY.total;
    }
    return FREE_LINK_NO_APPS_SIGN_IN_JOURNEY.total;
  };

  const getTotalStepsCorrectly = (): number => {
    if (isNewlyRegistered) {
      return REGULAR_SIGN_UP_JOURNEY.total;
    }
    return FREE_LINK_NO_APPS_SIGN_IN_JOURNEY.appFee;
  };
  
  return {
    ...props,
    stepperText: {
      value: t('stepperText', {
        currentStep: getCorrectProgressStep(),
        totalSteps: getTotalStepsCorrectly(),
      }),
    },
    textGroup: {
      topText: {
        value: t('student_app_payment.title'),
      },
      bottomText: {
        value: t('student_app_payment.description'),
      },
    },
    priceListItem: {
      amountText: {
        value: t('student_app_payment.label.price', {
          price: formatPrice(priceItem?.amount),
        }),
      },
      descriptionText: {
        value: t('student_app_payment.koru_app'),
      },
    },
    cardInfoField: {
      state: formErrorState.creditCard ? 'Error' : 'Default',
      labelText: {
        value: t('student_app_payment.label.payment'),
      },
      cardElementOptions: STRIPE_CARD_ELEMENT_CONFIG,
      handleChange: handleCardChange,
      contextualContent: {
        text: {
          value: formErrorState.creditCard,
        },
      },
    },
    address: {
      setAddressForm: handleAddressForm,
      setAddressFormError: handleAddressFormError,
      validateAddress: validateAddress,
      setIsAddressValid,
      streetAddressInputField: {
        text: {
          value: t('student_app_payment.label.billing'),
        },
      },
    },
    button: {
      loading: isPaymentProcessing ? 'Loading' : 'Default',
      text: {
        value: t('student_app_payment.button.payment'),
      },
      icon: {
        asset: 'ArrowRight',
      },
      onClick: handleMakePaymentButtonClick,
      disabled: isPaymentProcessing,
    },
    inlineNotification: {
      text: {
        value: notificationMessage,
      },
    },
    showNotification: showNotification,
  };
};

export default usePresenter;
