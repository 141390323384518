import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import StudentsList from '../StudentsList';

import styles from './StudentListSection.module.scss';
import usePresenter from './StudentListSection.presenter';
import getProps from './StudentListSection.props';
import { StudentListSectionCombinedProps } from './types';


const StudentListSection: React.FC<StudentListSectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,
    studentsList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.studentListSection;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <StudentsList
        className={cx(styles.studentsList, classes?.studentsList)}
        {...internalProps.studentsList}
        {...studentsList}/>
    </div>
  );
};

export default StudentListSection;

