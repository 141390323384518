import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type SelectInternalProps = {
  text?: TextProps;
  icon?: IconProps;
};

type SelectPropsValues = {
  'Error': SelectInternalProps;
  'None': SelectInternalProps;
};

const propValues: SelectPropsValues = {
  'Error': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body1',
    },
    icon: {
      asset: 'ChevronDownBig',
      colour: 'Base',
    },
  },
  'None': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body1',
    },
    icon: {
      asset: 'ChevronDownBig',
      colour: 'Base',
    },
  },
};

const getProps = (type: string): SelectInternalProps => {
  const values: SelectInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
