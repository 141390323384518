import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';

import styles from './LogEntryStatus.module.scss';
import usePresenter from './LogEntryStatus.presenter';
import getProps from './LogEntryStatus.props';
import { LogEntryStatusCombinedProps } from './types';


const LogEntryStatus: React.FC<LogEntryStatusCombinedProps> = (props) => {
  const {
    type,
    style,
    className,
    classes,
    icon,
    text,
  } = usePresenter(props);

  const variantName = `${type}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`logEntryStatus${variantName}`];

  let componentView;

  switch (variantName) {
    case 'AwaitingReplyDefault':
    case 'AwaitingReplyInverse':
    case 'RepliedDefault':
    case 'RepliedInverse': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default LogEntryStatus;

