import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { AddressProps } from '../../molecules/Address';
import { InputFieldProps } from '../../molecules/InputField';
import { TextGroupProps } from '../../molecules/TextGroup';


type CompleteProfileBlockInternalProps = {
  stepperText?: TextProps;
  textGroup?: TextGroupProps;
  emailInputField?: InputFieldProps;
  address?: AddressProps;
  button?: ButtonProps;
};

type CompleteProfileBlockPropsValues = {
  '': CompleteProfileBlockInternalProps;
};

const propValues: CompleteProfileBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    textGroup: {
    },
    emailInputField: {
      state: 'Default',
    },
    address: {
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
    },
  },
};

const getProps = (type: string): CompleteProfileBlockInternalProps => {
  const values: CompleteProfileBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
