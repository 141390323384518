import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { CheckboxItemProps } from '../../atoms/CheckboxItem';


type PaymentInfoSectionInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  cardInfoLabelText?: TextProps;
  cardNumberText?: TextProps;
  billingAddressLabelText?: TextProps;
  billingAddressText?: TextProps;
  checkboxItem?: CheckboxItemProps; //MANUAL OVERRIDE
  button?: ButtonProps;
  button1?: ButtonProps;
};

type PaymentInfoSectionPropsValues = {
  '': PaymentInfoSectionInternalProps;
};

const propValues: PaymentInfoSectionPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    cardInfoLabelText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    cardNumberText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    billingAddressLabelText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    billingAddressText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    checkboxItem: {
    },
    button: {
      style: 'Outline',
      size: 'Large',
      type: 'Text',
      colour: 'Critical',
      loading: 'Default',
    },
    button1: {
      style: 'Outline',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): PaymentInfoSectionInternalProps => {
  const values: PaymentInfoSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
