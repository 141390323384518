import React from 'react';
import cx from 'classnames';

import GenericStateNoActionBlock from '../../blocks/GenericStateNoActionBlock';

import styles from './StudentUpdatedPasswordPage.module.scss';
import usePresenter from './StudentUpdatedPasswordPage.presenter';
import getProps from './StudentUpdatedPasswordPage.props';
import { StudentUpdatedPasswordPageCombinedProps } from './types';


const StudentUpdatedPasswordPage: React.FC<StudentUpdatedPasswordPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    genericStateNoActionBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.studentUpdatedPasswordPage;

  return (
    <div className={cx(currentStyle, className)}>
      <GenericStateNoActionBlock
        className={cx(styles.genericStateNoActionBlock, classes?.genericStateNoActionBlock)}
        {...internalProps.genericStateNoActionBlock}
        {...genericStateNoActionBlock}/>
    </div>
  );
};

export default StudentUpdatedPasswordPage;

