import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import Address from '../../molecules/Address';
import InputField from '../../molecules/InputField';
import InputFieldGroup from '../../molecules/InputFieldGroup';

import styles from './BasicInfoSection.module.scss';
import usePresenter from './BasicInfoSection.presenter';
import getProps from './BasicInfoSection.props';
import { BasicInfoSectionCombinedProps } from './types';


const BasicInfoSection: React.FC<BasicInfoSectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,
    inputFieldGroup,
    emailAddressInputField,
    phoneNumberInputField,
    address,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.basicInfoSection;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <InputFieldGroup
        className={cx(styles.inputFieldGroup, classes?.inputFieldGroup)}
        {...internalProps.inputFieldGroup}
        {...inputFieldGroup}/>
      <InputField
        className={cx(styles.emailAddressInputField, classes?.emailAddressInputField)}
        {...internalProps.emailAddressInputField}
        {...emailAddressInputField}/>
      <InputField
        className={cx(styles.phoneNumberInputField, classes?.phoneNumberInputField)}
        {...internalProps.phoneNumberInputField}
        {...phoneNumberInputField}/>
      <Address
        className={cx(styles.address, classes?.address)}
        {...internalProps.address}
        {...address}/>
      <Button
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button}/>
    </div>
  );
};

export default BasicInfoSection;

