import React from 'react';

import InternalUserProfile from './UserProfile';
import { UserProfileProps, UserProfileCombinedProps } from './types';
import DropdownWrapper, { ExportedDropdownProps } from '../../../modules/common/DropdownWrapper';
import ContextualMenu from '../../organisms/ContextualMenu';
import { ContextualMenuCombinedProps } from '../../organisms/ContextualMenu/types';

// MANUAL OVERRIDE
export type { UserProfileProps };

// MANUAL OVERRIDE
export type UserProfileValueProps = ExportedDropdownProps<
UserProfileCombinedProps,
ContextualMenuCombinedProps
>;

// MANUAL OVERRIDE
const UserProfile: React.FC<UserProfileValueProps> = ({ id, toggleProps, menuProps, onToggle }) => (
  <DropdownWrapper
    id={id}
    Toggle={InternalUserProfile}
    Menu={ContextualMenu}
    toggleProps={toggleProps}
    menuProps={menuProps}
    onToggle={onToggle}
  />
);

export default UserProfile;
