import axiosInstance from '../../lib/api/axios';
import { BFF_URL } from '../../lib/config';
import { AccountResponse } from '../auth/types';
import { SpotAvailableTokenPayload } from '../course/types';
import { PaymentIntent } from '../payment/types';
import { CreateStudentPayload, InitialRegistrationCourse, Student, StudentSignInPayload } from './types';

export const studentSignUp = async (payload: CreateStudentPayload): Promise<Student> => {
  const { data } = await axiosInstance.post<Student>(`${BFF_URL}/students/`, {
    ...payload,
    email: payload.email.trim(),
    phoneNumber: payload.phoneNumber.trim(),
    initialRegistrationToken: payload.initialRegistrationToken || '',
  });
  return data;
};

export const studentSignIn = async (payload: StudentSignInPayload): Promise<AccountResponse> => {
  const { data } = await axiosInstance.post<AccountResponse>(`${BFF_URL}/students/auth/signIn`, payload);
  return data;
};

export const createStudentPaymentIntent = async (courseId: number): Promise<PaymentIntent> => {
  const { data } = await axiosInstance.post<PaymentIntent>(`${BFF_URL}/students/createPaymentIntent`, { courseId });
  return data;
};

export const verifyStudentAccount = async (id: number, token: string): Promise<void> => {
  await axiosInstance.post(`${BFF_URL}/students/auth/${id}/verifyAccount`, { token });
};

export const validateSpotAvailableToken = async (
  token: string,
): Promise<SpotAvailableTokenPayload> => {
  const { data } = await axiosInstance.post<SpotAvailableTokenPayload>(
    `${BFF_URL}/students/validateSpotAvailableToken`, { token },
  );
  return data;
};

export const getInitialRegistrationCourse = async (
  token: string,
): Promise<InitialRegistrationCourse> => {
  const { data } = await axiosInstance.get<InitialRegistrationCourse>(
    `${BFF_URL}/students/initialRegistrationCourse?token=${token}`,
  );
  return data;
};
