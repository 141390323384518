import { TextIconGroupProps } from '../../molecules/TextIconGroup';


type TextIconGroupListInternalProps = {
  textIconGroup?: TextIconGroupProps;
};

type TextIconGroupListPropsValues = {
  '': TextIconGroupListInternalProps;
};

const propValues: TextIconGroupListPropsValues = {
  '': {
    textIconGroup: {
    },
  },
};

const getProps = (type: string): TextIconGroupListInternalProps => {
  const values: TextIconGroupListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
