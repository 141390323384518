import { DataEntry } from '../../../lib/types';
import { Class } from '../../../modules/class/types';
import { ClassFormatEnumReverse, Course } from '../../../modules/course/types';
import { CourseBasicInfoForm } from '../../blocks/CreateCourseBasicDetailsBlock/types';
import { ClassCardForm, CourseScheduleForm } from '../../blocks/CreateCourseScheduleBlock/types';

export const setDateTime = (date: Date, time: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(time.getHours(), time.getMinutes(), time.getSeconds());
  return newDate;
};

export const getNextAvailableClassIndex = (classes: DataEntry<Class>[]): number => {
  const currentDate = new Date();
  const nextAvailableClassIndex = classes.findIndex((value) => {
    if (value) {
      const { date, startTime, endTime } = value.content;
      const start = setDateTime(date, new Date(startTime));
      const end = setDateTime(date, new Date(endTime));
      if (start.valueOf() <= currentDate.valueOf() && end.valueOf() >= currentDate.valueOf()) {
        return true;
      }
      if (start.valueOf() > currentDate.valueOf()) {
        return true;
      }
    }
    return false;
  });
  // for archived courses
  return nextAvailableClassIndex === -1 ? (classes.length - 1) : nextAvailableClassIndex;
};

export const normalizeCourseUpdateSteps = (course?: DataEntry<Course>) => {
  const courseBasicInfoForm = {
    classCapacity: course?.content?.classCapacity,
    description: course?.content?.description,
    name: course?.content?.title,
    type: course?.content?.courseType,
    coTeachers: course?.content?.coTeachers?.map((coTeacher) => coTeacher.content.email),
  } as CourseBasicInfoForm;

  const courseScheduleInfoForm = {
    type: ClassFormatEnumReverse[course?.content?.classFormat || ''],
    classes: course?.content?.classes?.map(({ uuid, content: classInfo }): ClassCardForm => {
      return {
        date: classInfo.date,
        end: classInfo.endTime,
        start: classInfo.startTime,
        link: classInfo.classLink || '',
        location: classInfo.location || '',
        uuid,
      };
    }),
  } as CourseScheduleForm;

  return {
    step1: courseBasicInfoForm,
    step2: courseScheduleInfoForm,
  };
};
