import { GenericStateBlockProps } from '../../blocks/GenericStateBlock';


type OnWaitlistPageInternalProps = {
  genericStateBlock?: GenericStateBlockProps;
};

type OnWaitlistPagePropsValues = {
  '': OnWaitlistPageInternalProps;
};

const propValues: OnWaitlistPagePropsValues = {
  '': {
    genericStateBlock: {
    },
  },
};

const getProps = (type: string): OnWaitlistPageInternalProps => {
  const values: OnWaitlistPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
