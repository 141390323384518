import { BasicInfoSectionProps } from '../../organisms/BasicInfoSection';
import { DashboardInfoSectionProps } from '../../organisms/DashboardInfoSection';
import { TeacherDirectorySectionProps } from '../../organisms/TeacherDirectorySection';


type MyProfileBlockInternalProps = {
  dashboardInfoSection?: DashboardInfoSectionProps;
  basicInfoSection?: BasicInfoSectionProps;
  teacherDirectorySection?: TeacherDirectorySectionProps;
};

type MyProfileBlockPropsValues = {
  '': MyProfileBlockInternalProps;
};

const propValues: MyProfileBlockPropsValues = {
  '': {
    dashboardInfoSection: {
    },
    basicInfoSection: {
    },
    teacherDirectorySection: {
    },
  },
};

const getProps = (type: string): MyProfileBlockInternalProps => {
  const values: MyProfileBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
