import { ContextualContentProps } from '../../atoms/ContextualContent';
import { TextProps } from '../../atoms/Text';
import { TextInputProps } from '../../atoms/TextInput';
import { DateTimePickerProps } from './types';

type DateTimePickerFieldInternalProps = {
  text?: TextProps;
  dateTimePickerField?: DateTimePickerProps;
  contextualContent?: ContextualContentProps;
  textInput?: TextInputProps;
};

type DateTimePickerFieldPropsValues = {
  'Default': DateTimePickerFieldInternalProps;
  'Error': DateTimePickerFieldInternalProps;
  'Help': DateTimePickerFieldInternalProps;
};

const propValues: DateTimePickerFieldPropsValues = {
  'Default': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    textInput: {
      type: 'Text',
      error: 'None',
      size: 'L',
    },
  },
  'Error': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    textInput: {
      type: 'Text',
      error: 'Error',
      size: 'L',
    },
    contextualContent: {
      type: 'Error',
    },
  },
  'Help': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    textInput: {
      type: 'Text',
      error: 'None',
      size: 'L',
    },
    contextualContent: {
      type: 'Help',
    },
  },
};

const getProps = (type: string): DateTimePickerFieldInternalProps => {
  const values: DateTimePickerFieldInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
