import axiosInstance from '../../lib/api/axios';
import { BFF_URL } from '../../lib/config';
import { SignInPayload } from '../account/types';
import { AccountResponse } from '../auth/types';
import { KoruFeeDetails, PaymentIntent, SetupIntent, Subscription } from '../payment/types';
import {
  CreateTeacherPaymentIntentPayload,
  CreateTeacherPayload,
  Teacher,
  TeacherRegistrationStatus,
  UpdateTeacherPayload,
  CreateAnnualSubscriptionPayload,
  UpdateAnnualSubscriptionPayload,
  TeacherValidationPayload,
} from './types';

export const teacherSignIn = async (payload: SignInPayload): Promise<AccountResponse> => {
  const { data } = await axiosInstance.post<AccountResponse>(`${BFF_URL}/teachers/auth/signIn`, {
    ...payload,
    email: payload.email.trim(),
  });
  return data;
};

export const createTeacher = async (payload: CreateTeacherPayload): Promise<Teacher> => {
  const { data } = await axiosInstance.post<Teacher>(`${BFF_URL}/teachers`, {
    ...payload,
    email: payload.email.trim(),
  });
  return data;
};

export const updateTeacher = async (teacherUuid: string, payload: UpdateTeacherPayload): Promise<Teacher> => {
  const { data } = await axiosInstance.patch<Teacher>(`${BFF_URL}/teachers/${teacherUuid}`, payload);
  return data;
};

export const getTeacherFees = async (): Promise<KoruFeeDetails> => {
  const { data } = await axiosInstance.get<KoruFeeDetails>(`${BFF_URL}/teachers/fees`);
  return data;
};

export const createTeacherPaymentIntent = async (
  teacherUuid: string,
  payload: CreateTeacherPaymentIntentPayload,
): Promise<PaymentIntent> => {
  const { data } = await axiosInstance.post<PaymentIntent>(`${BFF_URL}/teachers/${teacherUuid}/createPaymentIntent`, {
    ...payload,
    discountCode: payload.discountCode || undefined,
  });
  return data;
};

export const createTeacherSetupIntent = async (
  teacherUuid: string,
): Promise<PaymentIntent> => {
  const { data } = await axiosInstance.post<SetupIntent>(`${BFF_URL}/teachers/${teacherUuid}/createSetupIntent`);
  return data;
};

export const getTeacherRegistrationStatus = async (
  inviteToken: string,
): Promise<TeacherRegistrationStatus> => {
  const { data } = await axiosInstance.get<TeacherRegistrationStatus>(`${BFF_URL}/teachers/registrationStatus?inviteToken=${inviteToken}`);
  return data;
};

export const createAnnualSubscription = async (payload: CreateAnnualSubscriptionPayload): Promise<Subscription> => {
  const { data } = await axiosInstance.post<Subscription>(`${BFF_URL}/teachers/subscription`, payload);
  return data;
};

export const sendPaymentReceipt = async (teacherUuid: string, paymentId: string): Promise<void> => {
  await axiosInstance.post(`${BFF_URL}/teachers/${teacherUuid}/sendPaymentReceipt/${paymentId}`);
};

export const updateAnnualSubscription = async (teacherUuid: string, payload: UpdateAnnualSubscriptionPayload): Promise<Subscription> => {
  const { data } = await axiosInstance.patch<Subscription>(`${BFF_URL}/teachers/${teacherUuid}/subscription`, payload);
  return data;
};

export const removePaymentMethod = async (teacherUuid: string): Promise<void> => {
  await axiosInstance.post<void>(`${BFF_URL}/teachers/${teacherUuid}/detachPaymentMethod`);
};

export const validateTeacherByEmail = async (email: string): Promise<TeacherValidationPayload> => {
  const { data } = await axiosInstance.post<TeacherValidationPayload>(`${BFF_URL}/teachers/validate`, { email });
  return data;
};
