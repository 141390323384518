import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { TextGroupProps } from '../../molecules/TextGroup';


type AlreadySignupBlockInternalProps = {
  stepperText?: TextProps;
  textGroup?: TextGroupProps;
  button?: ButtonProps;
};

type AlreadySignupBlockPropsValues = {
  '': AlreadySignupBlockInternalProps;
};

const propValues: AlreadySignupBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    textGroup: {
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
    },
  },
};

const getProps = (type: string): AlreadySignupBlockInternalProps => {
  const values: AlreadySignupBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
