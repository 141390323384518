import { getProductDetails } from '../../../modules/payment/api';
import { PaymentIntent, PriceItem } from '../../../modules/payment/types';
import { createTeacherPaymentIntent } from '../../../modules/teacher/api';
import { CreateTeacherPaymentIntentPayload } from '../../../modules/teacher/types';

export const getProductDetailsUseCase = (slug: string): Promise<PriceItem> => {
  return getProductDetails(slug);
};

export const createTeacherPaymentIntentUseCase = (
  teacherUuid: string,
  payload: CreateTeacherPaymentIntentPayload,
): Promise<PaymentIntent> => {
  return createTeacherPaymentIntent(teacherUuid, payload);
};