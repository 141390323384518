import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../../modules/auth';
import Holly from '../../../resources/images/team/Holly.png';
import Alex from '../../../resources/images/team/Alex.png';
import Gigi from '../../../resources/images/team/Gigi.png';
import Jennie from '../../../resources/images/team/Jennie.png';
import Libby from '../../../resources/images/team/Libby.png';
import Vivian from '../../../resources/images/team/Vivian.png';
import { KORU_DASHBOARD_URL } from '../../../lib/config';
import { StaffItemValueProps } from '../../molecules/StaffItem';
import { WelcomeBlockCombinedProps } from './types';

const teamMembersArray = [Holly, Libby, Alex, Gigi, Vivian, Jennie];

const usePresenter = (props: WelcomeBlockCombinedProps): WelcomeBlockCombinedProps => {
  const { t } = useTranslation();
  const { account } = useContext(AuthContext);

  const handleFindWorkshop = () => {
    window.open(`${KORU_DASHBOARD_URL}/training/workshop`, '_blank');
  };

  const staffItemsProps: StaffItemValueProps[] = teamMembersArray.map((member, index) => (
    {
      image: {
        imageSrc: member,
      },
      topText: {
        value: t(`welcome.staff_items.${index}.name`),
      },
      subText: {
        value: t(`welcome.staff_items.${index}.role`),
      },
    }
  ));

  return {
    ...props,
    stepperText: {
      value: t('welcome.stepper_text'),
    },
    leftText: {
      value: t('welcome.left_text'),
    },
    rightText: {
      value: account?.firstName,
    },
    staffItems: staffItemsProps,
    bodyText: {
      value: t('welcome.body_text'),
    },
    noteText: {
      value: t('welcome.note_text'),
    },
    button: {
      icon: {
        asset: 'ArrowRight',
      },
      text: {
        value: t('button.find_workshop'),
      },
      onClick: handleFindWorkshop,
    },
  };
};

export default usePresenter;
