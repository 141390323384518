import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { IconTextGroupListProps } from '../../organisms/IconTextGroupList';


type ClassItemInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  iconTextGroupList?: IconTextGroupListProps;
};

type ClassItemPropsValues = {
  '': ClassItemInternalProps;
};

const propValues: ClassItemPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading4',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    iconTextGroupList: {
    },
  },
};

const getProps = (type: string): ClassItemInternalProps => {
  const values: ClassItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
