import { ImageProps } from '../../atoms/Image';
import { StudentCourseDetailsBlockProps } from '../../blocks/StudentCourseDetailsBlock';


type StudentCourseDetailsPageInternalProps = {
  image?: ImageProps;
  logo?: ImageProps;
  studentCourseDetailsBlock?: StudentCourseDetailsBlockProps;
};

type StudentCourseDetailsPagePropsValues = {
  '': StudentCourseDetailsPageInternalProps;
};

const propValues: StudentCourseDetailsPagePropsValues = {
  '': {
    image: {
      type: 'Square',
    },
    logo: {
      type: 'Oval',
    },
    studentCourseDetailsBlock: {
    },
  },
};

const getProps = (type: string): StudentCourseDetailsPageInternalProps => {
  const values: StudentCourseDetailsPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
