import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import TextGroup from '../../molecules/TextGroup';

import styles from './VerifyEmailBlock.module.scss';
import usePresenter from './VerifyEmailBlock.presenter';
import getProps from './VerifyEmailBlock.props';
import { VerifyEmailBlockCombinedProps } from './types';


const VerifyEmailBlock: React.FC<VerifyEmailBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    stepperText,
    textGroup,
    textGroup1,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.verifyEmailBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.stepperText, classes?.stepperText)}
          {...internalProps.stepperText}
          {...stepperText}/>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <TextGroup
          className={cx(styles.textGroup1, classes?.textGroup1)}
          {...internalProps.textGroup1}
          {...textGroup1}/>
      </div>
    </div>
  );
};

export default VerifyEmailBlock;

