import { ButtonProps } from '../../atoms/Button';
import { TextGroupProps } from '../../molecules/TextGroup';


type ForgotPasswordSuccessBlockInternalProps = {
  textGroup?: TextGroupProps;
  textGroup1?: TextGroupProps;
  button?: ButtonProps;
};

type ForgotPasswordSuccessBlockPropsValues = {
  '': ForgotPasswordSuccessBlockInternalProps;
};

const propValues: ForgotPasswordSuccessBlockPropsValues = {
  '': {
    textGroup: {
    },
    textGroup1: {
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default', // MANUAL OVERRIDE
    },
  },
};

const getProps = (type: string): ForgotPasswordSuccessBlockInternalProps => {
  const values: ForgotPasswordSuccessBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
