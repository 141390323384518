import React from 'react';
import cx from 'classnames';

import Text from '../Text';

import styles from './ToggleItem.module.scss';
import usePresenter from './ToggleItem.presenter';
import getProps from './ToggleItem.props';
import { ToggleItemCombinedProps } from './types';


const ToggleItem: React.FC<ToggleItemCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    text,
    onClick,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`toggleItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Selected':
    case 'Unselected': {
      componentView = (
        <div className={cx(currentStyle, className)} onClick={onClick} tabIndex={0}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ToggleItem;

