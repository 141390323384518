import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { AddressProps } from '../../molecules/Address';
import { InputFieldProps } from '../../molecules/InputField';
import { InputFieldGroupProps } from '../../molecules/InputFieldGroup';


type BasicInfoSectionInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  inputFieldGroup?: InputFieldGroupProps;
  emailAddressInputField?: InputFieldProps;
  phoneNumberInputField?: InputFieldProps;
  address?: AddressProps;
  button?: ButtonProps;
};

type BasicInfoSectionPropsValues = {
  '': BasicInfoSectionInternalProps;
};

const propValues: BasicInfoSectionPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    inputFieldGroup: {
    },
    emailAddressInputField: {
      state: 'Default',
    },
    phoneNumberInputField: {
      state: 'Default',
    },
    address: {
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): BasicInfoSectionInternalProps => {
  const values: BasicInfoSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
