import { HeaderProps } from '../../organisms/Header';
import { SigninPageProps } from '../../pages/SigninPage';


type SignInLayoutInternalProps = {
  signInPage?: SigninPageProps;
  header?: HeaderProps;
};

type SignInLayoutPropsValues = {
  '': SignInLayoutInternalProps;
};

const propValues: SignInLayoutPropsValues = {
  '': {
    header: {
      type: 'Default',
    },
    signInPage: {
    },
  },
};

const getProps = (type: string): SignInLayoutInternalProps => {
  const values: SignInLayoutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
