import { useTranslation } from 'react-i18next';

import { ExpiredStatePageCombinedProps } from './types';
import ExpiredLink from '../../../resources/images/ExpiredLink.png';
import styles from './ExpiredStatePage.module.scss';
import { getTranslation } from '../../../lib/reactUtils';

const usePresenter = (props: ExpiredStatePageCombinedProps): ExpiredStatePageCombinedProps => {
  const { t } = useTranslation();
  return {
    ...props,
    genericStateNoActionBlock: {
      image: {
        imageSrc: ExpiredLink,
      },
      textGroup: {
        classes: {
          bottomText: styles.bottomText,
          topText: styles.topText,
        },
        topText: {
          value: t('expired_invitation.title'),
        },
        bottomText: {
          value: getTranslation('expired_invitation.description', {}, {
            className: styles.fontVariants,
          }),
        },
      },
    },
  };
};

export default usePresenter;
