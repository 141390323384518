import { LogEntryStatusProps } from '../LogEntryStatus';
import { TextProps } from '../Text';


type LogEntryStatusItemInternalProps = {
  logEntryStatus?: LogEntryStatusProps;
  text?: TextProps;
};

type LogEntryStatusItemPropsValues = {
  'SelectedStatus': LogEntryStatusItemInternalProps;
  'SelectedText': LogEntryStatusItemInternalProps;
  'UnselectedStatus': LogEntryStatusItemInternalProps;
  'UnselectedText': LogEntryStatusItemInternalProps;
};

const propValues: LogEntryStatusItemPropsValues = {
  'SelectedStatus': {
    logEntryStatus: {
      type: 'AwaitingReply',
      style: 'Inverse',
    },
  },
  'SelectedText': {
    text: {
      style: 'Semibold',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  'UnselectedStatus': {
    logEntryStatus: {
      type: 'AwaitingReply',
      style: 'Default',
    },
  },
  'UnselectedText': {
    text: {
      style: 'Semibold',
      align: 'Center',
      colour: 'Default',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): LogEntryStatusItemInternalProps => {
  const values: LogEntryStatusItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
