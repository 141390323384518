import { RosterBlockProps } from '../../blocks/RosterBlock';
import { CourseHeaderProps } from '../../organisms/CourseHeader';


type RosterPageInternalProps = {
  courseHeader?: CourseHeaderProps;
  rosterBlock?: RosterBlockProps;
};

type RosterPagePropsValues = {
  '': RosterPageInternalProps;
};

const propValues: RosterPagePropsValues = {
  '': {
    courseHeader: {
    },
    rosterBlock: {
    },
  },
};

const getProps = (type: string): RosterPageInternalProps => {
  const values: RosterPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
