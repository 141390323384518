import React from 'react';
import cx from 'classnames';

import styles from './NotFoundPage.module.scss';


const NotFoundPage: React.FC = () => {
  const currentStyle = styles.notFoundPage;
  return (
    <div className={cx(currentStyle)}>
      <h1>Not Found</h1>
      <h3>Requested URL was not found</h3>
    </div>
  );
};

export default NotFoundPage;