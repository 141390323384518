import { DataEntry } from '../../../lib/types';
import { formatDate } from '../../../lib/utils';
import { Log, LogStatus } from '../../../modules/log/types';
import { LogbookDateTypes, StudentLogs } from './types';

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
const TWO_DAYS_IN_MILLISECONDS = 2 * DAY_IN_MILLISECONDS;

const LogbookRightPanelDateFormatOptions: Intl.DateTimeFormatOptions = {
  hour12: true,
  hour: '2-digit',
  minute: '2-digit',
};

const MeditationSessionDateFormatOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: '2-digit',
  month: 'short',
  hour12: true,
  hour: '2-digit',
  minute: '2-digit',
};

export const getLogBookDateTimeStamp = (date: Date, type: LogbookDateTypes): string => {
  const today = new Date();
  const entryDate = new Date(date);
  const timeDiff = today.getTime() - entryDate.getTime();
  let dateTime;

  switch (type) {
    case LogbookDateTypes.LeftPanel:
    case LogbookDateTypes.RightPanel:
      if (timeDiff < DAY_IN_MILLISECONDS) {
        const entryTime = formatDate(entryDate, LogbookRightPanelDateFormatOptions);
        dateTime = `Today ${type === LogbookDateTypes.RightPanel ? entryTime : ''}`;
      } else if (timeDiff < TWO_DAYS_IN_MILLISECONDS) {
        dateTime = 'Yesterday';
      } else if (entryDate.getFullYear() === today.getFullYear()) {
        dateTime = formatDate(entryDate, {
          month: 'short',
          day: 'numeric',
        });
      } else {
        dateTime = formatDate(entryDate, {
          month: 'short',
          day: 'numeric',
        });
      }
      break;

    case LogbookDateTypes.MeditationDateTime:
      dateTime = formatDate(entryDate, MeditationSessionDateFormatOptions);
      if (entryDate.getFullYear() !== today.getFullYear()) {
        dateTime = `${dateTime} ${entryDate.getFullYear()}`;
      }
      break;

    default:
      // no-op
      break;
  }

  return dateTime;
};

export const getStudentLogs = (logs: DataEntry<Log>[]): StudentLogs[] => {
  // student ids without duplicates
  const studentUuids = [...new Set(logs.map((log) => log.content.student.uuid))];

  return studentUuids.map((studentUuid): StudentLogs  => {
    let hasUnreadMessages = false;
    const currentStudentLogs: DataEntry<Log>[] = logs.filter((log) => {
      const isCurrentStudentLog = log.content.student.uuid === studentUuid;
      if (isCurrentStudentLog && log.content.unreadMessages) {
        hasUnreadMessages = true;
      }
      return isCurrentStudentLog;
    });
    return {
      student: currentStudentLogs[0].content.student,
      logs: currentStudentLogs,
      hasUnreadMessages,
    };
  });
};
