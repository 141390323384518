import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { useNotification } from '../../../modules/notification';

import {
  FREE_LINK_SIGN_UP_JOURNEY,
  JOIN_WAITLIST_SIGN_UP,
  NOTIFICATION_DURATION,
  REGULAR_SIGN_UP_JOURNEY,
} from '../../../lib/constants';
import { CourseStateType } from '../../../modules/course/types';

import { VerifyEmailBlockCombinedProps } from './types';
import { studentResendVerificationEmailUseCase } from './VerifyEmailBlock.interactor';
import styles from './VerifyEmailBlock.module.scss';

const usePresenter = (props: VerifyEmailBlockCombinedProps): VerifyEmailBlockCombinedProps => {
  const { hasAvailableSeats } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const { trigger } = useNotification();
  const { state: { email } } = useLocation<{ email: string }>();
  const { state: { isLogin } } = useLocation<{ isLogin: boolean }>();
  const { state: { course } } = useLocation<CourseStateType>();

  const { mutateAsync: resendVerificationEmail } = useMutation(['resendVerificationEmail', email], async () => {
    if (email) {
      await studentResendVerificationEmailUseCase(email);
    }
  });

  useEffect(() => {
    const sendVerificationEmail = async () => {
      try {
        if (isLogin) {
          await resendVerificationEmail();
          history.replace({ state: { course, isLogin: false, email } });
        }
      } catch (error) {
        // no-op
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    sendVerificationEmail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSendResetEmailClick = async () => {
    try {
      await resendVerificationEmail();
      trigger({
        type: 'Success',
        duration: NOTIFICATION_DURATION,
        message: t('student_verify_email.toast'),
      });
    } catch (error) {
      // no-op
    }
  };

  const getResendVerificationLink = (): React.ReactNode => {
    return (
      <Trans
        i18nKey='student_verify_email.description2'
        components={{
          firstLink: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              className={styles.link}
              onClick={handleSendResetEmailClick}
            />
          ),
        }}
      />
    );
  };

  const getCorrectTotalSteps = (): number => {
    if (hasAvailableSeats) {
      if (course.content.freeLinkToken) {
        return FREE_LINK_SIGN_UP_JOURNEY.total;
      }
      return REGULAR_SIGN_UP_JOURNEY.total;
    }
    return JOIN_WAITLIST_SIGN_UP.total;
  };

  return {
    ...props,
    stepperText: {
      value: t('stepperText', {
        currentStep: REGULAR_SIGN_UP_JOURNEY.verifyEmail,
        totalSteps: getCorrectTotalSteps(),
      }),
    },
    textGroup: {
      topText: {
        value: t('student_verify_email.header'),
      },
      bottomText: {
        value: t('student_verify_email.description', {
          email: email,
        }),
      },
    },
    textGroup1: {
      topText: {
        type: 'Heading4',
        value: t('student_verify_email.subheader'),
      },
      bottomText: {
        value: getResendVerificationLink(),
      },
    },
  };
};

export default usePresenter;
