import { LogEntryItemProps } from '../../molecules/LogEntryItem';


type LogEntriesListInternalProps = {
  logEntryItem?: LogEntryItemProps;
};

type LogEntriesListPropsValues = {
  '': LogEntriesListInternalProps;
};

const propValues: LogEntriesListPropsValues = {
  '': {
    logEntryItem: {
      type: 'Default',
    },
  },
};

const getProps = (type: string): LogEntriesListInternalProps => {
  const values: LogEntriesListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
