import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import InputField from '../InputField';
import PriceListItem from '../PriceListItem';

import styles from './DiscountCode.module.scss';
import usePresenter from './DiscountCode.presenter';
import getProps from './DiscountCode.props';
import { DiscountCodeCombinedProps } from './types';


const DiscountCode: React.FC<DiscountCodeCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    inputField,
    button,
    priceListItem,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`discountCode${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Applied': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <InputField
              className={cx(styles.inputField, classes?.inputField)}
              {...internalProps.inputField}
              {...inputField}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
          <PriceListItem
            className={cx(styles.priceListItem, classes?.priceListItem)}
            {...internalProps.priceListItem}
            {...priceListItem}/>
        </div>
      );
      break;
    }
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <InputField
              className={cx(styles.inputField, classes?.inputField)}
              {...internalProps.inputField}
              {...inputField}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default DiscountCode;

