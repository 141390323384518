import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { getCourseByIdUseCase } from './BodyFooterLayout.interactor';
import { BodyFooterLayoutCombinedProps } from './types';

const usePresenter = (props: BodyFooterLayoutCombinedProps): BodyFooterLayoutCombinedProps => {
  const { t } = useTranslation();
  const { id } = useParams<{ id?: string }>();
  const { data: course, isLoading } = useQuery(['getCourseById', id], () => getCourseByIdUseCase(Number(id)));

  return {
    ...props,
    isLoading,
    studentCourseDetailsPage: {
      studentCourseDetailsBlock: {
        course: course,
      },
    },
    studentCourseDetailsFooter: {
      course: course,
    },
    inlineNotification: course?.content?.registrationOpen ? undefined : {
      text: {
        value: t('notification.course_registration_closed'),
      },
    },
  };
};

export default usePresenter;
