import React, { useState } from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import NavMenuList from '../NavMenuList';

import styles from './LeftNav.module.scss';
import usePresenter from './LeftNav.presenter';
import getProps from './LeftNav.props';
import { LeftNavCombinedProps } from './types';


const LeftNav: React.FC<LeftNavCombinedProps> = (props) => {
  const {
    className,
    classes,
    navMenuList,
    divider,
  } = usePresenter(props);

  // MANUAL OVERRIDE
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const variantName = '';
  const internalProps = getProps(variantName);
  const currentStyle = styles[`leftNav${variantName}`];

  let componentView;

  switch (variantName) {
    case '': {
      componentView = (
        // MANUAL OVERRIDE
        <div className={cx(currentStyle, className)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <NavMenuList
            className={cx(styles.navMenuList, classes?.navMenuList)}
            {...internalProps.navMenuList}
            expand={isHovering}     // MANUAL OVERRIDE
            {...navMenuList}/>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default LeftNav;

