import React from 'react';
import cx from 'classnames';

import IconTextGroup from '../../molecules/IconTextGroup';

import styles from './IconTextGroupList.module.scss';
import usePresenter from './IconTextGroupList.presenter';
import getProps from './IconTextGroupList.props';
import { IconTextGroupListCombinedProps } from './types';


const IconTextGroupList: React.FC<IconTextGroupListCombinedProps> = (props) => {
  const {
    className,
    classes,
    iconTextGroups,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.iconTextGroupList;

  const iconTextGroupViews = iconTextGroups?.map((iconTextGroup, index) => (
    <IconTextGroup
      key={index}
      className={cx(styles.iconTextGroup, classes?.iconTextGroup)}
      {...internalProps.iconTextGroup}
      {...iconTextGroup} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {iconTextGroupViews}
    </div>
  );
};

export default IconTextGroupList;

