import React from 'react';
import cx from 'classnames';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import styles from './CopyStudentsEmailSection.module.scss';
import usePresenter from './CopyStudentsEmailSection.presenter';
import getProps from './CopyStudentsEmailSection.props';
import { CopyStudentsEmailSectionCombinedProps } from './types';
import Button from '../../atoms/Button';


const CopyStudentsEmailSection: React.FC<CopyStudentsEmailSectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,    
    copyEmailButton,
    bodyText,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.CopyStudentsEmailSection;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <Text
        className={cx(styles.bodyText, classes?.bodyText)}
        {...internalProps.bodyText}
        {...bodyText}/>
        <Button
      className={cx(styles.button, classes?.button)}
      {...internalProps.copyEmailButton}
      {...copyEmailButton}/>
    </div>
  );
};

export default CopyStudentsEmailSection;

