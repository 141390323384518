import React from 'react';
import cx from 'classnames';

import styles from './TextArea.module.scss';
import usePresenter from './TextArea.presenter';
import { TextAreaCombinedProps } from './types';


const TextArea: React.FC<TextAreaCombinedProps> = (props) => {
  const {
    error,
    size,
    className,
    classes,
    textValue,
    textPlaceholder,
    onTextChanged,
    disabled,
    maxLength, //MANUAL OVERRIDE
    textareaRef, // MANUAL OVERRIDE
    preProcessTextInput, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${error}${size}`;
  const currentStyle = styles[`textArea${variantName}`];

  const [inputValue, setInputValue] = React.useState<string>('');

  React.useEffect(() => {
    setInputValue(textValue || '');
  }, [textValue]);

  const onTextAreaChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (preProcessTextInput && textareaRef?.current) {
      textareaRef.current.value = preProcessTextInput(event.target.value);
    }
    setInputValue(textareaRef?.current?.value || '');
    if (onTextChanged) {
      onTextChanged(textareaRef?.current?.value || '');
    }
  };
  let componentView;

  switch (variantName) {
    case 'ErrorL':
    case 'NoneL': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <textarea
            className={cx(styles.input, classes?.input)}
            placeholder={textPlaceholder}
            ref={textareaRef}
            disabled={disabled}
            value={inputValue}
            maxLength={maxLength} //MANUAL OVERRIDE
            onChange={onTextAreaChanged} //MANUAL OVERRIDE
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TextArea;

