import { CreateCourseScheduleBlockProps } from '../../blocks/CreateCourseScheduleBlock';
import { CreateCourseHeaderProps } from '../../organisms/CreateCourseHeader';


type CreateCourseSchedulePageInternalProps = {
  createCourseHeader?: CreateCourseHeaderProps;
  createCourseScheduleBlock?: CreateCourseScheduleBlockProps;
};

type CreateCourseSchedulePagePropsValues = {
  '': CreateCourseSchedulePageInternalProps;
};

const propValues: CreateCourseSchedulePagePropsValues = {
  '': {
    createCourseHeader: {
    },
    createCourseScheduleBlock: {
    },
  },
};

const getProps = (type: string): CreateCourseSchedulePageInternalProps => {
  const values: CreateCourseSchedulePageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
