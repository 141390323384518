import { ButtonProps } from '../../atoms/Button';
import { InputFieldProps } from '../../molecules/InputField';
import { TextGroupProps } from '../../molecules/TextGroup';


type CreatePasswordBlockInternalProps = {
  textGroup?: TextGroupProps;
  createPasswordField?: InputFieldProps;
  confirmPasswordField?: InputFieldProps;
  button?: ButtonProps;
};

type CreatePasswordBlockPropsValues = {
  '': CreatePasswordBlockInternalProps;
};

const propValues: CreatePasswordBlockPropsValues = {
  '': {
    textGroup: {
    },
    createPasswordField: {
      state: 'Default',
    },
    confirmPasswordField: {
      state: 'Default',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default', // MANUAL OVERRIDE
    },
  },
};

const getProps = (type: string): CreatePasswordBlockInternalProps => {
  const values: CreatePasswordBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
