import React from 'react';
import cx from 'classnames';

import BasicInfoSection from '../../organisms/BasicInfoSection';
import DashboardInfoSection from '../../organisms/DashboardInfoSection';
import TeacherDirectorySection from '../../organisms/TeacherDirectorySection';

import styles from './MyProfileBlock.module.scss';
import usePresenter from './MyProfileBlock.presenter';
import getProps from './MyProfileBlock.props';
import { MyProfileBlockCombinedProps } from './types';


const MyProfileBlock: React.FC<MyProfileBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    dashboardInfoSection,
    basicInfoSection,
    teacherDirectorySection,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.myProfileBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.leftContent, classes?.leftContent)}>
        <DashboardInfoSection
          className={cx(styles.dashboardInfoSection, classes?.dashboardInfoSection)}
          {...internalProps.dashboardInfoSection}
          {...dashboardInfoSection}/>
        <BasicInfoSection
          className={cx(styles.basicInfoSection, classes?.basicInfoSection)}
          {...internalProps.basicInfoSection}
          {...basicInfoSection}/>
      </div>
      <div className={cx(styles.rightContent, classes?.rightContent)}>
        <TeacherDirectorySection
          className={cx(styles.teacherDirectorySection, classes?.teacherDirectorySection)}
          {...internalProps.teacherDirectorySection}
          {...teacherDirectorySection}/>
      </div>
    </div>
  );
};

export default MyProfileBlock;

