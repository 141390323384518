import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import RadioItemList from '../../organisms/RadioItemList';

import styles from './RadioField.module.scss';
import usePresenter from './RadioField.presenter';
import getProps from './RadioField.props';
import { RadioFieldCombinedProps } from './types';


const RadioField: React.FC<RadioFieldCombinedProps> = (props) => {
  const {
    className,
    classes,
    labelText,
    radioItemList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.radioField;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.labelText, classes?.labelText)}
        {...internalProps.labelText}
        {...labelText}/>
      <RadioItemList
        className={cx(styles.radioItemList, classes?.radioItemList)}
        {...internalProps.radioItemList}
        {...radioItemList}/>
    </div>
  );
};

export default RadioField;

