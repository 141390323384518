import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AUTH_ROUTES } from '../../lib/constants';
import { isStudent } from '../../lib/utils';
import { JourneyStatusEnum } from '../teacher/types';
import { UserContext } from '../user/UserContext';
import { AuthContext } from './AuthContext';

const CheckUserAccess: React.FC<RouteProps> = (props) => {
  const authContext = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const { account } = authContext;
  const { path } = props;

  if (!account) {
    return <Redirect to={{
      pathname: AUTH_ROUTES.signIn,
      state: {
        sessionEnd: true,
      },
    }}/>;
  }

  const isUserStudent = isStudent(user);
  if (user && !isUserStudent) {
    if (!user.content.profile && props.path !== AUTH_ROUTES.completeProfile) {
      return <Redirect to={AUTH_ROUTES.completeProfile} />;
    }
  
    if (user.content.profile && user.content.journeyStatus === JourneyStatusEnum.account_created && path !== AUTH_ROUTES.tuitionPayment) {
      return <Redirect to={AUTH_ROUTES.tuitionPayment} />;
    }
  
    if (user.content.journeyStatus === JourneyStatusEnum.payment_complete && path !== AUTH_ROUTES.certificationAgreement) {
      return <Redirect to={AUTH_ROUTES.certificationAgreement} />;
    }
  }
  
  // if no errors then proceed to the route
  return <Route {...props} />;
};

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  return <CheckUserAccess {...props} />;
};

export default ProtectedRoute;
