import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { ActionSectionProps } from '../ActionSection';


type StudentAppSectionInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  infoText?: TextProps;
  text1?: TextProps;
  text2?: TextProps;
  actionSection?: ActionSectionProps;
};

type StudentAppSectionPropsValues = {
  '': StudentAppSectionInternalProps;
};

const propValues: StudentAppSectionPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    infoText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    text1: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    text2: {
      style: 'Bold',
      align: 'Center',
      colour: 'Default',
      type: 'Heading2',
    },
    actionSection: {
    },
  },
};

const getProps = (type: string): StudentAppSectionInternalProps => {
  const values: StudentAppSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
