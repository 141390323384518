import { GenericActionBlockProps } from '../../blocks/GenericActionBlock';


type PasswordLinkExpiredPageInternalProps = {
  genericActionBlock?: GenericActionBlockProps;
};

type PasswordLinkExpiredPagePropsValues = {
  '': PasswordLinkExpiredPageInternalProps;
};

const propValues: PasswordLinkExpiredPagePropsValues = {
  '': {
    genericActionBlock: {
    },
  },
};

const getProps = (type: string): PasswordLinkExpiredPageInternalProps => {
  const values: PasswordLinkExpiredPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
