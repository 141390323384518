import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { 
  DUPLICATE_EMAIL,
  DUPLICATE_PHONE_NUMBER,
  FREE_LINK_SIGN_UP_JOURNEY,
  JOIN_WAITLIST_SIGN_UP,
  KORU_PRIVACY_POLICY_URL,
  KORU_TERMS_OF_USE_URL,
  MAX_EMAIL_LENGTH,
  MAX_NAME_LENGTH,
  MAX_PASSWORD_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
  MIN_PASSWORD_LENGTH,
  NOTIFICATION_DURATION,
  REGULAR_SIGN_UP_JOURNEY,
  STUDENT_ROUTES,
} from '../../../lib/constants';
import { acceptPhoneNumber, isEmail, isEmptyString, preprocessFirstAndLastName } from '../../../lib/utils';
import { CourseStateType } from '../../../modules/course/types';
import { useNotification } from '../../../modules/notification';
import { studentSignupUseCase } from './StudentSignupBlock.interactor';

import { StudentSignupBlockCombinedProps, StudentSignUpForm, StudentSignUpFormError } from './types';
import styles from './StudentSignupBlock.module.scss';

const getPrivacyPolicyAndTermOfUseLinkTrans = (): React.ReactNode => {
  return (
    <Trans
      i18nKey="student_sign_up.legalText"
      components={{
        privacyLink: (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            href={KORU_PRIVACY_POLICY_URL}
            rel="noreferrer"
            target='_blank'
            style={{ textDecoration: 'none', color: '#276EF1' }}
          />
        ),
        termsOfUseLink: (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            href={KORU_TERMS_OF_USE_URL}
            rel="noreferrer"
            target='_blank'
            style={{ textDecoration: 'none', color: '#276EF1' }}
          />
        ),
      }}
    />
  );
};

const formInitialState: StudentSignUpForm = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
  subscribeToNewsletter: true,
};

const formErrorInitialState: StudentSignUpFormError = {
  ...formInitialState,
};

const usePresenter = (props: StudentSignupBlockCombinedProps): StudentSignupBlockCombinedProps => {
  const { t } = useTranslation();
  const { trigger } = useNotification();
  const history = useHistory();
  const { hasAvailableSeats } = props;
  const { state: { course } } = useLocation<CourseStateType>();
  const [formState, setFormState] = useState<StudentSignUpForm>(formInitialState);
  const [formErrorState, setFormErrorState] = useState<StudentSignUpFormError>(formErrorInitialState);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const { mutateAsync: studentSignUp, isLoading } = useMutation(['studentSignUp'], async () => {
    const data = await studentSignupUseCase({
      firstName: formState.firstName,
      lastName: formState.lastName,
      email: formState.email,
      phoneNumber: formState.phoneNumber,
      password: formState.password,
      subscribeToNewsletter: formState.subscribeToNewsletter,
      initialRegistrationCourse: course.uuid,
      initialRegistrationToken: course.content.freeLinkToken,
    });
    return data;
  });

  const handleTextChange = (field: keyof StudentSignUpForm) => {
    return (value: string) => {
      setFormState({
        ...formState,
        [field]: value,
      });

      setFormErrorState({
        ...formErrorState,
        [field]: '',
      });
    };
  };

  const isFormValid = (): boolean => {
    const invalidFormErrors: StudentSignUpFormError = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
    };
    const isFirstNameEmpty = isEmptyString(formState.firstName);
    const isLastNameEmpty = isEmptyString(formState.lastName);
    const isEmailEmpty = isEmptyString(formState.email);
    const isPhoneNumberEmpty = isEmptyString(formState.phoneNumber);
    const isPasswordEmpty = isEmptyString(formState.password);
    const isConfirmPasswordEmpty = isEmptyString(formState.confirmPassword);

    if (isFirstNameEmpty) {
      invalidFormErrors.firstName = t('error.field_required');
    }
    if (isLastNameEmpty) {
      invalidFormErrors.lastName = t('error.field_required');
    }
    if (isEmailEmpty) {
      invalidFormErrors.email = t('error.field_required');
    } else {
      if (!isEmail(formState.email)) {
        invalidFormErrors.email = t('error.invalid', { field: 'email address' });
      }
    }
    if (isPhoneNumberEmpty) {
      invalidFormErrors.phoneNumber = t('error.field_required');
    }
    if (isPasswordEmpty) {
      invalidFormErrors.password = t('error.field_required');
    } else {
      if (formState.password.length < MIN_PASSWORD_LENGTH) {
        invalidFormErrors.password = t('error.weak_password');
        invalidFormErrors.confirmPassword = t('error.weak_password');
      }
    }
    if (isConfirmPasswordEmpty) {
      invalidFormErrors.confirmPassword = t('error.field_required');
    }
    if (formState.password !== formState.confirmPassword) {
      invalidFormErrors.password = t('error.password_mismatch');
      invalidFormErrors.confirmPassword = t('error.password_mismatch');
    }
    setFormErrorState(invalidFormErrors);
    return (
      Object.values(invalidFormErrors).filter((errorMessage: string) => !!errorMessage)
        .length === 0
    );
  };

  const handleStudentSignUp = async () => {
    try {
      if (isFormValid()) {
        await studentSignUp();
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.data.code === 401) {
          trigger({
            duration: NOTIFICATION_DURATION,
            message: t('notification.account_created'),
            type: 'Success',
          });
          history.push(STUDENT_ROUTES.auth.verifyEmail, { email: formState.email, course });
        }
        if (e.response?.data.code === 500) {
          switch (e.response?.data.message) {
            case DUPLICATE_PHONE_NUMBER:
              setFormErrorState({
                ...formErrorState,
                phoneNumber:  t('error.duplicate_phone_number'),
              });
              break;
            case DUPLICATE_EMAIL:
              setFormErrorState({
                ...formErrorState,
                email:  t('error.duplicate_email'),
              });
              break;
            default:
              break;
          }
        }
      }
    }
  };

  const getCorrectTotalSteps = (): number => {
    if (hasAvailableSeats) {
      if (course.content.freeLinkToken) {
        return FREE_LINK_SIGN_UP_JOURNEY.total;
      }
      return REGULAR_SIGN_UP_JOURNEY.total;
    }
    return JOIN_WAITLIST_SIGN_UP.total;
  };

  return {
    ...props,
    stepperText: {
      value: t('stepperText', {
        currentStep: JOIN_WAITLIST_SIGN_UP.createAccount,
        totalSteps: getCorrectTotalSteps(),
      }),
    },
    textGroup: {
      topText: {
        value: t('student_sign_up.header'),
      },
      bottomText: {
        value: hasAvailableSeats ? t('student_sign_up.description') : t('student_sign_up.waitlist_description'),
      },
    },
    inputFieldGroup: {
      inputFieldOne: {
        state: formErrorState.firstName ? 'Error' : 'Default',
        text: {
          value: t('signUp.labels.firstName'),
        },
        textInput: {
          maxLength: MAX_NAME_LENGTH,
          textValue: formState.firstName,
          onTextChanged: handleTextChange('firstName'),
          preProcessTextInput: preprocessFirstAndLastName,
        },
        contextualContent: {
          text: {
            value: formErrorState.firstName,
          },
        },
      },
      inputFieldTwo: {
        state: formErrorState.lastName ? 'Error' : 'Default',
        text: {
          value: t('signUp.labels.lastName'),
        },
        textInput: {
          maxLength: MAX_NAME_LENGTH,
          textValue: formState.lastName,
          onTextChanged: handleTextChange('lastName'),
          preProcessTextInput: preprocessFirstAndLastName,
        },
        contextualContent: {
          text: {
            value: formErrorState.lastName,
          },
        },
        className: styles.lastName,
      },
    },
    emailInputField: {
      state: formErrorState.email ? 'Error' : 'Default',
      text: {
        value: t('signUp.labels.email'),
      },
      textInput: {
        maxLength: MAX_EMAIL_LENGTH,
        textValue: formState.email,
        onTextChanged: handleTextChange('email'),
      },
      contextualContent: {
        text: {
          value: formErrorState.email,
        },
      },
    },
    phoneNumberInputField: {
      state: formErrorState.phoneNumber ? 'Error' : 'Default',
      text: {
        value: t('signUp.labels.phone_number'),
      },
      textInput: {
        textValue: formState.phoneNumber,
        onTextChanged: handleTextChange('phoneNumber'),
        maxLength: MAX_PHONE_NUMBER_LENGTH,
        preProcessTextInput: acceptPhoneNumber,
      },
      contextualContent: {
        text: {
          value: formErrorState.phoneNumber,
        },
      },
    },
    passwordInputField: {
      state: formErrorState.password ? 'Error' : 'Default',
      text: {
        value: t('signUp.labels.password'),
      },
      textInput: {
        maxLength: MAX_PASSWORD_LENGTH,
        button: {
          icon: {
            asset: showPassword ? 'Hide' : 'Show',
          },
          onClick: () => {
            setShowPassword(!showPassword);
          },
        },
        type: 'Password',
        textValue: formState.password,
        onTextChanged: handleTextChange('password'),
        htmlType: showPassword ? 'text' : 'password',
      },
      contextualContent: {
        text: {
          value: formErrorState.password,
        },
      },
    },
    confirmPasswordInputField: {
      state: formErrorState.confirmPassword ? 'Error' : 'Default',
      text: {
        value: t('signUp.labels.confirmPassword'),
      },
      textInput: {
        maxLength: MAX_PASSWORD_LENGTH,
        button: {
          icon: {
            asset: showConfirmPassword ? 'Hide' : 'Show',
          },
          onClick: () => {
            setShowConfirmPassword(!showConfirmPassword);
          },
        },
        type: 'Password',
        textValue: formState.confirmPassword,
        onTextChanged: handleTextChange('confirmPassword'),
        htmlType: showConfirmPassword ? 'text' : 'password',
      },
      contextualContent: {
        text: {
          value: formErrorState.confirmPassword,
        },
      },
    },
    checkboxItem: {
      state: formState.subscribeToNewsletter ? 'Checked' : 'Unchecked',
      text: {
        value: t('student_sign_up.checkboxItem'),
      },
      onClick: () => setFormState({
        ...formState,
        subscribeToNewsletter: !formState.subscribeToNewsletter,
      }),
    },
    button: {
      text: {
        value: t('student_sign_up.button'),
      },
      icon: {
        asset: 'ArrowRight',
      },
      onClick: handleStudentSignUp,
      loading: isLoading ? 'Loading' : 'Default',
      disabled: isLoading,
    },
    legalText: {
      value: getPrivacyPolicyAndTermOfUseLinkTrans(),
    },
  };
};

export default usePresenter;
