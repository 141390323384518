import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { RadioFieldProps } from '../../molecules/RadioField';
import { TextGroupProps } from '../../molecules/TextGroup';
import { ClassCardsListProps } from '../../organisms/ClassCardsList';
import { GenericModalProps } from '../../organisms/GenericModal';

type CreateCourseScheduleBlockInternalProps = {
  stepperText?: TextProps;
  textGroup?: TextGroupProps;
  radioField?: RadioFieldProps;
  classCardsList?: ClassCardsListProps;
  button?: ButtonProps;
  backButton?: ButtonProps;
  createCourseButton?: ButtonProps;
  deleteClassModal?: GenericModalProps;
};

type CreateCourseScheduleBlockPropsValues = {
  '': CreateCourseScheduleBlockInternalProps;
};

const propValues: CreateCourseScheduleBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    textGroup: {
    },
    radioField: {
    },
    classCardsList: {
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default', 
    },
    backButton: {
      style: 'Outline',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    createCourseButton: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): CreateCourseScheduleBlockInternalProps => {
  const values: CreateCourseScheduleBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
