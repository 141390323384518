import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as  Router } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';

import { LocaleProvider } from './modules/locale';
import { AuthProvider } from './modules/auth';
import { NotificationProvider } from './modules/notification';
import { UserProvider } from './modules/user/UserContext';
import { ModalProvider } from './modules/user/ModalContext';
import { stripeElementOptions, stripePromise } from './lib/utils';

const queryClient = new QueryClient();

const AppProvider: React.FC<{}> = ({ children }) => (
  <LocaleProvider>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <UserProvider>
          <Router>
            <ModalProvider>
              <NotificationProvider>
                <Elements
                  stripe={stripePromise}
                  options={stripeElementOptions}>
                  {children}
                </Elements>
              </NotificationProvider>
            </ModalProvider>
          </Router>
        </UserProvider>
      </AuthProvider>
    </QueryClientProvider>
  </LocaleProvider>
);

export default AppProvider;
