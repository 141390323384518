import { useContext } from 'react';

import { AuthContext } from '../../../modules/auth';
import KoruLogo from '../../../resources/icons/KoruLogo.svg';

import { HeaderSidenavLayoutCombinedProps } from './types';

const usePresenter = (props: HeaderSidenavLayoutCombinedProps): HeaderSidenavLayoutCombinedProps => {
  const { account } = useContext(AuthContext);
  
  return {
    ...props,
    header: {
      type: account ? 'WithUserProfile' : 'Default',
      koruLogo: {
        imageSrc: KoruLogo,
      },
    },
  };
};

export default usePresenter;
