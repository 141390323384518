import { ContextualMenuItemProps } from '../../atoms/ContextualMenuItem';


type ContextualMenuListInternalProps = {
  contextualMenuItem?: ContextualMenuItemProps;
};

type ContextualMenuListPropsValues = {
  '': ContextualMenuListInternalProps;
};

const propValues: ContextualMenuListPropsValues = {
  '': {
    contextualMenuItem: {
      type: 'Default',
    },
  },
};

const getProps = (type: string): ContextualMenuListInternalProps => {
  const values: ContextualMenuListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
