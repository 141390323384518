import { ButtonProps } from '../../atoms/Button';
import { ImageProps } from '../../atoms/Image';
import { TextProps } from '../../atoms/Text';


type GenericStateThreeBlockInternalProps = {
  image?: ImageProps;
  topText?: TextProps;
  button?: ButtonProps;
};

type GenericStateThreeBlockPropsValues = {
  '': GenericStateThreeBlockInternalProps;
};

const propValues: GenericStateThreeBlockPropsValues = {
  '': {
    image: {
      type: 'Square',
    },
    topText: {
      style: 'Bold',
      align: 'Center',
      colour: 'Default',
      type: 'Heading3',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'IconText',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): GenericStateThreeBlockInternalProps => {
  const values: GenericStateThreeBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
