import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import InputField from '../InputField';

import styles from './CustomApps.module.scss';
import usePresenter from './CustomApps.presenter';
import getProps from './CustomApps.props';
import { CustomAppsCombinedProps } from './types';


const CustomApps: React.FC<CustomAppsCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    button,
    inputField,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`customApps${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <InputField
              className={cx(styles.inputField, classes?.inputField)}
              {...internalProps.inputField}
              {...inputField}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default CustomApps;

