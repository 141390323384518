import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { 
  AUTH_ROUTES,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  NOTIFICATION_DURATION,
  STUDENT_ROUTES,
} from '../../../lib/constants';
import { extractJwtPayload } from '../../../lib/token';
import { isEmptyString, isExpiryDatePassed } from '../../../lib/utils';
import { useNotification } from '../../../modules/notification';
import { isStudentRoleUseCase, updatePasswordUseCase } from './CreatePasswordBlock.interactor';
import { CreateNewPasswordState, CreatePasswordBlockCombinedProps } from './types';

const formInitialState: CreateNewPasswordState = {
  password: '',
  confirmPassword: '',
};

const usePresenter = (props: CreatePasswordBlockCombinedProps): CreatePasswordBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');
  const { trigger } = useNotification();

  const [formState, setFormState] = useState<CreateNewPasswordState>(formInitialState);
  const [formErrorState, setFormErrorState] = useState<CreateNewPasswordState>(formInitialState);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  
  const { mutateAsync: updatePassword, isLoading } = useMutation(['updatePassword'], updatePasswordUseCase);
  
  let accountId;
  if (token) {
    const { id, exp } = extractJwtPayload(token);
    if (isExpiryDatePassed(exp)) {
      history.push(AUTH_ROUTES.resetPasswordExpired);
    }
    accountId = id;
  }

  const { data } = useQuery(['isStudentRole'], () => {
    return isStudentRoleUseCase(accountId);
  });

  const handleTextChange = (field: keyof CreateNewPasswordState) => {
    return (value: string) => {
      setFormState({
        ...formState,
        [field]: value,
      });

      setFormErrorState(formInitialState);
    };
  };

  const isFormValid = (): boolean => {
    const invalidFormErrors = {
      password: '',
      confirmPassword: '',
    };

    const isPasswordEmpty = isEmptyString(formState.password);
    const isConfirmPasswordEmpty = isEmptyString(formState.confirmPassword);

    if (isPasswordEmpty) {
      invalidFormErrors.password = t('create_new_password.errors.missingPassword');
    } else if (formState.password.length < MIN_PASSWORD_LENGTH) {
      invalidFormErrors.password = t('create_new_password.errors.weakPassword');
      invalidFormErrors.confirmPassword = t('create_new_password.errors.weakPassword');
    }
    if (isConfirmPasswordEmpty) {
      invalidFormErrors.confirmPassword = t('create_new_password.errors.missingConfirmPassword');
    }
    if (formState.password !== formState.confirmPassword) {
      invalidFormErrors.password = t('create_new_password.errors.passwordsMismatch');
      invalidFormErrors.confirmPassword = t('create_new_password.errors.passwordsMismatch');
    }
    setFormErrorState(invalidFormErrors);
    return (
      Object.values(invalidFormErrors).filter((errorMessage: string) => !!errorMessage)
        .length === 0
    );
  };

  const handleCreatePasswordButtonClick = async () => {
    if (isFormValid() && accountId && token) {
      try {
        await updatePassword({
          id: accountId,
          password: formState.password,
          token,
        });
        trigger({
          type: 'Success',
          duration: NOTIFICATION_DURATION,
          message: t('create_new_password.success_notification'),
        });
        if (data?.isStudent) {
          history.replace(STUDENT_ROUTES.auth.updatedPassword);
        } else {
          history.replace(AUTH_ROUTES.signIn);
        }
      } catch (error) {
        // no-op
      }
    }
  };

  return {
    ...props,
    button: {
      text: {
        value: t('create_new_password.create_password_button'),
      },
      onClick: handleCreatePasswordButtonClick,
      loading: isLoading ? 'Loading' : 'Default',
      disabled: isLoading,
    },
    textGroup: {
      topText: {
        value: t('create_new_password.title'),
      },
      bottomText: {
        value: t('create_new_password.description'),
      },
    },
    createPasswordField: {
      state: formErrorState.password ? 'Error' : 'Default',
      text: {
        value: t('create_new_password.labels.password'),
      },
      textInput: {
        maxLength: MAX_PASSWORD_LENGTH,
        button: {
          icon: {
            asset: showPassword ? 'Hide' : 'Show',
          },
          onClick: () => setShowPassword(!showPassword),
        },
        type: 'Password',
        textValue: formState.password,
        onTextChanged: handleTextChange('password'),
        htmlType: showPassword ? 'text' : 'password',
      },
      contextualContent: {
        text: {
          value: formErrorState.password,
        },
      },
    },
    confirmPasswordField: {
      state: formErrorState.confirmPassword ? 'Error' : 'Default',
      text: {
        value: t('create_new_password.labels.confirmPassword'),
      },
      textInput: {
        maxLength: MAX_PASSWORD_LENGTH,
        button: {
          icon: {
            asset: showConfirmPassword ? 'Hide' : 'Show',
          },
          onClick: () => setShowConfirmPassword(!showConfirmPassword),
        },
        type: 'Password',
        textValue: formState.confirmPassword,
        onTextChanged: handleTextChange('confirmPassword'),
        htmlType: showConfirmPassword ? 'text' : 'password',
      },
      contextualContent: {
        text: {
          value: formErrorState.confirmPassword,
        },
      },
    },
  };
};

export default usePresenter;
