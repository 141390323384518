import React from 'react';
import ModalWrapper, { ExportedModalProps } from '../../../modules/common/ModalWrapper';
import InternalPurchaseStudentAppModal from './PurchaseStudentAppModal';
import { 
  PurchaseStudentAppModalProps, 
  PurchaseStudentAppModalValueProps as InternalPurchaseStudentAppModalValueProps,
} from './types';

export type { PurchaseStudentAppModalProps };

export type PurchaseStudentAppModalValueProps = ExportedModalProps<InternalPurchaseStudentAppModalValueProps>;

const PurchaseStudentAppModal: React.FC<PurchaseStudentAppModalValueProps> = ({ className, onHide, show, modalProps }) => {
  return (
    <ModalWrapper
      className={className}
      content={<InternalPurchaseStudentAppModal {...modalProps} />}
      onHide={onHide}
      show={show}
    />
  );
};

export default PurchaseStudentAppModal;
