import { useContext } from 'react';

import { SignInLayoutCombinedProps } from './types';
import KoruLogo from '../../../resources/icons/KoruLogo.svg';
import { AuthContext } from '../../../modules/auth';

const usePresenter = (props: SignInLayoutCombinedProps): SignInLayoutCombinedProps => {
  const { account } = useContext(AuthContext);

  return {
    ...props,
    header: {
      type: account ? 'WithUserProfile' : 'Default',
      koruLogo: {
        imageSrc: KoruLogo,
      },
    },
  };
};

export default usePresenter;
