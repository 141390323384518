import React from 'react';
import cx from 'classnames';

import StudentItem from '../../molecules/StudentItem';

import styles from './StudentsList.module.scss';
import usePresenter from './StudentsList.presenter';
import getProps from './StudentsList.props';
import { StudentsListCombinedProps } from './types';


const StudentsList: React.FC<StudentsListCombinedProps> = (props) => {
  const {
    className,
    classes,
    studentItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.studentsList;

  const studentItemViews = studentItems?.map((studentItem, index) => (
    <StudentItem
      key={index}
      className={cx(styles.studentItem, classes?.studentItem)}
      {...internalProps.studentItem}
      {...studentItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {studentItemViews}
    </div>
  );
};

export default StudentsList;

