import { ButtonProps } from '../../atoms/Button';
import { CheckboxItemProps } from '../../atoms/CheckboxItem';
import { DividerProps } from '../../atoms/Divider';


type TeacherAgreementFooterInternalProps = {
  divider?: DividerProps;
  checkboxItem?: CheckboxItemProps;
  button?: ButtonProps;
};

type TeacherAgreementFooterPropsValues = {
  '': TeacherAgreementFooterInternalProps;
};

const propValues: TeacherAgreementFooterPropsValues = {
  '': {
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    checkboxItem: {
      state: 'Checked',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
    },
  },
};

const getProps = (type: string): TeacherAgreementFooterInternalProps => {
  const values: TeacherAgreementFooterInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
