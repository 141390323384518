import { TextProps } from '../../atoms/Text';


type LabelTextGroupInternalProps = {
  labelText?: TextProps;
  infoText?: TextProps;
};

type LabelTextGroupPropsValues = {
  'Horizontal': LabelTextGroupInternalProps;
  'Vertical': LabelTextGroupInternalProps;
};

const propValues: LabelTextGroupPropsValues = {
  'Horizontal': {
    labelText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    infoText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
  'Vertical': {
    labelText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    infoText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
};

const getProps = (type: string): LabelTextGroupInternalProps => {
  const values: LabelTextGroupInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
