import React from 'react';
import cx from 'classnames';

import TextGroup from '../../molecules/TextGroup';
import AppFeeDetailsSection from '../../organisms/AppFeeDetailsSection';
import ClassList from '../../organisms/ClassList';
import CourseDetailsSection from '../../organisms/CourseDetailsSection';

import styles from './StudentCourseDetailsBlock.module.scss';
import usePresenter from './StudentCourseDetailsBlock.presenter';
import getProps from './StudentCourseDetailsBlock.props';
import { StudentCourseDetailsBlockCombinedProps } from './types';


const StudentCourseDetailsBlock: React.FC<StudentCourseDetailsBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    textGroup,
    courseDetailsSection,
    appFeeDetailsSection,
    classList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.studentCourseDetailsBlock;

  const appFeeDetailsSectionView = appFeeDetailsSection ? (
    <AppFeeDetailsSection
      className={cx(styles.appFeeDetailsSection, classes?.appFeeDetailsSection)}
      {...internalProps.appFeeDetailsSection}
      {...appFeeDetailsSection}/>
  ) : undefined;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.leftContent, classes?.leftContent)}>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
        <CourseDetailsSection
          className={cx(styles.courseDetailsSection, classes?.courseDetailsSection)}
          {...internalProps.courseDetailsSection}
          {...courseDetailsSection}/>
        { appFeeDetailsSectionView }
      </div>
      <div className={cx(styles.rightContent, classes?.rightContent)}>
        <ClassList
          className={cx(styles.classList, classes?.classList)}
          {...internalProps.classList}
          {...classList}/>
      </div>
    </div>
  );
};

export default StudentCourseDetailsBlock;

