import { ButtonProps } from '../../atoms/Button';
import { InputFieldProps } from '../InputField';


type CustomAppsInternalProps = {
  button?: ButtonProps;
  inputField?: InputFieldProps;
};

type CustomAppsPropsValues = {
  'Default': CustomAppsInternalProps;
  'Expanded': CustomAppsInternalProps;
};

const propValues: CustomAppsPropsValues = {
  'Default': {
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'PrimaryAction',
    },
  },
  'Expanded': {
    inputField: {
      state: 'Default',
    },
  },
};

const getProps = (type: string): CustomAppsInternalProps => {
  const values: CustomAppsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
