import React from 'react';
import cx from 'classnames';


import styles from './Icon.module.scss';
import usePresenter from './Icon.presenter';
import { IconCombinedProps } from './types';

import { ReactComponent as AddSquareIcon } from '../../../resources/icons/AddSquare.svg';
import { ReactComponent as AddSquareFilledIcon } from '../../../resources/icons/AddSquareFilled.svg';
import { ReactComponent as AlertFilledIcon } from '../../../resources/icons/AlertFilled.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../resources/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '../../../resources/icons/ArrowRight.svg';
import { ReactComponent as CalendarIcon } from '../../../resources/icons/Calendar.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../resources/icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxUncheckedIcon } from '../../../resources/icons/CheckboxUnchecked.svg';
import { ReactComponent as ChevronDownBigIcon } from '../../../resources/icons/ChevronDownBig.svg';
import { ReactComponent as ChevronDownSmallIcon } from '../../../resources/icons/ChevronDownSmall.svg';
import { ReactComponent as ChevronUpBigIcon } from '../../../resources/icons/ChevronUpBig.svg';
import { ReactComponent as ClockIcon } from '../../../resources/icons/Clock.svg';
import { ReactComponent as ClockFilledIcon } from '../../../resources/icons/ClockFilled.svg';
import { ReactComponent as CloseIcon } from '../../../resources/icons/Close.svg';
import { ReactComponent as CopyIcon } from '../../../resources/icons/Copy.svg';
import { ReactComponent as CoursesIcon } from '../../../resources/icons/Courses.svg';
import { ReactComponent as DotNewIcon } from '../../../resources/icons/DotNew.svg';
import { ReactComponent as EditIcon } from '../../../resources/icons/Edit.svg';
import { ReactComponent as EmailIcon } from '../../../resources/icons/Email.svg';
import { ReactComponent as EventsIcon } from '../../../resources/icons/Events.svg';
import { ReactComponent as ExternalIcon } from '../../../resources/icons/External.svg';
import { ReactComponent as ForumIcon } from '../../../resources/icons/Forum.svg';
import { ReactComponent as HideIcon } from '../../../resources/icons/Hide.svg';
import { ReactComponent as InboxIcon } from '../../../resources/icons/Inbox.svg';
import { ReactComponent as InfoFilledIcon } from '../../../resources/icons/InfoFilled.svg';
import { ReactComponent as LocationIcon } from '../../../resources/icons/Location.svg';
import { ReactComponent as MenuIcon } from '../../../resources/icons/Menu.svg';
import { ReactComponent as PaymentCardFilledIcon } from '../../../resources/icons/PaymentCardFilled.svg';
import { ReactComponent as PeopleIcon } from '../../../resources/icons/People.svg';
import { ReactComponent as QuestionIcon } from '../../../resources/icons/Question.svg';
import { ReactComponent as RadioSelectedIcon } from '../../../resources/icons/RadioSelected.svg';
import { ReactComponent as RadioUnselectedIcon } from '../../../resources/icons/RadioUnselected.svg';
import { ReactComponent as ResourcesIcon } from '../../../resources/icons/Resources.svg';
import { ReactComponent as SearchIconIcon } from '../../../resources/icons/SearchIcon.svg';
import { ReactComponent as ShowIcon } from '../../../resources/icons/Show.svg';
import { ReactComponent as SupportIcon } from '../../../resources/icons/Support.svg';
import { ReactComponent as TimezoneIcon } from '../../../resources/icons/Timezone.svg';
import { ReactComponent as TrainingIcon } from '../../../resources/icons/Training.svg';
import { ReactComponent as VerticalMenuIcon } from '../../../resources/icons/VerticalMenu.svg';

const Icon: React.FC<IconCombinedProps> = (props) => {
  const {
    asset,
    colour,
    className,
  } = usePresenter(props);

  const variantName = `${asset}${colour}`;
  const currentStyle = styles[`icon${variantName}`];


  let componentView;

  switch (variantName) {
    case 'AddSquareBase':
    case 'AddSquareDisabled':
    case 'AddSquareInfo':
    case 'AddSquareInverse':
    case 'AddSquareSubdued': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <AddSquareIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'AddSquareFilledInfo': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <AddSquareFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'AlertFilledBase':
    case 'AlertFilledCritical': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <AlertFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ArrowLeftBase':
    case 'ArrowLeftDisabled': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowLeftIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ArrowRightBase':
    case 'ArrowRightInfo':
    case 'ArrowRightInverse': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowRightIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CalendarBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CalendarIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CheckboxCheckedDisabled':
    case 'CheckboxCheckedMulticolor':
    case 'CheckboxCheckedInfo': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckboxCheckedIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CheckboxUncheckedDisabled':
    case 'CheckboxUncheckedInfo': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckboxUncheckedIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronDownBigBase':
    case 'ChevronDownBigDisabled': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronDownBigIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronDownSmallBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronDownSmallIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronUpBigBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronUpBigIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ClockBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ClockIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ClockFilledBase':
    case 'ClockFilledInfo':
    case 'ClockFilledInverse': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ClockFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CloseBase':
    case 'CloseInfo': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CloseIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CopyBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CopyIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    // MANUAL OVERRIDE
    case 'CopyInverse': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CopyIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'DotNewNew': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <DotNewIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'EditDisabled':
    case 'EditInfo': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <EditIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'EmailBase':
    case 'EmailBrand': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <EmailIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ExternalBase':
    case 'ExternalDisabled':
    case 'ExternalInfo': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ExternalIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'HideInfo': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <HideIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'InfoFilledBase':
    case 'InfoFilledInfo':
    case 'InfoFilledSubdued': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InfoFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'LocationBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LocationIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'MenuBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'PaymentCardFilledBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PaymentCardFilledIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'PeopleBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PeopleIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'QuestionBase':
    case 'QuestionInfo': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <QuestionIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'RadioSelectedBase':
    case 'RadioSelectedBrand':
    case 'RadioSelectedMulticolor':
    case 'RadioSelectedBrandLight':
    case 'RadioSelectedDisabled':
    case 'RadioSelectedInfo': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <RadioSelectedIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'RadioUnselectedBase':
    case 'RadioUnselectedDisabled':
    case 'RadioUnselectedInfo': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <RadioUnselectedIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'SearchIconBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SearchIconIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ShowInfo': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ShowIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'TimezoneBase': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TimezoneIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'VerticalMenuBase':
    case 'VerticalMenuInfo':
    case 'VerticalMenuInverse': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <VerticalMenuIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CoursesMulticolor': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CoursesIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'EventsMulticolor': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <EventsIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ForumMulticolor': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ForumIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'InboxMulticolor': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InboxIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'SupportMulticolor': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SupportIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'TrainingMulticolor': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TrainingIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ResourcesMulticolor': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ResourcesIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Icon;

