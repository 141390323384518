import { ContextualContentProps } from '../../atoms/ContextualContent';
import { TextProps } from '../../atoms/Text';
import { TextInputProps } from '../../atoms/TextInput';


type InputFieldInternalProps = {
  text?: TextProps;
  textInput?: TextInputProps;
  contextualContent?: ContextualContentProps;
};

type InputFieldPropsValues = {
  'Default': InputFieldInternalProps;
  'Error': InputFieldInternalProps;
  'Help': InputFieldInternalProps;
};

const propValues: InputFieldPropsValues = {
  'Default': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    textInput: {
      type: 'Text',
      error: 'None',
      size: 'L',
    },
  },
  'Error': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    textInput: {
      type: 'Text',
      error: 'Error',
      size: 'L',
    },
    contextualContent: {
      type: 'Error',
    },
  },
  'Help': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    textInput: {
      type: 'Text',
      error: 'None',
      size: 'L',
    },
    contextualContent: {
      type: 'Help',
    },
  },
};

const getProps = (type: string): InputFieldInternalProps => {
  const values: InputFieldInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
