import { t } from 'i18next';
import { NOTIFICATION_DURATION } from '../../../lib/constants';
import { DataEntry } from '../../../lib/types';
import { EnrollmentStatus, Roster } from '../../../modules/course/types';
import { TriggerNotificationPayload } from '../../../modules/notification';

export const getEmailsFromRosters = (rosters: DataEntry<Roster>[]) :string=>{  
  const emails =  rosters?.reduce((emailString, roster) => {
    const { student, enrollmentStatus } = roster.content;
    const { email } = student.content;
    const isEnrolled = enrollmentStatus === EnrollmentStatus.enrolled;
    if (isEnrolled) {
      return emailString ? `${emailString},${email}` : `${email}`;
    } else {
      return `${emailString}`;
    }
  }, '');
  return emails;
};

export const fallbackCopyTextToClipboard = (text: string, trigger?: (payload: TriggerNotificationPayload) => void) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    if (trigger) {
      trigger({
        type: 'Success',
        duration: NOTIFICATION_DURATION,
        message: t('notification.emails_copied'),
      });
    }
  } catch {
    // TODO
  } finally {
    document.body.removeChild(textArea);
  }
};