import { DataEntry } from '../../../lib/types';
import { createCourse, getCourseById, updateCourse } from '../../../modules/course/api';
import { Course, CreateCoursePayload, UpdateCoursePayload } from '../../../modules/course/types';

export const createCourseUseCase = async (payload: CreateCoursePayload): Promise<DataEntry<Course>> => {
  return createCourse(payload);
};

export const updateCourseUseCase = async (
  courseId: number, payload: UpdateCoursePayload,
): Promise<DataEntry<Course>> => {
  return updateCourse(courseId, payload);
};

export const getCourseByIdUseCase = async (courseId: number): Promise<DataEntry<Course>> => {
  return getCourseById(courseId);
};