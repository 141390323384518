import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import LogEntryStatus from '../../atoms/LogEntryStatus';
import Text from '../../atoms/Text';

import styles from './LogEntryItem.module.scss';
import usePresenter from './LogEntryItem.presenter';
import getProps from './LogEntryItem.props';
import { LogEntryItemCombinedProps } from './types';


const LogEntryItem: React.FC<LogEntryItemCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    nameText,
    timeStampText,
    logEntryStatus,
    previewText,
    divider,
    icon,
    onClick,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`logEntryItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default':
    case 'Selected': {
      componentView = (
        <div className={cx(currentStyle, className)} onClick={onClick} tabIndex={0}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.selectSign, classes?.selectSign)}/>
            <div className={cx(styles.textContent, classes?.textContent)}>
              <div className={cx(styles.topContent, classes?.topContent)}>
                <Text
                  className={cx(styles.nameText, classes?.nameText)}
                  {...internalProps.nameText}
                  {...nameText}/>
                <Text
                  className={cx(styles.timeStampText, classes?.timeStampText)}
                  {...internalProps.timeStampText}
                  {...timeStampText}/>
              </div>
              <LogEntryStatus
                className={cx(styles.logEntryStatus, classes?.logEntryStatus)}
                {...internalProps.logEntryStatus}
                {...logEntryStatus}/>
              <Text
                className={cx(styles.previewText, classes?.previewText)}
                {...internalProps.previewText}
                {...previewText}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    case 'New': {
      componentView = (
        <div className={cx(currentStyle, className)} onClick={onClick} tabIndex={0}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.selectSign, classes?.selectSign)}/>
            <div className={cx(styles.textContent, classes?.textContent)}>
              <div className={cx(styles.topContent, classes?.topContent)}>
                <Icon
                  className={cx(styles.icon, classes?.icon)}
                  {...internalProps.icon}
                  {...icon}/>
                <Text
                  className={cx(styles.nameText, classes?.nameText)}
                  {...internalProps.nameText}
                  {...nameText}/>
                <Text
                  className={cx(styles.timeStampText, classes?.timeStampText)}
                  {...internalProps.timeStampText}
                  {...timeStampText}/>
              </div>
              <LogEntryStatus
                className={cx(styles.logEntryStatus, classes?.logEntryStatus)}
                {...internalProps.logEntryStatus}
                {...logEntryStatus}/>
              <Text
                className={cx(styles.previewText, classes?.previewText)}
                {...internalProps.previewText}
                {...previewText}/>
            </div>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default LogEntryItem;

