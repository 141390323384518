import { HeaderProps } from '../../organisms/Header';
import { SignupPageProps } from '../../pages/SignupPage';


type HeaderOnlyLayoutInternalProps = {
  header1?: HeaderProps;
  signupPage?: SignupPageProps;
};

type HeaderOnlyLayoutPropsValues = {
  '': HeaderOnlyLayoutInternalProps;
};

const propValues: HeaderOnlyLayoutPropsValues = {
  '': {
    header1: {
      type: 'WithUserProfile',
    },
    signupPage: {
    },
  },
};

const getProps = (type: string): HeaderOnlyLayoutInternalProps => {
  const values: HeaderOnlyLayoutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
