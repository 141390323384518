import { TextProps } from '../Text';
import { ButtonProps } from '../Button';


type ProductItemInternalProps = {
  dateText?: TextProps;
  amountText?: TextProps;
  productNameText?: TextProps;
  button?: ButtonProps;
};

type ProductItemPropsValues = {
  '': ProductItemInternalProps;
};

const propValues: ProductItemPropsValues = {
  '': {
    dateText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    amountText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    productNameText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Text', // MANUAL OVERRIDE
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): ProductItemInternalProps => {
  const values: ProductItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
