import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';

import { useNotification } from '../../../modules/notification';
import { COURSE_ROUTES, NOTIFICATION_DURATION } from '../../../lib/constants';
import { CreateClassPayload, UpdateClassPayload } from '../../../modules/class/types';
import { RadioItemStateEnum } from '../../atoms/RadioItem/types';
import { ClassFormCardValueProps } from '../../molecules/ClassFormCard';
import { CourseBasicInfoForm } from '../CreateCourseBasicDetailsBlock/types';
import { trackSuccessfulCourseCreation } from '../../../lib/trackingUtils';
import { isDateEqual, useValidateTeacher } from '../../../lib/utils';

import {
  ClassCardForm,
  ClassCardFormErrors,
  ClassFormat,
  CLASS_FORMAT,
  CourseScheduleForm,
  CourseScheduleFormErrors,
  CreateCourseScheduleBlockCombinedProps,
} from './types';
import {
  createCourseUseCase,
  getCourseByIdUseCase,
  updateCourseUseCase,
} from './CreateCourseScheduleBlock.interactor';
import { ClassFormatEnum } from '../../../modules/course/types';
import { convertClassToClassPayload, getUserTimezone } from './utils';
import { AuthContext } from '../../../modules/auth';

const initialCourseScheduleInfo: CourseScheduleForm = {
  type: 'InPerson',
  classes: [],
};

const initialClassCardInfo: ClassCardForm = {
  date: '',
  start: '',
  end: '',
  location: '',
  link: '',
};

const initialCourseScheduleInfoErrors: CourseScheduleFormErrors = {
  type: '',
  classes: [],
};

const initialClassCardInfoErrors: ClassCardFormErrors = {
  date: '',
  start: '',
  end: '',
  location: '',
  link: '',
};

const defaultDatePickerOptions = {
  altInput: true,
  altFormat: 'M j, Y',
  dateFormat: 'Y-m-d',
  minDate: 'today',
};

const defaultTimePickerOptions = {
  enableTime: true,
  noCalendar: true,
  dateFormat: 'h:i K',
};

const START_TIME_BUFFER = 1;
const INITIAL_NUMBER_OF_CLASSES = 4;
const DEFAULT_INTERVAL_TIME = 75;

export const ClassFormatReverse: Record<ClassFormatEnum, ClassFormat> = {
  'In-person': 'InPerson',
  'Online': 'Online',
  'Hybrid': 'Both',
};

const usePresenter = (props: CreateCourseScheduleBlockCombinedProps): CreateCourseScheduleBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: locationState } = useLocation<Record<string, object>>();
  const { id } = useParams<{ id?: string }>();
  const { trigger } = useNotification();
  const { account } = useContext(AuthContext);
  
  const [showDeleteClassWarningModal, setShowDeleteClassWarningModal] = useState(false);
  const [classIndex, setClassIndex] = useState<number>();
  const [formState, setFormState] = useState<CourseScheduleForm>({
    ...initialCourseScheduleInfo,
    classes: Array.from(Array(INITIAL_NUMBER_OF_CLASSES), () => {
      return { ...initialClassCardInfo };
    }),
  });

  const [formErrorState, setFormErrorState] = useState<CourseScheduleFormErrors>({
    ...initialCourseScheduleInfoErrors,
    classes: Array.from(Array(INITIAL_NUMBER_OF_CLASSES), () => {
      return { ...initialClassCardInfoErrors };
    }),
  });
  const [autoFillEnabled, setAutoFillEnabled] = useState(true);

  useEffect(() => {
    if (locationState?.step2) {
      const formData = locationState?.step2 as CourseScheduleForm;
      setFormState({
        ...initialCourseScheduleInfo,
        type: formData?.type,
        classes: formData.classes,
      });
      setFormErrorState({
        ...initialCourseScheduleInfoErrors,
        classes: Array.from(Array(formData.classes.length), () => {
          return { ...initialClassCardInfoErrors };
        }),
      });
    }
  }, [locationState?.step2]);
  
  const { data: course } = useQuery(['getCourseById', id], () => {
    if (id) {
      return getCourseByIdUseCase(Number(id));
    }
  });

  useValidateTeacher(account, course);

  const { mutateAsync: createCourse, isLoading } = useMutation(['createCourse'], async () => {
    if (formState.classes) {
      const classes: CreateClassPayload[] = formState.classes.map(convertClassToClassPayload);
      const courseBasicInfoState = locationState.step1 as CourseBasicInfoForm;
      return createCourseUseCase({
        title: courseBasicInfoState.name,
        description: courseBasicInfoState.description,
        courseType: courseBasicInfoState.type,
        classFormat: ClassFormatEnum[formState.type],
        classCapacity: courseBasicInfoState.classCapacity,
        registrationOpen: courseBasicInfoState.registrationOpen,
        coTeachers: courseBasicInfoState.coTeachers || [],
        classes: classes,
        timezone: getUserTimezone(),
      });
    }
  });

  const { mutateAsync: updateCourse, isLoading: isUpdating } = useMutation(['updateCourse'], async () => {
    if (formState.classes && course) {
      const classes: UpdateClassPayload[] = formState.classes.map((formClass): UpdateClassPayload => {
        return {
          uuid: formClass.uuid,
          ...convertClassToClassPayload(formClass),
        };
      });
      const courseBasicInfoState = locationState.step1 as CourseBasicInfoForm;
      return updateCourseUseCase(course.content.id, {
        title: courseBasicInfoState.name,
        description: courseBasicInfoState.description,
        courseType: courseBasicInfoState.type,
        classFormat: ClassFormatEnum[formState.type],
        classCapacity: courseBasicInfoState.classCapacity,
        registrationOpen: courseBasicInfoState.registrationOpen,
        coTeachers: courseBasicInfoState.coTeachers || [],
        classes: classes,
      });
    }
  });

  const closeDeleteClassWarningModal = () => {
    setShowDeleteClassWarningModal(false);
  };

  const isCardFilled = (index: number): boolean => {
    return (
      Object.values(formState.classes[index]).filter(
        (value: string | Date | number) => !!value,
      ).length > 0
    );
  };

  const deleteClass = (index: number) => {
    const updatedClasses = [...formState.classes];
    const updatedClassesErrors = [...formErrorState.classes];
    if (formState.classes.length > 1) {
      updatedClasses.splice(index, 1);
      updatedClassesErrors.splice(index, 1);
    } else {
      updatedClasses[0] = {
        ...initialClassCardInfo,
      };
      updatedClassesErrors[0] = {
        ...initialClassCardInfoErrors,
      };
    }
    
    // autofill should be disabled when class 1 with data is removed
    if (index === 0 && isCardFilled(index)) {
      setAutoFillEnabled(false);
    }
    setFormState({
      ...formState,
      classes: [...updatedClasses],
    });
    setFormErrorState({
      ...formErrorState,
      classes: [...updatedClassesErrors],
    });
    trigger({
      type: 'Success',
      duration: NOTIFICATION_DURATION,
      message: t('create_course_schedule.class_info.labels.toast'),
    });
  };

  const handleDeleteClass = (index: number) => () => {
    if (isCardFilled(index)) {
      setClassIndex(index);
      setShowDeleteClassWarningModal(true);
    } else {
      deleteClass(index);
    }
  };

  const handleAddClass = () => {
    setFormState({
      ...formState,
      classes: [...formState.classes, { ...initialClassCardInfo }],
    });
    setFormErrorState({
      ...formErrorState,
      classes: [...formErrorState.classes, { ...initialClassCardInfoErrors }],
    });
  };

  const handleRemoveClass = () => {
    if (typeof classIndex !== 'undefined') {
      deleteClass(classIndex);
      setShowDeleteClassWarningModal(false);
    }
  };

  const handleRadioSelection = (value: ClassFormat) => {
    setFormState({
      ...formState,
      type: value,
    });
  };

  const validateForm = (): boolean => {
    const { type } = formState;
    formState.classes.forEach((cardForm, index) => {
      Object.entries(cardForm).forEach(([key, value]) => {
        if (!value) {
          const { classes } = formErrorState;
          const currClassesErrors = [...classes];
          if (type === 'InPerson' && key !== 'link') {
            currClassesErrors[index][key] = t('create_course_schedule.class_info.labels.error');
          } else if (type === 'Online' && key !== 'location') {
            currClassesErrors[index][key] = t('create_course_schedule.class_info.labels.error'); 
          } else if (type === 'Both') {
            currClassesErrors[index][key] = t('create_course_schedule.class_info.labels.error');
          }
          setFormErrorState({
            ...formErrorState,
            classes: currClassesErrors,
          });
        }
      });
    });
    // iterate all course schedule form errors
    return !formErrorState.classes.map((formCardError) => {
      // check if forms fields have any errors
      return Object.values(formCardError).filter((value: string | Date) => !!value).length > 0;
    }).includes(true);
  };

  const updateText = (value: string, index: number, field: 'location' | 'link') => {
    const updateClasses = [...formState.classes];
    updateClasses[index][field] = value;
    setFormState({
      ...formState,
      classes: updateClasses,
    });
    const updateClassesErrors = [...formErrorState.classes];
    updateClassesErrors[index][field] = '';
    setFormErrorState({
      ...formErrorState,
      classes: updateClassesErrors,
    });
  };

  const autoFillText = (value: string, field: 'location' | 'link') => {
    if (autoFillEnabled) {
      const { classes } = formState;
      classes.forEach((_classForm, currIndex) => {
        updateText(value, currIndex, field);
      });
    }
  };

  const addCourse = async () => {
    try {
      const newCourse = await createCourse();
      if (newCourse) {
        trigger({
          type: 'Success',
          duration: NOTIFICATION_DURATION,
          message: t('notification.course_created'),
        });
        trackSuccessfulCourseCreation();
        history.replace(`/courses/detail/${newCourse.content.id}`);
      }
    } catch (e) {
      // TODO: should display error
    }
  };

  const updateCurrentCourse = async () => {
    if (course?.content?.id) {
      try {
        const updatedCourse = await updateCourse();
        if (updatedCourse) {
          trigger({
            type: 'Success',
            duration: NOTIFICATION_DURATION,
            message: t('notification.course_saved'),
          });
          history.replace(`/courses/detail/${updatedCourse.content.id}`);
        }
      } catch (e) {
        // TODO: should display error
      }
    }
  };
  
  const handleCreateCourse = async () => {
    if (validateForm()) {
      if (course) {
        await updateCurrentCourse();
        return;
      }
  
      await addCourse();
    }
  };

  const handleTextChange = (index: number, field: 'location' | 'link') => {
    return (value: string) => {
      if (index === 0 && autoFillEnabled) {
        autoFillText(value, field);
      } else {
        updateText(value, index, field);
        setAutoFillEnabled(false);
      }
    };
  };

  const handleDateTimeChange = (index: number, field: 'date' | 'start' | 'end') =>  {
    return (date: Date[]) => {
      const updateClasses = [...formState.classes];
      updateClasses[index][field] = date[0];  
      setFormState({
        ...formState,
        classes: updateClasses,
      });
      const updateClassesErrors = [...formErrorState.classes];
      updateClassesErrors[index][field] = '';
      setFormErrorState({
        ...formErrorState,
        classes: updateClassesErrors,
      });
      setAutoFillEnabled(false);
    };
  };

  const handleDateTimeAutofillChange = (field: 'date' | 'start' | 'end') =>  {
    return (date: Date[]) => {
      if (autoFillEnabled) {
        let updateClasses;
        let updateClassesErrors;
        const { classes } = formState;
        const { classes: classesErrors } = formErrorState;
        const defaultEndTime = new Date(date[0].getTime() + DEFAULT_INTERVAL_TIME * 60000);
        if (field === 'start') {
          // use same start and set end time as per default interval
          updateClasses = classes.map((classForm) => {
            return { 
              ...classForm,
              start: date[0],
              end: defaultEndTime,
            };
          });
        } else if (field === 'end') {
          // use same end time
          updateClasses = classes.map((classForm) => {
            return { 
              ...classForm,
              [field]: date[0],
            };
          });
        } else {
          // each proceeding class date is a week increment
          updateClasses = classes.map((classForm, index) => {
            const newDate = date[0].setDate(date[0].getDate() + (index ? 7 : 0));
            return { 
              ...classForm,
              [field]: newDate,
            };
          });
        }
        
        // update errors on autofill
        if (field === 'start') {
          updateClassesErrors = classesErrors.map((classFormError) => {
            return { 
              ...classFormError,
              start: '',
              end: '',
            };
          });
        } else {
          updateClassesErrors = classesErrors.map((classFormError) => {
            return { 
              ...classFormError,
              [field]: '',
            };
          });
        }
        
        setFormState({
          ...formState,
          classes: updateClasses,
        });
        setFormErrorState({
          ...formErrorState,
          classes: updateClassesErrors,
        });
      }
    };
  };

  const handleBack = () => {
    const redirectPath = course ? `/courses/detail/${course.content.id}/basicInfo` : COURSE_ROUTES.newCourse.step1;
    history.push(redirectPath, {
      ...locationState,
      step2: formState,
    });
  };

  const radioItems = CLASS_FORMAT.map((courseType) => {
    return {
      state: (formState.type === courseType ? 'Checked' : 'Unchecked') as RadioItemStateEnum,
      text: {
        value: t(`create_course_schedule.class_info.class_format.${courseType}`),
      },
      onClick: () => handleRadioSelection(courseType),
    };
  });

  useEffect(() => {
    if (course && course.content.classes) {
      setFormState({
        ...formState,
        type: ClassFormatReverse[course.content.classFormat],
        classes: course.content.classes.map(({ uuid, content: classInfo }): ClassCardForm => {
          return {
            date: classInfo.date,
            end: classInfo.endTime,
            start: classInfo.startTime,
            link: classInfo.classLink || '',
            location: classInfo.location || '',
            uuid,
          };
        }),
      });
      setFormErrorState({
        ...initialCourseScheduleInfoErrors,
        classes: Array.from(Array(course.content.classes.length), () => {
          return { ...initialClassCardInfoErrors };
        }),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const classFormCards: ClassFormCardValueProps[] = formState.classes.map((classFormCard, index) => {
    const startDate = classFormCard.start && new Date(classFormCard.start);
    const endDate = classFormCard.end && new Date(classFormCard.end);
    const minEndTime = classFormCard.start && new Date(new Date(classFormCard.start).getTime());
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + START_TIME_BUFFER);
    const prevDate = formState.classes[index > 0 ? index - 1 : index].date;
    let minDate: Date | string = '';
    if (prevDate && prevDate instanceof Date) {
      minDate = new Date(prevDate);
      minDate.setDate(minDate.getDate() + 1);
    }
    
    const isClassExpired = classFormCard.date && (
      new Date(classFormCard.date).getTime() < new Date().getTime()
    );
    const isMinDateExpired = minDate instanceof Date && (
      minDate.getTime() < new Date().getTime()
    );
    // If class date has passed then minDate should class date (to show date in input field)
    // Otherwise if minDate has passed then minDate should be today
    const minDateForValidation = isClassExpired ? classFormCard.date
      : (isMinDateExpired || index === 0 ? 'today' : minDate);
    return {
      type: formState.type,
      text: {
        value: t('create_course_schedule.class_info.labels.class_title', { number: `${index + 1}` }),
      },
      button: {
        loading: 'Default',
        icon: {
          asset: 'Close',
        },
        text: {
          value: t('create_course_schedule.class_info.buttons.add_class'),
        },
        onClick: handleDeleteClass(index),
      },
      dateInputField: {
        state: formErrorState.classes[index]?.date ? 'Error' : 'Default',
        text: {
          value: t('create_course_schedule.class_info.labels.date'),
        },
        flatpickr: {
          onChange: index === 0 && autoFillEnabled ?
            handleDateTimeAutofillChange('date')
            : handleDateTimeChange(index, 'date'),
          value: classFormCard.date ? classFormCard.date : '',
          options: {
            ...defaultDatePickerOptions,
            minDate: minDateForValidation,
          },
        },
        contextualContent: {
          text: {
            value: formErrorState.classes[index]?.date,
          },
        },
      },
      startTimeInputField: {
        state: formErrorState.classes[index]?.start ? 'Error' : 'Default',
        text: {
          value: t('create_course_schedule.class_info.labels.start'),
        },
        flatpickr: {
          onChange: index === 0 && autoFillEnabled ?
            handleDateTimeAutofillChange('start')
            : handleDateTimeChange(index, 'start'),
          value: startDate ? startDate : '',
          options: classFormCard.date && isDateEqual(new Date(classFormCard.date), currentDate) ? 
            {
              ...defaultTimePickerOptions,
              defaultHour: currentDate.getHours(),
              defaultMinute: currentDate.getMinutes(),
              minTime: currentDate,
            } : defaultTimePickerOptions,
        },
        textInput: {
          disabled: !classFormCard.date,
        },
        contextualContent: {
          text: {
            value: formErrorState.classes[index]?.start,
          },
        },
      },
      endTimeInputField: {
        state: formErrorState.classes[index]?.end ? 'Error' : 'Default',
        text: {
          value: t('create_course_schedule.class_info.labels.end'),
        },
        flatpickr: {
          onChange: index === 0 && autoFillEnabled ?
            handleDateTimeAutofillChange('end')
            : handleDateTimeChange(index, 'end'),
          value: endDate ? endDate : '',
          options: startDate instanceof Date ? {
            ...defaultTimePickerOptions,
            defaultHour: startDate.getHours(),
            defaultMinute: startDate.getMinutes(),
            minTime: minEndTime,
          } : defaultTimePickerOptions,
        },
        textInput: {
          disabled: !classFormCard.start,
        },
        contextualContent: {
          text: {
            value: formErrorState.classes[index]?.end,
          },
        },
      },
      locationInputField: {
        state: formErrorState.classes[index]?.location ? 'Error' : 'Default',
        text: {
          value: t('create_course_schedule.class_info.labels.location'),
        },
        textArea: {
          textValue: classFormCard.location,
          onTextChanged: handleTextChange(index, 'location'),
        },
        contextualContent: {
          text: {
            value: formErrorState.classes[index]?.location,
          },
        },
      },
      zoomLinkInputField: {
        state: formErrorState.classes[index]?.link ? 'Error' : 'Default',
        text: {
          value: t('create_course_schedule.class_info.labels.link'),
        },
        textArea: {
          textValue: classFormCard.link,
          onTextChanged: handleTextChange(index, 'link'),
        },
        contextualContent: {
          text: {
            value: formErrorState.classes[index]?.link,
          },
        },
      },
    };
  });

  return {
    ...props,
    stepperText: {
      value: t('stepperText', {
        currentStep: 2,
        totalSteps: 2,
      }),
    },
    textGroup: {
      topText: {
        value: t('create_course_schedule.class_info.step_title'),
      },
      bottomText: {
        value: t('create_course_schedule.class_info.step_description'),
      },
    },
    radioField: {
      labelText: {
        value: t('create_course_schedule.class_info.labels.class_format'),
      },
      radioItemList: {
        type: 'Horizontal',
        radioItems: radioItems,
      },
    },
    classCardsList: {
      classFormCards,
    },
    button: {
      text: {
        value: t('create_course_schedule.class_info.buttons.add_class'),
      },
      onClick: handleAddClass,
    },
    backButton: {
      icon: {
        asset: 'ArrowRight',
      },
      text: {
        value: t('create_course_schedule.class_info.buttons.back'),
      },
      onClick: handleBack,
    },
    createCourseButton: {
      icon: {
        asset: 'ArrowRight',
      },
      text: {
        value: course ? 
          t('create_course_schedule.class_info.buttons.save_course') : 
          t('create_course_schedule.class_info.buttons.create_course'),
      },
      loading: isLoading || isUpdating ? 'Loading' : 'Default',
      disabled: isLoading || isUpdating,
      onClick: handleCreateCourse,
    },
    deleteClassModal: {
      show: showDeleteClassWarningModal,
      onHide: closeDeleteClassWarningModal,
      modalProps: {
        type: 'Critical',
        button: {
          icon: {
            asset: 'Close',
          },
          onClick: closeDeleteClassWarningModal,
        },
        primaryButton: {
          text: {
            value: t('create_course_schedule.delete_class_warning.primary_button'),
          },
          onClick: handleRemoveClass,
        },
        secondaryButton: {
          text: {
            value: t('create_course_schedule.delete_class_warning.secondary_button'),
          },
          onClick: closeDeleteClassWarningModal,
        },
        text: {
          value: t('create_course_schedule.delete_class_warning.header'),
        },
        text1: {
          value: t('create_course_schedule.delete_class_warning.description'),
        },
      },
    },
  };
};

export default usePresenter;
