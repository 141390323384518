import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import InputField from '../../molecules/InputField';
import TextGroup from '../../molecules/TextGroup';

import styles from './CreatePasswordBlock.module.scss';
import usePresenter from './CreatePasswordBlock.presenter';
import getProps from './CreatePasswordBlock.props';
import { CreatePasswordBlockCombinedProps } from './types';


const CreatePasswordBlock: React.FC<CreatePasswordBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    textGroup,
    createPasswordField,
    confirmPasswordField,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.createPasswordBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <InputField
          className={cx(styles.createPasswordField, classes?.createPasswordField)}
          {...internalProps.createPasswordField}
          {...createPasswordField}/>
        <InputField
          className={cx(styles.confirmPasswordField, classes?.confirmPasswordField)}
          {...internalProps.confirmPasswordField}
          {...confirmPasswordField}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
    </div>
  );
};

export default CreatePasswordBlock;

