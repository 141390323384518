import React from 'react';
import cx from 'classnames';

import NavMenuItem from '../../atoms/NavMenuItem';

import styles from './NavMenuList.module.scss';
import usePresenter from './NavMenuList.presenter';
import getProps from './NavMenuList.props';
import { NavMenuListCombinedProps } from './types';


const NavMenuList: React.FC<NavMenuListCombinedProps> = (props) => {
  const {
    navMenuList,
    className,
    classes,
    navMenuItems,
    expand,     // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${navMenuList}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`navMenuList${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed':
    case 'Expanded': {
      const navMenuItemViews = navMenuItems?.map((navMenuItem, index) => (
        <NavMenuItem
          key={index}
          className={cx(styles.navMenuItem, classes?.navMenuItem)}
          {...internalProps.navMenuItem}
          expand={expand}     // MANUAL OVERRIDE
          {...navMenuItem} />
      )) || [];

      componentView = (
        <div className={cx(currentStyle, className)}>
          {navMenuItemViews}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default NavMenuList;

