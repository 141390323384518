import React from 'react';
import cx from 'classnames';

import ToggleItem from '../../atoms/ToggleItem';

import styles from './Toggle.module.scss';
import usePresenter from './Toggle.presenter';
import getProps from './Toggle.props';
import { ToggleCombinedProps } from './types';


const Toggle: React.FC<ToggleCombinedProps> = (props) => {
  const {
    className,
    classes,
    toggleItemOne,
    toggleItemTwo,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.toggle;

  return (
    <div className={cx(currentStyle, className)}>
      <ToggleItem
        className={cx(styles.toggleItemOne, classes?.toggleItemOne)}
        {...internalProps.toggleItemOne}
        {...toggleItemOne}/>
      <ToggleItem
        className={cx(styles.toggleItemTwo, classes?.toggleItemTwo)}
        {...internalProps.toggleItemTwo}
        {...toggleItemTwo}/>
    </div>
  );
};

export default Toggle;

