import { GenericActionBlockProps } from '../../blocks/GenericActionBlock';

type AlreadyRegisteredPageInternalProps = {
  genericActionBlock?: GenericActionBlockProps;
};

type AlreadyRegisteredPagePropsValues = {
  '': AlreadyRegisteredPageInternalProps;
};

const propValues: AlreadyRegisteredPagePropsValues = {
  '': {
    genericActionBlock: {
    },
  },
};

const getProps = (type: string): AlreadyRegisteredPageInternalProps => {
  const values: AlreadyRegisteredPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
