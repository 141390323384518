import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import TeacherStatusTag from '../../atoms/TeacherStatusTag';
import Text from '../../atoms/Text';
import LabelTextGroup from '../../molecules/LabelTextGroup';
import ActionSection from '../ActionSection';

import styles from './DashboardInfoSection.module.scss';
import usePresenter from './DashboardInfoSection.presenter';
import getProps from './DashboardInfoSection.props';
import { DashboardInfoSectionCombinedProps } from './types';


const DashboardInfoSection: React.FC<DashboardInfoSectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,
    text1,
    teacherStatusTag,
    labelTextGroup,
    actionSection,
    hideActionSection,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.dashboardInfoSection;

  const actionSectionView = hideActionSection ? undefined : (
    <ActionSection
    className={cx(styles.actionSection, classes?.actionSection)}
    {...internalProps.actionSection}
    {...actionSection}/>
  );

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <div className={cx(styles.status, classes?.status)}>
        <Text
          className={cx(styles.text1, classes?.text1)}
          {...internalProps.text1}
          {...text1}/>
        <TeacherStatusTag
          className={cx(styles.teacherStatusTag, classes?.teacherStatusTag)}
          {...internalProps.teacherStatusTag}
          {...teacherStatusTag}/>
      </div>
      <LabelTextGroup
        className={cx(styles.labelTextGroup, classes?.labelTextGroup)}
        {...internalProps.labelTextGroup}
        {...labelTextGroup}/>
      { actionSectionView }
    </div>
  );
};

export default DashboardInfoSection;

