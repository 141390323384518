import { TextProps } from '../Text';


type ToggleItemInternalProps = {
  text?: TextProps;
};

type ToggleItemPropsValues = {
  'Selected': ToggleItemInternalProps;
  'Unselected': ToggleItemInternalProps;
};

const propValues: ToggleItemPropsValues = {
  'Selected': {
    text: {
      style: 'Semibold',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  'Unselected': {
    text: {
      style: 'Semibold',
      align: 'Center',
      colour: 'Default',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): ToggleItemInternalProps => {
  const values: ToggleItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
