import { TabProps } from '../../atoms/Tab';


type TabsListInternalProps = {
  tab?: TabProps;
};

type TabsListPropsValues = {
  '': TabsListInternalProps;
};

const propValues: TabsListPropsValues = {
  '': {
    tab: {
      state: 'Unselected',
    },
  },
};

const getProps = (type: string): TabsListInternalProps => {
  const values: TabsListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
