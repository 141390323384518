import { ButtonProps } from '../../atoms/Button';
import { InlineNotificationProps } from '../../atoms/InlineNotification';
import { TextProps } from '../../atoms/Text';
import { CardInfoProps } from '../../molecules/CardInfo';
import { CustomAppsProps } from '../../molecules/CustomApps';
import { PriceListItemProps } from '../../molecules/PriceListItem';
import { RadioItemListProps } from '../RadioItemList';


type PurchaseStudentAppModalInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
  labelText?: TextProps;
  radioItemList?: RadioItemListProps;
  customApps?: CustomAppsProps;
  priceListItem?: PriceListItemProps;
  cardInfo?: CardInfoProps;
  primaryButton?: ButtonProps;
  primaryButton1?: ButtonProps;
  inlineNotification?: InlineNotificationProps; // MANUAL OVERRIDE
};

type PurchaseStudentAppModalPropsValues = {
  '': PurchaseStudentAppModalInternalProps;
};

const propValues: PurchaseStudentAppModalPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3', 
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    labelText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    radioItemList: {
      type: 'Horizontal',
    },
    customApps: {
      state: 'Expanded',
    },
    priceListItem: {
      type: 'Total',
    },
    cardInfo: {
    },
    primaryButton: {
      style: 'Contained',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    primaryButton1: {
      style: 'Outline',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    // MANUAL OVERRIDE
    inlineNotification: {
      type: 'Error',
    },
  },
};

const getProps = (type: string): PurchaseStudentAppModalInternalProps => {
  const values: PurchaseStudentAppModalInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
