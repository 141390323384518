import React from 'react';
import cx from 'classnames';

import GenericStateTwoBlock from '../../blocks/GenericStateTwoBlock';

import styles from './CongratulationsPage.module.scss';
import usePresenter from './CongratulationsPage.presenter';
import getProps from './CongratulationsPage.props';
import { CongratulationsPageCombinedProps } from './types';


const CongratulationsPage: React.FC<CongratulationsPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    genericStateTwoBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.congratulationsPage;

  return (
    <div className={cx(currentStyle, className)}>
      <GenericStateTwoBlock
        className={cx(styles.genericStateTwoBlock, classes?.genericStateTwoBlock)}
        {...internalProps.genericStateTwoBlock}
        {...genericStateTwoBlock}/>
    </div>
  );
};

export default CongratulationsPage;

