import React from 'react';
import cx from 'classnames';

import InputField from '../InputField';

import styles from './InputFieldGroup.module.scss';
import usePresenter from './InputFieldGroup.presenter';
import getProps from './InputFieldGroup.props';
import { InputFieldGroupCombinedProps } from './types';


const InputFieldGroup: React.FC<InputFieldGroupCombinedProps> = (props) => {
  const {
    className,
    classes,
    inputFieldOne,
    inputFieldTwo,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.inputFieldGroup;

  return (
    <div className={cx(currentStyle, className)}>
      <InputField
        className={cx(styles.inputFieldOne, classes?.inputFieldOne)}
        {...internalProps.inputFieldOne}
        {...inputFieldOne}/>
      <InputField
        className={cx(styles.inputFieldTwo, classes?.inputFieldTwo)}
        {...internalProps.inputFieldTwo}
        {...inputFieldTwo}/>
    </div>
  );
};

export default InputFieldGroup;

