import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';

import styles from './CourseRegistrationSubheader.module.scss';
import usePresenter from './CourseRegistrationSubheader.presenter';
import getProps from './CourseRegistrationSubheader.props';
import { CourseRegistrationSubheaderCombinedProps } from './types';


const CourseRegistrationSubheader: React.FC<CourseRegistrationSubheaderCombinedProps> = (props) => {
  const {
    className,
    classes,
    titleText,
    bodyText,
    button,
    divider,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.courseRegistrationSubheader;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.topContent, classes?.topContent)}>
          <div className={cx(styles.textContent, classes?.textContent)}>
            <Text
              className={cx(styles.titleText, classes?.titleText)}
              {...internalProps.titleText}
              {...titleText}/>
            <Text
              className={cx(styles.bodyText, classes?.bodyText)}
              {...internalProps.bodyText}
              {...bodyText}/>
          </div>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
    </div>
  );
};

export default CourseRegistrationSubheader;

