import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { TextIconGroupProps } from '../../molecules/TextIconGroup';
import { TextIconGroupListProps } from '../TextIconGroupList';


type ReminderEmailsSectionInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  textIconGroupList?: TextIconGroupListProps;
  divider1?: DividerProps;
  textIconGroup?: TextIconGroupProps;
};

type ReminderEmailsSectionPropsValues = {
  '': ReminderEmailsSectionInternalProps;
};

const propValues: ReminderEmailsSectionPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    textIconGroupList: {
    },
    divider1: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    textIconGroup: {
    },
  },
};

const getProps = (type: string): ReminderEmailsSectionInternalProps => {
  const values: ReminderEmailsSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
