import React from 'react';
import ModalWrapper, { ExportedModalProps } from '../../../modules/common/ModalWrapper';

import InternalGenericModal from './GenericModal';
import { 
  GenericModalProps, 
  GenericModalValueProps as InternalGenericModalValueProps,
} from './types';


export type { GenericModalProps };

export type GenericModalValueProps = ExportedModalProps<InternalGenericModalValueProps>;

const GenericModal: React.FC<GenericModalValueProps> = ({ className, onHide, show, modalProps }) => {
  return (
    <ModalWrapper
      className={className}
      content={<InternalGenericModal {...modalProps} />}
      onHide={onHide}
      show={show}
    />
  );
};

export default GenericModal;
