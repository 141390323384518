import { ChipRecord } from '../../atoms/Chip/types';

export const normalizeCoTeacherRepresentation = (coTeachers?: string[], primaryTeacher?: string): ChipRecord[] => {
  if (coTeachers) {
    const coTeachersChips = coTeachers.map((coTeacher) => {
      return {
        isValid: true,
        value: coTeacher,
      };
    });

    if (primaryTeacher) {
      return [{
        isValid: true,
        value: primaryTeacher,
        isPrimary: true,
      }, ...coTeachersChips];
    }
    return coTeachersChips;
  }
  return [];
};
