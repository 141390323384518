import React, { useContext } from 'react';
import {
  Switch,
  Redirect,
  RouteProps,
  Route,
} from 'react-router-dom';

import SignInLayout from './components/layouts/SignInLayout';
import HeaderSidenavLayout from './components/layouts/HeaderSidenavLayout';
import HeaderOnlyLayout from './components/layouts/HeaderOnlyLayout';
import BodyFooterLayout from './components/layouts/BodyFooterLayout';

import PaymentInfoModal from './components/organisms/PaymentInfoModal';
import ConfirmationModal from './components/organisms/ConfirmationModal';
import TrainingExtensionModal from './components/organisms/TrainingExtensionModal';
import PurchaseStudentAppModal from './components/organisms/PurchaseStudentAppModal';

import ProtectedRoute from './modules/auth/ProtectedRoute';
import { NotificationContainer } from './modules/notification';
import { ModalContext } from './modules/user/ModalContext';

import { ACCOUNT_STATUS_ROUTE, COURSE_ROUTES, DASHBOARD_ROUTE, NOT_FOUND_ROUTE, STUDENT_ROUTES } from './lib/constants';

import styles from './App.module.scss';
import NotFoundPage from './components/pages/NotFoundPage';

const routes = {
  home: DASHBOARD_ROUTE.dashboard,
  invalid: '/',
};

const InvalidRoute: React.FC<RouteProps> = () => <Redirect to={routes.home} />;

const App: React.FC<{}> = () => {
  const { modalState, paymentInfoModalState, onHideModal, closePaymentInfoModal } = useContext(ModalContext);
  return (
    <>
      <NotificationContainer className={styles.notificationContainer} />
      <Switch>
        {/* Public Routes */}
        <Route path={['/auth/', STUDENT_ROUTES.auth.updatedPassword]}>
          <SignInLayout className={styles.layout} />
        </Route>
        <Route path={STUDENT_ROUTES.courseRegistration}>
          <BodyFooterLayout className={styles.layout} />
        </Route>
        <Route path={STUDENT_ROUTES.auth.base}>
          <HeaderOnlyLayout className={styles.layout} />
        </Route>
        <Route path={NOT_FOUND_ROUTE}>
          <NotFoundPage/>
        </Route>

        {/* Protected Routes */}
        <ProtectedRoute path={[DASHBOARD_ROUTE.dashboard, COURSE_ROUTES.courses]}>
          <HeaderSidenavLayout className={styles.layout} />
        </ProtectedRoute>
        <ProtectedRoute path={[
          ACCOUNT_STATUS_ROUTE.congratulations, 
          ACCOUNT_STATUS_ROUTE.subscriptionExpired,
          STUDENT_ROUTES.auth.joinWaitlist,
          STUDENT_ROUTES.auth.joinWaitlistSuccess,
        ]}>
          <HeaderOnlyLayout />
        </ProtectedRoute>

        <InvalidRoute path={routes.invalid} />
      </Switch>

      {/* Modals */}
      <PaymentInfoModal
        className={styles.modalWrapper}
        onHide={closePaymentInfoModal}
        show={ paymentInfoModalState === 'PAYMENT' } />
      <ConfirmationModal 
        className={styles.modalWrapper}
        onHide={onHideModal}
        show={ modalState === 'CONFIRMATION' } />
      <TrainingExtensionModal
        className={styles.modalWrapper}
        onHide={onHideModal}
        show={ modalState === 'TRAINING_EXTENSION' || modalState === 'PURCHASE_ANNUAL_PLAN' } />
      <PurchaseStudentAppModal
        className={styles.modalWrapper}
        onHide={onHideModal}
        show={ modalState === 'PURCHASE_STUDENT_APPS' } />
    </>
  );
};

export default App;
