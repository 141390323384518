import { DetailsBlockProps } from '../../blocks/DetailsBlock';
import { CourseHeaderProps } from '../../organisms/CourseHeader';


type CourseDetailsPageInternalProps = {
  courseHeader?: CourseHeaderProps;
  detailsBlock?: DetailsBlockProps;
};

type CourseDetailsPagePropsValues = {
  '': CourseDetailsPageInternalProps;
};

const propValues: CourseDetailsPagePropsValues = {
  '': {
    courseHeader: {
    },
    detailsBlock: {
    },
  },
};

const getProps = (type: string): CourseDetailsPageInternalProps => {
  const values: CourseDetailsPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
