import { DataEntry } from '../../../lib/types';
import { getCourseLogsByCourseId } from '../../../modules/course/api';
import { createLogEntry, updateLog } from '../../../modules/log/api';
import {
  CreateLogEntryPayload, Log, LogEntry, SortableLogFields, UpdateLogPayload,
} from '../../../modules/log/types';

export const getCourseLogsByCourseIdUseCase = async (
  courseId: number, sortBy: SortableLogFields,
): Promise<DataEntry<Log>[]> => {
  const logs = await getCourseLogsByCourseId(courseId, sortBy);
  return logs.map((log) => {
    log.content.gratefulFor = log.content.gratefulFor ?
      JSON.parse(log.content.gratefulFor as string) as string[] : undefined;
    log.content.skills = log.content.skills ? 
      JSON.parse(log.content.skills as string) as string[] : undefined;
    return log;
  });
};

export const updateLogUseCase = (logUuid: string, payload: UpdateLogPayload): Promise<DataEntry<Log>> => {
  return updateLog(logUuid, payload);
};

export const createLogEntryUseCase = (
  logUuid: string, payload: CreateLogEntryPayload,
): Promise<DataEntry<LogEntry>> => {
  return createLogEntry(logUuid, payload);
};
