import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { StaffItemProps } from '../../molecules/StaffItem';


type WelcomeBlockInternalProps = {
  stepperText?: TextProps;
  leftText?: TextProps;
  rightText?: TextProps;
  staffItem?: StaffItemProps;
  divider?: DividerProps;
  bodyText?: TextProps;
  noteText?: TextProps;
  button?: ButtonProps;
};

type WelcomeBlockPropsValues = {
  '': WelcomeBlockInternalProps;
};

const propValues: WelcomeBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Center',
      colour: 'Default',
      type: 'Body2',
    },
    leftText: {
      style: 'Bold',
      align: 'Right',
      colour: 'Default',
      type: 'Heading1',
    },
    rightText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Brand',
      type: 'Heading1',
    },
    staffItem: {
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    bodyText: {
      style: 'Regular',
      align: 'Center',
      colour: 'Subdued',
      type: 'Body1',
    },
    noteText: {
      style: 'Bold',
      align: 'Center',
      colour: 'Default',
      type: 'Body2',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): WelcomeBlockInternalProps => {
  const values: WelcomeBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
