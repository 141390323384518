import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useTranslation, Trans } from 'react-i18next';

import { ModalContext } from '../../../modules/user/ModalContext';
import Congratulations from '../../../resources/images/Congratulations.png';
import { DASHBOARD_ROUTE, KORU_SUPPORT_URL, KORU_TEACHER_ANNUAL_PLAN_URL } from '../../../lib/constants';
import { UserContext } from '../../../modules/user/UserContext';
import { getTranslation } from '../../../lib/reactUtils';

import { CongratulationsPageCombinedProps } from './types';
import styles from './CongratulationsPage.module.scss';
import { trackViewPurchaseAnnualPlanModal } from '../../../lib/trackingUtils';

const getDescriptionWithLinks = (): React.ReactNode => {
  return (
    <Trans
      i18nKey='congratulations.bottomText1'
      components={{
        firstLink: (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            className={styles.link}
            target='_blank'
            rel='noreferrer noopener'
            href={KORU_TEACHER_ANNUAL_PLAN_URL}/>
        ),
        secondLink: (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            className={styles.link}
            target='_blank'
            rel='noreferrer noopener'
            href={KORU_SUPPORT_URL}/>
        ),
        br: <br/>,
      }}
    />
  );
};

const usePresenter = (props: CongratulationsPageCombinedProps): CongratulationsPageCombinedProps => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { setModalState } = useContext(ModalContext);
  const { t } = useTranslation();

  const handleClick = () => {
    history.push(DASHBOARD_ROUTE.profile);
  };

  const handlePurchaseAnnualPlan = () => {
    trackViewPurchaseAnnualPlanModal();
    setModalState('PURCHASE_ANNUAL_PLAN');
  };

  return {
    ...props,
    genericStateTwoBlock: {
      image: {
        imageSrc: Congratulations,
      },
      topText: {
        value: t('congratulations.title', {
          name: user && user.content.firstName,
        }),
      },
      bottomText: {
        value: t('congratulations.bottomText'),
      },
      topText1: {
        value: getTranslation('congratulations.subtitle'),
      },
      bottomText1: {
        value: getDescriptionWithLinks(),
      },
      button: {
        text: {
          value: t('congratulations.annual_plan'),
        },
        onClick: handlePurchaseAnnualPlan,
      },
      button1: {
        text: {
          value: t('congratulations.skip_link'),
        },
        onClick: handleClick,
        className: styles.skipLink,
      },
    },
  };
};

export default usePresenter;
