import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Divider from '../../atoms/Divider';

import styles from './PriceListItem.module.scss';
import usePresenter from './PriceListItem.presenter';
import getProps from './PriceListItem.props';
import { PriceListItemCombinedProps } from './types';


const PriceListItem: React.FC<PriceListItemCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    descriptionText,
    divider,
    amountText,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`priceListItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default':
    case 'Total': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.descriptionText, classes?.descriptionText)}
            {...internalProps.descriptionText}
            {...descriptionText}/>
          <div className={cx(styles.content, classes?.content)}>
            <Divider
              className={cx(styles.divider, classes?.divider)}
              {...internalProps.divider}
              {...divider}/>
          </div>
          <Text
            className={cx(styles.amountText, classes?.amountText)}
            {...internalProps.amountText}
            {...amountText}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default PriceListItem;

