import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';

import styles from './LabelTextGroup.module.scss';
import usePresenter from './LabelTextGroup.presenter';
import getProps from './LabelTextGroup.props';
import { LabelTextGroupCombinedProps } from './types';


const LabelTextGroup: React.FC<LabelTextGroupCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    labelText,
    infoText,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`labelTextGroup${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Horizontal':
    case 'Vertical': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.labelText, classes?.labelText)}
            {...internalProps.labelText}
            {...labelText}/>
          <Text
            className={cx(styles.infoText, classes?.infoText)}
            {...internalProps.infoText}
            {...infoText}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default LabelTextGroup;

