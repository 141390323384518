import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';


type TeacherDirectorySectionInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  text1?: TextProps;
  button?: ButtonProps;
};

type TeacherDirectorySectionPropsValues = {
  '': TeacherDirectorySectionInternalProps;
};

const propValues: TeacherDirectorySectionPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    text1: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Outline',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
    },
  },
};

const getProps = (type: string): TeacherDirectorySectionInternalProps => {
  const values: TeacherDirectorySectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
