import { DateTimePickerFieldCombinedProps } from './types';

const usePresenter = (props: DateTimePickerFieldCombinedProps): DateTimePickerFieldCombinedProps => {
  return { 
    ...props,
    flatpickr: {
      ...props.flatpickr,
      options: {
        ...props.flatpickr?.options,
        wrap: true, // Requires this prop to be enable using a text input as a children component
      },
    },
  };
};

export default usePresenter;
