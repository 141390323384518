import { DataEntry } from '../../../lib/types';
import { Course, RegistrationFlowEnum } from '../../../modules/course/types';
import { ButtonProps, ButtonValueProps } from '../../atoms/Button';
import { InlineNotificationValueProps } from '../../atoms/InlineNotification';
import { TextValueProps } from '../../atoms/Text';
import { InputFieldProps, InputFieldValueProps } from '../../molecules/InputField';
import { TextGroupValueProps } from '../../molecules/TextGroup';

export type StudentSignInBlockClasses = {
  topContent?: string;
  stepperText?: string;
  textGroup?: string;
  middleContent?: string;
  emailField?: string;
  passwordField?: string;
  button?: string;
  bottomContent?: string;
  button1?: string;
  inlineNotification?: string;
};

export type StudentSignInBlockValueProps = {
  stepperText?: TextValueProps;
  textGroup?: TextGroupValueProps;
  emailField?: InputFieldValueProps & InputFieldProps;
  passwordField?: InputFieldValueProps & InputFieldProps;
  button?: ButtonValueProps & ButtonProps;
  button1?: ButtonValueProps & ButtonProps;
  inlineNotification?: InlineNotificationValueProps;
  showNotification?: boolean;
  registerFlow?: RegistrationFlowEnum;
  course?: DataEntry<Course>;
  isCourseLoading?: boolean;
};

export type StudentSignInBlockProps = {
  className?: string;
  classes?: StudentSignInBlockClasses;
};

export type SignInState = {
  email: string;
  password: string;
};

export enum NotificationMsg {
  migrationWarning = 'migration_warning',
  invalidCreds = 'invalid_email_or_password',
  expiredSession = 'expired_ession',
}

export type StudentSignInBlockCombinedProps = StudentSignInBlockValueProps & StudentSignInBlockProps;
