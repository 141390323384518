import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';

import styles from './CopyLinkSection.module.scss';
import usePresenter from './CopyLinkSection.presenter';
import getProps from './CopyLinkSection.props';
import { CopyLinkSectionCombinedProps } from './types';


const CopyLinkSection: React.FC<CopyLinkSectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,
    bodyText,
    button,
    freeLinkButton,
    hasFreeApps,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.copyLinkSection;

  const copyFreeLinkButtonView = hasFreeApps ? (
    <Button
      className={cx(styles.button, classes?.button)}
      {...internalProps.freeLinkButton}
      {...freeLinkButton}/>
  ) : undefined;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <Text
        className={cx(styles.bodyText, classes?.bodyText)}
        {...internalProps.bodyText}
        {...bodyText}/>
      <Button
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button}/>
      { copyFreeLinkButtonView }
    </div>
  );
};

export default CopyLinkSection;

