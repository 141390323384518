import { TextProps } from '../../atoms/Text';
import { DividerProps } from '../../atoms/Divider';
import { ProductListProps } from '../ProductList';


type PaymentHistorySectionInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  dateText?: TextProps;
  amountText?: TextProps;
  productNameText?: TextProps;
  linkText?: TextProps;
  productList?: ProductListProps;
};

type PaymentHistorySectionPropsValues = {
  '': PaymentHistorySectionInternalProps;
};

const propValues: PaymentHistorySectionPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    dateText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    amountText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    productNameText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    linkText: {
      style: 'Bold',
      align: 'Right',
      colour: 'Default',
      type: 'Body1',
    },
    productList: {
    },
  },
};

const getProps = (type: string): PaymentHistorySectionInternalProps => {
  const values: PaymentHistorySectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
