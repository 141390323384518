import { ButtonProps } from '../../atoms/Button';
import { InlineNotificationProps } from '../../atoms/InlineNotification';
import { InputFieldProps } from '../../molecules/InputField';
import { TextGroupProps } from '../../molecules/TextGroup';


type SigninBlockInternalProps = {
  textGroup?: TextGroupProps;
  emailField?: InputFieldProps;
  passwordField?: InputFieldProps;
  button?: ButtonProps;
  button1?: ButtonProps;
  inlineNotification?: InlineNotificationProps;
};

type SigninBlockPropsValues = {
  '': SigninBlockInternalProps;
};

const propValues: SigninBlockPropsValues = {
  '': {
    textGroup: {
    },
    emailField: {
      state: 'Default',
    },
    passwordField: {
      state: 'Default',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'PrimaryAction',
    },
    button1: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
    },
    inlineNotification: {
      type: 'Error',
    },
  },
};

const getProps = (type: string): SigninBlockInternalProps => {
  const values: SigninBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
