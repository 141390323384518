import { InitialsProps } from '../../atoms/Initials';
import { TextProps } from '../../atoms/Text';


type TextMessageInternalProps = {
  text?: TextProps;
  initials?: InitialsProps;
  text1?: TextProps;
};

type TextMessagePropsValues = {
  'CoteacherLeft': TextMessageInternalProps;
  'StudentRight': TextMessageInternalProps;
  'TeacherLeft': TextMessageInternalProps;
};

const propValues: TextMessagePropsValues = {
  'CoteacherLeft': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    initials: {
      size: '20',
      colour: 'Green',
    },
    text1: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body2',
    },
  },
  'StudentRight': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    initials: {
      size: '20',
      colour: 'Green',
    },
    text1: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body2',
    },
  },
  'TeacherLeft': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    initials: {
      size: '20',
      colour: 'Green',
    },
    text1: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): TextMessageInternalProps => {
  const values: TextMessageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
