import TagManager, { TagManagerArgs } from 'react-gtm-module';

import { GTM_ID } from './config';
import { GTM_EVENTS } from './constants';
import { DefaultMetrics } from './types';

export const initializeTracking = (): void => {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: GTM_ID,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  TagManager.initialize(tagManagerArgs);
};

export const trackDataLayer = (data) => {
  TagManager.dataLayer({
    dataLayer: {
      ...data,
    },
  });
};

export const trackDefaultMetrics = (data: DefaultMetrics) => {
  trackDataLayer(data);
};

export const trackSuccessfulTuitionFeePayment = () => {
  trackDataLayer({
    event: GTM_EVENTS.successfulTuitionPayment,
  });
};

export const trackStudentAppsPurchase = (amount: string) => {
  trackDataLayer({
    event: GTM_EVENTS.studentAppsPurchase,
    amount,
  });
};

export const trackSuccessfulEmailVerification = () => {
  trackDataLayer({
    event: GTM_EVENTS.successfulEmailVerification,
  });
};

export const trackSuccessfulCourseCreation = () => {
  trackDataLayer({
    event: GTM_EVENTS.successfulCourseCreation,
  });
};

export const trackViewPurchaseStudentAppsModal = () => {
  trackDataLayer({
    event: GTM_EVENTS.viewPurchaseStudentAppsModal,
  });
};

export const trackViewPurchaseAnnualPlanModal = () => {
  trackDataLayer({
    event: GTM_EVENTS.viewPurchaseAnnualPlanModal,
  });
};

export const trackAnnualPlanPurchase = () => {
  trackDataLayer({
    event: GTM_EVENTS.annualPlanPurchase,
  });
};

export const trackSuccessfulCourseRegistrationFree = () => {
  trackDataLayer({
    event: GTM_EVENTS.successfulCourseRegistrationFree,
  });
};

export const trackSuccessfulCourseRegistrationPaid = () => {
  trackDataLayer({
    event: GTM_EVENTS.successfulCourseRegistrationPaid,
  });
};
