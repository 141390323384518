import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useHistory } from 'react-router';

import { 
  COURSE_DATE_FORMAT_OPTIONS, COURSE_ROUTES, COURSE_TIME_FORMAT_OPTIONS, NOTIFICATION_DURATION,
} from '../../../lib/constants';
import { TabStateEnum } from '../../atoms/Tab/types';
import { TextValueProps } from '../../atoms/Text';
import { ContextualMenuItemCombinedProps } from '../../atoms/ContextualMenuItem/types';
import EmptyCourseList from '../../../resources/images/EmptyCourseList.png';
import Koru2Image from '../../../resources/images/Koru2.png';
import KoruBasicImage from '../../../resources/images/KoruBasic.png';
import KoruRetreatImage from '../../../resources/images/KoruRetreat.png';
import {
  CourseCardValueProps,
} from '../../molecules/CourseCard';
import { formatDate, getClassIcons } from '../../../lib/utils';
import { getTranslation } from '../../../lib/reactUtils';
import { CourseCardCombinedProps } from '../../molecules/CourseCard/types';
import { Course, CourseState, CourseTypeEnum } from '../../../modules/course/types';
import { useNotification } from '../../../modules/notification';

import styles from './CoursesPage.module.scss';
import { CourseModalState, CoursesPageCombinedProps } from './types';
import { getCoursesUseCase, updateCourseUseCase } from './CoursesPage.interactor';
import { getNextAvailableClassIndex, normalizeCourseUpdateSteps } from './utils';
import { DataEntry } from '../../../lib/types';

const getArchiveCourseWarningModalDescription = (): React.ReactNode => {
  return (
    <Trans
      i18nKey="active_courses.archive_course.description"
      components={{
        bold: <div className={styles.warningText} />,
        br: <br />,
        ol: <ol className={styles.warningList} />,
        li: <li />,
        warning: <div className={styles.warning} />,
      }}
    />
  );
};

const usePresenter = (props: CoursesPageCombinedProps): CoursesPageCombinedProps => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const { trigger } = useNotification();
  const queryClient = useQueryClient();

  const [selectedCourse, setSelectedCourse] = useState<DataEntry<Course> | undefined>(undefined);
  const [courseModalState, setCourseModalState] = useState<CourseModalState>(CourseModalState.hidden);

  const closeCourseModal = () => {
    setCourseModalState(CourseModalState.hidden);
  };

  const handleOpenCloseRegistrationMenuClick = (course: DataEntry<Course>, event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      event.stopPropagation();
    }
    setSelectedCourse(course);
    setCourseModalState(CourseModalState.updateRegistrationStatus);
  };

  const handleArchiveCourseMenuClick = (course: DataEntry<Course>, event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      event.stopPropagation();
    }
    setSelectedCourse(course);
    setCourseModalState(CourseModalState.archive);
  };

  const handleAddCourse = () => {
    history.push(COURSE_ROUTES.newCourse.step1);
  };

  const handleCourseDuplicationMenuClick = (course: DataEntry<Course>, event?: React.MouseEvent<HTMLElement>) => {
    try {
      if (event) {
        event.stopPropagation();
        history.push(COURSE_ROUTES.newCourse.step1, normalizeCourseUpdateSteps(course));
      }
    } catch {
      // no-op
    }
  };

  const addNewCourseCard: CourseCardCombinedProps = {
    type: 'CreateNew',
    button2: {
      icon: {
        asset: 'AddSquareFilled',
        className: styles.iconTest,
      },
      text: {
        value: t('course_card.buttons.create_new_course'),
        className: styles.buttonText,
      },
      onClick: handleAddCourse,
    },
  };

  const [courseList, setCourseList] = useState<CourseCardValueProps[]>([]);

  const { data: courses, isLoading } = useQuery(['getActiveCourses'], () => {
    return getCoursesUseCase(CourseState.Active);
  });

  const { mutateAsync: updateRegistrationStatus, isLoading: registrationLoading } = useMutation(['updateRegistrationStatus', selectedCourse],
    async () => {
      if (selectedCourse) {
        return updateCourseUseCase(selectedCourse.content.id, {
          registrationOpen: !selectedCourse.content.registrationOpen,
        });
      }
    },
  );

  const { mutateAsync: archiveCourse, isLoading: archiveCourseLoading } = useMutation(['archiveCourse', selectedCourse],
    async () => {
      if (selectedCourse) {
        return updateCourseUseCase(selectedCourse.content.id, {
          archived: true,
          registrationOpen: false,
        });
      }
    },
  );

  useEffect(() => {
    if (courses?.length) {
      const courseListProps: (CourseCardCombinedProps)[] = [];
      courses.forEach((course) => {
        const { classes, courseType, classCapacity, classFormat, enrolledStudents } = course.content;
        let courseBackgroundImage;
        switch (courseType) {
          case CourseTypeEnum.koru_two:
            courseBackgroundImage = Koru2Image;
            break;

          case CourseTypeEnum.koru_basic:
            courseBackgroundImage = KoruBasicImage;
            break;

          case CourseTypeEnum.koru_retreat:
            courseBackgroundImage = KoruRetreatImage;
            break;
        
          default:
            // no-op
            break;
        }
        
        if (classes?.length && classes[0]) {
          const nextAvailableClassIndex = getNextAvailableClassIndex(classes);
          const { date, startTime, endTime, location, classLink } = classes[nextAvailableClassIndex].content;
          const icons = getClassIcons(classFormat, location, classLink);
          courseListProps.push({
            type: 'Course',
            courseBackgroundImage,
            registrationStatusTag: {
              text: {
                value: course.content.registrationOpen
                  ? t('course_card.registration_status.open')
                  : t('course_card.registration_status.closed'),
              },
            },
            iconDropdown: {
              toggleProps: {
                button: {
                  icon: {
                    asset: 'VerticalMenu',
                    colour: 'Inverse',
                  },
                },
              },
              menuProps: {
                contextualMenuList: {
                  contextualMenuItems: [
                    {
                      text: {
                        colour: 'Default',
                        value: t(`course_card.context_menu.registration.${
                          course?.content?.registrationOpen ? 'close' : 'open'
                        }`),
                      } as TextValueProps,
                      onClick: (event) => handleOpenCloseRegistrationMenuClick(course, event),
                    },
                    {
                      text: {
                        colour: 'Default',
                        value: t('course_card.context_menu.duplicate_course'),
                      } as TextValueProps,
                      onClick: (event) => handleCourseDuplicationMenuClick(course, event),
                    },
                    {
                      type: 'Critical',
                      text: {
                        value: t('course_card.context_menu.archive_course'),
                      },
                      onClick: (event) => handleArchiveCourseMenuClick(course, event),
                    } as ContextualMenuItemCombinedProps,
                  ],
                },
                className: styles.contextualMenu,
              },
            },
            text: {
              value: course.content.title,
              classes: {
                value: styles.title,
              },
            },
            classItem: {
              text: {
                value: t('course_card.class_name', {
                  classNumber: `${nextAvailableClassIndex + 1}`, 
                }),
              },
              iconTextGroupList: {
                iconTextGroups: [
                  {
                    labelIcon: {
                      asset: 'Calendar',
                    },
                    infoText: {
                      value: formatDate(date, COURSE_DATE_FORMAT_OPTIONS),
                    },
                  },
                  {
                    labelIcon: {
                      asset: 'Clock',
                    },
                    infoText: {
                      value: `${formatDate(startTime, COURSE_TIME_FORMAT_OPTIONS)} - ${formatDate(endTime, COURSE_TIME_FORMAT_OPTIONS)}`,
                    },
                  },
                  ...icons,
                ],
              },
            },
            iconTextGroup: {
              labelIcon: {
                asset: 'People',
              },
              infoText: {
                value: t('course_card.class_capacity', {
                  // TODO: fetch current number of student enrolled for course
                  currentEnrolled: enrolledStudents || 0,
                  totalCapacity: classCapacity,
                }),
              },
            },
            button1: {
              icon: {
                asset: 'ArrowRight',
              },
              text: {
                value: t('course_card.buttons.view_course'),
              },
              onClick: () => history.push(`/courses/detail/${course.content.id}`),
            },
            handleOnClick: () => history.push(`/courses/detail/${course.content.id}`),
          });
        }
        return;
      });
      setCourseList([addNewCourseCard, ...courseListProps]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses, t]);

  const getTabState = (tabPathname: string): TabStateEnum => {
    return pathname === tabPathname ? 'Selected' : 'Unselected';
  };

  const handleUpdateRegistration = async () => {
    try {
      const updatedCourse = await updateRegistrationStatus();
      if (updatedCourse) {
        setCourseModalState(CourseModalState.hidden);
        queryClient.setQueryData('getActiveCourses', (old: DataEntry<Course>[] | undefined): DataEntry<Course>[] => (
          old ? old.map(oldCourse => {
            if (updatedCourse.content.id === oldCourse.content.id) {
              return { 
                ...oldCourse, 
                content: { 
                  ...oldCourse.content,
                  registrationOpen: updatedCourse.content.registrationOpen,
                },
              };
            }
            return oldCourse;
          }) : []
        ));
        trigger({
          type: 'Success',
          duration: NOTIFICATION_DURATION,
          message: t('notification.course_updated'),
        });
      }
    } catch {
      // no-op
    }
  };

  const handleArchiveCourse = async () => {
    try {
      const updatedCourse = await archiveCourse();
      if (updatedCourse) {
        setCourseModalState(CourseModalState.hidden);
        trigger({
          type: 'Success',
          duration: NOTIFICATION_DURATION,
          message: t('notification.course_updated'),
        });
        history.replace(COURSE_ROUTES.archived);
      }
    } catch {
      // no-op
    }
  };

  return {
    ...props,
    isLoading,
    type: courses?.length ? 'Active' : 'Empty',
    coursesHeader: {
      text: {
        value: t('course_header.title'),
      },
      tabsList: {
        tabs: [
          {
            text: {
              value: t('course_header.tablist.active'),
            },
            state: getTabState(COURSE_ROUTES.active),
            onClick: () => history.push(COURSE_ROUTES.active),
          },
          {
            text: {
              value: t('course_header.tablist.archived'),
            },
            state: getTabState(COURSE_ROUTES.archived),
            onClick: () => history.push(COURSE_ROUTES.archived),
          },
        ],
      },
    },
    coursesBlock: {
      courseCardsList: {
        courseCards: courseList,
      },
    },
    genericStateThreeBlock: {
      image: {
        imageSrc: EmptyCourseList,
      },
      topText: {
        value: t('active_courses.empty.title'),
      },
      button: {
        text: {
          value: t('active_courses.empty.button'),
        },
        onClick: handleAddCourse,
      },
    },
    updateRegistrationStatusWarningModal: {
      show: courseModalState === CourseModalState.updateRegistrationStatus,
      onHide: closeCourseModal,
      modalProps: {
        type: selectedCourse?.content?.registrationOpen ? 'Critical' : 'Default',
        button: {
          icon: {
            asset: 'Close',
          },
          onClick: closeCourseModal,
        },
        primaryButton: {
          text: {
            value: t(`active_courses.open_close_registration_modal.primary_button.${
              selectedCourse?.content?.registrationOpen ? 'close' : 'open'
            }`),
          },
          onClick: handleUpdateRegistration,
          loading: registrationLoading ? 'Loading' : 'Default',
          disabled: registrationLoading,
        },
        secondaryButton: {
          text: {
            value: t('active_courses.open_close_registration_modal.secondary_button'),
          },
          onClick: closeCourseModal,
        },
        text: {
          value: t('active_courses.open_close_registration_modal.title'),
        },
        text1: {
          value: getTranslation(`active_courses.open_close_registration_modal.description.${
            selectedCourse?.content?.registrationOpen ? 'close' : 'open'
          }`),
        },
      },
    },
    archiveCourseWarningModal: {
      show: courseModalState === CourseModalState.archive,
      onHide: closeCourseModal,
      modalProps: {
        type: 'Critical',
        button: {
          icon: {
            asset: 'Close',
          },
          onClick: closeCourseModal,
        },
        primaryButton: {
          text: {
            value: t('active_courses.archive_course.primary_button'),
          },
          onClick: handleArchiveCourse,
          loading: archiveCourseLoading ? 'Loading' : 'Default',
          disabled: archiveCourseLoading,
        },
        secondaryButton: {
          text: {
            value: t('active_courses.archive_course.secondary_button'),
          },
          onClick: closeCourseModal,
        },
        text: {
          value: t('active_courses.archive_course.title'),
        },
        text1: {
          value: getArchiveCourseWarningModalDescription(),
        },
      },
    },
  };
};

export default usePresenter;
