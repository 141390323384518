import { GenericStateTwoBlockProps } from '../../blocks/GenericStateTwoBlock';


type CongratulationsPageInternalProps = {
  genericStateTwoBlock?: GenericStateTwoBlockProps;
};

type CongratulationsPagePropsValues = {
  '': CongratulationsPageInternalProps;
};

const propValues: CongratulationsPagePropsValues = {
  '': {
    genericStateTwoBlock: {
    },
  },
};

const getProps = (type: string): CongratulationsPageInternalProps => {
  const values: CongratulationsPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
