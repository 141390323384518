import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  KORU_TEACHER_ANNUAL_PLAN_URL,
  KORU_TEACHER_SUBMIT_INFO_URL,
  MAX_PURCHASED_EXTENSIONS,
  NOTIFICATION_DURATION,
} from '../../../lib/constants';

import { acceptPhoneNumber, formatDate, isEmptyString, preprocessFirstAndLastName } from '../../../lib/utils';
import { AuthContext } from '../../../modules/auth';
import { ModalContext } from '../../../modules/user/ModalContext';
import { UserContext } from '../../../modules/user/UserContext';
import { JourneyStatusEnum } from '../../../modules/teacher/types';

import { AddressForm } from '../../molecules/Address/types';
import { initAddressFormState } from '../../molecules/Address/utils';
import { updateTeacherUseCase } from './MyProfileBlock.interactor';
import { MyProfileBlockCombinedProps } from './types';
import styles from './MyProfileBlock.module.scss';
import { trackViewPurchaseAnnualPlanModal } from '../../../lib/trackingUtils';
import { useNotification } from '../../../modules/notification';

type CompleteProfileForm = AddressForm & {
  phoneNumber: string;
  firstName: string;
  lastName: string;
};

const initFormState: CompleteProfileForm = {
  ...initAddressFormState,
  phoneNumber: '',
  firstName: '',
  lastName: '',
};

const compareAddress = (address1: AddressForm, address2: AddressForm): boolean => {
  return Object.keys(address1).every((key) => address1[key] === address2[key]);
};

const usePresenter = (props: MyProfileBlockCombinedProps): MyProfileBlockCombinedProps => {
  const { t } = useTranslation();
  const { user, defaultPaymentInfo, isExpired } = useContext(UserContext);
  const { account, refetchAccount }  = useContext(AuthContext);
  const { setModalState } = useContext(ModalContext);
  const { trigger } = useNotification();

  const [formState, setFormState] = useState<CompleteProfileForm>(initFormState);
  const [addressFormState, setAddressFormState] = useState<AddressForm>(initAddressFormState);
  const [formErrorState, setFormErrorState] = useState<CompleteProfileForm>(initFormState);
  const [validateAddress, setValidateAddress] = useState<boolean>();
  const [isAddressValid, setIsAddressValid] = useState<boolean>();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  const getDescriptionWithLinks = (): React.ReactNode => {
    return (
      <Trans
        i18nKey={`my_profile.dashboard_info.action_section.${user?.content?.journeyStatus}.description`}
        components={{
          firstLink: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              className={styles.link}
              rel='noreferrer noopener'
              href={KORU_TEACHER_ANNUAL_PLAN_URL}/>
          ),
          br: <br/>,
        }}
      />
    );
  };

  useEffect(() => {
    // To update data only if user has no unsaved changes
    if (!isFormDirty && user) {
      const { firstName, lastName, profile } = user.content;
      setFormState({
        ...formState,
        phoneNumber: profile?.phoneNumber || formState.phoneNumber,
        firstName: firstName || formState.firstName,
        lastName: lastName || formState.lastName,
      });

      setAddressFormState({
        city: profile?.city || formState.city,
        country: profile?.country || formState.country,
        postalCode: profile?.postalCode || formState.postalCode,
        province: profile?.province || formState.province,
        streetAddress: profile?.streetAddress || formState.streetAddress,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const { mutateAsync: updateTeacher } = useMutation(['updateTeacher', user?.uuid], async () => {
    if (user) {
      return updateTeacherUseCase(user.uuid, {
        firstName: formState.firstName,
        lastName: formState.lastName,
        profile: JSON.stringify({
          city: formState.city,
          province: formState.province,
          country: formState.country,
          streetAddress: formState.streetAddress,
          postalCode: formState.postalCode,
          phoneNumber: formState.phoneNumber,
        }),
      });
    }
  });

  useEffect(() => {
    if (user?.content?.journeyStatus === 'In-Training') {
      if (user?.content?.purchasedExtensions) {
        setButtonDisabled(user.content.purchasedExtensions >= MAX_PURCHASED_EXTENSIONS);
      }
    } else {
      setButtonDisabled(false);
    }
  }, [user?.content?.purchasedExtensions, user?.content?.journeyStatus]);

  const handleAddressForm = (address: AddressForm) => {
    const { city, country, postalCode, province, streetAddress } = address;
    setFormState({
      ...formState,
      city,
      country,
      postalCode,
      province,
      streetAddress,
    });
    // only if address is updated with new value
    if (!isFormDirty && !compareAddress(addressFormState, address)) {
      setIsFormDirty(true);
    }
  };

  const handleAddressFormError = (addressError: AddressForm) => {
    setFormErrorState({
      ...formErrorState,
      ...addressError,
    });
  };

  const handleTextChange = (field: keyof CompleteProfileForm) => {
    return (value: string) => {
      setFormState({
        ...formState,
        [field]: value,
      });

      setIsFormDirty(true);

      setFormErrorState({
        ...formErrorState,
        [field]: '',
      });
    };
  };

  const isFormValid = (): boolean => {
    const newFormErrorState = {
      ...formErrorState,
      phoneNumber: isEmptyString(formState.phoneNumber) ? t('error.field_required') : '',
      firstName: isEmptyString(formState.firstName) ? t('error.field_required') : '',
      lastName: isEmptyString(formState.lastName) ? t('error.field_required') : '',
    };

    setFormErrorState(newFormErrorState);
    return (
      Object.values(newFormErrorState).filter((errorMessage: string) => !!errorMessage)
        .length === 0
    );
  };

  const handleUpdateProfile = async () => {
    setValidateAddress(!validateAddress);
    try {
      if (isFormValid() && isAddressValid) {
        await updateTeacher();
        refetchAccount();
        trigger({
          type: 'Success',
          duration: NOTIFICATION_DURATION,
          message: t('my_profile.basic_info.labels.toast'),
        });
      }
    } catch (e) {
      // no-op
      // should display notification with error message to help user navigate the error
    }
  };

  const handleSubmitInfo = () => {
    window.location.assign(KORU_TEACHER_SUBMIT_INFO_URL);
  };

  const handlePurchaseModal = () => {
    if (user?.content?.journeyStatus === 'In-Training') {
      setModalState('TRAINING_EXTENSION');
    } else {
      trackViewPurchaseAnnualPlanModal();
      setModalState('PURCHASE_ANNUAL_PLAN');
    }
  };

  const getExpiryDate = () => {
    if (user?.content?.expiryDate && user.content.journeyStatus !== JourneyStatusEnum.under_review) {
      return formatDate(user.content.expiryDate);
    }
    return '';
  };
  
  return {
    ...props,
    dashboardInfoSection: {
      text: {
        value: t('my_profile.dashboard_info.title'),
      },
      text1: {
        value: t('my_profile.dashboard_info.labels.status'),
      },
      teacherStatusTag: {
        type: user?.content?.journeyStatus,
        text: {
          value: user?.content?.journeyStatus?.toUpperCase(),
        },
      },
      labelTextGroup: {
        labelText: {
          value: user?.content?.subscriptionAutoRenew && defaultPaymentInfo
            ? t('my_profile.dashboard_info.labels.auto_renewal_date') : t('my_profile.dashboard_info.labels.expiration_date'),
        },
        infoText: {
          value: getExpiryDate(),
        },
      },
      hideActionSection: user?.content?.journeyStatus === JourneyStatusEnum.under_review,
      actionSection: {
        topText: {
          value: t(`my_profile.dashboard_info.action_section.${user?.content?.journeyStatus}.title`),
        },
        bottomText: {
          value: getDescriptionWithLinks(),
        },
        button: {
          text: {
            value: t(`my_profile.dashboard_info.button.${user?.content?.journeyStatus}`),
          },
          loading: 'Default',
          disabled: buttonDisabled,
          onClick: handlePurchaseModal,
        },
      },
    },
    basicInfoSection: {
      text: {
        value: t('my_profile.basic_info.title'),
      },
      inputFieldGroup: {
        inputFieldOne: {
          state: formErrorState.firstName ? 'Error' : 'Default',
          text: {
            value: t('my_profile.basic_info.labels.first_name'),
          },
          textInput: {
            textValue: formState.firstName, 
            onTextChanged: handleTextChange('firstName'),
            preProcessTextInput: preprocessFirstAndLastName,
          },
          contextualContent: {
            text: {
              value: formErrorState.firstName,
            },
          },
        },
        inputFieldTwo: {
          state: formErrorState.lastName ? 'Error' : 'Default',
          text: {
            value: t('my_profile.basic_info.labels.last_name'),
          },
          textInput: {
            textValue: formState.lastName,
            onTextChanged: handleTextChange('lastName'),
            preProcessTextInput: preprocessFirstAndLastName,
          },
          contextualContent: {
            text: {
              value: formErrorState.lastName,
            },
          },
        },
      },
      emailAddressInputField: {
        state: 'Help',
        text: {
          value: t('my_profile.basic_info.labels.email_address'),
        },
        textInput: {
          disabled: true,
          textValue: account?.email,
        },
        contextualContent: {
          type: 'Help',
          text: {
            value: t('signUp.hintText'),
          },
        },
      },
      phoneNumberInputField: {
        state: formErrorState.phoneNumber ? 'Error' : 'Default',
        text: {
          value: t('my_profile.basic_info.labels.phone_number'),
        },
        textInput: {
          textValue: formState.phoneNumber,
          onTextChanged: handleTextChange('phoneNumber'),
          preProcessTextInput: acceptPhoneNumber,
        },
        contextualContent: {
          text: {
            value: formErrorState.phoneNumber,
          },
        },
      },
      address: {
        setAddressForm: handleAddressForm,
        setAddressFormError: handleAddressFormError,
        validateAddress: validateAddress,
        setIsAddressValid,
        address: addressFormState,
      },
      button: {
        disabled: !isFormDirty,
        text: {
          value: t('my_profile.basic_info.button.save_changes'),
        },
        onClick: handleUpdateProfile,
      },
    },
    teacherDirectorySection: {
      text: {
        value: t('my_profile.teacher_directory.title'),
      },
      text1: {
        value: t('my_profile.teacher_directory.description'),
      },
      button: {
        text: {
          value: t('my_profile.teacher_directory.button'),
        },
        loading: 'Default',
        onClick: handleSubmitInfo,
      },
    },
  };
};

export default usePresenter;
