import { useDropdownToggle } from 'react-overlays';
import { SelectCombinedProps } from './types';

// MANUAL OVERRIDE
const usePresenter = (props: SelectCombinedProps): SelectCombinedProps => {
  const [toggleProps, { show }] = useDropdownToggle();

  return {
    ...props,
    ...{
      ...toggleProps,
      onClick: (event?: React.MouseEvent<HTMLElement>) => {
        if (event) {
          toggleProps.onClick(event);
        }
      },
    },
    state: show ? 'Expanded' : 'Collapsed',
  };
};

export default usePresenter;
