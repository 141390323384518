import React, { createContext, useState } from 'react';
import { Account, extractJwtPayload } from '../../lib/token';
import { getSessionCookie } from '../auth/utils';

export type AuthContextValue = {
  account?: Account;
  updateAccount: (account?: Account) => void;
  refetchAccount: () => void;
};

const initialAuthContext: AuthContextValue = {
  account: undefined,
  updateAccount: () => {},
  refetchAccount: () => {},
};

export const AuthContext = createContext<AuthContextValue>(initialAuthContext);

export const AuthProvider: React.FC<{}> = ({ children }) => {
  const cookie = getSessionCookie();
  const [account, setAccount] = useState<Account | undefined>(
    cookie ? extractJwtPayload(cookie) : undefined,
  );

  const updateAccount = (userAccount: Account | undefined) => {
    setAccount(userAccount);
  };

  const refetchAccount = () => {
    const refreshedCookie = getSessionCookie();
    setAccount(refreshedCookie ? extractJwtPayload(refreshedCookie) : undefined);
  };

  return (
    <AuthContext.Provider value={{
      account,
      updateAccount,
      refetchAccount,
    }}>
      {children}
    </AuthContext.Provider>
  );
};