import { InlineNotificationProps } from '../../atoms/InlineNotification';
import { StudentCourseDetailsFooterProps } from '../../organisms/StudentCourseDetailsFooter';
import { StudentCourseDetailsPageProps } from '../../pages/StudentCourseDetailsPage';


type BodyFooterLayoutInternalProps = {
  studentCourseDetailsPage?: StudentCourseDetailsPageProps;
  studentCourseDetailsFooter?: StudentCourseDetailsFooterProps;
  inlineNotification?: InlineNotificationProps;
};

type BodyFooterLayoutPropsValues = {
  '': BodyFooterLayoutInternalProps;
};

const propValues: BodyFooterLayoutPropsValues = {
  '': {
    studentCourseDetailsPage: {
    },
    studentCourseDetailsFooter: {
    },
    inlineNotification: {
      type: 'Error',
    },
  },
};

const getProps = (type: string): BodyFooterLayoutInternalProps => {
  const values: BodyFooterLayoutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
