import React from 'react';
import cx from 'classnames';

import Image from '../../atoms/Image';
import TextGroup from '../../molecules/TextGroup';

import styles from './ConfirmationBlock.module.scss';
import usePresenter from './ConfirmationBlock.presenter';
import getProps from './ConfirmationBlock.props';
import { ConfirmationBlockCombinedProps } from './types';


const ConfirmationBlock: React.FC<ConfirmationBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    textGroup,
    textGroup1,
    appStore, // MANUAL OVERRIDE
    googlePlay, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.confirmationBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Image
          className={cx(styles.image, classes?.image)}
          {...internalProps.image}
          {...image}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
        <TextGroup
          className={cx(styles.textGroup1, classes?.textGroup1)}
          {...internalProps.textGroup1}
          {...textGroup1}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        { /* MANUAL OVERRIDE */ }
        <a href={appStore?.link}>
          <Image
              className={cx(styles.appStore, classes?.appStore)}
              {...internalProps.appStore}
              {...appStore}/>
        </a>
        <a href={googlePlay?.link}>
          <Image
            className={cx(styles.googlePlay, classes?.googlePlay)}
            {...internalProps.googlePlay}
            {...googlePlay}/>
        </a>
      </div>
    </div>
  );
};

export default ConfirmationBlock;

