import React from 'react';
import cx from 'classnames';

import CourseCardsList from '../../organisms/CourseCardsList';

import styles from './CoursesBlock.module.scss';
import usePresenter from './CoursesBlock.presenter';
import getProps from './CoursesBlock.props';
import { CoursesBlockCombinedProps } from './types';


const CoursesBlock: React.FC<CoursesBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    courseCardsList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.coursesBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <CourseCardsList
        className={cx(styles.courseCardsList, classes?.courseCardsList)}
        {...internalProps.courseCardsList}
        {...courseCardsList}/>
    </div>
  );
};

export default CoursesBlock;

