import React from 'react';
import cx from 'classnames';

import InputFieldGroup from '../InputFieldGroup';
import SelectFieldGroup from '../SelectFieldGroup';
import InputField from '../InputField';

import styles from './Address.module.scss';
import usePresenter from './Address.presenter';
import getProps from './Address.props';
import { AddressCombinedProps } from './types';


const Address: React.FC<AddressCombinedProps> = (props) => {
  const {
    className,
    classes,
    streetAddressInputField,
    selectFieldGroup,
    inputFieldGroup,
    display,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.address;

  const displayStyle = { display: `${display || display === undefined ? 'flex' : 'none'}` };

  return (
    <div className={cx(currentStyle, className)} style={displayStyle}>
      <InputField
        className={cx(styles.streetAddressInputField, classes?.streetAddressInputField)}
        {...internalProps.streetAddressInputField}
        {...streetAddressInputField}/>
      <SelectFieldGroup
        className={cx(styles.selectFieldGroup, classes?.selectFieldGroup)}
        {...internalProps.selectFieldGroup}
        {...selectFieldGroup}/>
      <InputFieldGroup
        className={cx(styles.inputFieldGroup, classes?.inputFieldGroup)}
        {...internalProps.inputFieldGroup}
        {...inputFieldGroup}/>
    </div>
  );
};

export default Address;

