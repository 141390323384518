import { TextProps } from '../Text';


type TabInternalProps = {
  text?: TextProps;
};

type TabPropsValues = {
  'Selected': TabInternalProps;
  'Unselected': TabInternalProps;
};

const propValues: TabPropsValues = {
  'Selected': {
    text: {
      style: 'Bold',
      align: 'Center',
      colour: 'Brand',
      type: 'Body1',
    },
  },
  'Unselected': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Default',
      type: 'Body1',
    },
  },
};

const getProps = (type: string): TabInternalProps => {
  const values: TabInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
