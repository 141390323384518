import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { trackViewPurchaseStudentAppsModal } from '../../../lib/trackingUtils';
import { ModalContext } from '../../../modules/user/ModalContext';
import { UserContext } from '../../../modules/user/UserContext';

import { StudentAppBlockCombinedProps } from './types';

const usePresenter = (props: StudentAppBlockCombinedProps): StudentAppBlockCombinedProps => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { setModalState } = useContext(ModalContext);

  const handleOpenPurchaseStudentAppsModal = () => {
    trackViewPurchaseStudentAppsModal();
    setModalState('PURCHASE_STUDENT_APPS');
  };

  return {
    ...props,
    studentAppSection: {
      text: {
        value: t('student_apps.title'),
      },
      infoText: {
        value: t('student_apps.description'),
      },
      text1: {
        value: t('student_apps.remaining_apps_label'),
      },
      text2: {
        value: user?.content?.apps,
      },
      actionSection: {
        topText: {
          value: t('student_apps.action_section.title'),
        },
        bottomText: {
          value: t('student_apps.action_section.description'),
        },
        button: {
          text: {
            value: t('student_apps.action_section.button'),
          },
          onClick: handleOpenPurchaseStudentAppsModal,
        },
      },
    },
  };
};

export default usePresenter;
