import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { AddCoTeacherProps } from '../../molecules/AddCoTeacher';
import { InputFieldProps } from '../../molecules/InputField';
import { RadioFieldProps } from '../../molecules/RadioField';
import { TextGroupProps } from '../../molecules/TextGroup';


type CreateCourseBasicDetailsBlockInternalProps = {
  stepperText?: TextProps;
  textGroup?: TextGroupProps;
  courseNameInputField?: InputFieldProps;
  radioField?: RadioFieldProps;
  courseDescriptionTextAreaField?: InputFieldProps;
  classCapacityInputField?: InputFieldProps;
  addCoTeacher?: AddCoTeacherProps;
  button?: ButtonProps;
};

type CreateCourseBasicDetailsBlockPropsValues = {
  '': CreateCourseBasicDetailsBlockInternalProps;
};

const propValues: CreateCourseBasicDetailsBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    textGroup: {
    },
    courseNameInputField: {
      state: 'Default',
    },
    radioField: {
    },
    courseDescriptionTextAreaField: {
      state: 'Default',
    },
    classCapacityInputField: {
      state: 'Default',
    },
    addCoTeacher: {
      state: 'Default',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): CreateCourseBasicDetailsBlockInternalProps => {
  const values: CreateCourseBasicDetailsBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
