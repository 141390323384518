import { ButtonProps } from '../../atoms/Button';
import { ContextualContentProps } from '../../atoms/ContextualContent';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';


type CardInfoInternalProps = {
  labelText?: TextProps;
  icon?: IconProps;
  text?: TextProps;
  button?: ButtonProps;
  contextualContent?: ContextualContentProps;
};


type CardInfoPropsValues = {
  'Default': CardInfoInternalProps;
  'Error': CardInfoInternalProps;
  'Display': CardInfoInternalProps;
  'DisplayError': CardInfoInternalProps;
};

const propValues: CardInfoPropsValues = {
  'Default': {
    labelText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    icon: {
      asset: 'PaymentCardFilled',
      colour: 'Base',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Text',
      colour: 'PrimaryAction',
    },
  },
  'Error': {
    labelText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    icon: {
      asset: 'PaymentCardFilled',
      colour: 'Base',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Text',
      colour: 'PrimaryAction',
    },
    contextualContent: {
      type: 'Error',
    },
  },
  'Display': {
    labelText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    icon: {
      asset: 'PaymentCardFilled',
      colour: 'Base',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
  'DisplayError': {
    labelText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    icon: {
      asset: 'PaymentCardFilled',
      colour: 'Base',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    contextualContent: {
      type: 'Error',
    },
  },
};

const getProps = (type: string): CardInfoInternalProps => {
  const values: CardInfoInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
