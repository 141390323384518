import { ButtonProps } from '../../atoms/Button';
import { CheckboxItemProps } from '../../atoms/CheckboxItem';
import { TextProps } from '../../atoms/Text';
import { TextGroupProps } from '../../molecules/TextGroup';
import { ClassListProps } from '../../organisms/ClassList';


type ConfirmAttendenceBlockInternalProps = {
  stepperText?: TextProps;
  textGroup?: TextGroupProps;
  classList?: ClassListProps;
  checkboxItem?: CheckboxItemProps;
  button?: ButtonProps;
};

type ConfirmAttendenceBlockPropsValues = {
  '': ConfirmAttendenceBlockInternalProps;
};

const propValues: ConfirmAttendenceBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    textGroup: {
    },
    classList: {
    },
    checkboxItem: {
      state: 'Checked',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
    },
  },
};

const getProps = (type: string): ConfirmAttendenceBlockInternalProps => {
  const values: ConfirmAttendenceBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
