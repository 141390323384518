import React from 'react';
import cx from 'classnames';

import GenericStateNoActionBlock from '../../blocks/GenericStateNoActionBlock';

import styles from './NoSpotAvailablePage.module.scss';
import usePresenter from './NoSpotAvailablePage.presenter';
import getProps from './NoSpotAvailablePage.props';
import { NoSpotAvailablePageCombinedProps } from './types';


const NoSpotAvailablePage: React.FC<NoSpotAvailablePageCombinedProps> = (props) => {
  const {
    className,
    classes,
    genericStateNoActionBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.noSpotAvailablePage;

  return (
    <div className={cx(currentStyle, className)}>
      <GenericStateNoActionBlock
        className={cx(styles.genericStateNoActionBlock, classes?.genericStateNoActionBlock)}
        {...internalProps.genericStateNoActionBlock}
        {...genericStateNoActionBlock}/>
    </div>
  );
};

export default NoSpotAvailablePage;

