import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';
import { LogEntriesListProps } from '../LogEntriesList';


type LogEntriesAccordianItemInternalProps = {
  nameText?: TextProps;
  button?: ButtonProps;
  divider?: DividerProps;
  icon?: IconProps;
  logEntriesList?: LogEntriesListProps;
};

type LogEntriesAccordianItemPropsValues = {
  'CollapsedDefault': LogEntriesAccordianItemInternalProps;
  'CollapsedNew': LogEntriesAccordianItemInternalProps;
  'ExpandedDefault': LogEntriesAccordianItemInternalProps;
};

const propValues: LogEntriesAccordianItemPropsValues = {
  'CollapsedDefault': {
    nameText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
  'CollapsedNew': {
    icon: {
      asset: 'DotNew',
      colour: 'New',
    },
    nameText: {
      style: 'Bold',
      align: 'Left',
      colour: 'New',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
  'ExpandedDefault': {
    nameText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    logEntriesList: {
    },
  },
};

const getProps = (type: string): LogEntriesAccordianItemInternalProps => {
  const values: LogEntriesAccordianItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
