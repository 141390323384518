import React from 'react';
import cx from 'classnames';

import ContextualContent from '../../atoms/ContextualContent';
import Text from '../../atoms/Text';
import TextInput from '../../atoms/TextInput';

import styles from './InputField.module.scss';
import usePresenter from './InputField.presenter';
import getProps from './InputField.props';
import { InputFieldCombinedProps } from './types';


const InputField: React.FC<InputFieldCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    text,
    textInput,
    contextualContent,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`inputField${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <TextInput
            className={cx(styles.textInput, classes?.textInput)}
            {...internalProps.textInput}
            {...textInput}/>
        </div>
      );
      break;
    }
    case 'Error':
    case 'Help': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <TextInput
            className={cx(styles.textInput, classes?.textInput)}
            {...internalProps.textInput}
            {...textInput}/>
          <ContextualContent
            className={cx(styles.contextualContent, classes?.contextualContent)}
            {...internalProps.contextualContent}
            {...contextualContent}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default InputField;

