import React from 'react';
import cx from 'classnames';

import LabelTextGroup from '../../molecules/LabelTextGroup';

import styles from './LabelTextGroupList.module.scss';
import usePresenter from './LabelTextGroupList.presenter';
import getProps from './LabelTextGroupList.props';
import { LabelTextGroupListCombinedProps } from './types';


const LabelTextGroupList: React.FC<LabelTextGroupListCombinedProps> = (props) => {
  const {
    className,
    classes,
    labelTextGroups,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.labelTextGroupList;

  const labelTextGroupViews = labelTextGroups?.map((labelTextGroup, index) => (
    <LabelTextGroup
      key={index}
      className={cx(styles.labelTextGroup, classes?.labelTextGroup)}
      {...internalProps.labelTextGroup}
      {...labelTextGroup} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {labelTextGroupViews}
    </div>
  );
};

export default LabelTextGroupList;

