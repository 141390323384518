import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { COUNTRY_CODE_LIST, NOTIFICATION_DURATION, STRIPE_CARD_ELEMENT_CONFIG } from '../../../lib/constants';
import { ModalContext } from '../../../modules/user/ModalContext';
import { UserContext } from '../../../modules/user/UserContext';
import { useNotification } from '../../../modules/notification';
import { useInterval } from '../../../lib/utils';

import { AddressForm } from '../../molecules/Address/types';
import { initAddressFormState } from '../../molecules/Address/utils';
import { createTeacherSetupIntentUseCase } from './PaymentInfoModal.interactor';
import { PaymentInfoModalCombinedProps } from './types';

type PaymentInfoForm = AddressForm & {
  creditCard: string;
};

const initPaymentInfoForm: PaymentInfoForm = {
  ...initAddressFormState,
  creditCard: '',
};

const usePresenter = (props: PaymentInfoModalCombinedProps): PaymentInfoModalCombinedProps => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { trigger } = useNotification();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { user, defaultPaymentInfo, refetchDefaultPaymentMethod } = useContext(UserContext);
  const { closePaymentInfoModal } = useContext(ModalContext);

  const CountryCodeMap = new Map(
    COUNTRY_CODE_LIST.map((countryCode) => [countryCode.name, countryCode.code]),
  );

  // Credit card state
  const [creditCardState, setCreditCardState] = useState<StripeCardElementChangeEvent>();

  const [updatedPaymentMethod, setUpdatedPaymentMethod] = useState<string | undefined>(undefined);

  const [formState, setFormState] = useState<PaymentInfoForm>(initPaymentInfoForm);
  const [formErrorState, setFormErrorState] = useState<PaymentInfoForm>(initPaymentInfoForm);
  const [validateAddress, setValidateAddress] = useState<boolean>();
  const [isAddressValid, setIsAddressValid] = useState<boolean>();

  // button loading state
  const [savingPaymentInfo, setSavingPaymentInfo] = useState(false);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<string | undefined>(undefined);

  const [isFormDirty, setIsFormDirty] = useState(false);

  const { mutateAsync: createSetupIntent } = useMutation(['createSetupIntent'], async () => {
    if (user) {
      return createTeacherSetupIntentUseCase(user.uuid);
    }
  });

  useEffect(() => {
    let creditCardErrorMessage = '';
    if (creditCardState?.error) {
      creditCardErrorMessage = t('error.invalid_credit_card');
    }
    setFormErrorState({
      ...formErrorState,
      creditCard: creditCardErrorMessage,
    });
    // To remove stripe error when any changes are made
    setShowNotification(false);
    setNotificationMessage(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCardState]);

  const handleCloseModal = () => {
    closePaymentInfoModal();
  };

  const fetchPaymentInfo = useCallback(async () => {
    if (user && updatedPaymentMethod) {
      try {
        await refetchDefaultPaymentMethod();
        if (defaultPaymentInfo && defaultPaymentInfo.id === updatedPaymentMethod) {
          setSavingPaymentInfo(false);
          trigger({
            duration: NOTIFICATION_DURATION,
            message: t('payment_info_modal.notification.success'),
            type: 'Success',
          });
          handleCloseModal();
        }
      } catch {
        // no-op
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPaymentMethod, defaultPaymentInfo]);

  useInterval(fetchPaymentInfo, 1000);

  const handleAddressForm = (address: AddressForm) => {
    const { city, country, postalCode, province, streetAddress } = address;
    setFormState({
      ...formState,
      city,
      country,
      postalCode,
      province,
      streetAddress,
    });

    // To remove stripe error when any changes are made
    setShowNotification(false);
    setNotificationMessage(undefined);

    // To only enable save button if there's a change
    if (!isFormDirty && (city || country || postalCode || province || streetAddress)) {
      setIsFormDirty(true);
    }
  };

  const handleAddressFormError = (addressError: AddressForm) => {
    setFormErrorState({
      ...formErrorState,
      ...addressError,
    });
  };

  const handleCardChange = (stripeEvent: StripeCardElementChangeEvent) => {
    setCreditCardState(stripeEvent);
    setIsFormDirty(true);
  };

  const isFormValid = (): boolean => {
    return (
      Object.values(formErrorState).filter((errorMessage: string) => !!errorMessage)
        .length === 0
    );
  };

  const handlePaymentChange = async () => {
    setShowNotification(false);
    setValidateAddress(!validateAddress);
    if (!creditCardState || creditCardState?.empty) {
      setFormErrorState({
        ...formErrorState,
        creditCard: t('error.field_required'),
      });
    }

    if (!stripe || !elements) {
      return;
    }

    // Card details are filled completely
    if (!creditCardState?.complete) {
      return;
    }

    if (user && isFormValid() && isAddressValid) {
      setSavingPaymentInfo(true);
      const setupIntentResponse = await createSetupIntent();

      if (setupIntentResponse) {
        const result = await stripe.confirmCardSetup(setupIntentResponse.clientSecret, {
          payment_method: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            card: elements.getElement(CardElement)!,
            billing_details: {
              address: {
                line1: formState.streetAddress,
                city: formState.city,
                postal_code: formState.postalCode,
                state: formState.province,
                country: CountryCodeMap.get(formState.country) || '',
              },
            },
          },
        });

        if (result.setupIntent) {
          const { payment_method: paymentMethod } = result.setupIntent;

          if (paymentMethod) {
            setUpdatedPaymentMethod(typeof paymentMethod === 'string' ? paymentMethod : paymentMethod.id);
          }
        } else if (result.error) {
          setSavingPaymentInfo(false);
          setShowNotification(true);
          setNotificationMessage(result.error.message);
        }
      }
    }
  };

  return {
    ...props,
    containerRef,
    showNotification,
    inlineNotification: {
      text: {
        value: notificationMessage,
      },
    },
    button: {
      icon: {
        asset: 'Close',
      },
      onClick: handleCloseModal,
    },
    address: {
      setAddressForm: handleAddressForm,
      setAddressFormError: handleAddressFormError,
      validateAddress: validateAddress,
      setIsAddressValid,
    },
    primaryButton: {
      disabled: !isFormDirty || savingPaymentInfo,
      text: {
        value: t('payment_info_modal.button.save'),
      },
      onClick: handlePaymentChange,
      loading: savingPaymentInfo ? 'Loading' : 'Default',
    },
    primaryButton1: {
      text: {
        value: t('training_extension_modal.button.cancel'),
      },
      onClick: handleCloseModal,
    },
    cardInfoField: {
      state: formErrorState.creditCard ? 'Error' : 'Default',
      labelText: {
        value: t('payment_info_modal.card_information_label'),
      },
      cardElementOptions: STRIPE_CARD_ELEMENT_CONFIG,
      handleChange: handleCardChange,
      contextualContent: {
        text: {
          value: formErrorState.creditCard,
        },
      },
    },
    text: {
      value: t('payment_info_modal.title'),
    },
    text1: {
      value: t('payment_info_modal.address_title'),
    },
  };
};

export default usePresenter;
