import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import TextGroup from '../../molecules/TextGroup';

import styles from './GenericActionBlock.module.scss';
import usePresenter from './GenericActionBlock.presenter';
import getProps from './GenericActionBlock.props';
import { GenericActionBlockCombinedProps } from './types';


const GenericActionBlock: React.FC<GenericActionBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    textGroup,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.genericActionBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
    </div>
  );
};

export default GenericActionBlock;

