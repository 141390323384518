import React from 'react';
import cx from 'classnames';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';

import ContextualContent from '../../atoms/ContextualContent';
import Text from '../../atoms/Text';

import styles from './DateTimePickerField.module.scss';
import usePresenter from './DateTimePickerField.presenter';
import getProps from './DateTimePickerField.props';
import { DateTimePickerFieldCombinedProps } from './types';
import TextInput from '../../atoms/TextInput';


const DateTimePickerField: React.FC<DateTimePickerFieldCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    text,
    flatpickr,
    contextualContent,
    textInput,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`dateTimePickerField${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <Flatpickr
            className={cx(styles.flatPickr, classes?.flatPickr)}
            key={flatpickr?.options?.defaultHour}
            {...flatpickr}
          >
            <TextInput
              className={cx(styles.textInput, classes?.textInput)}
              dataInput={true}
              {...internalProps.textInput}
              {...textInput}/>
          </Flatpickr>
          
        </div>
      );
      break;
    }
    case 'Error':
    case 'Help': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <Flatpickr
            className={cx(styles.flatPickr, classes?.flatPickr)}
            key={flatpickr?.options?.defaultHour}
            {...flatpickr}
          >
            <TextInput
              className={cx(styles.textInput, classes?.textInput)}
              dataInput={true}
              {...internalProps.textInput}
              {...textInput}/>
          </Flatpickr>
          <ContextualContent
            className={cx(styles.contextualContent, classes?.contextualContent)}
            {...internalProps.contextualContent}
            {...contextualContent}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default DateTimePickerField;

