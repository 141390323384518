import { CourseCardsListProps } from '../../organisms/CourseCardsList';


type CoursesBlockInternalProps = {
  courseCardsList?: CourseCardsListProps;
};

type CoursesBlockPropsValues = {
  '': CoursesBlockInternalProps;
};

const propValues: CoursesBlockPropsValues = {
  '': {
    courseCardsList: {
    },
  },
};

const getProps = (type: string): CoursesBlockInternalProps => {
  const values: CoursesBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
