import { GenericStateNoActionBlockProps } from '../../blocks/GenericStateNoActionBlock';


type NoSpotAvailablePageInternalProps = {
  genericStateNoActionBlock?: GenericStateNoActionBlockProps;
};

type NoSpotAvailablePagePropsValues = {
  '': NoSpotAvailablePageInternalProps;
};

const propValues: NoSpotAvailablePagePropsValues = {
  '': {
    genericStateNoActionBlock: {
    },
  },
};

const getProps = (type: string): NoSpotAvailablePageInternalProps => {
  const values: NoSpotAvailablePageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
