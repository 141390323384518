import { LogEntriesAccordianListProps } from '../LogEntriesAccordianList';
import { LogEntriesListProps } from '../LogEntriesList';


type LogEntriesCatalogInternalProps = {
  logEntriesList?: LogEntriesListProps;
  logEntriesAccordianList?: LogEntriesAccordianListProps;
};

type LogEntriesCatalogPropsValues = {
  'ByDate': LogEntriesCatalogInternalProps;
  'ByStudent': LogEntriesCatalogInternalProps;
};

const propValues: LogEntriesCatalogPropsValues = {
  'ByDate': {
    logEntriesList: {
    },
  },
  'ByStudent': {
    logEntriesAccordianList: {
    },
  },
};

const getProps = (type: string): LogEntriesCatalogInternalProps => {
  const values: LogEntriesCatalogInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
