import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

import { getTranslation } from '../../../lib/reactUtils';
import { KORU_STUDENT_APP_SUPPORT_URL, NOTIFICATION_DURATION, TIME_FORMAT_OPTIONS } from '../../../lib/constants';
import { copyTextToClipboard, formatDate, getClassIcons, getPersonName } from '../../../lib/utils';
import { UserContext } from '../../../modules/user/UserContext';
import { useNotification } from '../../../modules/notification';

import { ClassItemValueProps } from '../../molecules/ClassItem';
import { LabelTextGroupValueProps } from '../../molecules/LabelTextGroup/types';

import { courseBasicInfoKeys, DetailsBlockCombinedProps } from './types';
import { updateCourseUseCase } from './DetailsBlock.interactor';
import styles from './DetailsBlock.module.scss';
import { CourseBasicInfoForm } from '../CreateCourseBasicDetailsBlock/types';
import { FRONTEND_URL } from '../../../lib/config';
import { ClassCardForm, CourseScheduleForm } from '../CreateCourseScheduleBlock/types';

const usePresenter = (props: DetailsBlockCombinedProps): DetailsBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { course } = props;
  const { trigger } = useNotification();
  const [showRegistrationStatusWarningModal, setShowRegistrationStatusWarningModal] = useState(false);

  const [hasFreeApps, setHasFreeApps] = useState(false);

  const { mutateAsync: updateRegistrationStatus, isLoading: registrationLoading } = useMutation(['updateRegistrationStatus', course],
    async () => {
      if (course && !course.content.archived) {
        return updateCourseUseCase(course.content.id, {
          registrationOpen: !course.content.registrationOpen,
        });
      }
    },
  );

  const supportArticleAnchorElement = <a
    className={styles.link}
    target='_blank'
    rel='noreferrer noopener'
    href={KORU_STUDENT_APP_SUPPORT_URL}
    >{t('course_detail.course_link.description')}
  </a>;

  useEffect(() => {
    if (user && !course?.content?.archived && course?.content?.primaryTeacher?.content?.apps && course.content.freeLinkToken) {
      const coTeacherUuids = course.content.coTeachers?.map((coTeacher) => coTeacher.uuid);
      const teacherUuids = [...coTeacherUuids || [], course.content.primaryTeacher.uuid];
      setHasFreeApps(teacherUuids.includes(user.uuid));
    }
  }, [user, course]);

  let labelTextGroups: LabelTextGroupValueProps[] = [];
  if (course) {
    const coTeacherNames = course.content.coTeachers?.map(coTeacher => getPersonName(coTeacher));
    const teacherNames = [getPersonName(course.content.primaryTeacher), ...coTeacherNames || []];
    labelTextGroups = courseBasicInfoKeys.map(key => {
      const labelText = t(`course_detail.basic_info.labels.${key}`);
      let infoText;
      switch (key) {
        case 'coTeachers': 
          infoText = teacherNames.join(', ');
          break;
        case 'classCapacity': 
          infoText = t('course_detail.basic_info.value.classCapacity', { classCapacity: course.content.classCapacity });
          break;
        default:
          infoText = course.content[key];
          break;
      }
      return (
        {
          labelText: {
            className: styles.labelText,
            value: labelText,
          },
          infoText: {
            value: infoText,
          },
        } as LabelTextGroupValueProps
      );
    });
  }

  let classItems: ClassItemValueProps[] = [];
  if (course?.content.classes) {
    classItems = course.content.classes.map((c, index) => {
      const icons = getClassIcons(course.content.classFormat, c.content.location, c.content.classLink);
      return {
        text: {
          value: `Class ${Number(index) + 1}`,
        },
        iconTextGroupList: {
          iconTextGroups: [
            {
              labelIcon: {
                asset: 'Calendar',
              },
              infoText: {
                value: formatDate(c.content.date),
              },
            },
            {
              labelIcon: {
                asset: 'Clock',
              },
              infoText: {
                value: `${formatDate(c.content.startTime, TIME_FORMAT_OPTIONS)} - ${formatDate(c.content.endTime, TIME_FORMAT_OPTIONS)}`,
              },
            },
            ...icons,
          ],
        },
      } as ClassItemValueProps;
    });
  }

  const triggerLinkCopiedToast = () => {
    trigger({
      type: 'Success',
      duration: NOTIFICATION_DURATION,
      message: t('course_detail.course_link.toast'),
    });
  };

  const handleCopyLinkButton = async () => {
    if (course) {
      const link = `${FRONTEND_URL}/courseRegistration/${course.content.id}`;
      await copyTextToClipboard(link);
      triggerLinkCopiedToast();
    }
  };

  const handleCopyFreeLinkButton = async () => {
    if (course) {
      const link = `${FRONTEND_URL}/courseRegistration/${course.content.id}?token=${course.content.freeLinkToken}`;
      await copyTextToClipboard(link);
      triggerLinkCopiedToast();
    }
  };

  const closeRegistrationStatusWarningModal = () => {
    setShowRegistrationStatusWarningModal(false);
  };

  const handleUpdateRegistration = async () => {
    try {
      const updatedCourse = await updateRegistrationStatus();
      if (updatedCourse) {
        closeRegistrationStatusWarningModal();
        await queryClient.invalidateQueries(['getCourseById', updatedCourse.content.id.toString()]);
        trigger({
          type: 'Success',
          duration: NOTIFICATION_DURATION,
          message: t('notification.course_updated'),
        });
      }
    } catch {
      // no-op
    }
  };

  const handleOpenRegistrationButtonClick = () => {
    if (course && !course.content.registrationOpen) {
      setShowRegistrationStatusWarningModal(true);
    }
  };

  const handleCloseRegistrationButtonClick = () => {
    if (course?.content?.registrationOpen) {
      setShowRegistrationStatusWarningModal(true);
    }
  };

  const courseBasicInfoForm = {
    classCapacity: course?.content?.classCapacity,
    description: course?.content?.description,
    registrationOpen: course?.content?.registrationOpen,
    name: course?.content?.title,
    type: course?.content?.courseType,
    coTeachers: course?.content?.coTeachers?.map((coTeacher) => coTeacher.content.email),
  } as CourseBasicInfoForm;

  const courseScheduleInfoForm = {
    type: course?.content?.classFormat,
    classes: course?.content?.classes?.map(({ uuid, content: classInfo }): ClassCardForm => {
      return {
        date: classInfo.date,
        end: classInfo.endTime,
        start: classInfo.startTime,
        link: classInfo.classLink || '',
        location: classInfo.location || '',
        uuid,
      };
    }),
  } as CourseScheduleForm;

  return {
    ...props,
    detailsBasicInfoSection: {
      text: {
        value: t('course_detail.basic_info.header'),
      },
      button: {
        text: {
          value: t('course_detail.basic_info.button.edit'),
          colour: course?.content?.archived ? 'Disabled' : 'Info',
        },
        icon: {
          asset: 'Edit',
          colour: course?.content?.archived ? 'Disabled' : 'Info',
        },
        onClick: () => history.push(`/courses/detail/${course?.content?.id}/basicInfo`, {
          step1: courseBasicInfoForm,
        }),
        disabled: course?.content?.archived,
      },
      labelTextGroupList: {
        labelTextGroups: labelTextGroups,
      },
    },
    detailsClassInfoSection: {
      text: {
        value: t('course_detail.class_information.header'),
      },
      labelTextGroupList: {
        labelTextGroups: [
          {
            labelText: {
              value: t('course_detail.class_information.labels.class_format'),
            },
            infoText: {
              value: course?.content?.classFormat,
            },
          },
          {
            labelText: {
              value: t('course_detail.class_information.labels.class_schedule'),
            },
            infoText: {
              value: '',
            },
          },
        ],
      },
      button: {
        text: {
          value: t('course_detail.class_information.button.edit'),
          colour: course?.content?.archived ? 'Disabled' : 'Info',
        },
        icon: {
          asset: 'Edit',
          colour: course?.content?.archived ? 'Disabled' : 'Info',
        },
        onClick: () => history.push(`/courses/detail/${course?.content?.id}/classInfo`, {
          step1: courseBasicInfoForm,
          step2: courseScheduleInfoForm,
        }),
        disabled: course?.content?.archived,
      },
      classList: {
        classItems: classItems,
      },
    },
    registrationStatusSection: {
      text: {
        value: t('course_detail.registration_status.header'),
      },
      radioItemList: {
        radioItems: [
          {
            text: {
              value: t('course_detail.registration_status.radio.open'),
              colour: course?.content?.archived ? 'Disabled' : 'Default',
            },
            icon: {
              colour: course?.content?.archived ? 'Disabled' : 'Info',
            },
            state: course?.content?.registrationOpen ? 'Checked' : 'Unchecked',
            onClick: handleOpenRegistrationButtonClick,
            disabled: course?.content?.archived,
          },
          {
            text: {
              value: t('course_detail.registration_status.radio.close'),
              colour: course?.content?.archived ? 'Disabled' : 'Default',
            },
            icon: {
              colour: course?.content?.archived ? 'Disabled' : 'Info',
            },
            state: course?.content?.registrationOpen ? 'Unchecked' : 'Checked',
            onClick: handleCloseRegistrationButtonClick,
            disabled: course?.content?.archived,
          },
        ],
      },
    },
    copyLinkSection: {
      text: {
        value: t('course_detail.course_link.header'),
      },
      bodyText: {
        value:  getTranslation('course_detail.course_link.description', {}, {
          className: styles.fontVariant,
        }, {
          support: supportArticleAnchorElement,
        }),
      },
      button: {
        text: {
          value: t('course_detail.course_link.button.copy_link'),
        },
        onClick: handleCopyLinkButton,
        disabled: course?.content?.archived,
      },
      freeLinkButton: {
        text: {
          value: t('course_detail.course_link.button.copy_free_link'),
        },
        onClick: handleCopyFreeLinkButton,
        disabled: course?.content?.archived,
      },
      hasFreeApps,
    },
    updateRegistrationStatusWarningModal: {
      show: showRegistrationStatusWarningModal,
      onHide: closeRegistrationStatusWarningModal,
      modalProps: {
        type: course?.content?.registrationOpen ? 'Critical' : 'Default',
        button: {
          icon: {
            asset: 'Close',
          },
          onClick: closeRegistrationStatusWarningModal,
        },
        primaryButton: {
          text: {
            value: t(`course_detail.open_close_registration_modal.primary_button.${
              course?.content?.registrationOpen ? 'close' : 'open'
            }`),
          },
          onClick: handleUpdateRegistration,
          loading: registrationLoading ? 'Loading' : 'Default',
          disabled: registrationLoading,
        },
        secondaryButton: {
          text: {
            value: t('course_detail.open_close_registration_modal.secondary_button'),
          },
          onClick: closeRegistrationStatusWarningModal,
        },
        text: {
          value: t('course_detail.open_close_registration_modal.title'),
        },
        text1: {
          value: getTranslation(`course_detail.open_close_registration_modal.description.${
            course?.content?.registrationOpen ? 'close' : 'open'
          }`),
        },
      },
    },
  };
};

export default usePresenter;
