import { CopyLinkSectionProps } from '../../organisms/CopyLinkSection';
import { DetailsBasicInfoSectionProps } from '../../organisms/DetailsBasicInfoSection';
import { DetailsClassInfoSectionProps } from '../../organisms/DetailsClassInfoSection';
import { RegistrationStatusSectionProps } from '../../organisms/RegistrationStatusSection';


type DetailsBlockInternalProps = {
  detailsBasicInfoSection?: DetailsBasicInfoSectionProps;
  detailsClassInfoSection?: DetailsClassInfoSectionProps;
  registrationStatusSection?: RegistrationStatusSectionProps;
  copyLinkSection?: CopyLinkSectionProps;
};

type DetailsBlockPropsValues = {
  '': DetailsBlockInternalProps;
};

const propValues: DetailsBlockPropsValues = {
  '': {
    detailsBasicInfoSection: {
    },
    detailsClassInfoSection: {
    },
    registrationStatusSection: {
    },
    copyLinkSection: {
    },
  },
};

const getProps = (type: string): DetailsBlockInternalProps => {
  const values: DetailsBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
