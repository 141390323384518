import { ButtonProps, ButtonValueProps } from '../../atoms/Button';
import { TextValueProps } from '../../atoms/Text';
import { RadioFieldProps, RadioFieldValueProps } from '../../molecules/RadioField';
import { TextGroupValueProps } from '../../molecules/TextGroup';
import { ClassCardsListValueProps } from '../../organisms/ClassCardsList';
import { GenericModalValueProps } from '../../organisms/GenericModal';

export type CreateCourseScheduleBlockClasses = {
  topContent?: string;
  stepperText?: string;
  textGroup?: string;
  middleContent?: string;
  radioField?: string;
  classCardsList?: string;
  button?: string;
  bottomContent?: string;
  backButton?: string;
  createCourseButton?: string;
  deleteClassModal?: string;
};

export type CreateCourseScheduleBlockValueProps = {
  stepperText?: TextValueProps;
  textGroup?: TextGroupValueProps;
  radioField?: RadioFieldValueProps & RadioFieldProps;
  classCardsList?: ClassCardsListValueProps;
  button?: ButtonValueProps;
  backButton?: ButtonValueProps;
  createCourseButton?: ButtonValueProps & ButtonProps;
  deleteClassModal?: GenericModalValueProps;
};

export type CreateCourseScheduleBlockProps = {
  className?: string;
  classes?: CreateCourseScheduleBlockClasses;
};

export type CreateCourseScheduleBlockCombinedProps = CreateCourseScheduleBlockValueProps & CreateCourseScheduleBlockProps;

export const CLASS_FORMAT = ['InPerson', 'Online', 'Both'] as const;
export type ClassFormat = typeof CLASS_FORMAT[number];

export type CourseScheduleForm = {
  type: ClassFormat;
  classes: ClassCardForm[];
};

export type ClassCardForm = {
  date: Date | string;
  start: Date | string;
  end: Date | string;
  location: string;
  link: string;
  uuid?: string;
};

export type ClassCardFormErrors = {
  date: string;
  start: string;
  end: string;
  location: string;
  link: string;
};

export type CourseScheduleFormErrors = {
  type: string;
  classes: ClassCardFormErrors[];
};
