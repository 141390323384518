import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router';
import { ACCOUNT_STATUS_ROUTE, COURSE_ROUTES, SIDE_NAV_LINKS } from '../../../lib/constants';
import { getIsAccessRestricted } from '../../../lib/utils';
import { UserContext } from '../../../modules/user/UserContext';
import { NavMenuItemStateEnum } from '../../atoms/NavMenuItem/types';
import { LeftNavCombinedProps } from './types';

const usePresenter = (props: LeftNavCombinedProps): LeftNavCombinedProps => {
  const { t } = useTranslation();
  const { isExpired, user } = useContext(UserContext);
  const { pathname } = useLocation();
  const history = useHistory();

  const getNavMenuItemState = (navItemPathName: string): NavMenuItemStateEnum => {
    return pathname.includes(navItemPathName) ? 'Selected' : 'Default';
  };

  const goToExternalLink = (url: string, isExternalLink: boolean) => {
    const isRestricted = getIsAccessRestricted(isExpired, user);
    if (isRestricted) {
      history.push(ACCOUNT_STATUS_ROUTE.subscriptionExpired);
    } else {
      if (isExternalLink) {
        window.location.assign(url);
      } else {
        history.push(url);
      }
    }
  };

  return {
    ...props,
    navMenuList: {
      navMenuItems: [
        {
          icon: {
            asset: 'Training',
          },
          type: 'IconText',
          text: {
            value: t('left_nav.training'),
          },
          onClick: () => goToExternalLink(SIDE_NAV_LINKS.teacher_training, true),
        },
        {
          state: getNavMenuItemState(COURSE_ROUTES.courses),
          icon: {
            asset: 'Courses',
          },
          type: 'IconText',
          text: {
            value: t('left_nav.courses'),
          },
          onClick: () =>  goToExternalLink(COURSE_ROUTES.active, false),
        },
        {
          icon: {
            asset: 'Events',
          },
          type: 'IconText',
          text: {
            value: t('left_nav.event'),
          },
          onClick: () =>  goToExternalLink(SIDE_NAV_LINKS.events, true),
        },
        {
          icon: {
            asset: 'Forum',
          },
          type: 'IconText',
          text: {
            value: t('left_nav.forum'),
          },
          onClick: () =>  goToExternalLink(SIDE_NAV_LINKS.forum, true),
        },
        {
          icon: {
            asset: 'Support',
          },
          type: 'IconText',
          text: {
            value: t('left_nav.resources'),
          },
          onClick: () =>  goToExternalLink(SIDE_NAV_LINKS.resource_support, true),
        },
      ],
    },
    divider: {
    },
  };
};

export default usePresenter;
