import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import ClassList from '../ClassList';
import LabelTextGroupList from '../LabelTextGroupList';

import styles from './DetailsClassInfoSection.module.scss';
import usePresenter from './DetailsClassInfoSection.presenter';
import getProps from './DetailsClassInfoSection.props';
import { DetailsClassInfoSectionCombinedProps } from './types';


const DetailsClassInfoSection: React.FC<DetailsClassInfoSectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    button,
    divider,
    labelTextGroupList,
    classList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.detailsClassInfoSection;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <div className={cx(styles.topContent, classes?.topContent)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <LabelTextGroupList
        className={cx(styles.labelTextGroupList, classes?.labelTextGroupList)}
        {...internalProps.labelTextGroupList}
        {...labelTextGroupList}/>
      <ClassList
        className={cx(styles.classList, classes?.classList)}
        {...internalProps.classList}
        {...classList}/>
    </div>
  );
};

export default DetailsClassInfoSection;

