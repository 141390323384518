import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import { SIGNUP_STEPS, AUTH_ROUTES } from '../../../lib/constants';
import { acceptPhoneNumber, isEmptyString } from '../../../lib/utils';
import { CompleteProfileBlockCombinedProps } from './types';
import { updateTeacherUseCase } from './CompleteProfileBlock.interactor';
import { UserContext } from '../../../modules/user/UserContext';
import { AddressForm } from '../../molecules/Address/types';
import { initAddressFormState } from '../../molecules/Address/utils';

type CompleteProfileForm = AddressForm & {
  phoneNumber: string;
};

const initFormState: CompleteProfileForm = {
  ...initAddressFormState,
  phoneNumber: '',
};

const usePresenter = (props: CompleteProfileBlockCombinedProps): CompleteProfileBlockCombinedProps => {
  const { t } = useTranslation();
  const { user, refetchUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [formState, setFormState] = useState<CompleteProfileForm>(initFormState);
  const [formErrorState, setFormErrorState] = useState<CompleteProfileForm>(initFormState);
  const [validateAddress, setValidateAddress] = useState<boolean>();
  const [isAddressValid, setIsAddressValid] = useState<boolean>();

  const { mutateAsync: updateTeacher } = useMutation(['updateTeacher', user?.uuid], async () => {
    if (user) {
      return updateTeacherUseCase(user.uuid, {
        profile: JSON.stringify(formState),
      });
    }
  });

  const handleAddressForm = (address: AddressForm) => {
    setFormState({
      ...formState,
      ...address,
    });
  };

  const handleAddressFormError = (addressError: AddressForm) => {
    setFormErrorState({
      ...formErrorState,
      ...addressError,
    });
  };

  const isFormValid = (): boolean => {
    setFormErrorState({
      ...formErrorState,
      phoneNumber: isEmptyString(formState.phoneNumber) ? t('error.field_required') : '',
    });
    return (
      Object.values(formErrorState).filter((errorMessage: string) => !!errorMessage)
        .length === 0
    );
  };

  const handleCompleteProfile = async () => {
    setValidateAddress(!validateAddress);
    setIsLoading(true);
    try {
      if (isFormValid() && !!isAddressValid && !isLoading) {
        const updatedTeacher = await updateTeacher();
        if (updatedTeacher) {
          await refetchUser();
          history.replace(AUTH_ROUTES.tuitionPayment);
        }
      }
    } catch (e) {
      // no-op
      // should display notification with error message to help user navigate the error
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleTextChange = (field: keyof CompleteProfileForm) => {
    return (value: string) => {
      setFormState({
        ...formState,
        [field]: value,
      });

      setFormErrorState({
        ...formErrorState,
        [field]: '',
      });
    };
  };

  return {
    ...props,
    stepperText: {
      value: t('stepperText', {
        currentStep: 2,
        totalSteps: SIGNUP_STEPS,
      }),
    },
    textGroup: {
      topText: {
        value: t('complete_profile.title'),
      },
      bottomText: {
        value: t('complete_profile.description'),
      },
    },
    emailInputField: {
      state: formErrorState.phoneNumber ? 'Error' : 'Default',
      text: {
        value: t('complete_profile.labels.phone_number'),
      },
      textInput: {
        textValue: formState.phoneNumber,
        onTextChanged: handleTextChange('phoneNumber'),
        maxLength: 40,
        preProcessTextInput: acceptPhoneNumber,
      },
      contextualContent: {
        text: {
          value: formErrorState.phoneNumber,
        },
      },
    },
    address: {
      setAddressForm: handleAddressForm,
      setAddressFormError: handleAddressFormError,
      validateAddress: validateAddress,
      setIsAddressValid,
    },
    button: {
      icon: {
        asset: 'ArrowRight',
      },
      text: {
        value: t('button.complete_profile'),
      },
      loading: isLoading ? 'Loading' : 'Default',
      disabled: isLoading,
      onClick: handleCompleteProfile,
    },
  };
};

export default usePresenter;
