import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { LabelTextGroupListProps } from '../LabelTextGroupList';


type CourseDetailsSectionInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  labelTextGroupList?: LabelTextGroupListProps;
};

type CourseDetailsSectionPropsValues = {
  '': CourseDetailsSectionInternalProps;
};

const propValues: CourseDetailsSectionPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    labelTextGroupList: {
    },
  },
};

const getProps = (type: string): CourseDetailsSectionInternalProps => {
  const values: CourseDetailsSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
