import { DataEntry } from '../../../lib/types';
import { getCourseById } from '../../../modules/course/api';
import { Course } from '../../../modules/course/types';
import { validateTeacherByEmail } from '../../../modules/teacher/api';
import { TeacherValidationPayload } from '../../../modules/teacher/types';

export const getCourseByIdUseCase = async (courseId: number): Promise<DataEntry<Course>> => {
  return getCourseById(courseId);
};

export const validateTeacherByEmailUseCase = (email: string): Promise<TeacherValidationPayload> => {
  return validateTeacherByEmail(email);
};
