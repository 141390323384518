import React from 'react';
import cx from 'classnames';

import GenericStateBlock from '../../blocks/GenericStateBlock';

import styles from './OnWaitlistPage.module.scss';
import usePresenter from './OnWaitlistPage.presenter';
import getProps from './OnWaitlistPage.props';
import { OnWaitlistPageCombinedProps } from './types';


const OnWaitlistPage: React.FC<OnWaitlistPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    genericStateBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.onWaitlistPage;

  return (
    <div className={cx(currentStyle, className)}>
      <GenericStateBlock
        className={cx(styles.genericStateBlock, classes?.genericStateBlock)}
        {...internalProps.genericStateBlock}
        {...genericStateBlock}/>
    </div>
  );
};

export default OnWaitlistPage;

