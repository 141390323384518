import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { User } from '../../lib/token';
import { AuthContext } from '../auth';
import { getUser } from '../auth/api';
import { getDefaultPaymentInfo } from '../payment/api';
import { PaymentInfo } from '../payment/types';

export type UserContextValue = {
  user?: User;
  updateUser: (user?: User) => void;
  refetchUser: () => Promise<void>;
  defaultPaymentInfo?: PaymentInfo;
  updatePaymentInfo: (paymentInfo?: PaymentInfo) => void;
  refetchDefaultPaymentMethod: () => Promise<void>
  isExpired: boolean;
  isLoading?: boolean;
};

const initialUserContext: UserContextValue = {
  user: undefined,
  updateUser: () => {},
  refetchUser: () => Promise.resolve(),
  defaultPaymentInfo: undefined,
  updatePaymentInfo: () => {},
  refetchDefaultPaymentMethod: () => Promise.resolve(),
  isExpired: true,
};

export const UserContext = createContext<UserContextValue>(initialUserContext);

export const UserProvider: React.FC<{}> = ({ children }) => {
  const queryClient = useQueryClient();
  const [defaultPaymentInfo, setPaymentInfo] = useState<PaymentInfo | undefined>(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);
  const { account } = useContext(AuthContext);
  const [isExpired, setIsExpired] = useState<boolean>(true);

  const { data: paymentInfoData } = useQuery(['getDefaultPaymentInfo', user?.content?.uuid], async () => {
    if (user?.uuid) {
      try {
        const data = await getDefaultPaymentInfo(user.uuid);
        return data;
      } catch {
        return undefined;
      }
    }
  }, { enabled: !!user?.content?.customerId });

  const { data, isSuccess, refetch, isLoading } =  useQuery(
    'users/me',
    () => getUser(),
    { enabled: !!account },
  );
  useEffect(() => {
    if (account && isSuccess) {
      setUser(data);
    }
  }, [account, data, isSuccess]);

  useEffect(() => {
    setPaymentInfo(paymentInfoData);
  }, [paymentInfoData]);

  useEffect(() => {
    if (user?.content?.expiryDate) {
      setIsExpired(new Date(user.content.expiryDate).getTime() - Date.now() < 0);
    }
  }, [user?.content?.expiryDate]);

  const refetchUser = async () => {
    await refetch();
  };

  const updateUser = (newUser?: User) => {
    if (newUser) {
      setUser(newUser);
    }
  };

  const updatePaymentInfo = (updatedPaymentInfo?: PaymentInfo) => {
    if (updatedPaymentInfo) {
      setPaymentInfo(updatedPaymentInfo);
    }
  };

  const refetchDefaultPaymentMethod = async () => {
    await queryClient.invalidateQueries('getDefaultPaymentInfo');
  };

  return (
    <UserContext.Provider value={{
      user,
      updateUser,
      refetchUser,
      defaultPaymentInfo,
      updatePaymentInfo,
      refetchDefaultPaymentMethod,
      isExpired,
      isLoading,
    }}>
      {children}
    </UserContext.Provider>
  );
};