import React from 'react';
import cx from 'classnames';

import Image from '../../atoms/Image';
import Text from '../../atoms/Text';

import styles from './StaffItem.module.scss';
import usePresenter from './StaffItem.presenter';
import getProps from './StaffItem.props';
import { StaffItemCombinedProps } from './types';


const StaffItem: React.FC<StaffItemCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    topText,
    subText,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.staffItem;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <Image
          className={cx(styles.image, classes?.image)}
          {...internalProps.image}
          {...image}/>
        <div className={cx(styles.textContent, classes?.textContent)}>
          <Text
            className={cx(styles.topText, classes?.topText)}
            {...internalProps.topText}
            {...topText}/>
          <Text
            className={cx(styles.subText, classes?.subText)}
            {...internalProps.subText}
            {...subText}/>
        </div>
      </div>
    </div>
  );
};

export default StaffItem;

