import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import CheckboxItem from '../../atoms/CheckboxItem';
import Divider from '../../atoms/Divider';

import styles from './TeacherAgreementFooter.module.scss';
import usePresenter from './TeacherAgreementFooter.presenter';
import getProps from './TeacherAgreementFooter.props';
import { TeacherAgreementFooterCombinedProps } from './types';


const TeacherAgreementFooter: React.FC<TeacherAgreementFooterCombinedProps> = (props) => {
  const {
    className,
    classes,
    divider,
    checkboxItem,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.teacherAgreementFooter;

  return (
    <div className={cx(currentStyle, className)}>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
      <div className={cx(styles.content, classes?.content)}>
        <CheckboxItem
          className={cx(styles.checkboxItem, classes?.checkboxItem)}
          {...internalProps.checkboxItem}
          {...checkboxItem}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
    </div>
  );
};

export default TeacherAgreementFooter;

