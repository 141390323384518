import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import cx from 'classnames';

import { DASHBOARD_ROUTE } from '../../../lib/constants';
import MyProfileBlock from '../../blocks/MyProfileBlock';
import MyAccountHeader from '../../organisms/MyAccountHeader';
import StudentAppBlock from '../../blocks/StudentAppBlock';
import PaymentBlock from '../../blocks/PaymentBlock';

import styles from './MyProfilePage.module.scss';
import usePresenter from './MyProfilePage.presenter';
import getProps from './MyProfilePage.props';
import { MyProfilePageCombinedProps } from './types';
import LoadingSpinner from '../../atoms/LoadingSpinner';

const MyProfilePage: React.FC<MyProfilePageCombinedProps> = (props) => {
  const {
    isLoading,
    className,
    classes,
    myAccountHeader,
    myProfileBlock,
    studentAppBlock,
    paymentBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.myProfilePage;
  const spinner = (
    <LoadingSpinner
      className={cx(styles.loadingSpinner)}
      size='Huge'
      styleType='Primary'
      isActive
    />
  );
  const myProfileBlockView = isLoading ? spinner : (
    <MyProfileBlock
      className={cx(styles.myProfileBlock, classes?.myProfileBlock)}
      {...internalProps.myProfileBlock}
      {...myProfileBlock}/>
  );

  const studentAppBlockView = isLoading ? spinner : (
    <StudentAppBlock
      className={cx(styles.myProfileBlock, classes?.myProfileBlock)}
      {...internalProps.studentAppBlock}
      {...studentAppBlock}/>
  );

  const paymentBlockView = isLoading ? spinner : (
    <PaymentBlock
      className={cx(styles.myProfileBlock, classes?.myProfileBlock)}
      {...internalProps.paymentBlock}
      {...paymentBlock}/>
  );

  return (
    <div className={cx(currentStyle, className)}>
      <MyAccountHeader
        className={cx(styles.myAccountHeader, classes?.myAccountHeader)}
        {...internalProps.myAccountHeader}
        {...myAccountHeader}/>
      <div className={cx(styles.content, classes?.content)}>
      <Switch>
        <Redirect exact from={DASHBOARD_ROUTE.dashboard} to={DASHBOARD_ROUTE.profile} />
        <Route path={DASHBOARD_ROUTE.profile}>
          { myProfileBlockView }
          </Route>
        <Route path={DASHBOARD_ROUTE.studentApps}>
          { studentAppBlockView }
        </Route>
        <Route path={DASHBOARD_ROUTE.payment}>
          { paymentBlockView }
        </Route>
      </Switch>
      </div>
    </div>
  );
};


export default MyProfilePage;
