import { getProductDetails } from '../../../modules/payment/api';
import { PaymentIntent, PriceItem, Subscription } from '../../../modules/payment/types';
import { createAnnualSubscription, createTeacherPaymentIntent } from '../../../modules/teacher/api';
import { CreateAnnualSubscriptionPayload, CreateTeacherPaymentIntentPayload } from '../../../modules/teacher/types';

export const getProductDetailsUseCase = (slug: string): Promise<PriceItem> => {
  return getProductDetails(slug);
};

export const createTeacherPaymentIntentUseCase = async (
  teacherUuid: string,
  payload: CreateTeacherPaymentIntentPayload,
): Promise<PaymentIntent> => {
  return createTeacherPaymentIntent(teacherUuid, payload);
};

export const createTeacherAnnualSubscriptionUseCase = async (
  payload: CreateAnnualSubscriptionPayload,
): Promise<Subscription> => {
  return createAnnualSubscription(payload);
};