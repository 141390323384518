import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import AddCoTeacher from '../../molecules/AddCoTeacher';
import InputField from '../../molecules/InputField';
import RadioField from '../../molecules/RadioField';
import TextGroup from '../../molecules/TextGroup';
import TextAreaField from '../../molecules/TextAreaField';

import styles from './CreateCourseBasicDetailsBlock.module.scss';
import usePresenter from './CreateCourseBasicDetailsBlock.presenter';
import getProps from './CreateCourseBasicDetailsBlock.props';
import { CreateCourseBasicDetailsBlockCombinedProps } from './types';


const CreateCourseBasicDetailsBlock: React.FC<CreateCourseBasicDetailsBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    stepperText,
    textGroup,
    courseNameInputField,
    radioField,
    courseDescriptionTextAreaField,
    classCapacityInputField,
    addCoTeacher,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.createCourseBasicDetailsBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.stepperText, classes?.stepperText)}
          {...internalProps.stepperText}
          {...stepperText}/>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <InputField
          className={cx(styles.courseNameInputField, classes?.courseNameInputField)}
          {...internalProps.courseNameInputField}
          {...courseNameInputField}/>
        <RadioField
          className={cx(styles.radioField, classes?.radioField)}
          {...internalProps.radioField}
          {...radioField}/>
        <TextAreaField
          className={cx(styles.courseDescriptionTextAreaField, classes?.courseDescriptionTextAreaField)}
          {...internalProps.courseDescriptionTextAreaField}
          {...courseDescriptionTextAreaField}/>
        <InputField
          className={cx(styles.classCapacityInputField, classes?.classCapacityInputField)}
          {...internalProps.classCapacityInputField}
          {...classCapacityInputField}/>
        <AddCoTeacher
          className={cx(styles.addCoTeacher, classes?.addCoTeacher)}
          {...internalProps.addCoTeacher}
          {...addCoTeacher}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
    </div>
  );
};

export default CreateCourseBasicDetailsBlock;

