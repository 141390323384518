import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Toggle from '../../molecules/Toggle';
import LogEntriesCatalog from '../../organisms/LogEntriesCatalog';
import LogEntry from '../../organisms/LogEntry';
import LogEntryStatusList from '../../organisms/LogEntryStatusList';
import ReplySection from '../../organisms/ReplySection';
import TextMessageList from '../../organisms/TextMessageList';

import styles from './LogbookBlock.module.scss';
import usePresenter from './LogbookBlock.presenter';
import getProps from './LogbookBlock.props';
import { LogbookBlockCombinedProps } from './types';
import GenericStateNoActionBlock from '../GenericStateNoActionBlock';
import LoadingSpinner from '../../atoms/LoadingSpinner';


const LogbookBlock: React.FC<LogbookBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    toggle,
    divider,
    logEntryStatusList,
    divider1,
    logEntriesCatalog,
    divider2,
    logEntry,
    textMessageList,
    replySection,
    isLogSelected, // MANUAL OVERRIDE
    noLogSelectedState, // MANUAL OVERRIDE
    isLoading,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.logbookBlock;
  const spinner = (
    <LoadingSpinner
      className={cx(styles.loadingSpinner)}
      size='Huge'
      styleType='Primary'
      isActive
    />
  );

  const rightContentView = isLogSelected ? (
    <>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <LogEntry
          className={cx(styles.logEntry, classes?.logEntry)}
          {...internalProps.logEntry}
          {...logEntry}/>
        <TextMessageList
          className={cx(styles.textMessageList, classes?.textMessageList)}
          {...internalProps.textMessageList}
          {...textMessageList}/>
      </div>
      <div className={cx(styles.bottomContent1, classes?.bottomContent1)}>
        <ReplySection
          className={cx(styles.replySection, classes?.replySection)}
          {...internalProps.replySection}
          {...replySection}/>
      </div>
    </>
  ) : (
    <GenericStateNoActionBlock
      className={cx(styles.noLogSelectedState, classes?.noLogSelectedState)}
      {...internalProps.noLogSelectedState}
      {...noLogSelectedState}/>
  );

  return isLoading ? spinner : (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.leftContent, classes?.leftContent)}>
        <div className={cx(styles.topSection, classes?.topSection)}>
          <div className={cx(styles.content, classes?.content)}>
            <Toggle
              className={cx(styles.toggle, classes?.toggle)}
              {...internalProps.toggle}
              {...toggle}/>
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <div className={cx(styles.content1, classes?.content1)}>
            <LogEntryStatusList
              className={cx(styles.logEntryStatusList, classes?.logEntryStatusList)}
              {...internalProps.logEntryStatusList}
              {...logEntryStatusList}/>
          </div>
          <Divider
            className={cx(styles.divider1, classes?.divider1)}
            {...internalProps.divider1}
            {...divider1}/>
        </div>
        <div className={cx(styles.bottomSection, classes?.bottomSection)}>
          <LogEntriesCatalog
            className={cx(styles.logEntriesCatalog, classes?.logEntriesCatalog)}
            {...internalProps.logEntriesCatalog}
            {...logEntriesCatalog}/>
        </div>
      </div>
      <Divider
        className={cx(styles.divider2, classes?.divider2)}
        {...internalProps.divider2}
        {...divider2}/>
      <div className={cx(styles.rightContent, classes?.rightContent)}>
        {rightContentView}
      </div>
    </div>
  );
};

export default LogbookBlock;

