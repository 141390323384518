import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';


type CourseRegistrationSubheaderInternalProps = {
  titleText?: TextProps;
  bodyText?: TextProps;
  button?: ButtonProps;
  divider?: DividerProps;
};

type CourseRegistrationSubheaderPropsValues = {
  '': CourseRegistrationSubheaderInternalProps;
};

const propValues: CourseRegistrationSubheaderPropsValues = {
  '': {
    titleText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading1',
    },
    bodyText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
};

const getProps = (type: string): CourseRegistrationSubheaderInternalProps => {
  const values: CourseRegistrationSubheaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
