import { DividerProps } from '../../atoms/Divider';
import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { RegistrationStatusTagProps } from '../../atoms/RegistrationStatusTag';
import { TextProps } from '../../atoms/Text';
import { ClassItemProps } from '../ClassItem';
import { IconTextGroupProps } from '../IconTextGroup';
import { IconDropDownProps } from '../IconDropDown';


type CourseCardInternalProps = {
  registrationStatusTag?: RegistrationStatusTagProps;
  iconDropdown?: IconDropDownProps; // MANUAL OVERRIDE
  text?: TextProps;
  classItem?: ClassItemProps;
  topDivider?: DividerProps;
  iconTextGroup?: IconTextGroupProps;
  bottomDivider?: DividerProps;
  button1?: ButtonProps;
  button2?: ButtonProps;
  icon?: IconProps;
};

type CourseCardPropsValues = {
  'Course': CourseCardInternalProps;
  'CreateNew': CourseCardInternalProps;
};

const propValues: CourseCardPropsValues = {
  'Course': {
    registrationStatusTag: {
      type: 'Open',
    },
    iconDropdown: {}, // MANUAL OVERRIDE
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Inverse',
      type: 'Heading2',
    },
    classItem: {
    },
    topDivider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    iconTextGroup: {
    },
    bottomDivider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    button1: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
  'CreateNew': {
    icon: {
      asset: 'AddSquareFilled',
      colour: 'Info',
    },
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Info',
      type: 'Label1',
    },
    button2: {
      style: 'Text',
      size: 'Small',
      type: 'IconText',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): CourseCardInternalProps => {
  const values: CourseCardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
