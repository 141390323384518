import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import Image from '../../atoms/Image';

import styles from './GenericStateBlock.module.scss';
import usePresenter from './GenericStateBlock.presenter';
import getProps from './GenericStateBlock.props';
import { GenericStateBlockCombinedProps } from './types';


const GenericStateBlock: React.FC<GenericStateBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    image,
    topText,
    bottomText,
    button,
    hideButton,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.genericStateBlock;

  const buttonView = hideButton ? undefined : (
    <div className={cx(styles.bottomContent, classes?.bottomContent)}>
      <Button
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button}/>
    </div>
  );

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Image
          className={cx(styles.image, classes?.image)}
          {...internalProps.image}
          {...image}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <div className={cx(styles.textGroupTop, classes?.textGroupTop)}>
          <Text
            className={cx(styles.topText, classes?.topText)}
            {...internalProps.topText}
            {...topText}/>
          <Text
            className={cx(styles.bottomText, classes?.bottomText)}
            {...internalProps.bottomText}
            {...bottomText}/>
        </div>
      </div>
      { buttonView }
    </div>
  );
};

export default GenericStateBlock;
