import { Subscription } from '../../../modules/payment/types';
import { removePaymentMethod, sendPaymentReceipt, updateAnnualSubscription } from '../../../modules/teacher/api';
import { UpdateAnnualSubscriptionPayload } from '../../../modules/teacher/types';

export const sendPaymentReceiptUseCase = async (teacherUuid: string, paymentId: string): Promise<void> => {
  await sendPaymentReceipt(teacherUuid, paymentId);
};

export const updateTeacherAnnualSubscriptionUseCase = async (
  uuid: string,
  payload: UpdateAnnualSubscriptionPayload,
): Promise<Subscription> => {
  return updateAnnualSubscription(uuid, payload);
};

export const removePaymentMethodUseCase = async (
  uuid: string,
): Promise<void> => {
  return removePaymentMethod(uuid);
};