import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';

import usePresenter from './IconDropDown.presenter';
import getProps from './IconDropDown.props';
import { IconDropDownCombinedProps } from './types';

import styles from './IconDropDown.module.scss';

const IconDropDown = React.forwardRef<HTMLButtonElement, IconDropDownCombinedProps>((props, ref) => {
  const {
    classes,
    button,
    className,
    forwardRef,
    ...otherProps
  } = usePresenter(props);
  const internalProps = getProps('');

  return (
    <div className={cx(className)}>
      <Button
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        ref={ref || forwardRef}
        {...button}
        {...otherProps}
        />
    </div>
  );
});

export default IconDropDown;