import { CoursesBlockProps } from '../../blocks/CoursesBlock';
import { CoursesHeaderProps } from '../../organisms/CoursesHeader';


type ArchivedCoursesPageInternalProps = {
  coursesHeader?: CoursesHeaderProps;
  coursesBlock?: CoursesBlockProps;
};

type ArchivedCoursesPagePropsValues = {
  '': ArchivedCoursesPageInternalProps;
};

const propValues: ArchivedCoursesPagePropsValues = {
  '': {
    coursesHeader: {
    },
    coursesBlock: {
    },
  },
};

const getProps = (type: string): ArchivedCoursesPageInternalProps => {
  const values: ArchivedCoursesPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
