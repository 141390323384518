import { StripeCardElementOptions } from '@stripe/stripe-js';
import { KORU_DASHBOARD_URL } from './config';

export const AUTH_ROUTES = {
  signUp: '/auth/signUp',
  signIn: '/auth/signIn',
  expiredInvitation: '/auth/expiredInvitation',
  forgotPassword: '/auth/forgotPassword',
  forgotPasswordConfirmed: '/auth/forgotPassword/confirmed',
  resetPassword: '/auth/resetPassword',
  resetPasswordExpired: '/auth/resetPasswordExpired',
  updatePassword: '/auth/updatePassword',
  updatedPasswordConfirmed: '/auth/updatePassword/confirmed',
  invalidInvite: '/auth/invalidInvite',
  accountCreated: '/auth/accountCreated',
  alreadyRegistered: '/auth/alreadyRegistered',
  completeProfile: '/auth/completeProfile',
  tuitionPayment: '/auth/tuitionPayment',
  certificationAgreement: '/auth/certificationAgreement',
  welcomeToKoru: '/auth/welcomeToKoru',
};

export const DASHBOARD_ROUTE = {
  dashboard: '/dashboard',
  profile: '/dashboard/profile',
  studentApps: '/dashboard/studentApps',
  payment: '/dashboard/payment',
};

export const ACCOUNT_STATUS_ROUTE = {
  congratulations: '/congratulations', 
  subscriptionExpired: '/subscriptionExpired',
};

export const COURSE_ROUTES = {
  courses: '/courses',
  editCourse: {
    step1: '/courses/detail/:id/basicInfo',
    step2: '/courses/detail/:id/classInfo',
  },
  courseDetail: '/courses/detail/:id',
  courseRoster: '/courses/detail/:id/roster',
  courseLogbookDetail: '/courses/detail/:id/logbook',
  active: '/courses/active',
  archived: '/courses/archived',
  newCourse: {
    step1: '/courses/new/basicInfo',
    step2: '/courses/new/classInfo',
  },
};

export const STUDENT_ROUTES = {
  courseRegistration: '/courseRegistration/:id',
  auth: {
    base: '/student/auth',
    signUp: '/student/auth/signUp',
    signIn: '/student/auth/signIn',
    confirmAttendence: '/student/auth/confirmAttendance',
    verifyEmail: '/student/auth/verifyEmail',
    payment: '/student/auth/payment',
    updatedPassword: '/student/auth/updatedPassword',
    registrationSuccess: '/student/auth/registrationSuccess',
    joinWaitlist: '/student/auth/joinWaitlist',
    joinWaitlistSuccess: '/student/auth/joinWaitlistSuccess',
    noSpotAvailable: '/student/auth/noSpotAvailable',
  },
};

export const NOT_FOUND_ROUTE = '/404';

export const SIGNUP_STEPS = 4;

export const FREE_LINK_AND_APPS_SIGN_IN_JOURNEY = {
  attendance: 1,
  total: 1,
};

export const FREE_LINK_NO_APPS_SIGN_IN_JOURNEY = {
  attendance: 1,
  appFee: 2,
  total: 2,
};

export const FREE_LINK_SIGN_UP_JOURNEY = {
  createAccount: 1,
  verifyEmail: 2,
  logIn: 3,
  attendance: 4,
  total: 4,
};

export const REGULAR_SIGN_UP_JOURNEY = {
  createAccount: 1,
  verifyEmail: 2,
  logIn: 3,
  attendance: 4,
  appFee: 5,
  total: 5,
};

export const JOIN_WAITLIST_SIGN_UP = {
  createAccount: 1,
  verifyEmail: 2,
  total: 2,
};

export const GTM_EVENTS = {
  successfulTuitionPayment: 'successful_tuition_payment',
  studentAppsPurchase: 'student_apps_purchase',
  successfulEmailVerification: 'successful_email_verification',
  successfulCourseCreation: 'successful_course_creation',
  viewPurchaseStudentAppsModal: 'view_purchase_student_apps_modal',
  viewPurchaseAnnualPlanModal: 'view_purchase_annual_plan_modal',
  annualPlanPurchase: 'annual_plan_purchase',
  successfulCourseRegistrationFree: 'successful_course_registration_free',
  successfulCourseRegistrationPaid: 'successful_course_registration_paid',
};

export const KORU_TERMS_OF_USE_URL = `${KORU_DASHBOARD_URL}/terms-of-use`;
export const KORU_PRIVACY_POLICY_URL = `${KORU_DASHBOARD_URL}/privacy-policy`;
export const KORU_TEACHER_ANNUAL_PLAN_URL = `${KORU_DASHBOARD_URL}/teacher-certification-costs/#annual-plan`;
export const KORU_SUPPORT_URL = `${KORU_DASHBOARD_URL}/resources-support/?top-category=tech-support`;
export const KORU_EVENTS_URL = `${KORU_DASHBOARD_URL}/schedule`;
export const KORU_TEACHER_DIR_URL = `${KORU_DASHBOARD_URL}/teacher-directory-map`;
export const KORU_TEACHER_CERT_ARG_URL = `${KORU_DASHBOARD_URL}/certification-agreement`;
export const KORU_TEACHER_SUBMIT_INFO_URL = `${KORU_DASHBOARD_URL}/teacher-directory-submit/`;
export const KORU_STUDENT_APP_SUPPORT_URL = `${KORU_DASHBOARD_URL}/resources-support/tech-support/course-management/pre-paying-for-the-student-app/`;

export const APP_DOWNLOAD_LINKS = {
  appStore: 'https://apps.apple.com/us/app/koru-mindfulness/id1127358341',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.koru',
};

export const SIDE_NAV_LINKS = {
  teacher_training: `${KORU_DASHBOARD_URL}/training/`,
  events: `${KORU_DASHBOARD_URL}/schedule/`,
  forum: `${KORU_DASHBOARD_URL}/forums/`,
  resource_support: `${KORU_DASHBOARD_URL}/resources-support/`,
};

export const PUBLIC_ROUTES = [
  AUTH_ROUTES.signIn,
  STUDENT_ROUTES.auth.signUp,
  STUDENT_ROUTES.auth.signIn,
];

export const EXPIRED_DISCOUNT_COUPON_MESSAGE = 'Expired coupon';

export const PAYMENT_REQUIRED_MESSAGE = 'Payment required.';
export const STUDENT_ALREADY_ENROLLED_MESSAGE = 'Student is already enrolled.';
export const REGISTRATION_CLOSED_MESSAGE = 'Course is closed for registration.';
export const CAPACITY_REACHED_MESSAGE = 'Class capacity reached.';
export const STUDENT_ALREADY_WAITLISTED_MESSAGE = 'Student is already waitlisted.';
export const STUDENT_ROSTER_INSTANCE_NOT_FOUND = 'Student roster instance not found.';
export const CLASS_CAPACITY_CANT_BE_LOWERED = 'Class capacity cannot be lower than current number of enrolled students';
export const USER_NOT_VERIFIED_MESSAGE = 'User has not confirmed sign up';
export const DUPLICATE_PHONE_NUMBER = 'Unable to create student, duplicate phone number';
export const DUPLICATE_EMAIL = 'Unable to create student, duplicate email';
export const MIGRATION_NOT_COMPLETE_MESSAGE = 'Migration not complete';
export const TEACHER_ALREADY_SUBSCRIBED = 'Teacher has an active subscription';

export const SPOT_AVAILABLE_TOKEN_QUERY_PARAM = 'spotAvailableToken';

export const NOTIFICATION_DURATION = 3000;

export const MAX_NAME_LENGTH = 80;
export const MAX_EMAIL_LENGTH = 255;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 128;
export const MAX_PHONE_NUMBER_LENGTH = 40;

export const MAX_PURCHASED_EXTENSIONS = 2;

export const VALIDATION_COUNTRY_LIST = {
  canada: 'Canada',
  unitedStates: 'United States',
};

export const TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  hour12: true,
  timeStyle: 'short',
};

export const COURSE_DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  month: 'short',
  day: '2-digit',
  year: 'numeric',
};

export const COURSE_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  hour12: true,
  minute: '2-digit',
  hour: 'numeric',
};

export const STRIPE_CARD_ELEMENT_CONFIG: StripeCardElementOptions = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      color: '#000000',
      fontFamily: 'Cabin, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#747474',
      },
    },
    invalid: {
      color: '#B73831',
      iconColor: '#B73831',
    },
  },
};

export const US_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const CANADA_PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon',
];

export const REGEX_FIRST_AND_LAST_NAME = /[^a-zA-Z -]/gi;
export const REGEX_ACCEPT_PHONE_NUMBER = /[^+0-9]/gi;

export const COUNTRY_CODE_LIST = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo, Democratic Republic of the', code: 'CD' },
  { name: 'Congo, Republic of the', code: 'CG' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Curaçao', code: 'CW' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Côte d’Ivoire', code: 'CI' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Eswatini (Swaziland)', code: 'SZ' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard and McDonald Islands', code: 'HM' },
  { name: 'Holy See', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lao People’s Democratic Republic', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macau', code: 'MO' },
  { name: 'Macedonia', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia', code: 'FM' },
  { name: 'Moldova', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'North Korea', code: 'KP' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestine, State of', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russia', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Réunion', code: 'RE' },
  { name: 'Saint Barthélemy', code: 'BL' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Martin', code: 'MF' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia', code: 'RS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Sint Maarten', code: 'SX' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia', code: 'GS' },
  { name: 'South Korea', code: 'KR' },
  { name: 'South Sudan', code: 'SS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen Islands', code: 'SJ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syria', code: 'SY' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'US Minor Outlying Islands', code: 'UM' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
  { name: 'Åland Islands', code: 'AX' },
];
