import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { AddressProps } from '../../molecules/Address';
import { InputFieldProps } from '../../molecules/InputField';
import { DiscountCodeProps } from '../../molecules/DiscountCode';
import { PriceListItemProps } from '../../molecules/PriceListItem';
import { RadioFieldProps } from '../../molecules/RadioField';
import { TextGroupProps } from '../../molecules/TextGroup';
import { PriceItemListProps } from '../../organisms/PriceItemList';
import { InlineNotificationProps } from '../../atoms/InlineNotification';


type TuitionPaymentBlockInternalProps = {
  stepperText?: TextProps;
  textGroup?: TextGroupProps;
  priceItemList?: PriceItemListProps;
  discountCode?: DiscountCodeProps;
  priceListItem?: PriceListItemProps;
  cardInfoField?: InputFieldProps;
  radioField?: RadioFieldProps;
  address?: AddressProps;
  button?: ButtonProps;
  inlineNotification?: InlineNotificationProps;
};

type TuitionPaymentBlockPropsValues = {
  '': TuitionPaymentBlockInternalProps;
};

const propValues: TuitionPaymentBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    textGroup: {
    },
    priceItemList: {
    },
    discountCode: {
      state: 'Default',
    },
    priceListItem: {
      type: 'Total',
    },
    cardInfoField: {
      state: 'Default',
    },
    radioField: {
    },
    address: {
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
    },
    inlineNotification: {
      type: 'Error',
    },
  },
};

const getProps = (type: string): TuitionPaymentBlockInternalProps => {
  const values: TuitionPaymentBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
