import { TextProps } from '../Text';


type TeacherStatusTagInternalProps = {
  text?: TextProps;
};

type TeacherStatusTagPropsValues = { //MANUAL OVERRIDE
  'Certified': TeacherStatusTagInternalProps;
  'Graduate': TeacherStatusTagInternalProps;
  'In-Training': TeacherStatusTagInternalProps;
  'Under Review': TeacherStatusTagInternalProps;
};

const propValues: TeacherStatusTagPropsValues = {
  'Certified': {
    text: {
      style: 'Bold',
      align: 'Center',
      colour: 'Default',
      type: 'Body2',
    },
  },
  'Graduate': {
    text: {
      style: 'Bold',
      align: 'Center',
      colour: 'Default',
      type: 'Body2',
    },
  },
  'In-Training': {
    text: {
      style: 'Bold',
      align: 'Center',
      colour: 'Default',
      type: 'Body2',
    },
  },
  'Under Review': {
    text: {
      style: 'Bold',
      align: 'Center',
      colour: 'Default',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): TeacherStatusTagInternalProps => {
  const values: TeacherStatusTagInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
