import { CourseTeacher } from '../modules/course/types';
import { Student } from '../modules/student/types';
import { Account, User } from './token';

export type ServiceErrorResponse = {
  code: number;
  type: string;
  message: string;
};

export type DefaultMetrics = {
  user: User;
  pageView: string;
  sessionDuration: number;
};

export type Person = Account | DataEntry<CourseTeacher | Student>;

export type DataEntry<T> = {
  uuid: string;
  content: T;
  databaseSlug: string;
  tableSlug: string;
  createdAt: Date;
  updatedAt: Date;
};

export const isPersonAccount = (value: Account | DataEntry<unknown>): value is Account => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !(value as any).content;
};

export const isDataEntry = <T>(value: string | DataEntry<T> | T): value is DataEntry<T> => {
  return typeof value !== 'string';
};
