import { SelectFieldProps } from '../SelectField';


type SelectFieldGroupInternalProps = {
  selectFieldOne?: SelectFieldProps;
  selectFieldTwo?: SelectFieldProps;
};

type SelectFieldGroupPropsValues = {
  '': SelectFieldGroupInternalProps;
};

const propValues: SelectFieldGroupPropsValues = {
  '': {
    selectFieldOne: {
      state: 'Default',
    },
    selectFieldTwo: {
      state: 'Default',
    },
  },
};

const getProps = (type: string): SelectFieldGroupInternalProps => {
  const values: SelectFieldGroupInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
