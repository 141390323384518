import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';
import { LogEntryStatusProps } from '../../atoms/LogEntryStatus';
import { TextProps } from '../../atoms/Text';


type LogEntryItemInternalProps = {
  nameText?: TextProps;
  timeStampText?: TextProps;
  logEntryStatus?: LogEntryStatusProps;
  previewText?: TextProps;
  divider?: DividerProps;
  icon?: IconProps;
};

type LogEntryItemPropsValues = {
  'Default': LogEntryItemInternalProps;
  'New': LogEntryItemInternalProps;
  'Selected': LogEntryItemInternalProps;
};

const propValues: LogEntryItemPropsValues = {
  'Default': {
    nameText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    timeStampText: {
      style: 'Regular',
      align: 'Right',
      colour: 'Default',
      type: 'Body2',
    },
    logEntryStatus: {
      type: 'AwaitingReply',
      style: 'Default',
    },
    previewText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body2',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
  'New': {
    icon: {
      asset: 'DotNew',
      colour: 'New',
    },
    nameText: {
      style: 'Bold',
      align: 'Left',
      colour: 'New',
      type: 'Body2',
    },
    timeStampText: {
      style: 'Regular',
      align: 'Right',
      colour: 'Default',
      type: 'Body2',
    },
    logEntryStatus: {
      type: 'AwaitingReply',
      style: 'Default',
    },
    previewText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body2',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
  'Selected': {
    nameText: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    timeStampText: {
      style: 'Regular',
      align: 'Right',
      colour: 'Default',
      type: 'Body2',
    },
    logEntryStatus: {
      type: 'AwaitingReply',
      style: 'Default',
    },
    previewText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body2',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
};

const getProps = (type: string): LogEntryItemInternalProps => {
  const values: LogEntryItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
