import { TextProps } from '../Text';


type ContextualMenuItemInternalProps = {
  text?: TextProps;
};

type ContextualMenuItemPropsValues = {
  'Critical': ContextualMenuItemInternalProps;
  'Default': ContextualMenuItemInternalProps;
};

const propValues: ContextualMenuItemPropsValues = {
  'Critical': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Critical',
      type: 'Body1',
    },
  },
  'Default': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
};

const getProps = (type: string): ContextualMenuItemInternalProps => {
  const values: ContextualMenuItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
