import React from 'react';
import cx from 'classnames';

import styles from './NotificationContainer.module.scss';

import { NotificationContainerProps } from './types';
import { useNotification } from './NotificationContext';
import ToastNotification from '../../components/atoms/ToastNotification';

const NotificationContainer: React.FC<NotificationContainerProps> = (props) => {
  const { className } = props;
  const { notifications } = useNotification();

  return (
    <div className={cx(styles.notificationContainer, className)}>
      {notifications.map((notification) => (
        <ToastNotification
          key={notification.id}
          type={notification.type}
          text={{ value: notification.message }}
        />
      ))}
    </div>
  );
};

export default NotificationContainer;
