import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import Divider from '../../atoms/Divider';
import ProductList from '../ProductList';

import styles from './PaymentHistorySection.module.scss';
import usePresenter from './PaymentHistorySection.presenter';
import getProps from './PaymentHistorySection.props';
import { PaymentHistorySectionCombinedProps } from './types';


const PaymentHistorySection: React.FC<PaymentHistorySectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,
    dateText,
    amountText,
    productNameText,
    linkText,
    productList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.paymentHistorySection;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <div className={cx(styles.tableHeader, classes?.tableHeader)}>
        <Text
          className={cx(styles.dateText, classes?.dateText)}
          {...internalProps.dateText}
          {...dateText}/>
        <Text
          className={cx(styles.amountText, classes?.amountText)}
          {...internalProps.amountText}
          {...amountText}/>
        <Text
          className={cx(styles.productNameText, classes?.productNameText)}
          {...internalProps.productNameText}
          {...productNameText}/>
        <Text
          className={cx(styles.linkText, classes?.linkText)}
          {...internalProps.linkText}
          {...linkText}/>
      </div>
      <ProductList
        className={cx(styles.productList, classes?.productList)}
        {...internalProps.productList}
        {...productList}/>
    </div>
  );
};

export default PaymentHistorySection;

