import { useDropdownMenu } from 'react-overlays';
import { ContextualMenuCombinedProps } from './types';

const usePresenter = (props: ContextualMenuCombinedProps): ContextualMenuCombinedProps => {

  const popperConfig = {
    modifiers: [{
      name: 'flip',
      options: {
        boundary: props.containerRef?.current || 'viewport',
      },
    }],
  };

  const [menuProps, { toggle, show }] = useDropdownMenu({
    flip: true,
    offset: props.offset || [0, 0],
    usePopper: true,
    popperConfig,
  });

  return {
    ...props,
    ...menuProps,
    role: 'menu',
    contextualMenuList: {
      ...props.contextualMenuList,
      toggle,
    },
    isMenuOpen: show,
  };
};

export default usePresenter;
