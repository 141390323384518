import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { AUTH_ROUTES, NOTIFICATION_DURATION, STUDENT_ROUTES } from '../../../lib/constants';
import { EmailState } from '../../../modules/account/types';
import { forgotPasswordUseCase } from '../ForgotPasswordBlock/ForgotPasswordBlock.interactor';
import { ForgotPasswordSuccessBlockCombinedProps } from './types';
import styles from './ForgotPasswordSuccessBlock.module.scss';
import { useNotification } from '../../../modules/notification';
import { CourseState, ResetPasswordState } from '../ForgotPasswordBlock/types';

const getResendPasswordLinkTrans = (onClick: () => void): React.ReactNode => {
  return (
    <Trans
      i18nKey="forgot_password_success.text_group_1.description"
      components={{
        resendPasswordButton: (
          <button
            onClick={onClick}
            className={styles.resendPasswordButton}
          />
        ),
      }}
    />
  );
};

const usePresenter = (props: ForgotPasswordSuccessBlockCombinedProps): ForgotPasswordSuccessBlockCombinedProps => {
  const { t } = useTranslation();
  const history  = useHistory();
  const [email, setEmail] = useState<string>('');
  const { state } = useLocation<EmailState & ResetPasswordState & CourseState>();
  const { trigger } = useNotification();

  useEffect(() => {
    if (state && state.email) {
      setEmail(state.email);
    }
  }, [state]);

  const { mutateAsync: resendPasswordLink } = useMutation(['forgotPassword', email], async () => {
    if (email) {
      await forgotPasswordUseCase(email);
    }
  });

  const handleCloseButtonClick = () => {
    if (state?.isStudentFlow) {
      const { course } = state;
      history.replace(STUDENT_ROUTES.auth.signIn, { ...course });
    } else {
      history.replace(AUTH_ROUTES.signIn);
    }
  };

  const handleSendResetEmailCLick = async () => {
    if (email) {
      try {
        await resendPasswordLink();
        trigger({
          duration: NOTIFICATION_DURATION,
          message: t('forgot_password_success.success_notification'),
          type: 'Success',
        });
      } catch (error) {
        // TODO: show error for not able to send reset password link
      }
    }
  };

  return {
    ...props,
    button: {
      text: {
        value: t('forgot_password_success.close_button'),
      },
      onClick: handleCloseButtonClick,
    },
    textGroup: {
      topText: {
        value: t('forgot_password_success.text_group.title'),
      },
      bottomText: {
        value: t('forgot_password_success.text_group.description'),
      },
    },
    textGroup1: {
      topText: {
        value: t('forgot_password_success.text_group_1.title'),
        type: 'Heading4',
      },
      bottomText: {
        value: getResendPasswordLinkTrans(handleSendResetEmailCLick),
      },
    },
  };
};

export default usePresenter;
