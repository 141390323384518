import { HeaderOnlyLayoutCombinedProps } from './types';
import KoruLogo from '../../../resources/icons/KoruLogo.svg';

const usePresenter = (props: HeaderOnlyLayoutCombinedProps): HeaderOnlyLayoutCombinedProps => {
  return {
    ...props,
    header1: {
      koruLogo: {
        imageSrc: KoruLogo,
      },
    },
  };
};

export default usePresenter;
