import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { InputFieldProps } from '../../molecules/InputField';
import { InputFieldGroupProps } from '../../molecules/InputFieldGroup';
import { TextGroupProps } from '../../molecules/TextGroup';


type SignupBlockInternalProps = {
  stepperText?: TextProps;
  textGroup?: TextGroupProps;
  inputFieldGroup?: InputFieldGroupProps;
  emailInputField?: InputFieldProps;
  passwordInputField?: InputFieldProps;
  confirmPasswordInputField?: InputFieldProps;
  button?: ButtonProps;
  legalText?: TextProps;
};

type SignupBlockPropsValues = {
  '': SignupBlockInternalProps;
};

const propValues: SignupBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    textGroup: {
    },
    inputFieldGroup: {
    },
    emailInputField: {
      state: 'Default',
    },
    passwordInputField: {
      state: 'Default',
    },
    confirmPasswordInputField: {
      state: 'Default',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
    },
    legalText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): SignupBlockInternalProps => {
  const values: SignupBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
