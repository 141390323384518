import React from 'react';
import cx from 'classnames';
import { Route, Switch } from 'react-router';

import { ACCOUNT_STATUS_ROUTE, STUDENT_ROUTES } from '../../../lib/constants';
import ProtectedRoute from '../../../modules/auth/ProtectedRoute';
import Header from '../../organisms/Header';
import CongratulationsPage from '../../pages/CongratulationsPage';
import AccountExpiredPage from '../../pages/AccountExpiredPage';
import StudentKoruAccountPage from '../../pages/StudentKoruAccountPage';
import RegistrationSuccessPage from '../../pages/RegistrationSuccessPage';
import OnWaitlistPage from '../../pages/OnWaitlistPage';

import styles from './HeaderOnlyLayout.module.scss';
import usePresenter from './HeaderOnlyLayout.presenter';
import getProps from './HeaderOnlyLayout.props';
import { HeaderOnlyLayoutCombinedProps } from './types';
import NoSpotAvailablePage from '../../pages/NoSpotAvailablePage';

const HeaderOnlyLayout: React.FC<HeaderOnlyLayoutCombinedProps> = (props) => {
  const {
    className,
    classes,
    header1,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.headerOnlyLayout;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Header
          className={cx(styles.header1, classes?.header1)}
          {...internalProps.header1}
          {...header1}/>
      </div>
      <div className={cx(styles.body, classes?.body)}>
        <Switch>
          <ProtectedRoute path={STUDENT_ROUTES.auth.registrationSuccess}>
            <RegistrationSuccessPage className={styles.registrationSuccess}/>
          </ProtectedRoute>
          <ProtectedRoute exact path={[
            STUDENT_ROUTES.auth.joinWaitlist,
            STUDENT_ROUTES.auth.joinWaitlistSuccess,
          ]}>
            <OnWaitlistPage className={styles.onWaitlist}/>
          </ProtectedRoute>
          <ProtectedRoute path={STUDENT_ROUTES.auth.noSpotAvailable}>
            <NoSpotAvailablePage className={styles.expired} />
          </ProtectedRoute>
          <Route path={STUDENT_ROUTES.auth.base}>
            <StudentKoruAccountPage className={styles.studentKoruAccountPage} />
          </Route>
          <ProtectedRoute path={ACCOUNT_STATUS_ROUTE.congratulations}>
            <CongratulationsPage className={styles.congratulations} />
          </ProtectedRoute>
          <ProtectedRoute path={ACCOUNT_STATUS_ROUTE.subscriptionExpired}>
            <AccountExpiredPage className={styles.expired} />
          </ProtectedRoute>
        </Switch>
      </div>
    </div>
  );
};

export default HeaderOnlyLayout;

