import React from 'react';
import DropdownWrapper, { ExportedDropdownProps } from '../../../modules/common/DropdownWrapper';
import InternalSelect from './Select';
import {
  SelectProps,
  SelectValueProps as InternalSelectValueProps,
} from './types';
import styles from './Select.module.scss';
import ContextualMenu from '../../organisms/ContextualMenu';
import { ContextualMenuCombinedProps } from '../../organisms/ContextualMenu/types';

export type { SelectProps };

export type SelectValueProps = ExportedDropdownProps<
InternalSelectValueProps & SelectProps,
ContextualMenuCombinedProps
>;

const Select: React.FC<SelectValueProps> = ({ id, toggleProps, menuProps, className, onToggle }) => (
  <DropdownWrapper
    id={id}
    Toggle={InternalSelect}
    Menu={ContextualMenu}
    toggleProps={toggleProps}
    menuProps={menuProps}
    className={`${className} ${styles.select}`}
    onToggle={onToggle}
  />
);

export default Select;
