import { ButtonProps } from '../../atoms/Button';
import { ChipFieldProps } from '../ChipField';


type AddCoTeacherInternalProps = {
  button?: ButtonProps;
  chipField?: ChipFieldProps;
};

type AddCoTeacherPropsValues = {
  'Default': AddCoTeacherInternalProps;
  'Expanded': AddCoTeacherInternalProps;
};

const propValues: AddCoTeacherPropsValues = {
  'Default': {
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
  'Expanded': {
    chipField: {},
  },
};

const getProps = (type: string): AddCoTeacherInternalProps => {
  const values: AddCoTeacherInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
