import { LogbookBlockProps } from '../../blocks/LogbookBlock';
import { CourseHeaderProps } from '../../organisms/CourseHeader';


type LogbookPageInternalProps = {
  courseHeader?: CourseHeaderProps;
  logbookBlock?: LogbookBlockProps;
};

type LogbookPagePropsValues = {
  '': LogbookPageInternalProps;
};

const propValues: LogbookPagePropsValues = {
  '': {
    courseHeader: {
    },
    logbookBlock: {
    },
  },
};

const getProps = (type: string): LogbookPageInternalProps => {
  const values: LogbookPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
