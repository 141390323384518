import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import Initials from '../../atoms/Initials';
import IconDropDown from '../IconDropDown';

import styles from './StudentItem.module.scss';
import usePresenter from './StudentItem.presenter';
import getProps from './StudentItem.props';
import { StudentItemCombinedProps } from './types';


const StudentItem: React.FC<StudentItemCombinedProps> = (props) => {
  const {
    className,
    classes,
    initials,
    nameText,
    infoText,
    iconDropdown,
    divider,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.studentItem;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <Initials
          className={cx(styles.initials, classes?.initials)}
          {...internalProps.initials}
          {...initials}/>
        <div className={cx(styles.studentInfo, classes?.studentInfo)}>
          <Text
            className={cx(styles.nameText, classes?.nameText)}
            {...internalProps.nameText}
            {...nameText}/>
          <Text
            className={cx(styles.infoText, classes?.infoText)}
            {...internalProps.infoText}
            {...infoText}/>
        </div>
        <IconDropDown /* MANUAL OVERRIDE */
          {...internalProps.iconDropdown}
          {...iconDropdown}/>
      </div>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
    </div>
  );
};

export default StudentItem;

