import { DataEntry } from '../../../lib/types';
import { Course } from '../../../modules/course/types';
import { TextGroupValueProps } from '../../molecules/TextGroup';
import { AppFeeDetailsSectionValueProps } from '../../organisms/AppFeeDetailsSection';
import { ClassListValueProps } from '../../organisms/ClassList';
import { CourseDetailsSectionValueProps } from '../../organisms/CourseDetailsSection';

export type StudentCourseDetailsBlockClasses = {
  leftContent?: string;
  textGroup?: string;
  courseDetailsSection?: string;
  appFeeDetailsSection?: string;
  rightContent?: string;
  classList?: string;
};

export type StudentCourseDetailsBlockValueProps = {
  textGroup?: TextGroupValueProps;
  courseDetailsSection?: CourseDetailsSectionValueProps;
  appFeeDetailsSection?: AppFeeDetailsSectionValueProps;
  classList?: ClassListValueProps;
  course?: DataEntry<Course>;
};

export type StudentCourseDetailsBlockProps = {
  className?: string;
  classes?: StudentCourseDetailsBlockClasses;
};

export const courseBasicInfoKeys = ['coTeachers', 'courseType', 'classCapacity', 'id'];

export type StudentCourseDetailsBlockCombinedProps = StudentCourseDetailsBlockValueProps & StudentCourseDetailsBlockProps;
