import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import LabelTextGroupList from '../LabelTextGroupList';

import styles from './CourseDetailsSection.module.scss';
import usePresenter from './CourseDetailsSection.presenter';
import getProps from './CourseDetailsSection.props';
import { CourseDetailsSectionCombinedProps } from './types';


const CourseDetailsSection: React.FC<CourseDetailsSectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,
    labelTextGroupList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.courseDetailsSection;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <LabelTextGroupList
        className={cx(styles.labelTextGroupList, classes?.labelTextGroupList)}
        {...internalProps.labelTextGroupList}
        {...labelTextGroupList}/>
    </div>
  );
};

export default CourseDetailsSection;

