import { DividerProps } from '../../atoms/Divider';
import { NavMenuListProps } from '../NavMenuList';


type LeftNavInternalProps = {
  navMenuList?: NavMenuListProps;
  divider?: DividerProps;
};

type LeftNavPropsValues = {
  '': LeftNavInternalProps;
};

const propValues: LeftNavPropsValues = {
  '': {
    navMenuList: {
      navMenuList: 'Collapsed',
    },
    divider: {
      type: 'Vertical',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
};

const getProps = (type: string): LeftNavInternalProps => {
  const values: LeftNavInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
