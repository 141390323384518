import { ToggleItemProps } from '../../atoms/ToggleItem';


type ToggleInternalProps = {
  toggleItemOne?: ToggleItemProps;
  toggleItemTwo?: ToggleItemProps;
};

type TogglePropsValues = {
  '': ToggleInternalProps;
};

const propValues: TogglePropsValues = {
  '': {
    toggleItemOne: {
      state: 'Selected',
    },
    toggleItemTwo: {
      state: 'Unselected',
    },
  },
};

const getProps = (type: string): ToggleInternalProps => {
  const values: ToggleInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
