import { TextProps } from '../Text';


type RegistrationStatusTagInternalProps = {
  text?: TextProps;
};

type RegistrationStatusTagPropsValues = {
  'Closed': RegistrationStatusTagInternalProps;
  'Open': RegistrationStatusTagInternalProps;
};

const propValues: RegistrationStatusTagPropsValues = {
  'Closed': {
    text: {
      style: 'Bold',
      align: 'Center',
      colour: 'Critical',
      type: 'Body2',
    },
  },
  'Open': {
    text: {
      style: 'Bold',
      align: 'Center',
      colour: 'Brand',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): RegistrationStatusTagInternalProps => {
  const values: RegistrationStatusTagInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
