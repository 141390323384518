import React from 'react';
import cx from 'classnames';

import ConfirmationBlock from '../../blocks/ConfirmationBlock';

import styles from './RegistrationSuccessPage.module.scss';
import usePresenter from './RegistrationSuccessPage.presenter';
import getProps from './RegistrationSuccessPage.props';
import { RegistrationSuccessPageCombinedProps } from './types';


const RegistrationSuccessPage: React.FC<RegistrationSuccessPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    confirmationBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.registrationSuccessPage;

  return (
    <div className={cx(currentStyle, className)}>
      <ConfirmationBlock
        className={cx(styles.confirmationBlock, classes?.confirmationBlock)}
        {...internalProps.confirmationBlock}
        {...confirmationBlock}/>
    </div>
  );
};

export default RegistrationSuccessPage;

