import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import IconTextGroupList from '../../organisms/IconTextGroupList';

import styles from './ClassItem.module.scss';
import usePresenter from './ClassItem.presenter';
import getProps from './ClassItem.props';
import { ClassItemCombinedProps } from './types';


const ClassItem: React.FC<ClassItemCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,
    iconTextGroupList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.classItem;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <IconTextGroupList
        className={cx(styles.iconTextGroupList, classes?.iconTextGroupList)}
        {...internalProps.iconTextGroupList}
        {...iconTextGroupList}/>
    </div>
  );
};

export default ClassItem;

