import cx from 'classnames';
import React from 'react';
import { Route, Switch } from 'react-router';

import { AUTH_ROUTES, STUDENT_ROUTES } from '../../../lib/constants';
import ProtectedRoute from '../../../modules/auth/ProtectedRoute';
import AlreadySignupBlock from '../../blocks/AlreadySignupBlock';
import CompleteProfileBlock from '../../blocks/CompleteProfileBlock';
import CreatePasswordBlock from '../../blocks/CreatePasswordBlock';
import ForgotPasswordBlock from '../../blocks/ForgotPasswordBlock';
import ForgotPasswordSuccessBlock from '../../blocks/ForgotPasswordSuccessBlock';
import SigninBlock from '../../blocks/SigninBlock';
import SignupBlock from '../../blocks/SignupBlock';
import TeacherAgreementBlock from '../../blocks/TeacherAgreementBlock';
import TuitionPaymentBlock from '../../blocks/TuitionPaymentBlock';
import WelcomeBlock from '../../blocks/WelcomeBlock';
import AlreadyRegisteredPage from '../AlreadyRegisteredPage';
import ExpiredStatePage from '../ExpiredStatePage';
import PasswordLinkExpiredPage from '../PasswordLinkExpiredPage';
import StudentUpdatedPasswordPage from '../StudentUpdatedPasswordPage';

import styles from './SigninPage.module.scss';
import usePresenter from './SigninPage.presenter';
import getProps from './SigninPage.props';
import { SigninPageCombinedProps } from './types';

const SigninPage: React.FC<SigninPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    signinBlock,
    signupBlock,
    alreadySignupBlock,
    completeProfileBlock,
    tuitionPaymentBlock,
    teacherAgreementBlock,
    welcomeBlock,
    forgotPasswordBlock,
    forgotPasswordSuccessBlock,
    expiredStatePage,
    createPasswordBlock,
    passwordLinkExpiredPage,
    alreadyRegisteredPage,
    studentUpdatedPasswordPage,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.signinPage;

  return (
    <div className={cx(currentStyle, className)}>
      <Switch>
        <Route path={AUTH_ROUTES.signUp}>
          <SignupBlock
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.signupBlock}
            {...signupBlock}
          />
        </Route>
        <Route path={AUTH_ROUTES.expiredInvitation}>
          <ExpiredStatePage
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.expiredStatePage}
            {...expiredStatePage}
          />
        </Route>
        <Route path={AUTH_ROUTES.signIn}>
          <SigninBlock
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.signinBlock}
            {...signinBlock}
          />
        </Route>
        <Route path={AUTH_ROUTES.resetPassword}>
          <CreatePasswordBlock
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.createPasswordBlock}
            {...createPasswordBlock}
          />
        </Route>
        <Route path={AUTH_ROUTES.resetPasswordExpired}>
          <PasswordLinkExpiredPage
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.passwordLinkExpiredPage}
            {...passwordLinkExpiredPage}
          />
        </Route>
        <Route path={AUTH_ROUTES.accountCreated}>
          <AlreadySignupBlock 
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.alreadySignupBlock}
            {...alreadySignupBlock}
          />
        </Route>
        <Route path={AUTH_ROUTES.alreadyRegistered}>
          <AlreadyRegisteredPage 
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.alreadyRegisteredPage}
            {...alreadyRegisteredPage}
          />
        </Route>
        <Route path={AUTH_ROUTES.forgotPasswordConfirmed}>
          <ForgotPasswordSuccessBlock
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.forgotPasswordSuccessBlock}
            {...forgotPasswordSuccessBlock}
          />
        </Route>
        <Route path={AUTH_ROUTES.forgotPassword}>
          <ForgotPasswordBlock
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.forgotPasswordBlock}
            {...forgotPasswordBlock}
          />
        </Route>
        <ProtectedRoute path={AUTH_ROUTES.completeProfile}>
          <CompleteProfileBlock
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.completeProfileBlock}
            {...completeProfileBlock}
          />
        </ProtectedRoute>
        <ProtectedRoute path={AUTH_ROUTES.tuitionPayment}>
          <TuitionPaymentBlock 
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.tuitionPaymentBlock}
            {...tuitionPaymentBlock}
          />
        </ProtectedRoute>
        <ProtectedRoute path={AUTH_ROUTES.certificationAgreement}>
          <TeacherAgreementBlock 
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.teacherAgreementBlock}
            {...teacherAgreementBlock}
          />
        </ProtectedRoute>
        <ProtectedRoute path={AUTH_ROUTES.welcomeToKoru}>
          <WelcomeBlock
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.welcomeBlock}
            {...welcomeBlock}
          />
        </ProtectedRoute>
        <Route exact path={STUDENT_ROUTES.auth.updatedPassword}>
          <StudentUpdatedPasswordPage
            className={cx(styles.signinBlock, classes?.signinBlock)}
            {...internalProps.studentUpdatedPasswordPage}
            {...studentUpdatedPasswordPage}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default SigninPage;
