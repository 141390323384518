import { ImageProps } from '../../atoms/Image';
import { TextGroupProps } from '../../molecules/TextGroup';


type ConfirmationBlockInternalProps = {
  image?: ImageProps;
  textGroup?: TextGroupProps;
  textGroup1?: TextGroupProps;
  appStore?: ImageProps; // MANUAL OVERRIDE
  googlePlay?: ImageProps; // MANUAL OVERRIDE
};

type ConfirmationBlockPropsValues = {
  '': ConfirmationBlockInternalProps;
};

const propValues: ConfirmationBlockPropsValues = {
  '': {
    image: {
      type: 'Square',
    },
    textGroup: {
    },
    textGroup1: {
    },
    // MANUAL OVERRIDE
    appStore: {
      type: 'Rectangle',
    },
    // MANUAL OVERRIDE
    googlePlay: {
      type: 'Rectangle',
    },
  },
};

const getProps = (type: string): ConfirmationBlockInternalProps => {
  const values: ConfirmationBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
