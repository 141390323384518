import { DataEntry } from '../../../lib/types';
import { checkCourseAvailability, getCourseById } from '../../../modules/course/api';
import { Course, SpotAvailableTokenPayload } from '../../../modules/course/types';
import { getInitialRegistrationCourse, validateSpotAvailableToken } from '../../../modules/student/api';
import { InitialRegistrationCourse } from '../../../modules/student/types';

export const checkCourseAvailabilityUseCase = (courseId: number): Promise<boolean> => {
  return checkCourseAvailability(courseId);
};

export const getCourseByIdUseCase = (courseId: number): Promise<DataEntry<Course>> => {
  return getCourseById(courseId);
};

export const validateSpotAvailableTokenUseCase = (
  token: string,
): Promise<SpotAvailableTokenPayload> => {
  return validateSpotAvailableToken(token);
};

export const getInitialRegistrationCourseUseCase = (
  token: string,
): Promise<InitialRegistrationCourse> => {
  return getInitialRegistrationCourse(token);
};
