import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { COURSE_ROUTES } from '../../../lib/constants';
import { CreateCourseSchedulePageCombinedProps } from './types';

const usePresenter = (props: CreateCourseSchedulePageCombinedProps): CreateCourseSchedulePageCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showPageCloseWarningModal, setShowPageCloseWarningModal] = useState(false);
  const { id: courseId } = useParams<{ id?: string }>();

  const goToCourses = () => {
    setShowPageCloseWarningModal(false);
    history.replace(COURSE_ROUTES.active);
  };

  const closePageCloseWarningModal = () => {
    setShowPageCloseWarningModal(false);
  };
  
  return {
    ...props,
    createCourseHeader: {
      text: {
        value: courseId ? t('create_course_schedule.header.update_course') : t('create_course_schedule.header.title'),
      },
      button: {
        text: {
          value: t('create_course_schedule.header.close'),
        },
        icon: {
          asset: 'Close',
        },
        onClick: () => setShowPageCloseWarningModal(true),
      },
    },
    pageCloseWarningModal: {
      show: showPageCloseWarningModal,
      onHide: closePageCloseWarningModal,
      modalProps: {
        type: 'Critical',
        button: {
          icon: {
            asset: 'Close',
          },
          onClick: closePageCloseWarningModal,
        },
        primaryButton: {
          text: {
            value: t('create_course_schedule.page_close_warning.primary_button'),
          },
          onClick: goToCourses,
        },
        secondaryButton: {
          text: {
            value: t('create_course_schedule.page_close_warning.secondary_button'),
          },
          onClick: closePageCloseWarningModal,
        },
        text: {
          value: t('create_course_schedule.page_close_warning.header'),
        },
        text1: {
          value: t('create_course_schedule.page_close_warning.description'),
        },
      },
    },
  };
};

export default usePresenter;
