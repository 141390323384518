import { getProductDetails } from '../../../modules/payment/api';
import { PaymentIntent, PriceItem } from '../../../modules/payment/types';
import { createStudentPaymentIntent } from '../../../modules/student/api';

export const getProductDetailsUseCase = (slug: string): Promise<PriceItem> => {
  return getProductDetails(slug);
};

export const createStudentPaymentIntentUseCase = async (courseId: number): Promise<PaymentIntent> => {
  return createStudentPaymentIntent(courseId);
};