import axiosInstance from '../../lib/api/axios';
import { BFF_URL } from '../../lib/config';
import { KoruCouponDiscount, PaymentInfo, PriceItem } from './types'; 

export const getCouponDetails = async (name: string): Promise<KoruCouponDiscount> => {
  const { data } = await axiosInstance.get<KoruCouponDiscount>(
    `${BFF_URL}/stripe/coupon/${encodeURIComponent(name)}`,
  );
  return data;
};

export const getDefaultPaymentInfo = async (uuid: string): Promise<PaymentInfo> => {
  const { data } = await axiosInstance.get<PaymentInfo>(`${BFF_URL}/stripe/paymentInfo/${uuid}`);
  return data;
};

export const getProductDetails = async (slug: string): Promise<PriceItem> => {
  const { data } = await axiosInstance.get<PriceItem>(`${BFF_URL}/stripe/product/${slug}`);
  return data;
};
