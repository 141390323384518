import { DataEntry } from '../../../lib/types';
import { Course } from '../../../modules/course/types';
import { CopyLinkSectionValueProps } from '../../organisms/CopyLinkSection';
import { DetailsBasicInfoSectionValueProps } from '../../organisms/DetailsBasicInfoSection';
import { DetailsClassInfoSectionValueProps } from '../../organisms/DetailsClassInfoSection';
import { GenericModalValueProps } from '../../organisms/GenericModal';
import { RegistrationStatusSectionValueProps } from '../../organisms/RegistrationStatusSection';

export type DetailsBlockClasses = {
  leftContent?: string;
  detailsBasicInfoSection?: string;
  detailsClassInfoSection?: string;
  rightContent?: string;
  registrationStatusSection?: string;
  copyLinkSection?: string;
};

export type DetailsBlockValueProps = {
  detailsBasicInfoSection?: DetailsBasicInfoSectionValueProps;
  detailsClassInfoSection?: DetailsClassInfoSectionValueProps;
  registrationStatusSection?: RegistrationStatusSectionValueProps;
  copyLinkSection?: CopyLinkSectionValueProps;
  course?: DataEntry<Course>;
  updateRegistrationStatusWarningModal?: GenericModalValueProps; // MANUAL OVERRIDE
  isRefetching?: boolean;
};

export type DetailsBlockProps = {
  className?: string;
  classes?: DetailsBlockClasses;
};

export const courseBasicInfoKeys = ['title', 'id', 'courseType', 'description', 'classCapacity', 'coTeachers'];

export type DetailsBlockCombinedProps = DetailsBlockValueProps & DetailsBlockProps;
