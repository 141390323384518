import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import InputField from '../../molecules/InputField';
import InputFieldGroup from '../../molecules/InputFieldGroup';
import TextGroup from '../../molecules/TextGroup';

import styles from './SignupBlock.module.scss';
import usePresenter from './SignupBlock.presenter';
import getProps from './SignupBlock.props';
import { SignupBlockCombinedProps } from './types';


const SignupBlock: React.FC<SignupBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    stepperText,
    textGroup,
    inputFieldGroup,
    emailInputField,
    passwordInputField,
    confirmPasswordInputField,
    button,
    legalText,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.signupBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.stepperText, classes?.stepperText)}
          {...internalProps.stepperText}
          {...stepperText}/>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <InputFieldGroup
          className={cx(styles.inputFieldGroup, classes?.inputFieldGroup)}
          {...internalProps.inputFieldGroup}
          {...inputFieldGroup}/>
        <InputField
          className={cx(styles.emailInputField, classes?.emailInputField)}
          {...internalProps.emailInputField}
          {...emailInputField}/>
        <InputField
          className={cx(styles.passwordInputField, classes?.passwordInputField)}
          {...internalProps.passwordInputField}
          {...passwordInputField}/>
        <InputField
          className={cx(styles.confirmPasswordInputField, classes?.confirmPasswordInputField)}
          {...internalProps.confirmPasswordInputField}
          {...confirmPasswordInputField}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
        <Text
          className={cx(styles.legalText, classes?.legalText)}
          {...internalProps.legalText}
          {...legalText}/>
      </div>
    </div>
  );
};

export default SignupBlock;
