import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';

import styles from './ConfirmationModal.module.scss';
import usePresenter from './ConfirmationModal.presenter';
import getProps from './ConfirmationModal.props';
import { ConfirmationModalCombinedProps } from './types';


const ConfirmationModal: React.FC<ConfirmationModalCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    button,
    image,
    text1,
    primaryButton,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.confirmationModal;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <Image
          className={cx(styles.image, classes?.image)}
          {...internalProps.image}
          {...image}/>
        <Text
          className={cx(styles.text1, classes?.text1)}
          {...internalProps.text1}
          {...text1}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.primaryButton, classes?.primaryButton)}
          {...internalProps.primaryButton}
          {...primaryButton}/>
      </div>
    </div>
  );
};

export default ConfirmationModal;

