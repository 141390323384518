import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';

import styles from './Select.module.scss';
import usePresenter from './Select.presenter';
import getProps from './Select.props';
import { SelectCombinedProps } from './types';

const Select = React.forwardRef<HTMLButtonElement, SelectCombinedProps>((props, ref) => {
  const {
    error,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    text,
    icon,
    state, // MANUAL OVERRIDE
    ...otherProps // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${error}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`select${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Error':
    case 'None': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}
          ref={ref}
          {...otherProps}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
});

export default Select;

