import React from 'react';
import cx from 'classnames';

import GenericActionBlock from '../../blocks/GenericActionBlock';

import styles from './PasswordLinkExpiredPage.module.scss';
import usePresenter from './PasswordLinkExpiredPage.presenter';
import getProps from './PasswordLinkExpiredPage.props';
import { PasswordLinkExpiredPageCombinedProps } from './types';


const PasswordLinkExpiredPage: React.FC<PasswordLinkExpiredPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    genericActionBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.passwordLinkExpiredPage;

  return (
    <div className={cx(currentStyle, className)}>
      <GenericActionBlock
        className={cx(styles.genericActionBlock, classes?.genericActionBlock)}
        {...internalProps.genericActionBlock}
        {...genericActionBlock}/>
    </div>
  );
};

export default PasswordLinkExpiredPage;

