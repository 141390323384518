import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Button from '../../atoms/Button';
import RegistrationStatusTag from '../../atoms/RegistrationStatusTag';
import Text from '../../atoms/Text';
import ClassItem from '../ClassItem';
import IconTextGroup from '../IconTextGroup';
import IconDropDown from '../IconDropDown';

import styles from './CourseCard.module.scss';
import usePresenter from './CourseCard.presenter';
import getProps from './CourseCard.props';
import { CourseCardCombinedProps } from './types';

const CourseCard: React.FC<CourseCardCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    registrationStatusTag,
    iconDropdown, // MANUAL OVERRIDE
    text,
    classItem,
    topDivider,
    iconTextGroup,
    bottomDivider,
    button1,
    button2,
    courseBackgroundImage, // MANUAL OVERRIDE
    handleOnClick, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`courseCard${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Course': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          onClick={handleOnClick}> {/*  MANUAL OVERRIDE */}
          {/*  MANUAL OVERRIDE */}
          <div className={cx(styles.topContent, classes?.topContent)} style={{
            backgroundImage: `url(${courseBackgroundImage})`,
          }}>
            <div className={cx(styles.courseCardHeader, classes?.courseCardHeader)}>
              <RegistrationStatusTag
                className={cx(styles.registrationStatusTag, classes?.registrationStatusTag)}
                {...internalProps.registrationStatusTag}
                {...registrationStatusTag}/>
              <IconDropDown /* MANUAL OVERRIDE */
                {...internalProps.iconDropdown}
                {...iconDropdown}/>
            </div>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
          <div className={cx(styles.bottomContent, classes?.bottomContent)}>
            <div className={cx(styles.content, classes?.content)}>
              <ClassItem
                className={cx(styles.classItem, classes?.classItem)}
                {...internalProps.classItem}
                {...classItem}/>
              <Divider
                className={cx(styles.topDivider, classes?.topDivider)}
                {...internalProps.topDivider}
                {...topDivider}/>
              <div className={cx(styles.progressContent, classes?.progressContent)}>
                <IconTextGroup
                  className={cx(styles.iconTextGroup, classes?.iconTextGroup)}
                  {...internalProps.iconTextGroup}
                  {...iconTextGroup}/>
              </div>
              <Divider
                className={cx(styles.bottomDivider, classes?.bottomDivider)}
                {...internalProps.bottomDivider}
                {...bottomDivider}/>
            </div>
            <Button
              className={cx(styles.button1, classes?.button1)}
              {...internalProps.button1}
              {...button1}/>
          </div>
        </div>
      );
      break;
    }
    case 'CreateNew': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Button
              className={cx(styles.button2, classes?.button2)}
              {...internalProps.button2}
              {...button2}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default CourseCard;

