import React from 'react';
import cx from 'classnames';

import CoursesBlock from '../../blocks/CoursesBlock';
import CoursesHeader from '../../organisms/CoursesHeader';

import styles from './ArchivedCoursesPage.module.scss';
import usePresenter from './ArchivedCoursesPage.presenter';
import getProps from './ArchivedCoursesPage.props';
import { ArchivedCoursesPageCombinedProps } from './types';
import LoadingSpinner from '../../atoms/LoadingSpinner';


const ArchivedCoursesPage: React.FC<ArchivedCoursesPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    coursesHeader,
    coursesBlock,
    isLoading,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.archivedCoursesPage;
  const coursesBlockView = isLoading ? (
    <LoadingSpinner
      className={cx(styles.loadingSpinner)}
      size='Huge'
      styleType='Primary'
      isActive
    />
  ) : (
    <CoursesBlock
      className={cx(styles.coursesBlock, classes?.coursesBlock)}
      {...internalProps.coursesBlock}
      {...coursesBlock}/>
  );

  return (
    <div className={cx(currentStyle, className)}>
      <CoursesHeader
        className={cx(styles.coursesHeader, classes?.coursesHeader)}
        {...internalProps.coursesHeader}
        {...coursesHeader}/>
      <div className={cx(styles.content, classes?.content)}>
        { coursesBlockView }
      </div>
    </div>
  );
};

export default ArchivedCoursesPage;

