import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { TextGroupProps } from '../../molecules/TextGroup';
import { RadioItemListProps } from '../../organisms/RadioItemList';


type StudentKoruAccountBlockInternalProps = {
  stepperText?: TextProps;
  textGroup?: TextGroupProps;
  radioItemList?: RadioItemListProps;
  button?: ButtonProps;
};

type StudentKoruAccountBlockPropsValues = {
  '': StudentKoruAccountBlockInternalProps;
};

const propValues: StudentKoruAccountBlockPropsValues = {
  '': {
    stepperText: {
      style: 'Semibold',
      align: 'Left',
      colour: 'Default',
      type: 'Body2',
    },
    textGroup: {
    },
    radioItemList: {
      type: 'Vertical',
    },
    button: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): StudentKoruAccountBlockInternalProps => {
  const values: StudentKoruAccountBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
