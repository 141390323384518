import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { StudentsListProps } from '../StudentsList';


type StudentListSectionInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  studentsList?: StudentsListProps;
};

type StudentListSectionPropsValues = {
  '': StudentListSectionInternalProps;
};

const propValues: StudentListSectionPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    studentsList: {
    },
  },
};

const getProps = (type: string): StudentListSectionInternalProps => {
  const values: StudentListSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
