import { ButtonProps } from '../Button';
import { IconProps } from '../Icon';
import { TextProps } from '../Text';


type ChipInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
  icon?: IconProps;
};

type ChipPropsValues = {
  'DefaultMTextNoneDefault': ChipInternalProps;
  'OutlineWhiteLTextIconNoneDefault': ChipInternalProps;
  'WhiteLTextIconErrorDefault': ChipInternalProps;
  'WhiteLTextIconNoneDefault': ChipInternalProps;
  'OutlineWhiteLTextIconNoneEdit': ChipInternalProps;
  'WhiteLTextIconNoneEdit': ChipInternalProps;
  'WhiteLTextIconErrorEdit': ChipInternalProps;
  'WhiteLTextIconNoneBlocked': ChipInternalProps;
};

const propValues: ChipPropsValues = {
  'DefaultMTextNoneDefault': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
  'OutlineWhiteLTextIconNoneDefault': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
  'WhiteLTextIconErrorDefault': {
    icon: {
      asset: 'Close',
      colour: 'Critical',
    },
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
  'WhiteLTextIconNoneDefault': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
  'WhiteLTextIconNoneBlocked': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
  'OutlineWhiteLTextIconNoneEdit': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
  'WhiteLTextIconNoneEdit': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
  'WhiteLTextIconErrorEdit': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
};

const getProps = (type: string): ChipInternalProps => {
  const values: ChipInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
