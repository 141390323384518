import React from 'react';
import cx from 'classnames';

import CourseHeader from '../../organisms/CourseHeader';
import LogbookBlock from '../../blocks/LogbookBlock';

import styles from './LogbookPage.module.scss';
import usePresenter from './LogbookPage.presenter';
import getProps from './LogbookPage.props';
import { LogbookPageCombinedProps } from './types';
import LoadingSpinner from '../../atoms/LoadingSpinner';


const LogbookPage: React.FC<LogbookPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    courseHeader,
    logbookBlock,
    isLoading,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.logbookPage;
  const logbookBlockView = isLoading ? (
    <LoadingSpinner
      className={cx(styles.loadingSpinner)}
      size='Huge'
      styleType='Primary'
      isActive
    />
  ) : (
    <LogbookBlock
      className={cx(styles.logbookBlock, classes?.logbookBlock)}
      {...internalProps.logbookBlock}
      {...logbookBlock}/>
  );

  return (
    <div className={cx(currentStyle, className)}>
      <CourseHeader
        className={cx(styles.courseHeader, classes?.courseHeader)}
        {...internalProps.courseHeader}
        {...courseHeader}/>
      <div className={cx(styles.content, classes?.content)}>
        { logbookBlockView }
      </div>
    </div>
  );
};

export default LogbookPage;

