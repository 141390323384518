import { TextProps } from '../Text';


type InitialsInternalProps = {
  text?: TextProps;
};

type InitialsPropsValues = {
  '20Amber': InitialsInternalProps;
  '20Aqua': InitialsInternalProps;
  '20Green': InitialsInternalProps;
  '20Purple': InitialsInternalProps;
  '32Amber': InitialsInternalProps;
  '32Aqua': InitialsInternalProps;
  '32Green': InitialsInternalProps;
  '32Purple': InitialsInternalProps;
  '48Amber': InitialsInternalProps;
  '48Aqua': InitialsInternalProps;
  '48Green': InitialsInternalProps;
  '48Purple': InitialsInternalProps;
  '20Default': InitialsInternalProps;
  '32Default': InitialsInternalProps;
  '48Default': InitialsInternalProps;
};

const propValues: InitialsPropsValues = {
  '20Amber': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '20Aqua': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '20Green': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '20Purple': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '32Amber': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '32Aqua': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '32Green': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '32Purple': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '48Amber': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '48Aqua': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '48Green': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '48Purple': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '20Default': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '32Default': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
  '48Default': {
    text: {
      style: 'Regular',
      align: 'Center',
      colour: 'Inverse',
      type: 'Body2',
    },
  },
};

const getProps = (type: string): InitialsInternalProps => {
  const values: InitialsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
