import { CourseTypeEnum } from '../../../modules/course/types';
import { ButtonValueProps } from '../../atoms/Button';
import { TextValueProps } from '../../atoms/Text';
import { AddCoTeacherValueProps, AddCoTeacherProps } from '../../molecules/AddCoTeacher';
import { InputFieldProps, InputFieldValueProps } from '../../molecules/InputField';
import { RadioFieldProps, RadioFieldValueProps } from '../../molecules/RadioField';
import { TextAreaFieldProps, TextAreaFieldValueProps } from '../../molecules/TextAreaField';
import { TextGroupValueProps } from '../../molecules/TextGroup';

export type CreateCourseBasicDetailsBlockClasses = {
  topContent?: string;
  stepperText?: string;
  textGroup?: string;
  middleContent?: string;
  courseNameInputField?: string;
  radioField?: string;
  courseDescriptionTextAreaField?: string;
  classCapacityInputField?: string;
  addCoTeacher?: string;
  bottomContent?: string;
  button?: string;
};

export type CreateCourseBasicDetailsBlockValueProps = {
  stepperText?: TextValueProps;
  textGroup?: TextGroupValueProps;
  courseNameInputField?: InputFieldValueProps & InputFieldProps;
  radioField?: RadioFieldValueProps & RadioFieldProps;
  // MANUAL OVERRIDE
  courseDescriptionTextAreaField?: TextAreaFieldValueProps & TextAreaFieldProps;
  classCapacityInputField?: InputFieldValueProps & InputFieldProps;
  addCoTeacher?: AddCoTeacherValueProps & AddCoTeacherProps;
  button?: ButtonValueProps;
};

export type CreateCourseBasicDetailsBlockProps = {
  className?: string;
  classes?: CreateCourseBasicDetailsBlockClasses;
};

export type CreateCourseBasicDetailsBlockCombinedProps = CreateCourseBasicDetailsBlockValueProps & CreateCourseBasicDetailsBlockProps;

export enum CoTeacherErrorStates {
  default = 'Default',
  not_found = 'Not Found',
  invalid = 'Invalid',
}

export type CourseBasicInfoForm = {
  name: string;
  type: CourseTypeEnum;
  description: string;
  registrationOpen: boolean;
  coTeachers?: string[];
  classCapacity: number;
};

export type CourseBasicInfoFormErrors = Partial<
Record<keyof CourseBasicInfoForm, string>
>;

export const DefaultCourseType: CourseTypeEnum = CourseTypeEnum.koru_basic;
