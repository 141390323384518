import { useRef } from 'react';
import { TextInputCombinedProps } from './types';

const usePresenter = (props: TextInputCombinedProps): TextInputCombinedProps => {
  const inputRef = useRef<HTMLInputElement>(null);
  return {
    ...props,
    inputRef: props.inputRef || inputRef,
  };
};

export default usePresenter;
