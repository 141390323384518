import { ContextualContentProps } from '../../atoms/ContextualContent';
import { TextProps } from '../../atoms/Text';
import { TextAreaProps } from '../../atoms/TextArea';


type TextAreaFieldInternalProps = {
  text?: TextProps;
  textArea?: TextAreaProps;
  contextualContent?: ContextualContentProps;
};

type TextAreaFieldPropsValues = {
  'Default': TextAreaFieldInternalProps;
  'Error': TextAreaFieldInternalProps;
  'Help': TextAreaFieldInternalProps;
};

const propValues: TextAreaFieldPropsValues = {
  'Default': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    textArea: {
      error: 'None',
      size: 'L',
    },
  },
  'Error': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    textArea: {
      error: 'Error',
      size: 'L',
    },
    contextualContent: {
      type: 'Error',
    },
  },
  'Help': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    textArea: {
      error: 'None',
      size: 'L',
    },
    contextualContent: {
      type: 'Help',
    },
  },
};

const getProps = (type: string): TextAreaFieldInternalProps => {
  const values: TextAreaFieldInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
