import { ContextualContentProps } from '../../atoms/ContextualContent';
import { SelectProps } from '../../atoms/Select';
import { TextProps } from '../../atoms/Text';
import { TextInputProps } from '../../atoms/TextInput';


type SelectFieldInternalProps = {
  text?: TextProps;
  select?: SelectProps;
  contextualContent?: ContextualContentProps;
  textInput?: TextInputProps;   // MANUAL OVERRIDE
};

type SelectFieldPropsValues = {
  'Default': SelectFieldInternalProps;
  'Error': SelectFieldInternalProps;
  'NoOptions': SelectFieldInternalProps;   // MANUAL OVERRIDE
};

const propValues: SelectFieldPropsValues = {
  'Default': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    select: {
      error: 'None',
    },
    contextualContent: {
      type: 'Help',
    },
  },
  'Error': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    select: {
      error: 'None',
    },
    textInput: {
      type: 'Text',
      error: 'None',
      htmlType: 'text',
    },
    contextualContent: {
      type: 'Error',
    },
  },
  // MANUAL OVERRIDE
  'NoOptions': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Label2',
    },
    select: {
      error: 'None',
    },
    textInput: {
      type: 'Text',
      error: 'None',
      htmlType: 'text',
      size: 'L',
    },
    contextualContent: {
      type: 'Error',
    },
  },
};

const getProps = (type: string): SelectFieldInternalProps => {
  const values: SelectFieldInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
