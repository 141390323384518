import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import Address from '../../molecules/Address';
import CardInfo from '../../molecules/CardInfo';

import styles from './PaymentInfoModal.module.scss';
import usePresenter from './PaymentInfoModal.presenter';
import getProps from './PaymentInfoModal.props';
import { PaymentInfoModalCombinedProps } from './types';
import InlineNotification from '../../atoms/InlineNotification';



const PaymentInfoModal: React.FC<PaymentInfoModalCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    button,
    inlineNotification,
    cardInfoField,
    text1,
    address,
    primaryButton,
    primaryButton1,
    showNotification,
    containerRef,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.paymentInfoModal;

  const inlineNotificationView = showNotification ? (
    <InlineNotification 
      className={cx(styles.inlineNotification, classes?.inlineNotification)}
      {...internalProps.inlineNotification}
      {...inlineNotification}
    />
  ) : null;

  return (
    <div className={cx(currentStyle, className)} ref={containerRef}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        { inlineNotificationView }
        {/* MANUAL OVERRIDE */}
        <CardInfo
          className={cx(styles.cardInfoField, classes?.cardInfoField)}
          {...internalProps.cardInfoField}
          {...cardInfoField}
        />
        <Text
          className={cx(styles.text1, classes?.text1)}
          {...internalProps.text1}
          {...text1}/>
        <Address
          className={cx(styles.address, classes?.address)}
          containerRef={containerRef}
          {...internalProps.address}
          {...address}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.primaryButton, classes?.primaryButton)}
          {...internalProps.primaryButton}
          {...primaryButton}/>
        <Button
          className={cx(styles.primaryButton1, classes?.primaryButton1)}
          {...internalProps.primaryButton1}
          {...primaryButton1}/>
      </div>
    </div>
  );
};

export default PaymentInfoModal;

