import { getCouponDetails } from '../../../modules/payment/api';
import { KoruFeeDetails, KoruCouponDiscount, PaymentIntent } from '../../../modules/payment/types';
import { createTeacherPaymentIntent, getTeacherFees } from '../../../modules/teacher/api';
import { CreateTeacherPaymentIntentPayload } from '../../../modules/teacher/types';

export const getTeacherFeesUseCase = async (): Promise<KoruFeeDetails> => {
  return getTeacherFees();
};

export const getCouponDetailsUseCase = async (id: string): Promise<KoruCouponDiscount> => {
  return getCouponDetails(id);
};

export const createTeacherPaymentIntentUseCase = async (
  teacherUuid: string,
  payload: CreateTeacherPaymentIntentPayload,
): Promise<PaymentIntent> => {
  return createTeacherPaymentIntent(teacherUuid, payload);
};