import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';

import styles from './NavMenuItem.module.scss';
import usePresenter from './NavMenuItem.presenter';
import getProps from './NavMenuItem.props';
import { NavMenuItemCombinedProps } from './types';


const NavMenuItem: React.FC<NavMenuItemCombinedProps> = (props) => {
  const {
    type,
    state,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    onClick,
    icon,
    text,
    trailingIcon,
    expand,     // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${type}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`navMenuItem${variantName}`];

  // MANUAL OVERRIDE
  const textIconView = expand ? (
    <Text
      className={cx(styles.text, classes?.text)}
      {...internalProps.text}
      {...text}
    />
  ) : undefined;

  let componentView;

  switch (variantName) {
    case 'IconDefault':
    case 'IconSelected': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <div className={cx(styles.indicator, classes?.indicator)}/>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
          </div>
        </button>
      );
      break;
    }
    case 'IconTextDefault':
    case 'IconTextSelected': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <div className={cx(styles.indicator, classes?.indicator)}/>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            { textIconView }      {/* MANUAL OVERRIDE */}
          </div>
        </button>
      );
      break;
    }
    case 'IconTextIconDefault':
    case 'IconTextIconSelected': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}>
          <div className={cx(styles.indicator, classes?.indicator)}/>
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
            <Icon
              className={cx(styles.trailingIcon, classes?.trailingIcon)}
              {...internalProps.trailingIcon}
              {...trailingIcon}/>
          </div>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default NavMenuItem;

