import { CreateCourseBasicDetailsBlockProps } from '../../blocks/CreateCourseBasicDetailsBlock';
import { CreateCourseHeaderProps } from '../../organisms/CreateCourseHeader';


type CreateCourseBasicDetailsPageInternalProps = {
  createCourseHeader?: CreateCourseHeaderProps;
  createCourseBasicDetailsBlock?: CreateCourseBasicDetailsBlockProps;
};

type CreateCourseBasicDetailsPagePropsValues = {
  '': CreateCourseBasicDetailsPageInternalProps;
};

const propValues: CreateCourseBasicDetailsPagePropsValues = {
  '': {
    createCourseHeader: {
    },
    createCourseBasicDetailsBlock: {
    },
  },
};

const getProps = (type: string): CreateCourseBasicDetailsPageInternalProps => {
  const values: CreateCourseBasicDetailsPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
