import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import InputField from '../../molecules/InputField';
import TextGroup from '../../molecules/TextGroup';

import styles from './ForgotPasswordBlock.module.scss';
import usePresenter from './ForgotPasswordBlock.presenter';
import getProps from './ForgotPasswordBlock.props';
import { ForgotPasswordBlockCombinedProps } from './types';


const ForgotPasswordBlock: React.FC<ForgotPasswordBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    button,
    textGroup,
    emailField,
    button1,
    hideBackButton, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.forgotPasswordBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        {!hideBackButton && 
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>}
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <InputField
          className={cx(styles.emailField, classes?.emailField)}
          {...internalProps.emailField}
          {...emailField}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.button1, classes?.button1)}
          {...internalProps.button1}
          {...button1}/>
      </div>
    </div>
  );
};

export default ForgotPasswordBlock;

