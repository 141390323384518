import axiosInstance from '../../lib/api/axios';
import { BFF_URL } from '../../lib/config';
import { DataEntry } from '../../lib/types';
import { CreateLogEntryPayload, Log, LogEntry, UpdateLogPayload } from './types';

export const updateLog = async (logUuid: string, payload: UpdateLogPayload): Promise<DataEntry<Log>> => {
  const { data } = await axiosInstance.patch<DataEntry<Log>>(`${BFF_URL}/logs/${logUuid}`, payload);
  return data;
};

export const createLogEntry = async (
  logUuid: string, payload: CreateLogEntryPayload,
): Promise<DataEntry<LogEntry>> => {
  const { data } = await axiosInstance.post<DataEntry<LogEntry>>(`${BFF_URL}/logs/${logUuid}/entries`, payload);
  return data;
};
