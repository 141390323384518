import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import { AUTH_ROUTES, SIGNUP_STEPS } from '../../../lib/constants';
import { getTranslation } from '../../../lib/reactUtils';
import { JourneyStatusEnum } from '../../../modules/teacher/types';
import { UserContext } from '../../../modules/user/UserContext';
import { TextGroupValueProps } from '../../molecules/TextGroup';
import { TOTAL_TEXT_GROUPS } from './constant';
import { updateTeacherUseCase } from './TeacherAgreementBlock.interactor';
import { TeacherAgreementBlockCombinedProps } from './types';

const usePresenter = (props: TeacherAgreementBlockCombinedProps): TeacherAgreementBlockCombinedProps => {
  const { t } = useTranslation();
  const { user, refetchUser } = useContext(UserContext);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);

  const { mutateAsync: updateTeacher } = useMutation(['updateTeacher', user?.uuid], async () => {
    if (user) {
      return updateTeacherUseCase(user.uuid, {
        journeyStatus: JourneyStatusEnum.in_training,
      });
    }
  });

  const handleAgreeToTerms = () => {
    setAgreeToTerms(!agreeToTerms);
  };

  const handleCompleteRegistration = async () => {
    if (agreeToTerms && user) {
      setIsLoading(true);
      try {
        const updatedTeacher = await updateTeacher();
        if (updatedTeacher) {
          await refetchUser();
          history.replace(AUTH_ROUTES.welcomeToKoru);
        }
      } catch (e) {
        // no-op
        // should display notification with error message to help user navigate the error
      } finally {
        setIsLoading(false);
      }
    }
  };

  const textGroupProps: TextGroupValueProps[] = [];
  for (let i = 1; i <= TOTAL_TEXT_GROUPS; i ++) {
    textGroupProps.push({
      topText: {
        type: 'Heading4',
        value: t(`teacher_agreement.textgroup.${i}.title`),
      },
      bottomText: {
        value: getTranslation(`teacher_agreement.textgroup.${i}.description`),
      },
    });
  }

  return {
    ...props,
    stepperText: {
      value: t('stepperText', {
        currentStep: 4,
        totalSteps: SIGNUP_STEPS,
      }),
    },
    textGroup: {
      topText: {
        value: t('teacher_agreement.title'),
      },
      bottomText: {
        value: getTranslation('teacher_agreement.description'),
      },
    },
    text: {
      value: t('teacher_agreement.text'),
    },
    textGroups: textGroupProps,
    teacherAgreementFooter: {
      checkboxItem: {
        state: agreeToTerms ? 'Checked' : 'Unchecked',
        text: {
          value: t('teacher_agreement.checkbox'),
        },
        onClick: handleAgreeToTerms,
      },
      button: {
        disabled: !agreeToTerms || isLoading,
        icon: {
          asset: 'ArrowRight',
        },
        text: {
          value: t('button.complete_registration'),
        },
        loading: isLoading ? 'Loading' : 'Default',
        onClick: handleCompleteRegistration,
      },
    },
  };
};

export default usePresenter;
