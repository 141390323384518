import { ButtonProps } from '../../atoms/Button';
import { InlineNotificationProps } from '../../atoms/InlineNotification';
import { TextProps } from '../../atoms/Text';
import { AddressProps } from '../../molecules/Address';
import { CardInfoProps } from '../../molecules/CardInfo';


type PaymentInfoModalInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
  inlineNotification?: InlineNotificationProps;
  cardInfoField?: CardInfoProps; // MANUAL OVERRIDE
  text1?: TextProps;
  address?: AddressProps;
  primaryButton?: ButtonProps;
  primaryButton1?: ButtonProps;
};

type PaymentInfoModalPropsValues = {
  '': PaymentInfoModalInternalProps;
};

const propValues: PaymentInfoModalPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'Base',
      loading: 'Default',
    },
    inlineNotification: {
      type: 'Error',
    },
    // MANUAL OVERRIDE
    cardInfoField: {
    },
    text1: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading4',
    },
    address: {
    },
    primaryButton: {
      style: 'Contained',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    primaryButton1: {
      style: 'Outline',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): PaymentInfoModalInternalProps => {
  const values: PaymentInfoModalInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
