import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';


type CopyStudentsEmailSectionInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  bodyText?: TextProps;
  copyEmailButton?: ButtonProps;
};

type CopyStudentsEmailSectionPropsValues = {
  '': CopyStudentsEmailSectionInternalProps;
};

const propValues: CopyStudentsEmailSectionPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    bodyText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },    
    copyEmailButton: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): CopyStudentsEmailSectionInternalProps => {
  const values: CopyStudentsEmailSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
