import { CoursesBlockProps } from '../../blocks/CoursesBlock';
import { GenericStateThreeBlockProps } from '../../blocks/GenericStateThreeBlock';
import { CoursesHeaderProps } from '../../organisms/CoursesHeader';


type CoursesPageInternalProps = {
  coursesHeader?: CoursesHeaderProps;
  coursesBlock?: CoursesBlockProps;
  genericStateThreeBlock?: GenericStateThreeBlockProps;
};

// MANUAL OVERRIDE
type CoursesPagePropsValues = {
  'Active': CoursesPageInternalProps;
  'Empty': CoursesPageInternalProps;
};

// MANUAL OVERRIDE
const propValues: CoursesPagePropsValues = {
  'Active': {
    coursesHeader: {
    },
    coursesBlock: {
    },
  },
  'Empty': {
    coursesHeader: {
    },
    genericStateThreeBlock: {
    },
  },
};

const getProps = (type: string): CoursesPageInternalProps => {
  const values: CoursesPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
