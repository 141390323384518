import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import CardInfo from '../../molecules/CardInfo';
import CustomApps from '../../molecules/CustomApps';
import PriceListItem from '../../molecules/PriceListItem';
import RadioItemList from '../RadioItemList';

import styles from './PurchaseStudentAppModal.module.scss';
import usePresenter from './PurchaseStudentAppModal.presenter';
import getProps from './PurchaseStudentAppModal.props';
import { PurchaseStudentAppModalCombinedProps } from './types';
import InlineNotification from '../../atoms/InlineNotification';


const PurchaseStudentAppModal: React.FC<PurchaseStudentAppModalCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    button,
    labelText,
    radioItemList,
    customApps,
    priceListItem,
    cardInfo,
    primaryButton,
    primaryButton1,
    inlineNotification,
    showNotification,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.purchaseStudentAppModal;

  const inlineNotificationView = showNotification ? 
  <InlineNotification
    className={cx(styles.inlineNotification, classes?.inlineNotification)}
    {...internalProps.inlineNotification}
    {...inlineNotification}
  /> : undefined;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        { inlineNotificationView }
        <div className={cx(styles.content, classes?.content)}>
          <Text
            className={cx(styles.labelText, classes?.labelText)}
            {...internalProps.labelText}
            {...labelText}/>
          <RadioItemList
            className={cx(styles.radioItemList, classes?.radioItemList)}
            {...internalProps.radioItemList}
            {...radioItemList}/>
          <CustomApps
            className={cx(styles.customApps, classes?.customApps)}
            {...internalProps.customApps}
            {...customApps}/>
        </div>
        <PriceListItem
          className={cx(styles.priceListItem, classes?.priceListItem)}
          {...internalProps.priceListItem}
          {...priceListItem}/>
        <CardInfo
          className={cx(styles.cardInfo, classes?.cardInfo)}
          {...internalProps.cardInfo}
          {...cardInfo}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.primaryButton, classes?.primaryButton)}
          {...internalProps.primaryButton}
          {...primaryButton}/>
        <Button
          className={cx(styles.primaryButton1, classes?.primaryButton1)}
          {...internalProps.primaryButton1}
          {...primaryButton1}/>
      </div>
    </div>
  );
};

export default PurchaseStudentAppModal;

