export enum JourneyStatusEnum {
  account_created = 'Account Created',
  payment_complete = 'Payment Complete',
  in_training = 'In-Training',
  under_review = 'Under Review',
  certified = 'Certified',
  graduate = 'Graduate',
  renew_required = 'Renew Required',
}

export enum RegistrationStatus {
  profile_completed = 'Profile Completed',
}

export type RegistrationStatusEnum = JourneyStatusEnum | RegistrationStatus;

export type TeacherRegistrationStatus = {
  registrationStatus: RegistrationStatusEnum
};

export type Teacher = {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  journeyStatus: JourneyStatusEnum;
  expiryDate?: Date;
  notes?: string;
  apps?: number;
};

export type CreateTeacherPayload = Omit<Teacher, 'uuid'> & {
  password: string;
  inviteToken: string | null;
};

export type UpdateTeacherPayload = Partial<Omit<Teacher, 'uuid'>> & {
  profile?: string;
};

export type CreateTeacherPaymentIntentPayload = {
  productSlug: string;
  discountCode?: string;
  quantity?: number;
};

export type NextStepState = {
  nextStepRoute: string;
};

export type CreateAnnualSubscriptionPayload = {
  teacherUuid: string;
  autoRenew: boolean;
};

export type UpdateAnnualSubscriptionPayload = {
  autoRenew: boolean;
};

export type TeacherValidationPayload = {
  isValid: boolean;
};
