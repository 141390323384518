import React from 'react';
import cx from 'classnames';

import DetailsBlock from '../../blocks/DetailsBlock';
import CourseHeader from '../../organisms/CourseHeader';

import styles from './CourseDetailsPage.module.scss';
import usePresenter from './CourseDetailsPage.presenter';
import getProps from './CourseDetailsPage.props';
import { CourseDetailsPageCombinedProps } from './types';
import LoadingSpinner from '../../atoms/LoadingSpinner';


const CourseDetailsPage: React.FC<CourseDetailsPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    courseHeader,
    detailsBlock,
    isLoading,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.courseDetailsPage;

  const detailsBlockView = isLoading ? (
    <LoadingSpinner
      className={cx(styles.loadingSpinner)}
      size='Huge'
      styleType='Primary'
      isActive
    />
  ) : (
    <DetailsBlock
      className={cx(styles.detailsBlock, classes?.detailsBlock)}
      {...internalProps.detailsBlock}
      {...detailsBlock}/>
  );

  return (
    <div className={cx(currentStyle, className)}>
      <CourseHeader
        className={cx(styles.courseHeader, classes?.courseHeader)}
        {...internalProps.courseHeader}
        {...courseHeader}/>
      <div className={cx(styles.content, classes?.content)}>
        { detailsBlockView }
      </div>
    </div>
  );
};

export default CourseDetailsPage;

