import { ButtonProps } from '../Button';
import { TextProps } from '../Text';
import { TextInputHtmlTypeEnum } from './types';


type TextInputInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
  htmlType?: TextInputHtmlTypeEnum;
};

type TextInputPropsValues = {
  'PasswordErrorL': TextInputInternalProps;
  'PasswordNoneL': TextInputInternalProps;
  'TextErrorL': TextInputInternalProps;
  'TextNoneL': TextInputInternalProps;
};

const propValues: TextInputPropsValues = {
  'PasswordErrorL': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    htmlType: 'password',
  },
  'PasswordNoneL': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body1',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    htmlType: 'password',
  },
  'TextErrorL': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    htmlType: 'text',
  },
  'TextNoneL': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Subdued',
      type: 'Body1',
    },
    htmlType: 'text',
  },
};

const getProps = (type: string): TextInputInternalProps => {
  const values: TextInputInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
