import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import {
  AUTH_ROUTES,
  KORU_PRIVACY_POLICY_URL,
  KORU_TERMS_OF_USE_URL,
  MAX_EMAIL_LENGTH,
  MAX_NAME_LENGTH,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  NOTIFICATION_DURATION,
  SIGNUP_STEPS,
} from '../../../lib/constants';
import { decodeInviteToken } from '../../../lib/token';
import { useNotification } from '../../../modules/notification';
import { JourneyStatusEnum } from '../../../modules/teacher/types';
import { createTeacherUseCase, getTeacherRegistrationStatusUseCase } from './SignupBlock.interactor';
import { preprocessFirstAndLastName, isEmptyString, isExpiryDatePassed, getRegistrationStepRoute } from '../../../lib/utils';
import { SignupBlockCombinedProps, SignUpState } from './types';
import { AuthContext } from '../../../modules/auth';
import { UserContext } from '../../../modules/user/UserContext';
import { KORU_DASHBOARD_URL } from '../../../lib/config';
import { getSessionCookie } from '../../../modules/auth/utils';

const formInitialState: SignUpState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const getPrivacyPolicyAndTermOfUseLinkTrans = (): React.ReactNode => {
  return (
    <Trans
      i18nKey="signUp.legalText"
      components={{
        privacyLink: (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            href={KORU_PRIVACY_POLICY_URL}
            rel="noreferrer"
            target='_blank'
            style={{ textDecoration: 'none', color: '#276EF1' }}
          />
        ),
        termsOfUseLink: (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            href={KORU_TERMS_OF_USE_URL}
            rel="noreferrer"
            target='_blank'
            style={{ textDecoration: 'none', color: '#276EF1' }}
          />
        ),
      }}
    />
  );
};

const usePresenter = (props: SignupBlockCombinedProps): SignupBlockCombinedProps => {
  const { account, refetchAccount } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const inviteToken = query.get('token');
  const { trigger } = useNotification();

  const [formState, setFormState] = useState<SignUpState>(formInitialState);
  const [formErrorState, setFormErrorState] = useState<SignUpState>(formInitialState);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  const [enableRegistrationStatusFetching, setEnableRegistrationStatusFetching] = useState<boolean>(true);

  const { data: teacherRegistrationStatus } = useQuery(['getTeacherRegistrationStatus', inviteToken], () => {
    if (inviteToken) {
      return getTeacherRegistrationStatusUseCase(inviteToken);
    }
  }, { enabled: enableRegistrationStatusFetching });
  const { mutateAsync: createTeacher } = useMutation('createTeacher', async () => {
    const data = await createTeacherUseCase({
      firstName: formState.firstName,
      lastName: formState.lastName,
      email: formState.email,
      password: formState.password,
      journeyStatus: JourneyStatusEnum.account_created,
      inviteToken,
    });
    return data;
  });

  if (inviteToken) {
    const payload = decodeInviteToken(inviteToken);
    if (payload) {
      if (isExpiryDatePassed(payload.exp)) {
        history.replace(AUTH_ROUTES.expiredInvitation);
      }
      formState.email = payload.email;
    } else {
      // TODO: handle invalid payload
    }
  }

  // user is authenticated and his journey status is in-training
  // should be redirected to koru dashboard
  if (account && user && user.content.journeyStatus === JourneyStatusEnum.in_training) {
    window.location.assign(KORU_DASHBOARD_URL);
  }

  useEffect(() => {
    if (teacherRegistrationStatus) {
      const nextStepRoute = getRegistrationStepRoute(teacherRegistrationStatus);
      if (nextStepRoute) {
        // when teacher's journey status is in-training and clicks on invitation link
        if (nextStepRoute === AUTH_ROUTES.alreadyRegistered) {
          history.replace(nextStepRoute);
          return;
        }
        if (account) {
          history.replace(AUTH_ROUTES.accountCreated, { nextStepRoute });
          return;
        }

        history.replace(AUTH_ROUTES.signIn);
      }
    }
  }, [teacherRegistrationStatus, account, history]);

  const handleTextChange = (field: keyof SignUpState) => {
    return (value: string) => {
      setFormState({
        ...formState,
        [field]: value,
      });

      setFormErrorState({
        ...formErrorState,
        [field]: '',
      });
    };
  };

  const isFormValid = (): boolean => {
    const invalidFormErrors = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    };

    const isFirstNameEmpty = isEmptyString(formState.firstName);
    const isLastNameEmpty = isEmptyString(formState.lastName);
    const isPasswordEmpty = isEmptyString(formState.password);
    const isConfirmPasswordEmpty = isEmptyString(formState.confirmPassword);

    if (isFirstNameEmpty) {
      invalidFormErrors.firstName = t('signUp.errors.missingFirstName');
    }
    if (isLastNameEmpty) {
      invalidFormErrors.lastName = t('signUp.errors.missingLastName');
    }
    if (isPasswordEmpty) {
      invalidFormErrors.password = t('signUp.errors.missingPassword');
    } else {
      if (formState.password.length < MIN_PASSWORD_LENGTH) {
        invalidFormErrors.password = t('signUp.errors.weakPassword');
        invalidFormErrors.confirmPassword = t('signUp.errors.weakPassword');
      }
    }
    if (isConfirmPasswordEmpty) {
      invalidFormErrors.confirmPassword = t('signUp.errors.missingConfirmPassword');
    }
    if (formState.password !== formState.confirmPassword) {
      invalidFormErrors.password = t('signUp.errors.passwordsMismatch');
      invalidFormErrors.confirmPassword = t('signUp.errors.passwordsMismatch');
    }
    setFormErrorState(invalidFormErrors);
    return (
      Object.values(invalidFormErrors).filter((errorMessage: string) => !!errorMessage)
        .length === 0
    );
  };

  const handleSignUp = async () => {
    setIsLoading(true);
    try {
      if (isFormValid()) {
        const newTeacher = await createTeacher();
        if (newTeacher) {
          setEnableRegistrationStatusFetching(false);
          trigger({
            duration: NOTIFICATION_DURATION,
            message: t('notification.account_created'),
            type: 'Success',
          });
          refetchAccount();
          const token = getSessionCookie();
          window.location.assign(`${KORU_DASHBOARD_URL}/auto-signup?token=${token}&target=${window.location.origin + AUTH_ROUTES.completeProfile}`);
        }
      }
    } catch (e) {
      // no-op
      // should display notification with error message to help user navigate the error
    } finally {
      setIsLoading(false);
    }
  };

  return {
    ...props,
    stepperText: {
      value: t('stepperText', {
        currentStep: 1,
        totalSteps: SIGNUP_STEPS,
      }),
    },
    textGroup: {
      topText: {
        value: t('signUp.title'),
      },
      bottomText: {
        value: t('signUp.bottomText'),
      },
    },
    inputFieldGroup: {
      inputFieldOne: {
        state: formErrorState.firstName ? 'Error' : 'Default',
        text: {
          value: t('signUp.labels.firstName'),
        },
        textInput: {
          maxLength: MAX_NAME_LENGTH,
          textValue: formState.firstName,
          onTextChanged: handleTextChange('firstName'),
          preProcessTextInput: preprocessFirstAndLastName,
        },
        contextualContent: {
          text: {
            value: formErrorState.firstName,
          },
        },
      },
      inputFieldTwo: {
        state: formErrorState.lastName ? 'Error' : 'Default',
        text: {
          value: t('signUp.labels.lastName'),
        },
        textInput: {
          maxLength: MAX_NAME_LENGTH,
          textValue: formState.lastName,
          onTextChanged: handleTextChange('lastName'),
          preProcessTextInput: preprocessFirstAndLastName,
        },
        contextualContent: {
          text: {
            value: formErrorState.lastName,
          },
        },
      },
    },
    emailInputField: {
      state: 'Help',
      text: {
        value: t('signUp.labels.email'),
      },
      textInput: {
        maxLength: MAX_EMAIL_LENGTH,
        textValue: formState.email,
        onTextChanged: handleTextChange('email'),
        disabled: formState.email !== '',
      },
      contextualContent: {
        type: 'Help',
        text: {
          value: t('signUp.hintText'),
        },
      },
    },
    passwordInputField: {
      state: formErrorState.password ? 'Error' : 'Default',
      text: {
        value: t('signUp.labels.password'),
      },
      textInput: {
        maxLength: MAX_PASSWORD_LENGTH,
        button: {
          icon: {
            asset: showPassword ? 'Hide' : 'Show',
          },
          onClick: () => {
            setShowPassword(!showPassword);
          },
        },
        type: 'Password',
        textValue: formState.password,
        onTextChanged: handleTextChange('password'),
        htmlType: showPassword ? 'text' : 'password',
      },
      contextualContent: {
        text: {
          value: formErrorState.password,
        },
      },
    },
    confirmPasswordInputField: {
      state: formErrorState.confirmPassword ? 'Error' : 'Default',
      text: {
        value: t('signUp.labels.confirmPassword'),
      },
      textInput: {
        maxLength: MAX_PASSWORD_LENGTH,
        button: {
          icon: {
            asset: showConfirmPassword ? 'Hide' : 'Show',
          },
          onClick: () => {
            setShowConfirmPassword(!showConfirmPassword);
          },
        },
        type: 'Password',
        textValue: formState.confirmPassword,
        onTextChanged: handleTextChange('confirmPassword'),
        htmlType: showConfirmPassword ? 'text' : 'password',
      },
      contextualContent: {
        text: {
          value: formErrorState.confirmPassword,
        },
      },
    },
    legalText: {
      value: getPrivacyPolicyAndTermOfUseLinkTrans(),
    },
    button: {
      icon: {
        asset: 'ArrowRight',
      },
      text: {
        value: t('signUp.createAccountButton'),
      },
      onClick: handleSignUp,
      loading: isLoading ? 'Loading' : 'Default',
      disabled: isLoading,
    },
  };
};

export default usePresenter;
