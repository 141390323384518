import React from 'react';
import cx from 'classnames';

import LogEntryItem from '../../molecules/LogEntryItem';

import styles from './LogEntriesList.module.scss';
import usePresenter from './LogEntriesList.presenter';
import getProps from './LogEntriesList.props';
import { LogEntriesListCombinedProps } from './types';


const LogEntriesList: React.FC<LogEntriesListCombinedProps> = (props) => {
  const {
    className,
    classes,
    logEntryItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.logEntriesList;

  const logEntryItemViews = logEntryItems?.map((logEntryItem, index) => (
    <LogEntryItem
      key={index}
      className={cx(styles.logEntryItem, classes?.logEntryItem)}
      {...internalProps.logEntryItem}
      {...logEntryItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {logEntryItemViews}
    </div>
  );
};

export default LogEntriesList;

