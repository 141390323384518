import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import Address from '../../molecules/Address';
import PriceListItem from '../../molecules/PriceListItem';
import TextGroup from '../../molecules/TextGroup';

import styles from './StudentAppFeeBlock.module.scss';
import usePresenter from './StudentAppFeeBlock.presenter';
import getProps from './StudentAppFeeBlock.props';
import { StudentAppFeeBlockCombinedProps } from './types';
import CardInfo from '../../molecules/CardInfo';
import InlineNotification from '../../atoms/InlineNotification';


const StudentAppFeeBlock: React.FC<StudentAppFeeBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    stepperText,
    textGroup,
    priceListItem,
    cardInfoField,
    address,
    button,
    inlineNotification,
    showNotification,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.studentAppFeeBlock;

  const inlineNotificationView = showNotification ? 
  <InlineNotification 
    className={cx(styles.inlineNotification, classes?.inlineNotification)}
    {...internalProps.inlineNotification}
    {...inlineNotification}
  /> : undefined;

  return (
    <div className={cx(currentStyle, className)}>
      { inlineNotificationView }
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.stepperText, classes?.stepperText)}
          {...internalProps.stepperText}
          {...stepperText}/>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <PriceListItem
          className={cx(styles.priceListItem, classes?.priceListItem)}
          {...internalProps.priceListItem}
          {...priceListItem}/>
        <CardInfo
          className={cx(styles.cardInfoField, classes?.cardInfoField)}
          {...internalProps.cardInfoField}
          {...cardInfoField}/>
        <Address
          className={cx(styles.address, classes?.address)}
          {...internalProps.address}
          {...address}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
    </div>
  );
};

export default StudentAppFeeBlock;

