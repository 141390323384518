import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import TextIconGroup from '../../molecules/TextIconGroup';
import TextIconGroupList from '../TextIconGroupList';

import styles from './ReminderEmailsSection.module.scss';
import usePresenter from './ReminderEmailsSection.presenter';
import getProps from './ReminderEmailsSection.props';
import { ReminderEmailsSectionCombinedProps } from './types';


const ReminderEmailsSection: React.FC<ReminderEmailsSectionCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    divider,
    textIconGroupList,
    divider1,
    textIconGroup,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.reminderEmailsSection;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.header, classes?.header)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <TextIconGroupList
        className={cx(styles.textIconGroupList, classes?.textIconGroupList)}
        {...internalProps.textIconGroupList}
        {...textIconGroupList}/>
      <Divider
        className={cx(styles.divider1, classes?.divider1)}
        {...internalProps.divider1}
        {...divider1}/>
      <TextIconGroup
        className={cx(styles.textIconGroup, classes?.textIconGroup)}
        {...internalProps.textIconGroup}
        {...textIconGroup}/>
    </div>
  );
};

export default ReminderEmailsSection;

