import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

import styles from './ClassFormCard.module.scss';
import usePresenter from './ClassFormCard.presenter';
import getProps from './ClassFormCard.props';
import { ClassFormCardCombinedProps } from './types';
import TextAreaField from '../TextAreaField';
import DateTimePickerField from '../DateTimePicker';


const ClassFormCard: React.FC<ClassFormCardCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    text,
    button,
    dateInputField,
    startTimeInputField,
    endTimeInputField,
    locationInputField,
    zoomLinkInputField,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`classFormCard${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Both':
    case 'type3': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
          <div className={cx(styles.textFieldGroup, classes?.textFieldGroup)}>
            <DateTimePickerField
              className={cx(styles.dateInputField, classes?.dateInputField)}
              {...internalProps.dateInputField}
              {...dateInputField}
              />
            <DateTimePickerField
              className={cx(styles.startTimeInputField, classes?.startTimeInputField)}
              {...internalProps.startTimeInputField}
              {...startTimeInputField}/>
            <DateTimePickerField
              className={cx(styles.endTimeInputField, classes?.endTimeInputField)}
              {...internalProps.endTimeInputField}
              {...endTimeInputField}/>
          </div>
          <TextAreaField
            className={cx(styles.locationInputField, classes?.locationInputField)}
            {...internalProps.locationInputField}
            {...locationInputField}/>
          <TextAreaField
            className={cx(styles.zoomLinkInputField, classes?.zoomLinkInputField)}
            {...internalProps.zoomLinkInputField}
            {...zoomLinkInputField}/>
        </div>
      );
      break;
    }
    case 'InPerson': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
          <div className={cx(styles.textFieldGroup, classes?.textFieldGroup)}>
            <DateTimePickerField
              className={cx(styles.dateInputField, classes?.dateInputField)}
              {...internalProps.dateInputField}
              {...dateInputField}/>
            <DateTimePickerField
              className={cx(styles.startTimeInputField, classes?.startTimeInputField)}
              {...internalProps.startTimeInputField}
              {...startTimeInputField}/>
            <DateTimePickerField
              className={cx(styles.endTimeInputField, classes?.endTimeInputField)}
              {...internalProps.endTimeInputField}
              {...endTimeInputField}/>
          </div>
          <TextAreaField
            className={cx(styles.locationInputField, classes?.locationInputField)}
            {...internalProps.locationInputField}
            {...locationInputField}/>
        </div>
      );
      break;
    }
    case 'Online': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
          <div className={cx(styles.textFieldGroup, classes?.textFieldGroup)}>
            <DateTimePickerField
              className={cx(styles.dateInputField, classes?.dateInputField)}
              {...internalProps.dateInputField}
              {...dateInputField}/>
            <DateTimePickerField
              className={cx(styles.startTimeInputField, classes?.startTimeInputField)}
              {...internalProps.startTimeInputField}
              {...startTimeInputField}/>
            <DateTimePickerField
              className={cx(styles.endTimeInputField, classes?.endTimeInputField)}
              {...internalProps.endTimeInputField}
              {...endTimeInputField}/>
          </div>
          <TextAreaField
            className={cx(styles.zoomLinkInputField, classes?.zoomLinkInputField)}
            {...internalProps.zoomLinkInputField}
            {...zoomLinkInputField}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ClassFormCard;

