import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';


type ActionSectionInternalProps = {
  topText?: TextProps;
  bottomText?: TextProps;
  button?: ButtonProps;
};

type ActionSectionPropsValues = {
  '': ActionSectionInternalProps;
};

const propValues: ActionSectionPropsValues = {
  '': {
    topText: {
      style: 'Bold',
      align: 'Center',
      colour: 'Default',
      type: 'Heading3',
    },
    bottomText: {
      style: 'Regular',
      align: 'Center',
      colour: 'Default',
      type: 'Body1',
    },
    button: {
      style: 'Outline',
      size: 'Large',
      type: 'Text',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
  },
};

const getProps = (type: string): ActionSectionInternalProps => {
  const values: ActionSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
