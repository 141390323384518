import React from 'react';
import cx from 'classnames';

import LogEntriesAccordianItem from '../LogEntriesAccordianItem';

import styles from './LogEntriesAccordianList.module.scss';
import usePresenter from './LogEntriesAccordianList.presenter';
import getProps from './LogEntriesAccordianList.props';
import { LogEntriesAccordianListCombinedProps } from './types';


const LogEntriesAccordianList: React.FC<LogEntriesAccordianListCombinedProps> = (props) => {
  const {
    className,
    classes,
    logEntriesAccordianItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.logEntriesAccordianList;

  const logEntriesAccordianItemViews = logEntriesAccordianItems?.map((logEntriesAccordianItem, index) => (
    <LogEntriesAccordianItem
      key={index}
      className={cx(styles.logEntriesAccordianItem, classes?.logEntriesAccordianItem)}
      {...internalProps.logEntriesAccordianItem}
      {...logEntriesAccordianItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {logEntriesAccordianItemViews}
    </div>
  );
};

export default LogEntriesAccordianList;

