import React from 'react';
import DropdownWrapper, { ExportedDropdownProps } from '../../../modules/common/DropdownWrapper';
import ContextualMenu from '../../organisms/ContextualMenu';
import { ContextualMenuCombinedProps } from '../../organisms/ContextualMenu/types';
import InternalIconDropDown from './IconDropDown';
import {
  IconDropDownProps,
  IconDropDownCombinedProps,
} from './types';

export type { IconDropDownProps };

export type IconDropDownValueProps = ExportedDropdownProps<
IconDropDownCombinedProps,
ContextualMenuCombinedProps
>;

const IconDropDown: React.FC<IconDropDownValueProps> = ({ id, toggleProps, menuProps, alignEnd }) => {

  return (
    <DropdownWrapper
      id={id}
      Toggle={InternalIconDropDown}
      Menu={ContextualMenu}
      toggleProps={toggleProps}
      menuProps={menuProps}
      alignEnd={alignEnd}
    />
  );
};

export default IconDropDown;
