import { ButtonProps } from '../../atoms/Button';
import { InputFieldProps } from '../../molecules/InputField';
import { TextGroupProps } from '../../molecules/TextGroup';


type ForgotPasswordBlockInternalProps = {
  button?: ButtonProps;
  textGroup?: TextGroupProps;
  emailField?: InputFieldProps;
  button1?: ButtonProps;
};

type ForgotPasswordBlockPropsValues = {
  '': ForgotPasswordBlockInternalProps;
};

const propValues: ForgotPasswordBlockPropsValues = {
  '': {
    button: {
      style: 'Text',
      size: 'Small',
      type: 'TextIcon',
      colour: 'Base',
      loading: 'Default', // MANUAL OVERRIDE
    },
    textGroup: {
    },
    emailField: {
      state: 'Default',
    },
    button1: {
      style: 'Contained',
      size: 'Large',
      type: 'TextIcon',
      colour: 'PrimaryAction',
      loading: 'Default', // MANUAL OVERRIDE
    },
  },
};

const getProps = (type: string): ForgotPasswordBlockInternalProps => {
  const values: ForgotPasswordBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
