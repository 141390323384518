import { DataEntry } from '../../../lib/types';
import { getCourseRoster, joinWaitlist, removeStudent, updateCourse, enrollStudentInCourse } from '../../../modules/course/api';
import { Course, Roster, UpdateCoursePayload } from '../../../modules/course/types';

export const updateCourseUseCase = async (
  courseId: number, payload: UpdateCoursePayload,
): Promise<DataEntry<Course>> => {
  return updateCourse(courseId, payload);
};

export const getCourseRosterUseCase = async (courseId: number): Promise<DataEntry<Roster>[]> => {
  return getCourseRoster(courseId);
};

export const removeStudentUseCase = async (courseId: number, email: string): Promise<DataEntry<Roster>> => {
  return removeStudent(courseId, email);
};

export const waitlistStudentUseCase = async (courseId: number, email: string): Promise<DataEntry<Roster>> => {
  return joinWaitlist(courseId, email);
};

export const enrollStudentUseCase = async (courseId: number, email: string): Promise<DataEntry<Roster>> => {
  return enrollStudentInCourse(courseId, undefined, email);
};

