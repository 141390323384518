import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../../lib/reactUtils';
import NoSpotAvailable from '../../../resources/images/NoSpotAvailable.png';

import styles from './NoSpotAvailablePage.module.scss';
import { NoSpotAvailablePageCombinedProps } from './types';

const usePresenter = (props: NoSpotAvailablePageCombinedProps): NoSpotAvailablePageCombinedProps => {
  const { t } = useTranslation();
  return {
    ...props,
    genericStateNoActionBlock: {
      classes: {
        image: styles.image,
      },
      image: {
        imageSrc: NoSpotAvailable,
      },
      textGroup: {
        classes: {
          bottomText: styles.bottomText,
          topText: styles.topText,
        },
        topText: {
          classes: {
            value: styles.textValue,
          },
          value: t('no_spot_available.title'),
        },
        bottomText: {
          classes: {
            value: styles.textValue,
          },
          value: getTranslation('no_spot_available.description'),
        },
      },
    },
  };
};

export default usePresenter;
