import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';
import { KoruLogoProps } from '../../atoms/KoruLogo';
import { TextProps } from '../../atoms/Text';
import { UserProfileProps } from '../../molecules/UserProfile';


type HeaderInternalProps = {
  koruLogo?: KoruLogoProps;
  divider?: DividerProps;
  text?: TextProps;
  icon?: IconProps;
  userProfile?: UserProfileProps;
};

type HeaderPropsValues = {
  'Default': HeaderInternalProps;
  'Search': HeaderInternalProps;
  'SearchIcon': HeaderInternalProps;
  'WithUserProfile': HeaderInternalProps;
};

const propValues: HeaderPropsValues = {
  'Default': {
    koruLogo: {
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
  'Search': {
    text: {
      style: 'Regular',
      align: 'Left',
      colour: 'Disabled',
      type: 'Body1',
    },
    icon: {
      asset: 'Close',
      colour: 'Base',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
  'SearchIcon': {
    koruLogo: {
    },
    icon: {
      asset: 'SearchIcon',
      colour: 'Base',
    },
    userProfile: {
      size: 'Medium',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
  'WithUserProfile': {
    koruLogo: {
    },
    userProfile: {
      size: 'Medium',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
  },
};

const getProps = (type: string): HeaderInternalProps => {
  const values: HeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
