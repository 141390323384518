import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { STUDENT_ROUTES } from '../../../lib/constants';
import { CourseStateType, RegistrationFlowEnum } from '../../../modules/course/types';

import { StudentKoruAccountBlockCombinedProps } from './types';

const usePresenter = (props: StudentKoruAccountBlockCombinedProps): StudentKoruAccountBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { registerFlow } = props;
  const [createNewAccount, setCreateNewAccount] = useState<boolean>(true);
  const { state: courseState } = useLocation<CourseStateType>();

  const goToNextStep = () => {
    const nextStep = createNewAccount ? 
      STUDENT_ROUTES.auth.signUp : STUDENT_ROUTES.auth.signIn;
    history.push(nextStep, { ...courseState });
  };

  return {
    ...props,
    textGroup: {
      topText: {
        value: t('student_koru_account.header'),
      },
      bottomText: {
        value: registerFlow === RegistrationFlowEnum.register ? 
          t('student_koru_account.description') : t('student_koru_account.description_join_waitlist'),
      },
    },
    radioItemList: {
      radioItems: [
        {
          text: {
            value: t('student_koru_account.radio_options.create_new_koru_account'),
          },
          state: createNewAccount ? 'Checked' : 'Unchecked',
          onClick: () => setCreateNewAccount(true),
        },
        {
          text: {
            value: t('student_koru_account.radio_options.login'),
          },
          state: !createNewAccount ? 'Checked' : 'Unchecked',
          onClick: () => setCreateNewAccount(false),
        },
      ],
    },
    button: {
      text: {
        value: t('student_koru_account.button.next'),
      },
      icon: {
        asset: 'ArrowRight',
      },
      onClick: goToNextStep,
    },
  };
};

export default usePresenter;
