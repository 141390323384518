import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { InputFieldProps } from '../InputField';
import { TextAreaFieldValueProps, TextAreaFieldProps } from '../TextAreaField';


type ClassFormCardInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
  dateInputField?: InputFieldProps;
  startTimeInputField?: InputFieldProps;
  endTimeInputField?: InputFieldProps;
  locationInputField?: TextAreaFieldValueProps & TextAreaFieldProps;
  zoomLinkInputField?: TextAreaFieldValueProps & TextAreaFieldProps;
};

type ClassFormCardPropsValues = {
  'Both': ClassFormCardInternalProps;
  'InPerson': ClassFormCardInternalProps;
  'Online': ClassFormCardInternalProps;
  'type3': ClassFormCardInternalProps;
};

const propValues: ClassFormCardPropsValues = {
  'Both': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading2',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'PrimaryAction',
      loading: 'Default',
    },
    dateInputField: {
      state: 'Default',
    },
    startTimeInputField: {
      state: 'Default',
    },
    endTimeInputField: {
      state: 'Default',
    },
    locationInputField: {
      state: 'Default',
    },
    zoomLinkInputField: {
      state: 'Default',
    },
  },
  'InPerson': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading2',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'PrimaryAction',
    },
    dateInputField: {
      state: 'Default',
    },
    startTimeInputField: {
      state: 'Default',
    },
    endTimeInputField: {
      state: 'Default',
    },
    locationInputField: {
      state: 'Default',
    },
  },
  'Online': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading2',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'PrimaryAction',
    },
    dateInputField: {
      state: 'Default',
    },
    startTimeInputField: {
      state: 'Default',
    },
    endTimeInputField: {
      state: 'Default',
    },
    zoomLinkInputField: {
      state: 'Default',
    },
  },
  'type3': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading2',
    },
    button: {
      style: 'Text',
      size: 'Small',
      type: 'Icon',
      colour: 'PrimaryAction',
    },
    dateInputField: {
      state: 'Default',
    },
    startTimeInputField: {
      state: 'Default',
    },
    endTimeInputField: {
      state: 'Default',
    },
    locationInputField: {
      state: 'Default',
    },
    zoomLinkInputField: {
      state: 'Default',
    },
  },
};

const getProps = (type: string): ClassFormCardInternalProps => {
  const values: ClassFormCardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
