import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import ChipField from '../ChipField';

import styles from './AddCoTeacher.module.scss';
import usePresenter from './AddCoTeacher.presenter';
import getProps from './AddCoTeacher.props';
import { AddCoTeacherCombinedProps } from './types';


const AddCoTeacher: React.FC<AddCoTeacherCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    button,
    chipField, // MANUAL_OVERRIDE
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`addCoTeacher${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <ChipField // MANUAL_OVERRIDE
              className={cx(styles.chipField, classes?.chipField)}
              {...internalProps.chipField}
              {...chipField}
            />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default AddCoTeacher;

