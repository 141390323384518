import { zonedTimeToUtc } from 'date-fns-tz';
import { CreateClassPayload } from '../../../modules/class/types';
import { ClassCardForm } from './types';

export const DEFAULT_TIMEZONE = 'America/New_York';

export const getUserTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || DEFAULT_TIMEZONE;
};

export const convertClassToClassPayload = (formClass: ClassCardForm): CreateClassPayload => {
  const { date, start, end, location, link } = formClass;
  const userTimeZone = getUserTimezone();
  const utcDate = zonedTimeToUtc(new Date(date), userTimeZone);
  const utcStartTime = zonedTimeToUtc(new Date(start), userTimeZone);
  utcDate.setHours(utcStartTime.getHours());
  utcDate.setMinutes(utcStartTime.getMinutes());
  utcDate.setSeconds(utcStartTime.getSeconds());
  utcDate.setMilliseconds(utcStartTime.getMilliseconds());

  return {
    date: utcDate,
    startTime: utcStartTime,
    endTime: zonedTimeToUtc(new Date(end), userTimeZone),
    location: location,
    classLink: link,
  };
};
