import { TextProps } from '../../atoms/Text';
import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';


type AppFeeDetailsSectionInternalProps = {
  text?: TextProps;
  divider?: DividerProps;
  decriptionText?: TextProps;
  labelIcon?: IconProps;
  infoText?: TextProps;
};

type AppFeeDetailsSectionPropsValues = {
  '': AppFeeDetailsSectionInternalProps;
};

const propValues: AppFeeDetailsSectionPropsValues = {
  '': {
    text: {
      style: 'Bold',
      align: 'Left',
      colour: 'Default',
      type: 'Heading3',
    },
    divider: {
      type: 'Horizontal',
      style: 'Solid',
      colour: 'BorderBase',
    },
    decriptionText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
    labelIcon: {
      asset: 'InfoFilled',
      colour: 'Base',
    },
    infoText: {
      style: 'Regular',
      align: 'Left',
      colour: 'Default',
      type: 'Body1',
    },
  },
};

const getProps = (type: string): AppFeeDetailsSectionInternalProps => {
  const values: AppFeeDetailsSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
