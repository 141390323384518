import axiosInstance from '../../lib/api/axios';
import { User, UserResponse } from '../../lib/token';
import { getServerUrl } from '../../lib/utils';
import { BFF_URL } from '../../lib/config';

export const getUser = async (fetchStudent?: boolean): Promise<User> => {
  const query = fetchStudent ? `?fetchStudent=${fetchStudent}` : '';
  const { data } = await axiosInstance.get<UserResponse>(`${BFF_URL}/users/me${query}`);
  const { profile: profileString, ...user } = data.content;
  const profile = profileString ? JSON.parse(profileString) : undefined;

  return {
    ...data,
    content: {
      ...user,
      profile,
    },
  };
};

export const redirectToLogin = (options?: { readonly returnUrl: string }): void => {

  let returnUrl: string = options?.returnUrl ?? window.location.href;

  // Check if URL is relative
  if (returnUrl.startsWith('/')) {
    // See https://developer.mozilla.org/en-US/docs/Web/API/Location/origin
    returnUrl = `${window.location.origin}${returnUrl}`;
  }

  window.location.assign(`${getServerUrl()}/auth/login?target_uri=${returnUrl}`);
};
