import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import TextGroup from '../../molecules/TextGroup';
import RadioItemList from '../../organisms/RadioItemList';

import styles from './StudentKoruAccountBlock.module.scss';
import usePresenter from './StudentKoruAccountBlock.presenter';
import getProps from './StudentKoruAccountBlock.props';
import { StudentKoruAccountBlockCombinedProps } from './types';


const StudentKoruAccountBlock: React.FC<StudentKoruAccountBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    stepperText,
    textGroup,
    radioItemList,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.studentKoruAccountBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Text
          className={cx(styles.stepperText, classes?.stepperText)}
          {...internalProps.stepperText}
          {...stepperText}/>
        <TextGroup
          className={cx(styles.textGroup, classes?.textGroup)}
          {...internalProps.textGroup}
          {...textGroup}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <RadioItemList
          className={cx(styles.radioItemList, classes?.radioItemList)}
          {...internalProps.radioItemList}
          {...radioItemList}/>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}/>
      </div>
    </div>
  );
};

export default StudentKoruAccountBlock;

