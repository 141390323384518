import React from 'react';
import cx from 'classnames';

import RadioItem from '../../atoms/RadioItem';

import styles from './RadioItemList.module.scss';
import usePresenter from './RadioItemList.presenter';
import getProps from './RadioItemList.props';
import { RadioItemListCombinedProps } from './types';


const RadioItemList: React.FC<RadioItemListCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    radioItems,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`radioItemList${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Horizontal':
    case 'Vertical': {
      const radioItemViews = radioItems?.map((radioItem, index) => (
        <RadioItem
          key={index}
          className={cx(styles.radioItem, classes?.radioItem)}
          {...internalProps.radioItem}
          {...radioItem} />
      )) || [];

      componentView = (
        <div className={cx(currentStyle, className)}>
          {radioItemViews}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default RadioItemList;
